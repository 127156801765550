import SegicComponent from '@/mixins/segic-component';
export default {
    name: 'SDialogDisplay',
    mixins: [SegicComponent],
    props: {
        showDialog: {
            required: true,
            default: false,
        },
        title: {},
        component: {
            type: Object,
        },
        width: {
            default: '1200px',
        },
        name: {},
    },
    methods: {
        toggleDialog() {
            this.$emit('close:dialog', false);
        },
    },
    i18n: {
        messages: {
            fr: {
                labels: {
                    subTitle: 'Historique',
                },
            },
            en: {
                labels: {
                    subTitle: 'History',
                },
            },
        },
    },
};

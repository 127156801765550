import i18n from '@/plugins/vue-i18n';
import { isNaNGlobal } from 'segic_shared_front_end_utils/src/utils/types';
export function fileMaxSizeValidate(files, { maxFileSize }) {
    const maxSizeInBytes = parseInt(maxFileSize, 10) * 1024 * 1024;
    if (isNaNGlobal(maxSizeInBytes)) {
        console.warn('maxSize is not a number', maxSizeInBytes);
        return true;
    }
    let totalSize = 0;
    files.forEach((file) => {
        totalSize += file.size;
    });
    if (totalSize >= maxSizeInBytes) {
        const validationKey = files.length > 1 ? 'filesMaxSize' : 'fileMaxSize';
        return i18n.t(`validation.${validationKey}`, {
            maxFileSize,
        });
    }
    return true;
}

export default {
    name: 'searchFilter',
    data() {
        return {
            search: '',
        };
    },
    methods: {
        updateSearch(val) {
            this.$emit('update:search', val);
        },
    },
};

import ComponentMixins from '@/mixins/segic-component';
// CONFIRM CANCEL
import DialogConfirmCancelMixin from '@/page-layouts/administrator/dialogs/confirm-cancel';
import EVENTS from 'segic_shared_front_end_utils/src/constants/events';
import SPageLayoutsParticipantsAddParticipantByFile from './byFile/dialog.vue';
import SPageLayoutsParticipantAddParticipantDialog from './byForm/dialog.vue';
export default {
    name: 'SPageLayoutsParticipantAddParticipant',
    components: {
        SPageLayoutsParticipantsAddParticipantByFile,
        SPageLayoutsParticipantAddParticipantDialog,
    },
    mixins: [ComponentMixins, DialogConfirmCancelMixin],
    data() {
        return {
            EVENTS,
            showDialog: false,
            showDialogAddParticipantForm: false,
        };
    },
    methods: {
        toggleDialog(show, dialogName) {
            this[dialogName] = show;
        },
        addParticipantSuccess() {
            this.$emit('SUCCESS');
        },
    },
    i18n: {
        messages: {
            fr: {
                or: 'ou',
                completeAnOnlineForm: 'Remplir une demande en ligne',
                sendAnEnrollmentForm: 'envoyer un formulaire d\'adhésion',
            },
            en: {
                or: 'or',
                completeAnOnlineForm: 'Complete an online form',
                sendAnEnrollmentForm: 'send an enrollment form',
            },
        },
    },
};

const HTTP_CODES = {
    STOPPED: 0,
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    MODIFIED: 205,
    INFO: 206,
    NO_CHANGE: 207,
    MOVED_PERMANENTLY: 301,
    MOVED_TEMPORARILY: 302,
    NOT_MODIFIED: 304,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    NOT_ACCEPTABLE: 406,
    ARGUMENTS_MISSING: 411,
    VALIDATION_FAILED: 412,
    UNSUPPORTED_MEDIA_TYPE: 415,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
};
export default HTTP_CODES;

import { getEmptyComponents } from '@/utils/page-layouts';
import Logo from '@/components/ui/logo.vue';
const routes = [];
const children = [];
const components = getEmptyComponents();
components.default = () => import(/* webpackChunkName: "errors" */ './index.vue');
routes.push({
    name: 'accessDenied',
    path: '/access-denied',
    components,
    children,
    meta: {
        headerComponents: {
            left: {
                component: Logo,
            },
        },
    },
});
export default routes;

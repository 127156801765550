<template>
  <ValidationProvider
    :key="id"
    ref="mappingCode"
    v-slot="{ errors }"
    :rules="rules"
    :validation-name="`${id}_mappingCode`"
    class="s-w-100"
    tag="div"
  >
    <v-text-field
      :class="classComputer"
      :clearable="false"
      :disabled="disabled"
      :error-messages="errors"
      :name="name"
      :outlined="true"
      :readonly="readonly"
      :s-e2e="nameE2E"
      :single-line="singleLine"
      :value="model"
      class="s-select"
      dense
      @blur="customBlur"
      @focus="focus"
      @input="update"
      @keyup.enter="keyupEnter"
    >
      <template
        v-if="hasWarning && !model"
        #append
      >
        <v-icon
          class="mr-2 mt-1"
          color="warning"
          size="18"
        >
          warning
        </v-icon>
      </template>
    </v-text-field>
  </ValidationProvider>
</template>
<script>
import SFieldText from '@/components/form/fields/text.vue';
import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
import SLabelForm from '@/components/ui/label-form.vue';
import FieldsMixin from '@/mixins/components/fields';
import ComponentMixins from '@/mixins/segic-component';
import ChangeHighLight from '@/mixins/components/highlight';

export default {
  name: 'SPageLayoutsAdministratorFormsPayrollFilesConversionsMappingCode',
  components: {
    SLabelForm,
    SFieldText,
  },
  mixins: [
    FieldsMixin,
    ComponentMixins,
    ChangeHighLight,
  ],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    hasWarning: {
      type: Boolean,
      default: false,
    },
    id: {
      required: true,
      type: [String, Number],
    },
    model: {
      default: '',
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
  },
  computed: {
    classComputer() {
      let classes = this.classField;

      if (this.backgroundColor) {
        classes += 'inputPadding';
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.classesOutput.push(classes);

      return this.classesOutput;
    },
  },
  methods: {
    async customBlur(event) {
      this.blur(event);
      const validation = await this.validationOnUpdate();

      if (validation.valid) {
        if (this.maskOnBlur && this.mask) {
          this.$emit('update:model', { value: this.model, id: this.id });
        }
      }
    },
    focus(e) {
      e.target.select();

      this.$emit('focus', e);
    },
    keyupEnter(event) {
      this.$emit('keyupEnter', event);
    },
    update(value) {
      this.$emit('update:model', { value, id: this.id });
    },
  },
  i18n,
};
</script>

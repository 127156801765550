import { forEach, isUndefined } from 'lodash';
import { isArray, isNotNullOrUndefined, isNullOrUndefined, isNumber, isObject, } from 'segic_shared_front_end_utils/src/utils/types';
export function setChangesObject(defaultData, formHasChangedValues) {
    formHasChangedValues.hasChanged = false;
    forEach(defaultData, (value, key) => {
        if (typeof value === 'object' && value !== null) {
            formHasChangedValues[key] = {};
            formHasChangedValues[key] = setChangesObject(defaultData[key], formHasChangedValues[key]);
            return;
        }
        formHasChangedValues[key] = false;
    });
    return formHasChangedValues;
}
export function emptyInitialArray(data) {
    forEach(data, (value, key) => {
        if (isArray(value)) {
            data[key] = [];
        }
    });
    return data;
}
// Disable eslint rule, the function has side effects
/* eslint-disable no-param-reassign */
export function checkHasDifferences(defaultData, formData, initialFormData, formHasChangedValues) {
    let hasDifferent = false;
    formHasChangedValues.hasChanged = false;
    const blockedKeys = ['EffectiveDate', 'EventsEffectiveDates'];
    const isArrayDefaultData = isArray(defaultData);
    const isArrayFormData = isArray(formData);
    // eslint-disable-next-line consistent-return
    forEach(formData, (formValue, key) => {
        let isDifferent = false;
        // block these to keys of validations
        if (blockedKeys.indexOf(key) !== -1) {
            return true;
        }
        let defaultValue;
        if (defaultData) {
            if (isArrayFormData && isArrayDefaultData) {
                [defaultValue] = defaultData;
            }
            else if (!isUndefined(defaultData[key])) {
                defaultValue = defaultData[key];
            }
        }
        if (defaultValue === undefined) {
            return true;
        }
        let initialFormValue = '';
        if (initialFormData && isNotNullOrUndefined(initialFormData[key])) {
            initialFormValue = initialFormData[key];
        }
        if (isNullOrUndefined(formValue)) {
            formValue = defaultValue;
        }
        const isObjectBoolean = isObject(formValue);
        const isArrayBoolean = isArray(formValue);
        const isArrayInitialBoolean = isArray(initialFormValue);
        if (!isArrayBoolean && !isObjectBoolean) {
            if (isNullOrUndefined(formValue)) {
                formValue = '';
            }
            if (isNumber(initialFormValue)) {
                initialFormValue = parseFloat(initialFormValue);
            }
            if (isNumber(formValue)) {
                formValue = parseFloat(formValue);
            }
            isDifferent = Boolean(initialFormValue !== formValue);
            formHasChangedValues[key] = isDifferent;
            if (isDifferent) {
                formHasChangedValues.hasChanged = true;
                hasDifferent = true;
            }
        }
        if (isObjectBoolean || isArrayBoolean) {
            if (!formHasChangedValues[key]) {
                formHasChangedValues[key] = {};
            }
        }
        if (isArrayBoolean) {
            if (!isArrayInitialBoolean) {
                formHasChangedValues.hasChanged = true;
                hasDifferent = true;
                return true;
            }
            if (formValue.length === 0) {
                isDifferent = (initialFormValue.length !== formValue.length);
                if (isDifferent) {
                    formHasChangedValues.hasChanged = true;
                    hasDifferent = true;
                }
                return true;
            }
            if (initialFormValue.length !== formValue.length) { // [1, 2], [1]
                formHasChangedValues.hasChanged = true;
                hasDifferent = true;
                return true;
            }
            const isObjectArray = (isObject(formValue[0]));
            const isObjectArrayDefault = (isObject(defaultValue[0]));
            if (isObjectArray) { // { id: 1 }
                if (!isObjectArrayDefault) {
                    return true;
                }
                isDifferent = checkHasDifferences(defaultValue, formValue, initialFormValue, formHasChangedValues[key]);
                if (isDifferent) {
                    formHasChangedValues.hasChanged = true;
                    hasDifferent = true;
                }
            }
            else { // 1
                isDifferent = checkHasDifferences([''], formValue, initialFormValue, formHasChangedValues[key]);
                if (isDifferent) {
                    formHasChangedValues.hasChanged = true;
                    hasDifferent = true;
                }
            }
        }
        if (isObjectBoolean) {
            if (initialFormValue === '') {
                formHasChangedValues.hasChanged = true;
                hasDifferent = true;
                return true;
            }
            isDifferent = checkHasDifferences(defaultValue, formValue, initialFormValue, formHasChangedValues[key]);
            if (isDifferent) {
                formHasChangedValues.hasChanged = true;
                hasDifferent = true;
            }
        }
    });
    return hasDifferent;
}
/* eslint-enable no-param-reassign */

export default {
    data() {
        return {
            showDialogConfirmCancel: false,
        };
    },
    methods: {
        toggleConfirmCloseSave(show) {
            this.showDialogConfirmCancel = show;
        },
        cancelSavingForm() {
            this.$refs.form.closeDialog();
            this.$refs.form.$refs.component.resetForm();
            this.toggleConfirmCloseSave(false);
        },
    },
};

import ChangeHighLight from '@/mixins/components/highlight';
import SLabelForm from '@/components/ui/label-form.vue';
import FieldsMixin from '@/mixins/components/fields';
import ComponentMixins from '@/mixins/segic-component';
import { Mask, Masker, MASKS, TOKENS, } from '@/utils/mask/index';
/**
     * @displayName Textfield
     * */
export default {
    name: 'SFieldText',
    components: { SLabelForm },
    directives: { Mask },
    mixins: [
        FieldsMixin,
        ComponentMixins,
        ChangeHighLight,
    ],
    props: {
        /**
                 * Icon displayed in after of text
                 */
        appendIcon: {
            type: String,
        },
        /**
                 * Hint shown under textfield.
                 */
        hint: {
            type: String,
        },
        /**
                 * Flat
                 */
        flat: {
            default: false,
        },
        /**
                 * Mask
                 */
        mask: {
            default: null,
        },
        /**
                 * Mask on blur
                 */
        maskOnBlur: {
            default: true,
        },
        /**
                 * Adds border to give it vuetify outlined style
                 */
        outlined: {
            type: Boolean,
            default: false,
        },
        /**
                 * Hint is always visible.
                 */
        persistentHint: {
            type: Boolean,
        },
        /**
                 * Placeholder shown in textfield.
                 */
        placeholder: {
            type: String,
            default: '',
        },
        /**
                 * Prefix added before textfield.
                 */
        prefixText: {
            type: String,
        },
        /**
                 * Icon displayed in front of text
                 */
        prependIcon: {
            type: String,
        },
        /**
                 * Single lined
                 */
        singleLine: {
            default: false,
        },
        /**
                 * Solo
                 */
        solo: {
            default: false,
        },
        /**
                 * Suffix added after textfield.
                 */
        suffixText: {
            type: String,
        },
        backgroundColor: {
            type: String,
            default: '',
        },
        value: {
            default: null,
        },
    },
    computed: {
        maskObject() {
            if (!this.maskOnBlur && this.mask) {
                return {
                    mask: this.mask,
                    tokens: TOKENS,
                };
            }
            return null;
        },
        rulesFormated() {
            let { rules } = this;
            if (this.mask && this.mask.indexOf(MASKS.POSTAL_CODE) !== -1) {
                const postalCodeRules = 'postalCode';
                rules += rules.length === 0 ? postalCodeRules : `|${postalCodeRules}`;
            }
            if (this.mask && this.mask.indexOf(MASKS.PHONE) !== -1) {
                const phoneRules = 'phone';
                rules += rules.length === 0 ? phoneRules : `|${phoneRules}`;
            }
            if (rules.length !== 0 && rules[0] === '|') {
                rules = rules.substring(1);
            }
            return rules;
        },
        maxlengthFormated() {
            let { maxlength } = this;
            if (this.mask && this.mask.indexOf(MASKS.POSTAL_CODE) !== -1) {
                maxlength = MASKS.POSTAL_CODE.length;
            }
            if (this.mask && this.mask.indexOf(MASKS.PHONE) !== -1) {
                maxlength = MASKS.PHONE.length;
            }
            return maxlength;
        },
        placeholderFormattedTextField() {
            let placeholder = this.placeholderFormatted;
            if (this.mask && this.mask.indexOf(MASKS.POSTAL_CODE) !== -1) {
                placeholder = '___ ___';
            }
            if (this.mask && this.mask.indexOf(MASKS.PHONE) !== -1) {
                placeholder = '(___) ___-____';
            }
            return placeholder;
        },
        classComputer() {
            let classes = this.classField;
            if (this.backgroundColor) {
                classes += 'inputPadding';
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.classesOutput.push(classes);
            return this.classesOutput;
        },
    },
    methods: {
        /**
                 * When the user click inside of the textfield.
                 * @event click
                 * @param {Object} e event object
                 * @public
                 */
        clickStopPropagation(e) {
            if (this.stopPropagation) {
                e.stopPropagation();
            }
            this.$emit('click');
        },
        /**
                 * When press enter event is send.
                 * @event keyup
                 * @param {Object} e event object
                 * @public
                 */
        keyupEnter(event) {
            this.$emit('keyupEnter', event);
        },
        clickPrepend(event) {
            this.$emit('click:prepend', event);
        },
        focus(e) {
            e.target.select();
            this.$emit('focus', e);
        },
        async customBlur(event) {
            this.blur(event);
            const validation = await this.validationOnUpdate();
            if (validation.valid) {
                if (this.maskOnBlur && this.mask) {
                    const value = Masker(this.model, this.mask, true, TOKENS);
                    this.update(value);
                }
            }
        },
    },
};

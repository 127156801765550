export default {
    messages: {
        fr: {
            'dialog.header0': 'Systèmes de paie',
            'dialog.header1': 'Garanties & services',
            'dialog.header2': 'Avantages imposables',
            'dialog.header3': 'Numéros de certificat',
            actionRequired: 'Action requise',
            checkToIncludeTheBenefitInThePayrollSystemFile: 'Cocher pour inclure la garantie au fichier du système de paie',
            companyNumber: 'Numéro de compagnie',
            configure: 'Configurer',
            currentSupportedFormats: 'Nethris, Employeur D',
            displayUnmappedParticipants: 'Afficher seulement les numéros manquants',
            downloadTheTemplateIncludingTheParticipantsOfYourRegime: 'Téléchargez le gabarit contenant les participants de votre régime',
            earningNumber: 'Numéro de la déduction',
            employeeNumber: 'Numéro d\'employé',
            fillTheEmployeeNumberColumnWithTheMatchingData: 'Remplissez la colonne "Numéro d\'employé" avec les données correspondantes',
            gainNumber: 'Numéro du gain',
            import: 'Importer',
            importNumbersFromFile: 'Importer les numéros d\'employé à partir d\'un fichier',
            later: 'Plus tard',
            payrollFile: 'Fichier de déductions',
            payrollSystem: 'Système de paie',
            payrollSystemConversion: 'Configurer vos systèmes de paie',
            selectIfYourPayrollSystemIsntListedOrToDisableThisFilesPayrollSystemConfiguration: 'Choisir cette option si votre système de paie n\'est pas listé ou pour désactiver la configuration du système de paie pour ce fichier.',
            subtitle: 'Associez chaque code et numéro à ceux de votre système de paie pour activer le téléchargement de vos fichiers de déductions au format approprié.',
            supportedFormats: 'Formats supportés :',
            taxableBenefit: 'Avantage imposable',
            templateToFillForThisGroup: 'Gabarit à remplir pour l\'importation',
            toEnableDownloadingInYourPayrollSystemsFormatsMapMissingCodesAndNumbers: 'Pour activer le téléchargement aux formats de vos systèmes de paie, associez les codes et numéros manquants.',
            uploadTheFileAndClickOnValidateFile: 'Téléversez ce fichier et cliquez sur "Valider le fichier"',
            useSegicCertificateNumbers: 'Utiliser les mêmes numéros',
            useSegicCertificateNumbersTooltip: 'Activez cette option si les numéros de certificats correspondent exactement aux numéros d\'employé de vos systèmes de paie.',
            youCanNowDownloadYourPayrollFilesInFormats: 'Vous pouvez désormais télécharger vos fichiers de déductions aux formats Nethris et Employeur D',
        },
        en: {
            'dialog.header0': 'Payroll systems',
            'dialog.header1': 'Benefits & services',
            'dialog.header2': 'Taxable benefits',
            'dialog.header3': 'Certificate numbers',
            actionRequired: 'Action required',
            checkToIncludeTheBenefitInThePayrollSystemFile: 'Check to include the benefit in the payroll system file',
            companyNumber: 'Company number for your group',
            configure: 'Configure',
            currentSupportedFormats: 'Nethris, Employer D',
            displayUnmappedParticipants: 'Show only missing numbers',
            downloadTheTemplateIncludingTheParticipantsOfYourRegime: 'Download the template containing your plan members',
            earningNumber: 'Deduction code',
            employeeNumber: 'Employee number',
            fillTheEmployeeNumberColumnWithTheMatchingData: 'Fill in the "Employee Number" column with the corresponding data',
            gainNumber: 'Earning code',
            import: 'Import',
            importNumbersFromFile: 'Import employee numbers from a file\n',
            later: 'Later',
            payrollFile: 'Deduction file',
            payrollSystem: 'Payroll system',
            payrollSystemConversion: 'Configure your payroll systems',
            selectIfYourPayrollSystemIsntListedOrToDisableThisFilesPayrollSystemConfiguration: 'Select if your payroll system isn\'t listed or to disable this file\'s payroll system configuration.',
            subtitle: 'Map each code and number to those in your payroll system to enable downloading your deduction files in the appropriate format.',
            supportedFormats: 'Supported formats:',
            taxableBenefit: 'Taxable benefit',
            templateToFillForThisGroup: 'Template to be filled out for import',
            toEnableDownloadingInYourPayrollSystemsFormatsMapMissingCodesAndNumbers: 'To enable downloading in your payroll systems formats, map missing codes and numbers.',
            uploadTheFileAndClickOnValidateFile: 'Upload this file and click "Validate File"',
            useSegicCertificateNumbers: 'Use the same numbers',
            useSegicCertificateNumbersTooltip: 'Activate this option if the certificate numbers exactly match the employee numbers in your payroll systems.',
            youCanNowDownloadYourPayrollFilesInFormats: 'You can now download your deduction files in Nethris and Employer D formats',
        },
    },
};

import StoreBasics from '@/store/basics';
import storeUtils from '@/store/utils';
import { filter } from 'lodash';
import entities from './entities';
import Service from './service';
const cacheDelay = 0;
const storeData = [
    {
        key: 'data',
        defaultValue: [],
        methods: [
            {
                key: 'get',
                params: ['clientGuid', 'groupGuid'],
                cacheDelay,
                inputFormatting(state, stateKey, { insuredPlanMembers }) {
                    let newData = insuredPlanMembers.map((insuredPlanMember) => entities.input(insuredPlanMember));
                    newData = filter(newData, (item) => item.participant.category && item.participant.division);
                    state[stateKey].value = newData;
                },
            },
        ],
        service: Service,
    },
];
const state = storeUtils.mergeStates(StoreBasics.statesFromData(storeData));
const getters = storeUtils.mergeGetters(StoreBasics.gettersFromData(storeData), {
    // eslint-disable-next-line no-shadow
    getPlanMembersSelect(state) {
        return state.data.value.map((insuredPlanMember) => ({
            certificateNumber: insuredPlanMember.participant.certificateNumber,
            description: `${insuredPlanMember.participant.certificateNumber}, ${insuredPlanMember.firstName} ${insuredPlanMember.lastName}`,
            id: insuredPlanMember.participant.id,
            firstName: insuredPlanMember.firstName,
            lastName: insuredPlanMember.lastName,
        }));
    },
});
const mutations = storeUtils.mergeMutations(StoreBasics.mutationsFromData(storeData));
const actions = storeUtils.mergeActions(StoreBasics.actionsFromData(storeData));
const PlanMembersStore = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default PlanMembersStore;

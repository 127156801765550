import SCategoriesField from '@/components/form/fields/categories-select.vue';
import SFieldCheckbox from '@/components/form/fields/checkbox.vue';
import SFieldDatepicker from '@/components/form/fields/datepicker.vue';
import SDivisionsField from '@/components/form/fields/divisions-select.vue';
import SOccupationField from '@/components/form/fields/occupation-select.vue';
import SFieldSelect from '@/components/form/fields/select.vue';
import SFieldTextMoney from '@/components/form/fields/textfield-money.vue';
import SLabelForm from '@/components/ui/label-form.vue';
import FormSimpleMixin from '@/mixins/form-simple';
import ComponentMixins from '@/mixins/segic-component';
import { participantStatusSelectData } from 'segic_shared_front_end_utils/src/data-dump/participant-status';
import { provincesSelectData } from 'segic_shared_front_end_utils/src/data-dump/provinces';
import { systemProtectionsGetValues } from 'segic_shared_front_end_utils/src/enums-bitwise/system-protections';
import { getMessageContentTypeValuePerCode, MESSAGE_CONTENT_TYPE_CONSTANT } from 'segic_shared_front_end_utils/src/enums/message-content-type';
import StepperFormMixin from './mixins';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantFormSectionsEmploymentInformation',
    components: {
        SFieldSelect,
        SFieldDatepicker,
        SFieldCheckbox,
        SOccupationField,
        SFieldTextMoney,
        SDivisionsField,
        SCategoriesField,
        SLabelForm,
    },
    mixins: [
        ComponentMixins,
        FormSimpleMixin,
        StepperFormMixin,
    ],
    data() {
        return {
            recipients: [],
            MESSAGE_CONTENT_TYPE_CONSTANT,
        };
    },
    mounted() {
        this.emitCurrentEnabledNextBtn();
    },
    methods: {
        getMessageContentTypeValuePerCode,
        participantStatusSelectData,
        provincesSelectData,
        emitCurrentEnabledNextBtn() {
            this.emitEnabledNextBtn(this.stepIndex, Boolean(this.overwriteData?.id));
        },
        setRecipients(recipients) {
            this.recipients = recipients;
        },
        slotTooltip() {
            return {
                iconPlacement: 'prepend',
                iconName: 'help',
                iconClass: '',
                tipPlacement: 'bottom',
            };
        },
        systemProtectionsGetValues,
    },
    i18n: {
        messages: {
            fr: {
                tooltips: {
                    exemption: 'Les participants exemptés doivent avoir une couverture Santé et Dentaire alternative. Confirmez avant de cocher la case.',
                    insuranceDate: 'Permet d\'imposer la date d\'admissibilité pour ce participant au regime. En renseignant cette date, le système ignorera la logique basée sur la date d\'embauche et la période d\'admissibilité.',
                    removeWaitingPeriod: 'Cocher pour activer immédiatement les garanties, sans appliquer le délai d\'attente qui pourrait être configuré dans le régime.',
                },
            },
            en: {
                tooltips: {
                    exemption: 'Exempt plan members must have alternative Health and Dental coverage. Confirm before checking the box.',
                    insuranceDate: 'Allows you to impose the eligibility date for this plan member. By entering a date, the system will ignore the logic based on the hiring date and the waiting period.',
                    removeWaitingPeriod: 'Check to activate benefits immediately, without applying the waiting period that might be configured in the group.',
                },
            },
        },
    },
};

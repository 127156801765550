import AccessDenied from '@/page-layouts/access-denied/router';
import Administrator from '@/page-layouts/administrator/router';
import Billing from '@/page-layouts/billing/router';
import Dashboard from '@/page-layouts/dashboard/router';
import Documents from '@/page-layouts/administrator/documents/router';
import Groups from '@/page-layouts/groups/router';
import ManageAccount from '@/page-layouts/manage-account/router';
import Errors from '@/page-layouts/not-found/router';
let children = [];
children = children.concat(AccessDenied);
children = children.concat(Administrator);
children = children.concat(Billing);
children = children.concat(Dashboard);
children = children.concat(Documents);
children = children.concat(Groups);
children = children.concat(ManageAccount);
children = children.concat(Errors);
const routes = children;
export default routes;

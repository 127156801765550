export default function validateSIN(value) {
    // Removing spaces and dashes
    const sin = value.replace(/[ -]*/g, '');
    // Check if SIN is 9 digits long
    if (sin.length !== 9) {
        return false;
    }
    if (sin === '000000000') {
        return false;
    }
    const checkDigit = sin.charAt(8); // Last digit of SIN
    // Compute the check digit
    let sum = 0;
    for (let i = 0; i < 8; i += 1) {
        let digit = Number(sin.charAt(i));
        // Double every second digit starting from right
        // then subtract 9 if the result is 10 or higher
        if (i % 2 !== 0) {
            digit *= 2;
            digit = digit > 9 ? digit - 9 : digit;
        }
        sum += digit;
    }
    // Computed check digit should be subtracted from 10 and modulo 10
    const computedCheckDigit = (10 - (sum % 10)) % 10;
    // Compare computed check digit with the last digit of SIN
    return computedCheckDigit === Number(checkDigit);
}

import { render, staticRenderFns } from "./btn-history-dialog.vue?vue&type=template&id=535d11fa&"
import script from "./btn-history-dialog.vue?vue&type=script&lang=ts&"
export * from "./btn-history-dialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import { i18n } from '@/utils/data-formatting/display/i18n';
import { forEach, sortBy, deburr } from 'lodash';
import I18n from '@/plugins/vue-i18n';
const Provinces = {
    'dcc3d495-fe31-45b1-bd19-1d8200b407b4': {
        Description: "[{ 'Code': 1, 'Value': 'Nouveau-Brunswick' }, { 'Code': 2, 'Value': 'New Brunswick' }]",
        CountryId: '621A08FA-4768-E811-81D3-000D3AF46B5D',
        Code: 'NB',
    },
    'b5020fc7-bc1d-415d-8832-2631c74baeab': {
        Description: "[{ 'Code': 1, 'Value': 'Territoires du Nord-Ouest' }, { 'Code': 2, 'Value': 'Northwest Territories' }]",
        CountryId: '621A08FA-4768-E811-81D3-000D3AF46B5D',
        Code: 'NT',
    },
    '89953413-81d5-46c5-b7ff-3753f6fdb6da': {
        Description: "[{ 'Code': 1, 'Value': 'Ontario' }, { 'Code': 2, 'Value': 'Ontario' }]",
        CountryId: '621A08FA-4768-E811-81D3-000D3AF46B5D',
        Code: 'ON',
    },
    '6d74f234-8834-4c69-9213-38454b7cb231': {
        Description: "[{ 'Code': 1, 'Value': 'Colombie-Britannique' }, { 'Code': 2, 'Value': 'British Columbia' }]",
        CountryId: '621A08FA-4768-E811-81D3-000D3AF46B5D',
        Code: 'BC',
    },
    '7c9898c9-f383-4dca-b1a8-44aaa3069317': {
        Description: "[{ 'Code': 1, 'Value': 'Île du Prince Édouard' }, { 'Code': 2, 'Value': 'Prince Edward Island' }]",
        CountryId: '621A08FA-4768-E811-81D3-000D3AF46B5D',
        Code: 'PE',
    },
    '142ac700-e7a5-45c2-b4a6-7f0c8e393cb2': {
        Description: "[{ 'Code': 1, 'Value': 'Terre-Neuve-et-Labrador' }, { 'Code': 2, 'Value': 'Newfoundland and Labrador' }]",
        CountryId: '621A08FA-4768-E811-81D3-000D3AF46B5D',
        Code: 'NL',
    },
    'db5f6aac-31ef-4664-a831-8357c69fea74': {
        Description: "[{ 'Code': 1, 'Value': 'Nouvelle-Écosse' }, { 'Code': 2, 'Value': 'Nova Scotia' }]",
        CountryId: '621A08FA-4768-E811-81D3-000D3AF46B5D',
        Code: 'NS',
    },
    'a69c6255-fdc5-451e-8589-877553d44d8f': {
        Description: "[{ 'Code': 1, 'Value': 'Saskatchewan' }, { 'Code': 2, 'Value': 'Saskatchewan' }]",
        CountryId: '621A08FA-4768-E811-81D3-000D3AF46B5D',
        Code: 'SK',
    },
    '8457023c-a07e-4a9a-9573-abe6aa478372': {
        Description: "[{ 'Code': 1, 'Value': 'Nunavut' }, { 'Code': 2, 'Value': 'Nunavut' }]",
        CountryId: '621A08FA-4768-E811-81D3-000D3AF46B5D',
        Code: 'NU',
    },
    'a6a7d66a-2a99-4407-a08f-b8bbb397cdd3': {
        Description: "[{ 'Code': 1, 'Value': 'Québec' }, { 'Code': 2, 'Value': 'Quebec' }]",
        CountryId: '621A08FA-4768-E811-81D3-000D3AF46B5D',
        Code: 'QC',
    },
    '7bd5ee23-5a1f-4680-a1bb-bb8646c53c8f': {
        Description: "[{ 'Code': 1, 'Value': 'Yukon' }, { 'Code': 2, 'Value': 'Yukon' }]",
        CountryId: '621A08FA-4768-E811-81D3-000D3AF46B5D',
        Code: 'YT',
    },
    '2bf9bfc9-ffff-4bf6-8726-dd71db546490': {
        Description: "[{ 'Code': 1, 'Value': 'Alberta' }, { 'Code': 2, 'Value': 'Alberta' }]",
        CountryId: '621A08FA-4768-E811-81D3-000D3AF46B5D',
        Code: 'AB',
    },
    '6db13b90-ec96-43ab-9d59-e3745f537419': {
        Description: "[{ 'Code': 1, 'Value': 'Manitoba' }, { 'Code': 2, 'Value': 'Manitoba' }]",
        CountryId: '621A08FA-4768-E811-81D3-000D3AF46B5D',
        Code: 'MB',
    },
};
export function provincesSelectData() {
    const provinces = [];
    forEach(Provinces, (item, key) => {
        provinces.push({
            id: key,
            description: i18n(item.Description)[I18n.locale],
        });
    });
    return sortBy(provinces, (province) => deburr(province.description));
}
export function getProvinceDescription(provinceId) {
    if (!provinceId) {
        return '';
    }
    if (Provinces[provinceId] && Provinces[provinceId].Description) {
        return i18n(Provinces[provinceId].Description)[I18n.locale];
    }
    console.warn(`status id ${provinceId}`);
    return '';
}
export function getProvinceCode(provinceId) {
    let code = '';
    if (provinceId) {
        code = Provinces[provinceId].Code;
    }
    return code;
}
export default Provinces;

import { forEach } from 'lodash';
import moment from 'moment';
import { NULL_DATE_TIME } from '../../../constants/null';
import SETTINGS from '../../../constants/settings';
import { getI18nDictionnary } from '../../i18n';
const messages = {
    en: {
        format: {
            date: {
                viewLong: 'MMMM Do YYYY',
                viewLongNoYear: 'MMMM Do',
            },
            dateTime: {
                view: 'MMMM Do YYYY, h:mm:ss a',
            },
        },
    },
    fr: {
        format: {
            date: {
                viewLong: 'Do MMMM YYYY',
                viewLongNoYear: 'Do MMMM',
            },
            dateTime: {
                view: 'Do MMMM YYYY, HH:mm:ss',
            },
        },
    },
};
/* Possible type: unix, moment */
export function date(dateTime, type) {
    const dictionary = getI18nDictionnary(messages, SETTINGS.LANGUAGE);
    if (!dateTime) {
        return undefined;
    }
    function getFormattedDate(dateTime, type) {
        moment.locale(SETTINGS.LANGUAGE);
        let returnData = moment(dateTime);
        if (!type) {
            returnData = returnData.format('YYYY-MM-DD');
        }
        else if (type === 'unix') {
            returnData = returnData.valueOf();
        }
        else if (type === 'viewLong') {
            returnData = returnData.format(dictionary.t('format.date.viewLong'));
        }
        else {
            returnData = returnData.format(dictionary.t('format.date.viewLongNoYear'));
        }
        return (dateTime !== NULL_DATE_TIME) ? returnData : null;
    }
    let returnData;
    if (typeof dateTime === 'object' && dateTime.length) {
        returnData = [];
        forEach(dateTime, (dateTime) => {
            returnData.push(getFormattedDate(dateTime, type));
        });
    }
    else {
        returnData = getFormattedDate(dateTime, type);
    }
    return returnData;
}
/* Possible type: unix, moment */
export function time(dateTime, type) {
    const dictionary = getI18nDictionnary(messages, SETTINGS.LANGUAGE);
    if (!dateTime) {
        return undefined;
    }
    function getFormattedTime(dateTime, type) {
        let returnData = moment(dateTime);
        if (!type) {
            returnData = returnData.format(dictionary.t('format.dateTime.view'));
        }
        else if (type === 'unix') {
            returnData = returnData.valueOf();
        }
        return (dateTime !== NULL_DATE_TIME) ? returnData : null;
    }
    let returnData;
    if (typeof dateTime === 'object' && dateTime.length) {
        returnData = [];
        forEach(dateTime, (dateTime) => {
            returnData.push(getFormattedTime(dateTime, type));
        });
    }
    else {
        returnData = getFormattedTime(dateTime, type);
    }
    return returnData;
}
/* Possible type: unix, moment */
export function dateTime(dateTime, type) {
    const dictionary = getI18nDictionnary(messages, SETTINGS.LANGUAGE);
    if (!dateTime) {
        return undefined;
    }
    function getFormattedDateTime(dateTime, type) {
        let returnData;
        if (typeof dateTime === 'string') {
            if (dateTime.indexOf('T') !== -1 && dateTime.indexOf('Z') !== -1) {
                returnData = moment(`${dateTime}`).utc().local();
            }
            else if (dateTime.indexOf('T') !== -1) {
                returnData = moment(`${dateTime}Z`).utc().local();
            }
        }
        else {
            returnData = moment(dateTime);
        }
        if (!type) {
            returnData = returnData.format(dictionary.t('format.dateTime.view'));
        }
        else if (type === 'unix') {
            returnData = returnData.valueOf();
        }
        return (dateTime !== `${NULL_DATE_TIME}Z`) ? returnData : null;
    }
    let returnData;
    if (typeof dateTime === 'object' && dateTime.length) {
        returnData = [];
        forEach(dateTime, (dateTime) => {
            returnData.push(getFormattedDateTime(dateTime, type));
        });
    }
    else {
        returnData = getFormattedDateTime(dateTime, type);
    }
    return returnData;
}

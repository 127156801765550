import MicroServices from '@/configs/MicroServices.types';
import proxy from '@/store/proxy';
import { getQueryString, replaceStringRoutingData } from 'segic_shared_front_end_utils/src/utils/routing';
import messages from './locales';
export default {
    namespace: 'changeRequests',
    uri: 'clients/:{clientGuid}/groups/:{groupGuid}/changerequests/',
    get(clientGuid, groupGuid, data, query) {
        const methodName = 'get';
        const service = MicroServices.MESSAGING;
        const params = {
            clientGuid,
            groupGuid,
        };
        const url = `${this.uri}search/`;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
            data,
        };
        return proxy.post(replacedUrl, data, requestData);
    },
    create(clientGuid, groupGuid, data, query) {
        const methodName = 'create';
        const service = MicroServices.MESSAGING;
        const params = {
            clientGuid,
            groupGuid,
        };
        const url = this.uri;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
            data,
        };
        return proxy.post(replacedUrl, data, requestData);
    },
    delete(clientGuid, groupGuid, requestGuid, query) {
        const methodName = 'delete';
        const service = MicroServices.MESSAGING;
        const params = {
            clientGuid,
            groupGuid,
            requestGuid,
        };
        const url = `${this.uri}:{requestGuid}/`;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
            data: null,
        };
        return proxy.delete(replacedUrl, null, requestData);
    },
    update(clientGuid, groupGuid, requestGuid, data, query) {
        const methodName = 'update';
        const service = MicroServices.MESSAGING;
        const params = {
            clientGuid,
            groupGuid,
            requestGuid,
        };
        const url = `${this.uri}:{requestGuid}/`;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
            data,
        };
        return proxy.put(replacedUrl, data, requestData);
    },
};

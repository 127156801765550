import I18n from '@/plugins/vue-i18n';
import moment from 'moment';
export function getToday() {
    return moment().format(I18n.t('format.date.viewShort').toString());
}
export function isAfterToday(referenceDate) {
    return moment().isSameOrBefore(getMomentInstance(referenceDate));
}
export function isBeforeOrToday(referenceDate) {
    return moment().isAfter(getMomentInstance(referenceDate));
}
export function getMomentInstance(referenceDate) {
    return moment(referenceDate, I18n.t('format.date.viewShort').toString());
}
export function today(format) {
    return moment().format(format);
}
export function tomorrow(format) {
    return moment().add(1, 'days').format(format);
}
export function isDateXYearsAgo(date, years) {
    const momentDate = moment(date);
    return moment().subtract(years, 'years').isAfter(momentDate);
}
export function isDateLessThanXYearsAgo(date, years) {
    const momentDate = moment(date);
    return moment().subtract(years, 'years').isBefore(momentDate);
}
export function nowMinusYears(years) {
    return moment().subtract(years, 'years').format('YYYY-MM-DD');
}
;

import { setQueryParamsObject } from '@/utils/helpers';
export default {
    props: {
        appendLabel: {},
        addQuery: {
            default: false,
        },
        /**
             * Clearable
             */
        clearable: {
            type: Boolean,
            default: false,
        },
        /**
             * Dense
             */
        dense: {
            type: Boolean,
            default: true,
        },
        /**
             * Is the input disabled.
             */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
             * Component is loading.
             */
        isLoading: {
            type: Boolean,
            default: false,
        },
        isStrike: {
            default: false,
        },
        /**
             * Label shown in field.
             */
        label: {
            type: String,
            default: '',
        },
        labelOnTop: {
            default: false,
            type: Boolean,
        },
        maxlength: {},
        /**
             * Value of the field
             *
             */
        model: {
            required: true,
        },
        /**
             * Name of the field.
             */
        name: {
            type: String,
            required: true,
        },
        /**
             * Is the input a readonly.
             */
        readonly: {
            type: Boolean,
            default: false,
        },
        /**
             *  Validation rules passed to component
             */
        rules: {
            type: [Object, String],
            default: '',
        },
        /**
             * Hide details and label of field.
             */
        singleLine: {
            type: Boolean,
            default: false,
        },
        /**
             * Text shown in validation error message.
             */
        validationName: {
            type: String,
        },
        hideDetails: {
            default: false,
            type: Boolean,
        },
        placeholder: {
            default: null,
            type: String,
        },
    },
    computed: {
        placeholderFormatted() {
            let { placeholder } = this;
            if (this.outlined && !this.placeholder) {
                placeholder = ' ';
            }
            return placeholder;
        },
        classField() {
            let classes = '';
            if (this.isStrike) {
                classes += 'text-decoration-line-through';
            }
            return classes;
        },
        validationProviderName() {
            return this.validationName ? this.validationName : this.label;
        },
    },
    methods: {
        /**
             * Gets called when the user update the textfield.
             * @event update:model
             * @param {String} value Text updated
             * @public
             */
        update(value) {
            this.$emit('update:model', value, this.name);
        },
        /**
             * Gets called when the user update the textfield.
             * @param {String} value Text updated
             * @public
             */
        blur(event) {
            if (this.addQuery) {
                setQueryParamsObject(this.name, event.target.value);
            }
            this.$emit('blur');
        },
        async validationOnUpdate() {
            const validation = await this.$refs[this.name].validate();
            if (!this.$refs[this.name]) {
                return;
            }
            const validationUpdateData = {
                name: this.name,
                flags: this.$refs[this.name].flags,
                model: this.model,
                validationProviderName: this.validationProviderName,
            };
            this.emitValidationOnUpdate(validationUpdateData);
            return validation;
        },
        emitValidationOnUpdate(data) {
            this.$emit('validationOnUpdate', data);
        },
    },
};

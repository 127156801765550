export default {
    active: 'Active',
    add: 'Add',
    addANewSpouse: 'Add a new spouse',
    addChild: 'Add child',
    addNewPlanMember: 'Add new plan member',
    address: 'Address',
    age: 'Age',
    allDivisions: 'All divisions',
    annualSalary: 'Annual salary',
    at: 'à',
    bankAccount: 'Bank account',
    benefit: 'Benefit',
    benefitOrService: 'Benefit or service',
    benefits: 'Benefits',
    benefitsAndServices: 'Benefits & services',
    birthDate: 'Birth date',
    bornOnMan: 'Born on',
    bornOnWoman: 'Born on',
    bulkSalaryUpdate: 'Bulk salary update',
    cancel: 'Cancel',
    carrier: 'Carrier',
    cellPhone: 'Cellphone',
    certificate: 'Certificate',
    changeRequests: 'Change requests',
    changeTheSchoolAttendanceConfirmationEndDate: 'Change the school attendance confirmation end date',
    child: 'Child',
    children: 'Children',
    civilStatus: 'Civil status',
    class: 'Class',
    clientPortal: 'Client portal',
    close: 'Close',
    communicationLanguage: 'Communication language',
    confirm: 'Confirm',
    contactEmail: 'Contact email',
    coverageEffectiveDate: 'Coverage effective date',
    covered: 'Covered',
    coveredPeople: 'Covered people',
    createReport: 'Create report',
    current: 'Current',
    date: 'Date',
    dateFrom: 'Date from',
    dateOnWhichTheChangeIsToTakeEffect: 'Date on which the change is to take effect',
    dateTo: 'Date to',
    delete: 'Delete',
    deleted: 'Deleted',
    dental: 'Dental',
    dependents: 'Dependents',
    description: 'Description',
    discardChanges: 'Discard changes',
    division: 'Division',
    downloadInvoice: 'Download invoice',
    downloadPayrollDeductionsFile: 'Download payroll deductions file',
    effective: 'Effective',
    effectiveDate: 'Effective date',
    effectiveOn: 'Effective on',
    end: 'End',
    exemption: 'Exemption',
    existing: 'Existing',
    extension: 'Extension',
    fictive: 'Fictive',
    files: 'Files',
    filter: 'Filter',
    filterBy: 'Filter',
    filters: 'Filters',
    firstName: 'First name',
    firstNationsStatus: 'First Nations status',
    gainNumber: 'Earning number',
    gender: 'Gender',
    globalChange: 'Global change',
    goBack: 'Go back',
    group: 'Group',
    groups: 'Groups',
    health: 'Health',
    hiredDate: 'Hired date',
    history: 'History',
    ifRequired: '(if required)',
    import: 'Import',
    informationAbout: 'Information about',
    insuredAmount: 'Insured Amount',
    invoiceDate: 'Invoice date',
    invoiceNo: 'Invoice No.',
    invoicePeriod: 'Invoice period',
    invoices: 'Invoices',
    lastName: 'Last name',
    logout: 'Logout',
    makeAChangeRequest: 'Make a change request',
    memberPortal: 'Member portal',
    messageToThePlanAdministrator: 'Message to the plan administrator',
    name: 'Name',
    new: 'New',
    no: 'Non',
    noData: 'No data available',
    noMatchingRecordFound: 'No matching record found',
    notCovered: 'Not covered',
    note: 'Note',
    number: 'Number',
    occupation: 'Occupation',
    option: 'Option',
    optional: '(optional)',
    planMemberStatus: 'Plan member status',
    planMembersAndDependentsReport: 'Plan members and dependents report',
    payrollFiles: 'Payroll files',
    payrollFrequency: 'Payroll frequency',
    personal: 'Personal',
    phone: 'Phone',
    phones: 'Phones',
    planMember: 'Plan member',
    planMembers: 'Plan members',
    policy: 'Policy',
    premiumsSimulation: 'Premiums simulation',
    profile: 'Profile',
    selectAPlanMember: 'Select a plan member',
    startDateOfCohabitation: 'Start date of cohabitation',
};

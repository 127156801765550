import { checkAccess } from '@/mixins/security';
import { find, forEach } from 'lodash';
export function processRoutesMetaOverrides(routes, securityPermissionSets) {
    routes.forEach((route) => {
        if (route?.meta?.conditionalRedirect) {
            route?.meta?.conditionalRedirect(route, securityPermissionSets);
        }
    });
    return routes;
}
export function getRouteChildren(name, routes) {
    let children = find(routes, ['name', name]);
    if (children) {
        children = find(routes, ['name', name]).children;
    }
    return children;
}
export function getAllRoutesAccessible(name, securityPermissionSets, routes, params = {}) {
    const routeChildren = getRouteChildren(name, routes);
    const allRouteAccessible = [];
    forEach(routeChildren, (route) => {
        if (!route.meta) {
            console.warn(`Missing meta and security ressource: ${route.name}`);
            return;
        }
        const hasPermission = checkAccess(route.meta.resourcePermission, route.meta.resource, securityPermissionSets, params);
        if (hasPermission) {
            allRouteAccessible.push(route);
        }
    });
    return allRouteAccessible;
}
export function getFirstRouteAccessible(name, securityPermissionSets, routes) {
    const routesAccessible = getAllRoutesAccessible(name, securityPermissionSets, routes);
    return routesAccessible.length !== 0 ? routesAccessible[0] : null;
}

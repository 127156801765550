import { forEach } from 'lodash';
import SETTINGS from '../constants/settings';
export function i18nEmpty(defaultEmptyValue = '') {
    const i18n = {};
    forEach(SETTINGS.LANGUAGES, (value) => {
        i18n[value] = defaultEmptyValue;
    });
    return i18n;
}
export function phoneNumbersDefaultData() {
    return {
        Type: '',
        LineNumber: '',
        Extension: '',
    };
}
export function contactPersonDefaultData() {
    return {
        EmailAddress: '',
        FirstName: '',
        LastName: '',
        JobTitle: i18nEmpty(),
        PhoneNumbers: [phoneNumbersDefaultData()],
    };
}
export function addressDefaultData() {
    return {
        City: '',
        CivicAddress: '',
        PostalCode: '',
        ProvinceId: '',
        CountryId: '',
    };
}
export function enterpriseDefaultData() {
    return {
        Address: addressDefaultData(),
        ContactPerson: contactPersonDefaultData(),
        EmailAddress: '',
        IndustryType: i18nEmpty(),
        PhoneNumbers: [phoneNumbersDefaultData()],
        WebsiteUrl: '',
    };
}
export function enterpriseGroupDefaultData() {
    return {
        Address: addressDefaultData(),
        EmailAddress: '',
        IndustryType: i18nEmpty(),
        PhoneNumbers: [phoneNumbersDefaultData()],
        WebsiteUrl: '',
    };
}
export function ROUND_CALCULATION() {
    return {
        RoundValue: 1,
        RoundType: 0,
    };
}
export function ROUND_REDUCTION() {
    return {
        RoundValue: null,
        RoundType: null,
    };
}

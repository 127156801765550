import StoreExtender from '@/store/extender';
import STORE_CONSTANT_NAMES from '@/store/names';
import ComponentMixins from '@/mixins/segic-component';
import GroupsCategoriesStore from '@/store/groups/categories';
import SFieldSelect from '@/components/form/fields/select.vue';
import SBtnHistoryDialog from '@/components/display/btn-history-dialog.vue';
import { getMessageContentTypeValuePerCode, MESSAGE_CONTENT_TYPE_CONSTANT } from 'segic_shared_front_end_utils/src/enums/message-content-type';
import { orderBy } from 'lodash';
export default {
    name: 'SCategoriesField',
    components: {
        SFieldSelect,
        SBtnHistoryDialog,
    },
    mixins: [ComponentMixins],
    props: {
        attach: {
            default: false,
            type: Boolean,
        },
        categoriesToExclude: {
            default: () => [],
            type: Array,
        },
        itemText: {
            default: 'DisplayCodeName',
            type: String,
        },
        model: {
            default: null,
            type: [String, Object],
        },
        name: {
            required: true,
            type: String,
        },
        rules: {
            default: '',
            type: String,
        },
    },
    data() {
        return {
            categories: [],
            categoriesIsLoading: false,
            store: null,
            showDialogHistory: false,
            categoryHistoryItems: [],
            MESSAGE_CONTENT_TYPE_CONSTANT,
        };
    },
    mounted() {
        this.getCategories();
    },
    methods: {
        getMessageContentTypeValuePerCode,
        async getCategories() {
            this.categories = [];
            this.categoriesIsLoading = true;
            this.store = new StoreExtender(this, STORE_CONSTANT_NAMES.GROUPS.CATEGORIES, GroupsCategoriesStore);
            await this.store.sendActions('get');
            this.categories = this.setCategories(this.store.getGetter('get', this.$i18n.locale), this.categoriesToExclude);
            this.categories = orderBy(this.categories, [`DisplayCodeName.${this.$i18n.locale}`]);
            this.categoriesIsLoading = false;
        },
        getCategoryHistory() {
            this.categoryHistoryItems = this.store.getGetter('getHistory', { CategoryId: this.model, locale: this.$i18n.locale });
        },
        historyHeaders() {
            return [
                {
                    text: this.$t('status'),
                    value: 'Status',
                },
                {
                    text: this.$t('effectiveDate'),
                    value: 'EffectiveDate',
                },
            ];
        },
        setCategories(categories, categoriesToExclude) {
            if (categoriesToExclude.length === 0) {
                return categories;
            }
            return categories.filter((category) => categoriesToExclude.indexOf(category.Id) === -1);
        },
    },
};

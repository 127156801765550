import Datatable from '@/components/datatables/base.vue';
export default {
    name: 'SParticipantHistory',
    components: {
        's-datatable-base': Datatable,
    },
    props: {
        classes: {
            default: '',
            type: String,
        },
        headers: {
            required: true,
            type: Array,
        },
        items: {
            required: true,
            type: Array,
        },
    },
};

import { i18n } from '@/utils/data-formatting/display/i18n';
import { concat, deburr, filter, forEach, sortBy, } from 'lodash';
import I18n from '@/plugins/vue-i18n';
const Status = {
    '31c3fc03-d3b5-e811-823e-000d3af46b5d': {
        Description: "[{'Code':1,'Value':'Actif'},{'Code':2,'Value':'Active'}]",
        Code: 0,
    },
    '32c3fc03-d3b5-e811-823e-000d3af46b5d': {
        Description: "[{'Code':1,'Value':'Terminé'},{'Code':2,'Value':'Terminated'}]",
        Code: 1,
    },
    'b8630a33-4e87-48d2-bc2a-008ae555d822': {
        Description: "[{'Code':1,'Value':'Décédé'},{'Code':2,'Value':'Deceased'}]",
        Code: 1,
    },
    '8eb65b9a-1fa2-4be9-8a76-34c6320af623': {
        Description: "[{'Code':1,'Value':'Retraité'},{'Code':2,'Value':'Retired'}]",
        Code: 1,
    },
    '160d632f-daae-4462-8aed-36c7766b9cce': {
        Description: "[{'Code':1,'Value':'Cessation emploi'},{'Code':2,'Value':'Employment terminated'}]",
        Code: 1,
    },
    '5477a091-f1f3-45f4-b990-7efc7fdc3d96': {
        Description: "[{'Code':1,'Value':'Décédé - Survivants actifs'},{'Code':2,'Value':'Deceased - Active survivors'}]",
        Code: 0,
    },
    'aebf4001-b6d7-421e-b995-25343abf3d9f': {
        Description: "[{'Code':1,'Value':'Mise à pied temporaire'},{'Code':2,'Value':'Temporary layoff'}]",
        Code: 0,
    },
    '3a20a0bb-5066-4060-881f-7681db9aecd5': {
        Description: "[{'Code':1,'Value':'Invalidité'},{'Code':2,'Value':'Disabled'}]",
        Code: 0,
    },
    'b1617714-9b57-417b-8cd4-1b25b89f7c13': {
        Description: "[{'Code':1,'Value':'Congé sans solde'},{'Code':2,'Value':'Unpaid leave of absence'}]",
        Code: 0,
    },
    '4d63808e-d278-4b5d-992c-a0ede38d2ce1': {
        Description: "[{'Code':1,'Value':'Congé parental'},{'Code':2,'Value':'Parental leave'}]",
        Code: 0,
    },
    '88e7f8a9-f4a2-4522-88db-538e6391311d': {
        Description: "[{'Code':1,'Value':'Absence pour maladie'},{'Code':2,'Value':'Leave of absence'}]",
        Code: 0,
    },
};
export function statusSelectData() {
    const status = [];
    forEach(Status, (item, key) => {
        status.push({
            code: item.Code,
            id: key,
            description: i18n(item.Description)[I18n.locale],
        });
    });
    const activeStatus = sortBy(filter(status, { code: 0 }), (item) => deburr(item.description));
    const terminatedStatus = sortBy(filter(status, { code: 1 }), (item) => deburr(item.description));
    return concat(activeStatus, terminatedStatus);
}
export function getStatusDescription(statusId) {
    if (statusId && Status[statusId]) {
        return i18n(Status[statusId].Description)[I18n.locale];
    }
    console.warn(`status id ${statusId}`);
    return '';
}
export default Status;

import SegicLayoutComponent from '@/mixins/segic-layout-component';
import ConfirmDialog from '@/components/dialogs/confirm.vue';
export default {
    name: 'SDialogConfirmCancel',
    components: {
        's-dialog-confirm': ConfirmDialog,
    },
    mixins: [SegicLayoutComponent],
    props: {
        showDialog: {
            required: true,
            type: Boolean,
        },
    },
    i18n: {
        messages: {
            fr: {
                title: 'Annuler ?',
                text: 'Les modifications non sauvegardées seront perdues.',
            },
            en: {
                title: 'Cancel?',
                text: 'Unsaved changes will be lost.',
            },
        },
    },
};

import MicroServices from '@/configs/MicroServices.types';
import proxy from '@/store/proxy';
import { getQueryString, replaceStringRoutingData, } from 'segic_shared_front_end_utils/src/utils/routing';
import messages from './locales';
export default {
    namespace: 'global.logo',
    uri: '/clients/:{clientGuid}/portal',
    get(clientGuid, data, query) {
        const methodName = 'get';
        const service = MicroServices.CLIENT_STRUCTURE;
        const params = {
            clientGuid,
        };
        const url = this.uri;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
        };
        return proxy.get(replacedUrl, requestData);
    },
};

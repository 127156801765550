import { forEach, merge } from 'lodash';
import Q from 'q';
import MESSAGE_TYPE from 'segic_shared_front_end_utils/src/constants/message-types';
import HTTP_CODES from 'segic_shared_front_end_utils/src/constants/http-codes';
import EVENTS from 'segic_shared_front_end_utils/src/constants/events';
import { EventBus } from '@/utils/event-bus';
import I18n from '@/plugins/vue-i18n';
import Store from '@/store/index';
import { replaceStringBrackets, replaceStringRoutingData, } from 'segic_shared_front_end_utils/src/utils/routing';
import moment from 'moment';
import ErrorCodes from '@/store/change-requests-attachments/error-codes';
export default {
    mergeStates(...data) {
        return merge(this.defaultStates(), ...data);
    },
    defaultStates() {
        return {
            promises: {},
            version: 0,
        };
    },
    mergeGetters(...data) {
        return merge(this.defaultGetters(), ...data);
    },
    defaultGetters() {
        return {
            getPromise: (state) => (name) => state.promises[`${name} - ${state.version}`],
        };
    },
    mergeMutations(...data) {
        return merge(this.defaultMutations(), ...data);
    },
    defaultMutations() {
        return {
            addPromise(state, payload) {
                state.promises[`${payload.name} - ${state.version}`] = payload.promise;
            },
        };
    },
    mergeActions(...data) {
        return merge(this.defaultActions(), ...data);
    },
    defaultActions() {
        return {};
    },
    createDefer() {
        return Q.defer();
    },
    returnDeferred(deferred, call, context, payload) {
        if (call) {
            context.state.version += 1;
            deferred.promise.version = context.state.version;
            deferred.promise.time = new Date();
            deferred.promise.lang = call.lang;
            deferred.promise.reject = call.reject;
            if (context.state.promises) {
                context.commit('addPromise', {
                    name: payload.actionKey,
                    promise: deferred.promise,
                });
            }
        }
        deferred.promise.actionKey = payload.actionKey;
        deferred.promise.actionNamespace = payload.actionNamespace;
        return deferred.promise;
    },
    getAllThenResponse(response, finalDefaultValue) {
        let data = null;
        switch (response.code) {
            case HTTP_CODES.NO_CONTENT:
                data = finalDefaultValue;
                break;
            default:
                data = response.data;
                break;
        }
        return data;
    },
    showErrorSnackbar(response) {
        const codes = [
            ErrorCodes.VIRUS,
        ];
        return codes.indexOf(response.internalCode) === -1;
    },
    getAllCatchResponse(response, payload) {
        if (payload && payload.options.isHandlingErrors) {
            return;
        }
        if (this.showErrorSnackbar(response)) {
            if (typeof response === 'undefined') {
                EventBus.$emit(EVENTS.SNACKBAR, { message: I18n.t('api.generic.error'), type: MESSAGE_TYPE.ERROR });
            }
            else if (typeof response.code !== 'undefined' && response.code !== HTTP_CODES.STOPPED) {
                EventBus.$emit(EVENTS.SNACKBAR, { message: response.message, type: response.messageType });
            }
        }
    },
    getStringThenResponse(response) {
        let data = null;
        switch (response.code) {
            case HTTP_CODES.OK:
                data = response.data;
                break;
            default:
                data = '';
                break;
        }
        return data;
    },
    findSameCall(call, callStacks) {
        const findCall = callStacks.find((callstack) => callstack.actionNamespace === call.actionNamespace && callstack.actionKey === call.actionKey && !callstack.isFulfilled());
        if (findCall) {
            call.reject();
        }
        return (findCall);
    },
    createCache(cacheDelay, cacheName, cacheReference, payload) {
        if (cacheDelay) {
            const started = moment();
            let expiring = started.clone();
            if (cacheDelay === -1) {
                expiring = -1;
            }
            else if (typeof cacheDelay === 'object') {
                expiring.add(cacheDelay.value, cacheDelay.unit);
            }
            else {
                expiring.add(cacheDelay, 'm');
            }
            Store.state.storeReferences[cacheReference] = cacheName;
            Store.state.storesCaching[cacheName] = {
                started,
                expiring,
                actionKey: payload.actionKey,
                actionNamespace: payload.actionNamespace,
                actionRegexNamespace: payload.actionRegexNamespace,
                params: payload.params,
                query: payload.query,
                data: payload.data,
            };
            if (payload.debug) {
                console.log('creating cache', cacheName, Store.state.storesCaching[cacheName]);
                console.log(Store.state.storesCaching);
            }
        }
    },
    bustCache(cacheBustParams, namespace, payload, dontEmit) {
        if (payload.debug) {
            console.log('bustCache', cacheBustParams, namespace, payload);
        }
        if (cacheBustParams) {
            let name = namespace;
            let isRecursive = true;
            if (typeof cacheBustParams === 'boolean') {
                this.loopCache(name, isRecursive, payload);
            }
            else if (typeof cacheBustParams === 'object') {
                isRecursive = (!cacheBustParams.notRecursive);
                if (!payload.params.dontDestroyMyCache) {
                    this.loopCache(name, isRecursive, payload);
                }
                if (cacheBustParams.others) {
                    forEach(cacheBustParams.others, (cache) => {
                        if (typeof cache === 'object') {
                            name = cache.value;
                            isRecursive = (!cache.notRecursive);
                            if (cache.extraParams) {
                                payload.cacheExtraParams = cache.extraParams;
                                payload.cacheExtraQuery = cache.extraQuery;
                            }
                        }
                        else {
                            name = cache;
                            isRecursive = true;
                        }
                        this.loopCache(name, isRecursive, payload);
                    });
                }
            }
            if (!dontEmit) {
                EventBus.$emit('globalStore.refresh');
            }
        }
    },
    loopCache(name, isRecursive, payload) {
        if (!payload.cacheExtraParams) {
            payload.cacheExtraParams = {};
        }
        payload.cacheExtraParams.requestDate = 'any';
        const cacheParams = merge({}, payload.params, payload.cacheExtraParams);
        const nameReplaced = replaceStringBrackets(replaceStringRoutingData(name, cacheParams));
        if (payload.debug) {
            console.log('cacheParams', cacheParams);
            console.log('loopCache', name, payload);
            console.log(nameReplaced);
            console.log(Store.state.storesCaching);
        }
        forEach(Store.state.storesCaching, (storeValue, storeKey) => {
            forEach(payload.cacheExtraParams, (paramValue, paramKey) => {
                if (paramValue === 'any') {
                    storeValue.params[paramKey] = paramValue;
                }
            });
            forEach(storeValue.params, (paramValue, paramKey) => {
                if (paramValue === 'all') {
                    storeValue.params[paramKey] = cacheParams[paramKey];
                }
            });
            const storeName = replaceStringBrackets(replaceStringRoutingData(storeValue.actionRegexNamespace, storeValue.params));
            if (payload.debug) {
                console.log('storeName', storeName);
                console.log('nameReplaced', nameReplaced);
            }
            if (storeName === nameReplaced) {
                if (payload.debug) {
                    console.log('remove my cache', storeKey);
                }
                delete Store.state.storesCaching[storeKey];
            }
            else if (isRecursive && nameReplaced.indexOf(storeName) !== -1) {
                if (payload.debug) {
                    console.log('remove recursive cache', storeKey);
                }
                delete Store.state.storesCaching[storeKey];
            }
        });
    },
};

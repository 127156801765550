import STextCell from './text.vue';
export default {
    name: 'SCellParticipantName',
    components: { STextCell },
    props: {
        firstName: {
            required: true,
            type: String,
        },
        lastName: {
            required: true,
            type: String,
        },
    },
};

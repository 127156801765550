import MicroServices from '@/configs/MicroServices.types';
import proxy from '@/store/proxy';
import { getQueryString, replaceStringRoutingData } from 'segic_shared_front_end_utils/src/utils/routing';
import messages from './locales';
export default {
    namespace: 'payroll.file.conversion',
    uri: 'api/clients/:{clientGuid}/groups/:{groupGuid}/payroll/integration/mappings',
    uriDownloadFile: 'api/clients/:{clientGuid}/groups/:{groupGuid}/payroll/integration/:{integrationGuid}',
    uriBulkCertificateValidation: '/api/clients/:{clientGuid}/groups/:{groupGuid}/payroll/integration/participants',
    create(clientGuid, groupGuid, data) {
        const methodName = 'create';
        const service = MicroServices.BFF;
        const params = {
            clientGuid,
            groupGuid,
        };
        const url = this.uri;
        const replacedUrl = replaceStringRoutingData(url, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            methodName,
            service,
            messages,
            data,
        };
        return proxy.put(replacedUrl, data, requestData);
    },
    getIntegrationDataStructure(clientGuid, groupGuid, data) {
        const methodName = 'getIntegrationDataStructure';
        const service = MicroServices.BFF;
        const params = {
            clientGuid,
            groupGuid,
        };
        const url = this.uri;
        const replacedUrl = replaceStringRoutingData(url, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            methodName,
            service,
            messages,
            data,
        };
        return proxy.post(replacedUrl, data, requestData);
    },
    get(clientGuid, groupGuid, query) {
        const methodName = 'get';
        const service = MicroServices.BFF;
        const params = {
            clientGuid,
            groupGuid,
        };
        const url = this.uri;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
        };
        return proxy.get(replacedUrl, requestData);
    },
    getTemplate(clientGuid, groupGuid, query) {
        const methodName = 'getTemplate';
        const service = MicroServices.BFF;
        const params = {
            clientGuid,
            groupGuid,
        };
        const url = this.uriBulkCertificateValidation;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
        };
        return proxy.get(replacedUrl, requestData, { responseType: 'blob' });
    },
    validate(clientGuid, groupGuid, data) {
        const methodName = 'validate';
        const service = MicroServices.BFF;
        const params = {
            clientGuid,
            groupGuid,
        };
        const url = this.uriBulkCertificateValidation;
        const replacedUrl = replaceStringRoutingData(`${url}/validate`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            methodName,
            service,
            messages,
            data,
        };
        return proxy.post(replacedUrl, data, requestData);
    },
    getConfigurationStatus(clientGuid, groupGuid) {
        const methodName = 'getConfigurationStatus';
        const service = MicroServices.BFF;
        const params = {
            clientGuid,
            groupGuid,
        };
        const url = this.uri;
        const replacedUrl = replaceStringRoutingData(`${url}/status`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            methodName,
            service,
            messages,
        };
        return proxy.get(replacedUrl, requestData);
    },
    getByID(clientGuid, groupGuid, integrationGuid, query) {
        const methodName = 'getByID';
        const service = MicroServices.BFF;
        const params = {
            clientGuid,
            groupGuid,
            integrationGuid,
        };
        const url = this.uriDownloadFile;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
        };
        return proxy.get(replacedUrl, requestData, { responseType: 'blob' });
    },
};

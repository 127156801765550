import ComponentMixins from '@/mixins/segic-component';
import SFieldSelect from '@/components/form/fields/select.vue';
import SFieldText from '@/components/form/fields/text.vue';
import SLabelForm from '@/components/ui/label-form.vue';
import SActionIcon from '@/components/ui/action-icon.vue';
import { MASKS } from '@/utils/mask';
import PHONE_LOCALIZATION_TYPES, { PHONE_LOCALIZATION_TYPES_CONSTANT } from 'segic_shared_front_end_utils/src/enums/phone-localization-types';
import { cloneDeep } from 'lodash';
export default {
    name: 'SPhones',
    components: {
        SFieldSelect, SFieldText, SLabelForm, SActionIcon,
    },
    mixins: [ComponentMixins],
    props: {
        name: {
            required: true,
            type: String,
        },
        phones: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            MASKS,
        };
    },
    computed: {
        showBtnAdd() {
            return this.phones.length < this.phonesSorted().length;
        },
    },
    methods: {
        addPhone() {
            const newPhone = {
                extension: '',
                phoneType: null,
                number: '',
            };
            this.$emit('update:model', newPhone, `${this.name}.${this.phones.length}`);
        },
        deletePhone(phone, index) {
            const newPhone = cloneDeep(this.phones);
            newPhone.splice(index, 1);
            this.$emit('update:model', newPhone, this.name);
        },
        disabledDelete() {
            return this.phones.length === 1;
        },
        phonesSorted(excluded = []) {
            let allExcluded = [PHONE_LOCALIZATION_TYPES_CONSTANT.OFFICE];
            allExcluded = allExcluded.concat(excluded);
            return PHONE_LOCALIZATION_TYPES.getValues(this.$i18n.locale, allExcluded);
        },
        phonesSelectData(index) {
            const allPhoneTypeTaken = [];
            this.phones.forEach((item) => {
                if (this.phones[index].phoneType !== null && this.phones[index].phoneType !== item.phoneType) {
                    allPhoneTypeTaken.push(item.phoneType);
                }
            });
            return this.phonesSorted(allPhoneTypeTaken);
        },
    },
    i18n: {
        messages: {
            fr: {
                addPhone: 'Ajouter un téléphone',
            },
            en: {
                addPhone: 'Add phone',
            },
        },
    },
};

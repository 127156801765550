import HTTP_CODES from 'segic_shared_front_end_utils/src/constants/http-codes';
import VALIDATION_CODES from './validation-codes';
const FR = {
    create: {
        [HTTP_CODES.OK]: 'La demande a été modifiée.',
        [VALIDATION_CODES.ATTACHEMENT_FAILED]: 'La pièce jointe n\'a pas été téléchargée correctement.',
    },
    delete: {
        [HTTP_CODES.OK]: 'La demande a été modifiée.',
    },
    download: {
        [HTTP_CODES.OK]: '',
    },
};
const EN = {
    create: {
        [HTTP_CODES.OK]: 'The request has been updated.',
        [VALIDATION_CODES.ATTACHEMENT_FAILED]: 'The attachment didn\'t upload correctly.',
    },
    delete: {
        [HTTP_CODES.OK]: 'The request has been updated.',
    },
    download: {
        [HTTP_CODES.OK]: '',
    },
};
export default {
    fr: FR,
    en: EN,
};

import { getEnumValue, getEnumValues, toValuesEnum } from './_utils';
export var GENERATION_MODE_CONSTANT;
(function (GENERATION_MODE_CONSTANT) {
    GENERATION_MODE_CONSTANT[GENERATION_MODE_CONSTANT["MANUALLY"] = 0] = "MANUALLY";
    GENERATION_MODE_CONSTANT[GENERATION_MODE_CONSTANT["AUTOMATICALLY"] = 1] = "AUTOMATICALLY";
})(GENERATION_MODE_CONSTANT || (GENERATION_MODE_CONSTANT = {}));
;
const values = toValuesEnum(GENERATION_MODE_CONSTANT);
const FR = {
    [GENERATION_MODE_CONSTANT.MANUALLY]: 'Définir manuellement les numéros de certificat',
    [GENERATION_MODE_CONSTANT.AUTOMATICALLY]: 'Générer les numéros automatiquement à partir d\'un gabarit',
};
const EN = {
    [GENERATION_MODE_CONSTANT.MANUALLY]: 'Manually set certificate numbers',
    [GENERATION_MODE_CONSTANT.AUTOMATICALLY]: 'Generate numbers automatically from a template',
};
const Dictionaries = {
    fr: FR,
    en: EN,
};
export function getGenerationModeValues(lang) {
    return getEnumValues(values, Dictionaries, lang);
}
export function getGenerationModePerCode(code, lang) {
    return getEnumValue(values, Dictionaries, lang, code);
}

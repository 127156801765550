import ComponentMixins from '@/mixins/segic-component';
import { getNestedObject } from '@/utils/helpers';
import { get } from 'lodash';
import { isNotNullOrUndefined, isObject } from 'segic_shared_front_end_utils/src/utils/types';
export default {
    name: 'SSlotTemplateSelectDefaultItem',
    mixins: [ComponentMixins],
    props: {
        hasIcon: {
            default: false,
            type: Boolean,
        },
        isActive: {
            required: true,
            type: Boolean,
        },
        item: {
            required: true,
            type: [String, Object],
        },
        itemText: {
            default: 'text',
            type: String,
        },
        multiple: {
            type: Boolean,
        },
        statusIconColorKey: {
            default: 'StatusIconColor',
            type: String,
        },
        statusIconKey: {
            default: 'StatusIcon',
            type: String,
        },
        statusKey: {
            default: 'Status',
            type: String,
        },
        subtitleKey: {
            default: 'subtitle',
            type: String,
        },
    },
    methods: {
        getNestedObject,
        getStatus(item) {
            return get(item, this.statusKey);
        },
        getStatusColor(item) {
            return get(item, this.statusIconColorKey);
        },
        getStatusIcon(item) {
            const icon = get(item, this.statusIconKey);
            return icon || 'circle';
        },
        hasStatus(item) {
            return isNotNullOrUndefined(this.getStatus(item)) && isNotNullOrUndefined(this.getStatusColor(item));
        },
        isObject,
        sendEvent(item) {
            this.$emit('change', item);
        },
    },
};

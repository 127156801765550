import MicroServices from '@/configs/MicroServices.types';
import proxy from '@/store/proxy';
import { getFiltersQueryString, replaceStringRoutingData } from 'segic_shared_front_end_utils/src/utils/routing';
import messages from './locales';
export default {
    namespace: 'participants',
    uri: 'api/clients/:{clientGuid}/groups/:{groupGuid}/planmembers',
    get(clientGuid, groupGuid, query) {
        const methodName = 'get';
        const service = MicroServices.BFF;
        const params = {
            clientGuid,
            groupGuid,
        };
        const url = this.uri;
        const replacedUrl = replaceStringRoutingData(`${url}${getFiltersQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
        };
        return proxy.get(replacedUrl, requestData);
    },
};

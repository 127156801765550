import HTTP_CODES from 'segic_shared_front_end_utils/src/constants/http-codes';
const FR = {
    get: {
        [HTTP_CODES.OK]: '',
    },
    updateLanguage: {
        [HTTP_CODES.OK]: 'La langue de l\'utilisateur a été changée.',
    },
};
const EN = {
    get: {
        [HTTP_CODES.OK]: '',
    },
    updateLanguage: {
        [HTTP_CODES.OK]: 'The user language has been changed.',
    },
};
export default {
    fr: FR,
    en: EN,
};

const STORE_CONSTANT_NAMES = {
    CLIENTS: {
        LOGO: 'global|:{clientGuid}|logo',
    },
    GLOBALS: {
        BREADCRUMB: 'global|breadcrumb',
        INSURERS: 'global|insurers',
        LOADER: 'global|loader',
        NOTIFICATIONS: 'global|notifications',
        PROTECTIONS: 'global|protections',
        USER: 'global|user',
    },
    USER_MANAGER_ACCOUNT: {
        PREFERENCES: 'userManagerAccount|securityPrincipals|:{securityPrincipalGuid}|preferences',
    },
    ATTACHMENTS: {
        SINGLE: 'clients|:{clientGuid}|groups|:{groupGuid}|messaging|:{messagingGuid}|attachment|:{attachmentGuid}',
    },
    BULK_SALARY_CHANGE_REQUESTS: {
        TEMPLATE: 'clients|:{clientGuid}|groups|:{groupGuid}|bulkUpdateSalaries',
    },
    GROUPS: {
        BENEFITS: 'client|:{clientGuid}|group|:{groupGuid}|benefits',
        BENEFITS_HISTORY: 'client|:{clientGuid}|group|:{groupGuid}|benefits|:{groupBenefitGuid}',
        CATEGORIES: 'clients|:{clientGuid}|groups|:{groupGuid}|categories',
        CERTIFICATE_TEMPLATES: 'clients|:{clientGuid}|groups|:{groupGuid}|certificateTemplates',
        DIVISIONS: 'clients|:{clientGuid}|groups|:{groupGuid}|divisions',
        DIVISIONS_HISTORY: 'clients|:{clientGuid}|groups|:{groupGuid}|divisions|:{divisionGuid}|history',
        OCCUPATIONS: 'clients|:{clientGuid}|groups|:{groupGuid}|occupations',
        RECIPIENTS: 'clients|:{clientGuid}|groups|:{groupGuid}|recipients',
        SINGLE: 'clients|:{clientGuid}|groups|:{groupGuid}',
    },
    CHANGE_REQUESTS: {
        LIST: 'clients|:{clientGuid}|groups|:{groupGuid}|changerequests|search',
        SINGLE: 'clients|:{clientGuid}|groups|:{groupGuid}|changerequests',
        BENEFITS: 'clients|:{clientGuid}|groups|:{groupGuid}|changerequests|:{changeRequestGuid}',
    },
    INVOICES: {
        LIST: 'clients|:{clientGuid}|groups|:{groupGuid}|invoices',
        SINGLE: 'clients|:{clientGuid}|groups|:{groupGuid}|invoices|:{invoiceId}',
    },
    PLAN_MEMBERS: {
        BENEFITS: 'clients|:{clientGuid}|groups|:{groupGuid}|planMembers|:{participantGuid}|benefits',
        LIST: 'clients|:{clientGuid}|groups|:{groupGuid}|planMembers',
        SINGLE: 'clients|:{clientGuid}|groups|:{groupGuid}|planMembers|:{participantGuid}',
        SIMULATION: 'clients|:{clientGuid}|groups|:{groupGuid}|simulation|planMembers|:{participantGuid}',
    },
    PARTICIPANTS: {
        BANK_ACCOUNTS: 'clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}|bankAccounts',
        BENEFITS: ':{requestDate}|clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}|benefits|grouped',
        CATEGORIES: 'clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}|categories',
        DIVISIONS: 'clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}|division',
        EMAILS: 'clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}|emails',
        OCCUPATION: 'clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}|occupation',
        PHONES: 'clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}|phones',
        PROVINCES: 'clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}|provinces',
        RECIPIENTS: 'clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}|recipients',
        RELATIONSHIPS_STATUSES: 'clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}|relationships|:{personGuid}|statuses',
        SINGLE: 'clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}',
        SALARIES: 'clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}|salaries',
        SMOKER_STATUS: 'clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}|smokerStatus',
        SPOUSES: 'clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}|spouses',
        STATUS: 'clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}|participant-statuses',
    },
    REPORTS: {
        R001: 'clients|:{clientGuid}|groups|:{groupGuid}|participants|:{participantGuid}|reports',
        R038_PARTICIPANTS: 'clients|:{clientGuid}|groups|:{groupGuid}|report|participants|R038',
        R038_DEPENDENTS: 'clients|:{clientGuid}|groups|:{groupGuid}|report|dependents|R038',
    },
    PREMIUMS: {
        SIMULATION: 'clients|:{clientGuid}|groups|:{groupGuid}|simulation',
        INSURED_CAPITAL_SIMULATION: 'clients|:{clientGuid}|groups|:{groupGuid}|insuredcapital|simulation',
    },
    PAYROLL_FILES: {
        LIST: 'clients|:{clientGuid}|groups|:{groupGuid}|payrollDeductions',
        SINGLE: 'clients|:{clientGuid}|groups|:{groupGuid}|payrollDeductions|:{payrollDeductionGuid}',
    },
    ONBOARDING: {
        EDIT: ':{requestDate}|clients|:{clientGuid}|groups|:{groupGuid}|onboarding|invitations|:{invitationGuid}',
        DATA: 'clients|:{clientGuid}|groups|:{groupGuid}|onboarding|data',
        LIST: ':clients|:{clientGuid}|groups|:{groupGuid}|onboarding|validation',
        SEND: 'clients|:{clientGuid}|groups|:{groupGuid}|onboarding|send',
        TEMPLATE: 'clients|:{clientGuid}|groups|:{groupGuid}|onboarding|template',
        VALIDATE: 'clients|:{clientGuid}|groups|:{groupGuid}|onboarding|validate',
        RESEND: 'clients|:{clientGuid}|groups|:{groupGuid}|onboarding|invitations|resend',
        DELETE: 'clients|:{clientGuid}|groups|:{groupGuid}|onboarding|invitations|delete',
        EMAIL_TEMPLATES: ':{requestDate}|onboarding|invitations|emailTemplates',
    },
    PAYROLL_SYSTEM_CONVERSION: {
        DOWNLOAD_FILE: 'clients|:{clientGuid}|groups|:{groupGuid}|payroll|integration|:{integrationGuid}',
        BULK_CERTIFICATE_MAPPING_CONFIGURATION_STATUS: 'clients|:{clientGuid}|groups|:{groupGuid}|payroll|integration|config',
        BULK_CERTIFICATE_MAPPING_TEMPLATE: 'clients|:{clientGuid}|groups|:{groupGuid}|payroll|integration|participants',
        BULK_CERTIFICATE_MAPPING_VALIDATE: 'clients|:{clientGuid}|groups|:{groupGuid}|payroll|integration|participants|validate',
        SINGLE: 'clients|:{clientGuid}|groups|:{groupGuid}|payroll|integration|mappings',
    },
    DOCUMENTS: {
        LIST: 'clients|:{clientGuid}|groups|:{groupGuid}|documents',
    },
};
export default STORE_CONSTANT_NAMES;

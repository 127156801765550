import StoreExtender from '@/store/extender';
import SegicCommons from '@/mixins/segic-commons';
import Security from '@/mixins/security';
import AppLoader from '@/mixins/app-loader';
import STORE_CONSTANT_NAMES from '@/store/names';
import GlobalUserStore from '@/store/global/user';
export default {
    mixins: [SegicCommons, Security, AppLoader],
    created() {
        this.userStore = new StoreExtender(this, STORE_CONSTANT_NAMES.GLOBALS.USER, GlobalUserStore);
    },
    data() {
        return {
            isSegicComponent: true,
            userStore: null,
        };
    },
};

import { EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES } from '@/mixins/security/codes/employer-portal';
import { getEmptyComponents } from '@/utils/page-layouts';
import SECURITY_PERMISSIONS from 'segic_shared_front_end_utils/src/enums-bitwise/security-permissions';
import List from './list/router';
const routes = [];
let children = [];
children = children.concat(List);
const components = getEmptyComponents();
components.default = () => import(/* webpackChunkName: "changeRequests" */ './index.vue');
routes.push({
    name: 'requests',
    path: 'requests',
    redirect: {
        name: 'requests.list',
    },
    components,
    children,
    meta: {
        resourcePermission: SECURITY_PERMISSIONS.VIEW,
        resource: EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.EMPLOYER_CHANGE_REQUEST_RESOURCE,
        menuTitle: 'changeRequests',
        icon: 'create',
    },
});
export default routes;

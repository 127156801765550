export default {
    generic: {
        success: '',
        info: '',
        error: 'An error has occurred.',
    },
    file: {
        virusCreated: `<div class="font-weight-bold s-font-16">Your change request hasn't been sent.</div>
                <div class="mt-2">These attachments have been flagged as malicious by our system:</div>
                <ul>
                {files}
                </ul>`,
        virusModified: `<div class="font-weight-bold s-font-16">Your change request has been changed, but some files could not be attached.</div>
                <div class="mt-2">These attachements have been flagged as suspects or malicious by our system:</div>
                <ul>
                {files}
                </ul>`,
    },
};

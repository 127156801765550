import { merge, isEqual, forEach, } from 'lodash';
export function replaceStringRoutingDataWithAny(string) {
    return string.replace(/:{([^}]*)}/g, () => 'any');
}
export function removeStringRoutingQuerySymbol(string) {
    let result = string.replace(/\?/g, () => '');
    if (string.substr(-1) !== '/') {
        result += '/';
    }
    return result;
}
export function getClientGroupGuid(params, userStore) {
    if (!userStore) {
        console.error('You need a userStore');
        return null;
    }
    if (!params) {
        return null;
    }
    if (params.clientCode && params.groupCode) {
        const data = {
            clientCode: params.clientCode,
            groupCode: params.groupCode,
        };
        const group = userStore.getGetter('group', data);
        // Warning: side effect on params
        params.clientGuid = group.ClientId;
        params.groupGuid = group.Id;
    }
    return params;
}
export function replaceStringData(string, data = {}) {
    return string.replace(/\${([^}]*)}/g, (str, matched) => ((typeof data[matched] !== 'undefined') ? data[matched] : matched));
}
export function replaceStringRoutingData(string, data = {}) {
    return string.replace(/:{([^}]*)}/g, (str, matched) => ((typeof data[matched] !== 'undefined') ? data[matched] : ''));
}
export function replaceStringLocaleData(string, i18n) {
    if (!i18n) {
        console.warn('You need to pass translate function');
    }
    return string.replace(/t{([^}]*)}/g, (str, matched) => i18n.call(this, matched));
}
export function replaceStringBrackets(string) {
    return string.replace(/[{}]*/g, '');
}
export function getQueryString(query) {
    let finalFilters = '';
    Object.keys(query).forEach((key) => {
        finalFilters += (finalFilters.length === 0) ? '?' : '&';
        if (typeof query[key] === 'object') {
            finalFilters += `${key}=${JSON.stringify(query[key])}`;
        }
        else {
            finalFilters += `${key}=${query[key]}`;
        }
    });
    return finalFilters;
}
export function compareRoute(route, cRouteObject, debug) {
    if (debug) {
        console.log(route, cRouteObject);
    }
    if (route && route.params && route.query) {
        const combineParams = merge({}, cRouteObject.params, route.params);
        const combineQuery = merge({}, cRouteObject.query, route.query);
        return (isEqual(cRouteObject.params, combineParams) && isEqual(cRouteObject.query, combineQuery));
    }
    if (route && route.params) {
        const combineParams = merge({}, cRouteObject.params, route.params);
        return (isEqual(cRouteObject.params, combineParams));
    }
    if (route && route.query) {
        const combineQuery = merge({}, cRouteObject.query, route.query);
        return (isEqual(cRouteObject.query, combineQuery));
    }
    return true;
}
export function hasSameParams(route, windowTabRoute) {
    if (!route.params) {
        return true;
    }
    return (isEqual(route.params, windowTabRoute.params));
}
export function hasSameQueryParams(route, windowTabRoute) {
    if (!route.query) {
        return true;
    }
    return (isEqual(route.query, windowTabRoute.query));
}
export function hasAtLeastRouteParams(route, windowTabRoute) {
    if (!route.params) {
        return true;
    }
    let hasRouteParams = true;
    // eslint-disable-next-line consistent-return
    forEach(route.params, (val, key) => {
        if (route.params[key] !== windowTabRoute.params[key]) {
            hasRouteParams = false;
            return false;
        }
    });
    return hasRouteParams;
}
export function hasAtLeastRouteQueryParams(route, windowTabRoute) {
    if (!route.query) {
        return true;
    }
    let hasRouteQueryParams = true;
    // eslint-disable-next-line consistent-return
    forEach(route.query, (val, key) => {
        if (route.query[key] !== windowTabRoute.query[key]) {
            hasRouteQueryParams = false;
            return false;
        }
    });
    return hasRouteQueryParams;
}
export function getEnv() {
    const envs = {
        local: 'localhost',
        static: 'segicdev.azurewebsites.net',
        prod: 'segic.ca',
    };
    let env = 'local';
    const { host } = window.location;
    // eslint-disable-next-line consistent-return
    forEach(envs, (value, key) => {
        if (host.indexOf(value) !== -1) {
            env = key;
            return false;
        }
    });
    return env;
}

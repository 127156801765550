import StoreExtender from '@/store/extender';
import STORE_CONSTANT_NAMES from '@/store/names';
import GroupsDivisionsStore from '@/store/groups/divisions';
import ParticipantsDivisionStore from '@/store/participants/division';
export default {
    data() {
        return {
            groupDivisionsStore: null,
            participantDivisionsStore: null,
        };
    },
    methods: {
        initializeParticipantDivisionStore() {
            this.groupDivisionsStore = new StoreExtender(this, STORE_CONSTANT_NAMES.GROUPS.DIVISIONS, GroupsDivisionsStore);
            this.participantDivisionsStore = new StoreExtender(this, STORE_CONSTANT_NAMES.PARTICIPANTS.DIVISIONS, ParticipantsDivisionStore);
        },
        startParticipantDivisionLoadingData() {
            this.groupDivisionsStore.sendActions('get');
            this.participantDivisionsStore.sendActions('get');
        },
    },
    computed: {
        participantDivisionIsLoading() {
            return this.participantDivisionsStore ? this.participantDivisionsStore.getGetter('getIsLoading') : true;
        },
        participantDivision() {
            return !this.participantDivisionIsLoading ? this.participantDivisionsStore.getGetter('get') : {};
        },
        isDivisionsLoading() {
            const groupDivisionsLoading = this.groupDivisionsStore ? this.groupDivisionsStore.getGetter('getIsLoading') : true;
            return this.participantDivisionIsLoading || groupDivisionsLoading;
        },
        groupDivision() {
            return !this.isDivisionsLoading ? this.groupDivisionsStore.getGetter('getById', this.participantDivision.DivisionId) : null;
        },
    },
};

export default {
    name: 'SComponentPhones',
    props: {
        phones: {
            type: Array,
            required: true,
        },
        phoneTypeCss: {
            default: 'font-weight-medium s-font-12',
            type: String,
        },
        phoneDisplayCss: {
            default: 'font-weight-medium',
            type: String,
        },
    },
};

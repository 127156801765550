import { forEach } from 'lodash';
export default {
    name: 'SFieldFileDropzone',
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        emptyValue: {
            default: null,
            type: [String, Object],
        },
        fileSupported: {
            default: '*.*',
            type: [Array, String],
        },
        infoMessage: {
            default: '',
            type: String,
        },
        isBase64: {
            default: false,
            type: Boolean,
        },
        label: {
            default: '',
            type: String,
        },
        model: {
            required: true,
            type: [String, Object],
        },
        title: {
            default: null,
            type: String,
        },
    },
    data() {
        return {
            dropArea: null,
            progressBar: null,
            uploadProgress: [],
            showError: false,
        };
    },
    computed: {
        dropAreaId() {
            // eslint-disable-next-line no-underscore-dangle
            return `dropArea${this._uid}`;
        },
        hasChangeClass() {
            let classes = this.model !== this.initialModel ? 's-value-changed' : '';
            classes += this.disabled ? ' s-filedropzone-disabled' : '';
            return classes;
        },
        hasFile() {
            return this.model?.name !== this.emptyValue?.name;
        },
        inputId() {
            // eslint-disable-next-line no-underscore-dangle
            return `input${this._uid}`;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        clearData(event) {
            this.$emit('update:model', this.emptyValue);
        },
        getBase64(file) {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = () => {
                this.$emit('update:model', {
                    name: file.name,
                    dataBase64: btoa(reader.result),
                });
            };
            reader.onerror = (error) => {
                throw new Error(error.toString());
            };
        },
        handleBase64Files(files) {
            this.showError = false;
            forEach((files), (file) => {
                if (this.fileSupported !== '*.*') {
                    const extension = `.${file.name.split('.').pop()}`;
                    if (this.fileSupported.indexOf(extension) !== -1) {
                        this.getBase64(file);
                    }
                    else {
                        this.showError = true;
                    }
                }
                else {
                    this.getBase64(file);
                }
            });
        },
        handleDrop(e) {
            const dt = e.dataTransfer;
            const { files } = dt;
            this.handleFiles(files);
        },
        handleFiles(files) {
            if (this.isBase64) {
                this.handleBase64Files(files);
            }
            else {
                this.handleNotFormattedFiles(files);
            }
        },
        handleInput() {
            const input = this.$refs[this.inputId];
            this.$emit('change');
            this.handleFiles(input.files);
            input.value = '';
        },
        handleNotFormattedFiles(files) {
            const filesFiltered = [];
            let filesName = '';
            forEach((files), (file, index) => {
                const extension = `.${file.name.split('.').pop().toLowerCase()}`;
                if (this.fileSupported.indexOf(extension) !== -1) {
                    const separator = index < files.length - 1 ? ', ' : '';
                    filesName += file.name + separator;
                    filesFiltered.push(file);
                }
            });
            this.$emit('update:model', {
                name: filesName,
                files: filesFiltered,
            });
        },
        highlight() {
            this.dropArea.classList.add('highlight');
        },
        init() {
            this.dropArea = document.getElementById(this.dropAreaId);
            [
                'dragenter',
                'dragover',
                'dragleave',
                'drop',
            ].forEach((eventName) => {
                this.dropArea.addEventListener(eventName, this.preventDefaults, false);
                document.body.addEventListener(eventName, this.preventDefaults, false);
            });
            [
                'dragenter',
                'dragover',
            ].forEach((eventName) => {
                this.dropArea.addEventListener(eventName, this.highlight, false);
            });
            [
                'dragleave',
                'drop',
            ].forEach((eventName) => {
                this.dropArea.addEventListener(eventName, this.unhighlight, false);
            });
            this.dropArea.addEventListener('drop', this.handleDrop, false);
        },
        preventDefaults(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        unhighlight() {
            this.dropArea.classList.remove('active');
            this.dropArea.classList.remove('highlight');
        },
    },
    i18n: {
        messages: {
            fr: {
                infoText: 'Faites glisser & déposez le fichier ici ou',
                selectBtnText: 'Sélectionner le fichier',
                btnChange: 'Modifier',
                fileFormatError: 'Le format du fichier n\'est pas valide',
            },
            en: {
                infoText: 'Drag and drop the file here or',
                selectBtnText: 'Select a file',
                btnChange: 'Change',
                fileFormatError: 'File format not valid',
            },
        },
    },
};

import french from 'vee-validate/dist/locale/fr.json';
french.messages.required = 'Requis';
french.messages.email = 'Format non valide';
const validation = french.messages;
// custom Validations
validation.date = 'Le format de la date doit être AAAA-MM-JJ.';
validation.postalCode = 'Doit être au format A1B 2C3.';
validation.phone = 'Format non valide.';
validation.money = 'Format non valide.';
validation.isSame = 'Doit être différent de {otherValue}.';
validation.isDateBeforeEffectiveDate = 'La date entrée est avant la date effective du participant.';
validation.isDateBeforeMax = 'Le champ date doit être antérieur à {date}.';
validation.isDateAfter = 'Le champ date doit être supérieur à {date}.';
validation.isDateNotInFuture = 'La date ne peut pas être dans le futur.';
validation.maxAge = 'La date de naissance ne peut pas indiquer un âge de plus de {age} ans.';
validation.spouseBirthDateByAge = 'Le conjoint doit avoir au moins {age} ans.';
validation.participantBirthDateByAge = 'le participant doit avoir au moins {age} ans.';
validation.Sin = 'Entrer un numéro d\'assurance sociale valide.';
validation.fileMaxSize = 'Le fichier joint dépasse la taille maximale autorisée. Sélectionner un fichier de taille inférieure à {maxFileSize} Mo.';
validation.filesMaxSize = 'Les fichiers joints dépassent la taille maximale autorisée. Sélectionner des fichiers dont la taille totale est inférieure à {maxFileSize} Mo.';
validation.maxMonthsInFuture = 'La date entrée ne peut pas être de plus de {months} mois dans le futur.';
validation.anyFieldInteger = 'Le champ doit être numérique.';
validation.anyFieldMax = 'Le champ ne peut pas contenir plus de {length} caractères.';
validation.anyFieldMin = 'Le champ doit contenir au minimum {length} caractères.';
validation.unique = 'La valeur du champ doit être unique.';
export default validation;

import { cloneDeep, map } from 'lodash';
import I18n from '@/plugins/vue-i18n';
import { localized } from '@/utils/data-formatting/display/i18n';
export function formatGroup(group) {
    const newGroup = { ...group };
    newGroup.NameI18N = cloneDeep(newGroup.Name);
    newGroup.Name = localized(newGroup.Name, I18n.locale);
    newGroup.ClientNameI18N = cloneDeep(newGroup.ClientName);
    newGroup.ClientName = localized(newGroup.ClientName, I18n.locale);
    newGroup.Full = `${newGroup.Code} - ${newGroup.Name}`;
    newGroup.SelectLabel = `${newGroup.Code} - ${newGroup.Name}`;
    newGroup.ClientGroupName = `${newGroup.ClientName} ${newGroup.Name}`;
    return newGroup;
}
export function formatClient(client) {
    const newClient = { ...client };
    newClient.NameI18N = cloneDeep(newClient.Name);
    newClient.Name = localized(newClient.Name, I18n.locale);
    newClient.selectLabel = `${newClient.Code} - ${newClient.Name}`;
    newClient.groups = map(newClient.groups, (group) => formatGroup(group));
    return newClient;
}

import FieldsMixin from '@/mixins/components/fields';
import ChangeHighLight from '@/mixins/components/highlight';
export default {
    name: 'SFieldCheckbox',
    mixins: [
        ChangeHighLight,
        FieldsMixin,
    ],
    props: {
        color: {
            required: false,
            type: String,
            default: 'primary',
        },
        classes: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        singleLine: {
            type: Boolean,
            default: false,
        },
        slotTooltip: {
            type: Object,
            default: null,
        },
        value: {
            type: [String, Number, Boolean],
            default: 0,
        },
        labelClass: {
            type: String,
            default: '',
        },
    },
    computed: {
        classComputer() {
            if (this.classes) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.classesOutput.push(this.classes);
            }
            if (this.slotTooltip) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.classesOutput.push('s-checkbox-tooltip');
            }
            return this.classesOutput;
        },
    },
};

import SPageTitle from '@/components/ui/page-title.vue';
import SegicLayoutComponent from '@/mixins/segic-layout-component';
export default {
    name: 'DocumentsHeaderContent',
    components: {
        SPageTitle,
    },
    mixins: [
        SegicLayoutComponent,
    ],
};

import { isObject } from 'lodash';
import classError from 'segic_shared_front_end_utils/src/utils/onboarding/onboarding';
export default {
    name: 'SCellError',
    props: {
        text: {
            type: [String, Number, Object],
        },
        hasError: {
            required: true,
            type: Boolean,
        },
        cssClass: {
            default: '',
            type: String,
        },
    },
    methods: {
        cssClassFormatted() {
            const cssClass = `${classError(this.hasError)} ${this.cssClass}`;
            return cssClass.trim();
        },
        displayText(text) {
            if (!text) {
                return '';
            }
            return isObject(text) ? text[this.$i18n.locale] : text;
        },
    },
};

import MicroServices from '@/configs/MicroServices.types';
import proxy from '@/store/proxy';
import { getQueryString, replaceStringRoutingData, } from 'segic_shared_front_end_utils/src/utils/routing';
import messages from './locales';
export default {
    namespace: 'participants.dependent-report',
    uri: 'api/clients/:{clientGuid}/groups/:{groupGuid}/report/participants/R038',
    downloadReport(clientGuid, groupGuid, query) {
        const methodName = 'downloadReport';
        const service = MicroServices.BFF;
        const params = {
            clientGuid,
            groupGuid,
        };
        const url = this.uri;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            methodName,
            service,
            messages,
        };
        return proxy.get(replacedUrl, requestData, { responseType: 'blob' });
    },
};

import StoreExtender from '@/store/extender';
import { checkHasDifferences, setChangesObject } from '@/utils/data-compare';
import { EventBus } from '@/utils/event-bus';
import { cloneDeep, merge, set } from 'lodash';
import EVENTS from 'segic_shared_front_end_utils/src/constants/events';
import HTTP_CODES from 'segic_shared_front_end_utils/src/constants/http-codes';
import MESSAGE_TYPE from 'segic_shared_front_end_utils/src/constants/message-types';
import { isNotNullOrUndefined } from 'segic_shared_front_end_utils/src/utils/types';
export default {
    beforeMount() {
        this.resetForm();
    },
    props: {
        id: {
            default: null,
        },
        overwriteData: {
            default: null,
            type: Object,
        },
    },
    watch: {
        formData: {
            handler() {
                checkHasDifferences(this.defaultData, this.formData, this.initialFormData, this.formHasChangedValues);
                this.formHasChangedValues = merge({}, this.formHasChangedValues);
                if (this.formDataDebugging) {
                    console.log('FormData Watcher ::::::');
                    console.log('initialFormData', cloneDeep(this.initialFormData));
                    console.log('formData', cloneDeep(this.formData));
                    console.log('defaultData', cloneDeep(this.defaultData));
                    console.log('formHasChangedValues', cloneDeep(this.formHasChangedValues));
                }
                this.$emit('FORM_CHANGE', this.formChanged());
            },
            deep: true,
        },
    },
    data() {
        return {
            store: null,
            formData: {},
            initialFormData: {},
            defaultData: {},
            formHasChangedValues: {},
            formDataDebugging: false,
            formLoading: false,
            formFieldsFlags: {},
            formSendDataHasChanged: true,
            responseGetDataCode: null,
            ACTION: {
                CREATE: 'create',
                UPDATE: 'update',
            },
        };
    },
    methods: {
        formChanged() {
            return this.formHasChangedValues.hasChanged;
        },
        mergeData(data, overwriteData = {}) {
            return merge(this.defaultData, data, overwriteData);
        },
        updateField(key, value) {
            this.formData = { ...set(this.formData, key, isNotNullOrUndefined(value) ? value : null) };
        },
        resetForm() {
            if (typeof this.resetFormData === 'function') {
                if (this.$refs.observer) {
                    this.$refs.observer.reset();
                }
                this.resetFormData();
                this.defaultData = this.mergeData(this.defaultData, this.overwriteData);
                this.formHasChangedValues = setChangesObject(this.defaultData, this.formHasChangedValues);
                this.formData = cloneDeep(this.defaultData);
                this.initialFormData = cloneDeep(this.defaultData);
            }
            else {
                console.warn('missing resetFormData()');
            }
        },
        sendRequest() {
            this.$refs.observer.validate().then((valid) => {
                if (valid) {
                    this.sendData();
                }
            });
        },
        payloadData(formData) {
            return typeof this.formatFormData === 'function' ? this.formatFormData(formData) : formData;
        },
        actionSendData() {
            return this.id || this.forceUpdateSendData ? this.ACTION.UPDATE : this.ACTION.CREATE;
        },
        async getData(storeName, storeTemplate, extraParams = {}, action = 'getByID') {
            this.store = new StoreExtender(this, storeName, storeTemplate, { extraParams });
            const response = await this.store.sendActions(action);
            this.responseGetDataCode = response.code;
            this.formData = this.mergeData(response.data);
            this.initialFormData = this.mergeData(response.data);
            return response;
        },
        async sendData() {
            try {
                this.formLoading = true;
                const payload = {
                    data: this.payloadData(this.formData),
                };
                if (typeof this.setSubmitParams === 'function') {
                    payload.params = this.setSubmitParams(this.formData);
                }
                const action = this.actionSendData();
                const alertData = { message: '', type: 'success' };
                const response = await this.store.sendActions(action, payload);
                if (response.code === HTTP_CODES.OK) {
                    alertData.message = response.message;
                    this.formData = this.mergeData(response.data);
                    this.initialFormData = this.mergeData(response.data);
                    this.responseGetDataCode = this.ACTION.UPDATE;
                }
                if (typeof this.handleSendSuccess === 'function') {
                    this.handleSendSuccess(response);
                }
                else {
                    EventBus.$emit(EVENTS.SNACKBAR, alertData);
                }
            }
            catch (error) {
                if (typeof this.handleSendError === 'function') {
                    this.handleSendError(error);
                }
                else {
                    EventBus.$emit(EVENTS.SNACKBAR, { message: this.$t('api.generic.error'), type: MESSAGE_TYPE.ERROR });
                }
            }
        },
        validationOnUpdate(data) {
            const newData = {};
            newData[data.name] = data.flags;
            this.formFieldsFlags = merge({}, this.formFieldsFlags, newData);
        },
    },
};

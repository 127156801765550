var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"autocomplete":"off","tag":"form"}},[(_vm.isStepLoading)?_c('SDialogLoader',{staticClass:"s-step-loader-container"}):_c('VContainer',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('SDatatableBase',{attrs:{"disable-sort":true,"headers":_vm.headers,"hide-default-footer":_vm.items.length <= 100,"hide-top":true,"items":_vm.items,"items-per-page":100,"items-per-page-options":[100, 200, 300, -1],"classes":"s-dense-inputs-table","min-width":"725px","name-e2-e-suffix":"groupBenefits","outlined":""},scopedSlots:_vm._u([{key:"isDisabled",fn:function(ref){
var index = ref.index;
var ref_item = ref.item;
var isDisabled = ref_item.isDisabled;
var id = ref_item.id;
return [_c('SFieldCheckbox',{staticStyle:{"padding-left":"4px"},attrs:{"model":!isDisabled,"initial-model":_vm.initialFormData.content.groupBenefits ? !_vm.initialFormData.content.groupBenefits[index].isDisabled : true,"s-e2e":("isDisabled_" + id),"hideDetails":"","name":"isDisabled"},on:{"update:model":function($event){return _vm.handleUpdateIsDisabled($event, id)}}})]}},{key:"name",fn:function(ref){
var ref_item = ref.item;
var id = ref_item.id;
var ref_item_name = ref_item.name;
var description = ref_item_name.description;
var status = ref_item_name.status;
return [_c('SStatus',{attrs:{"s-e2e":("benefitStatusName_" + id),"status":status,"text":description,"name":"benefitStatusName"}})]}},_vm._l((_vm.mappingCodeHeaders),function(header,mappingCodeHeaderIndex){return {key:header.value,fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(!item.isDisabled)?_c('SMappingCode',{key:("mappingCode_" + mappingCodeHeaderIndex + "_" + (header.value)),attrs:{"id":item.id,"model":_vm.getMappingValueByCode(item.mappings, header.integrationType, header.recipientMappingCode),"initial-model":_vm.initialFormData.content.groupBenefits ? _vm.getMappingValueByCode(_vm.initialFormData.content.groupBenefits[index].mappings, header.integrationType, header.recipientMappingCode) : null,"has-warning":_vm.canShowMappingCodeWarning && !_vm.getMappingValueByCode(item.mappings, header.integrationType, header.recipientMappingCode),"s-e2e":("mappingCode_" + (header.name.replace(/\s/g,'')) + "_" + (item.id)),"name":"mappingCode","rules":_vm.rowMappingsValidationRules[item.id][mappingCodeHeaderIndex]},on:{"update:model":function($event){return _vm.handleUpdateMappings($event, item.id, header.integrationType, header.recipientMappingCode)}}}):_vm._e()]}}})],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
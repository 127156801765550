import { actionTemplate } from '@/store/actions';
import { isArray } from 'segic_shared_front_end_utils/src/utils/types';
import { cloneDeep, forEach, merge } from 'lodash';
function state(stateKey, value) {
    const result = {};
    result[stateKey] = {
        isLoading: false,
        value,
    };
    return result;
}
function statesFromData(data) {
    let finalData = {};
    data.forEach((stateData) => {
        const stateTmp = state(stateData.key, stateData.defaultValue);
        finalData = merge(finalData, stateTmp);
    });
    return finalData;
}
function getter(method, stateKey, formatting, isAdditionnal) {
    const result = {};
    result[method] = (getterState) => function getterMethod(payload) {
        if (formatting && typeof formatting === 'function' && getterState[stateKey].value) {
            const copiedData = cloneDeep(getterState[stateKey].value);
            return formatting(copiedData, payload);
        }
        return cloneDeep(getterState[stateKey].value);
    };
    if (!isAdditionnal) {
        result[`${method}IsLoading`] = (getterState) => getterState[stateKey].isLoading;
    }
    return result;
}
function gettersFromData(data) {
    let finalData = {};
    forEach(data, (stateData) => {
        forEach(stateData.methods, (method) => {
            finalData = merge(finalData, getter(method.key, stateData.key, method.displayFormatting, false));
        });
        if (stateData.additionalGetters && stateData.additionalGetters.length > 0) {
            forEach(stateData.additionalGetters, (method) => {
                finalData = merge(finalData, getter(method.key, stateData.key, method.displayFormatting, true));
            });
        }
    });
    return finalData;
}
function mutation(method, stateKey, formatting, requiredStores) {
    const result = {};
    const key = method.replace('get', 'set');
    result[key] = (stateData, payload) => {
        if (formatting && typeof formatting === 'function' && payload.data) {
            if (isArray(payload.data) && payload.data.length === 0) {
                stateData[stateKey].value = payload.data;
            }
            else {
                formatting.call(payload.scope, stateData, stateKey, payload.data, payload, requiredStores);
            }
        }
        else {
            stateData[stateKey].value = payload.data;
        }
    };
    result[`${key}IsLoading`] = (stateData, payload) => {
        stateData[stateKey].isLoading = payload;
    };
    return result;
}
function mutationsFromData(data) {
    let finalData = {};
    forEach(data, (stateData) => {
        forEach(stateData.methods, (method) => {
            finalData = merge(finalData, mutation(method.key, stateData.key, method.inputFormatting, method.requiredStores));
        });
    });
    return finalData;
}
function action(service, methodName, stateKey, defaultValue, methodData) {
    const result = {};
    result[methodName] = (context, payload) => {
        payload.isResettingData = Boolean(methodData.emptyResult);
        return actionTemplate(context, payload, service, methodName, stateKey, defaultValue, methodData.params, methodData.outputFormatting, methodData.cacheDelay, methodData.cacheBust);
    };
    return result;
}
function actionsFromData(data) {
    let finalData = {};
    forEach(data, (stateData) => {
        forEach(stateData.methods, (method) => {
            finalData = merge(finalData, action(stateData.service, method.key, stateData.key, stateData.defaultValue, method));
        });
    });
    return finalData;
}
export default {
    state,
    statesFromData,
    getter,
    gettersFromData,
    mutation,
    mutationsFromData,
    action,
    actionsFromData,
};

import SCellError from '@/page-layouts/administrator/onboarding/invite/dialog/component/send-invitation-cells/error.vue';
import { isObject } from 'lodash';
export default {
    name: 'SCellCodeText',
    components: { SCellError },
    props: {
        cssClass: {
            default: '',
            type: String,
        },
        text: {
            required: true,
            type: String,
        },
    },
    methods: {
        cssClassFormatted() {
            const { cssClass } = this;
            return cssClass.trim();
        },
        displayText(text) {
            if (!text) {
                return '';
            }
            return isObject(text) ? text[this.$i18n.locale] : text;
        },
    },
};

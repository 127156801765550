import { EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES } from '@/mixins/security/codes/employer-portal';
import { getEmptyComponents } from '@/utils/page-layouts';
import SECURITY_PERMISSIONS from 'segic_shared_front_end_utils/src/enums-bitwise/security-permissions';
import SHeaderContent from './header-content.vue';
const routes = [];
const children = [];
const components = getEmptyComponents();
components.default = () => import(/* webpackChunkName: "payrollList" */ './index.vue');
routes.push({
    name: 'payrollFiles.list',
    path: '/',
    components,
    children,
    meta: {
        resourcePermission: SECURITY_PERMISSIONS.VIEW,
        resource: EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.EMPLOYER_BILLING_AUTHORIZE_RESOURCE,
        headerComponents: {
            extension: {
                component: SHeaderContent,
                height: 75,
            },
        },
        title: 'payrollFiles',
    },
});
export default routes;

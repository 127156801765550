export function getEmptyView(name) {
    const routerViewHtml = (name && name !== 'default') ? `<router-view name="${name}"></router-view>` : '<router-view></router-view>';
    return { template: routerViewHtml };
}
// todo add core components named to be use at lower level
const coreComponentsList = [];
export function getEmptyComponents() {
    const components = {};
    for (let i = 0; i < coreComponentsList.length; i += 1) {
        components[coreComponentsList[i]] = getEmptyView(coreComponentsList[i]);
    }
    return components;
}

export default {
    fr: {
        step: {
            headers: {
                header0: 'Informations personnelles',
                header1: 'Informations d\'emploi',
                header2: 'Coordonnées',
                header3: 'Fichiers & messages',
                header4: 'Confirmer',
            },
        },
    },
    en: {
        step: {
            headers: {
                header0: 'Personal information',
                header1: 'Employment information',
                header2: 'Contact details',
                header3: 'Files & messages',
                header4: 'Confirm',
            },
        },
    },
};

import { cloneDeep, filter, find, forEach, last, } from 'lodash';
import StoreBasics from '@/store/basics';
import storeUtils from '@/store/utils';
import { isDateSameOrAfter } from '@/utils/helpers';
import entities from './entities';
import Service from './service';
const cacheDelay = 0;
const storeData = [
    {
        key: 'data',
        defaultValue: [],
        methods: [
            {
                key: 'get',
                params: ['clientGuid', 'groupGuid', 'participantGuid', 'data'],
                cacheDelay,
                inputFormatting(state, stateKey, data) {
                    const newData = data.map((value) => entities.input(value));
                    let value = find(newData, (item) => isDateSameOrAfter(item.EffectiveDate));
                    if (!value) {
                        value = last(newData);
                    }
                    state[stateKey].value = value;
                    state.history.value = newData;
                },
                displayFormatting(data) {
                    return entities.display(data);
                },
            },
        ],
        service: Service,
    },
    {
        key: 'history',
        defaultValue: [],
        additionalGetters: [
            {
                key: 'getHistory',
            },
        ],
    },
];
const state = storeUtils.mergeStates(StoreBasics.statesFromData(storeData));
const getters = storeUtils.mergeGetters(StoreBasics.gettersFromData(storeData), {
    // eslint-disable-next-line no-shadow
    getHistory(state) {
        const newData = [];
        forEach(state.history.value, (item) => {
            newData.push(entities.display(item));
        });
        return cloneDeep(filter(newData, { IsRecordCanceled: false }));
    },
});
const mutations = storeUtils.mergeMutations(StoreBasics.mutationsFromData(storeData));
const actions = storeUtils.mergeActions(StoreBasics.actionsFromData(storeData));
const ParticipantsDivisionStore = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default ParticipantsDivisionStore;

import { formatDate } from 'segic_shared_front_end_utils/src/utils/date';
export default {
    input(data) {
        return {
            ...data,
            profile: {
                ...data.profile,
                birthDate: formatDate(data.profile.birthDate),
                spouseBirthDate: formatDate(data.profile.spouseBirthDate),
            },
        };
    },
};

// eslint-disable-next-line no-shadow
var MicroServices;
(function (MicroServices) {
    MicroServices["BFF"] = "BFF";
    MicroServices["CLAIMS"] = "claims";
    MicroServices["CLIENT_STRUCTURE"] = "clientStructureMS";
    MicroServices["COMPOSITION"] = "composition";
    MicroServices["MESSAGING"] = "messaging";
    MicroServices["PARTICIPANT"] = "participant";
    MicroServices["PARTICIPANT_MS"] = "participantMS";
    MicroServices["PLATFORM"] = "platform";
    MicroServices["PORTAL_PARTICIPANT"] = "portalParticipant";
    MicroServices["PREMIUM_MS"] = "premiumMS";
    MicroServices["REPORTING_MS"] = "reportingMS";
    MicroServices["SECURITY_MS"] = "securityMS";
})(MicroServices || (MicroServices = {}));
export default MicroServices;

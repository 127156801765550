import { forEach } from 'lodash';
import SETTINGS from 'segic_shared_front_end_utils/src/constants/settings';
import { isJson } from 'segic_shared_front_end_utils/src/utils/types';
export function decode(str) {
    const element = document.createElement('div');
    let result = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
    result = result.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
    element.innerHTML = result;
    result = element.textContent;
    element.textContent = '';
    element.remove();
    return result;
}
export function i18n(value) {
    if (!value || typeof value !== 'string') {
        return value;
    }
    let json = value;
    if (value.indexOf('[{"') === -1) {
        json = value.replace(/'([^}:]+)'/g, '"$1"').replace(/',[ ]?'/g, '","');
    }
    if (!isJson(json)) {
        if (json.indexOf('[{') !== -1 && window.location.hostname === 'localhost') {
            throw new Error(`Invalide json: ${json}`);
        }
        return value;
    }
    const languages = SETTINGS.LANGUAGES;
    const result = {};
    const fieldString = JSON.parse(json);
    forEach(fieldString, (item) => {
        const i18nValue = item.Value;
        result[languages[item.Code - 1]] = decode(i18nValue);
    });
    return result;
}
export function i18nEmpty() {
    const result = {};
    forEach(SETTINGS.LANGUAGES, (value) => {
        result[value] = '';
    });
    return result;
}
export function localized(data, lang, placeholder) {
    let string = (placeholder) || null;
    if (data) {
        if (typeof data[lang] !== 'string') {
            console.warn('language not supported for that string:', lang, data);
        }
        string = data[lang];
    }
    return string;
}

import { get } from 'lodash';
export function displayTooltipStatus(status, statusName) {
    let tooltip = status;
    if (status !== statusName) {
        tooltip += ` (${statusName})`;
    }
    return tooltip;
}
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export function sortWithAccents(array, key) {
    if (!array || !key) {
        return [];
    }
    if (array.length === 1) {
        return array;
    }
    return array.sort((a, b) => get(a, key).localeCompare(get(b, key)));
}
export function removeSpecialCharacters(str) {
    if (!str) {
        return '';
    }
    return str.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, ' '); // Remove accents
}
export function replaceSpaceForUnderscore(str) {
    if (!str) {
        return '';
    }
    return str.replace(/ /g, '_'); // Remove accents
}
export function getSortingKey(str) {
    if (!str) {
        return '';
    }
    return replaceSpaceForUnderscore(removeSpecialCharacters(str)).toLowerCase();
}
export function normalize(str) {
    if (!str) {
        return '';
    }
    return removeSpecialCharacters(str) // Remove accents
        .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
        .replace(/\-\-+/g, '-') // Replaces multiple hyphens by one hyphen
        .replace(/(^-+|-+$)/, ''); // Remove extra hyphens from beginning or end of the string
}
function removeLastCommaAndTrim(string) {
    return string.replace(/,\s*$/, '').trim();
}
export function removeAllSpace(string) {
    if (typeof string !== 'string') {
        return string;
    }
    return string.replace(/ /g, '');
}
export function toMailto(email) {
    return `<a href="mailto:${email}">${email}</a>`;
}
const StringUtils = {
    removeLastCommaAndTrim,
};
export default StringUtils;

import SFieldCombobox from '@/components/form/fields/combobox.vue';
import SFieldText from '@/components/form/fields/text.vue';
import ComponentMixins from '@/mixins/segic-component';
import { find } from 'lodash';
import { getI18nWithSameValue } from 'segic_shared_front_end_utils/src/utils/i18n';
export default {
    name: 'SOccupationField',
    components: { SFieldCombobox, SFieldText },
    mixins: [ComponentMixins],
    props: {
        appendLabel: {
            default: null,
            type: String,
        },
        dense: {
            default: false,
            type: Boolean,
        },
        label: {
            default: null,
            type: String,
        },
        labelOnTop: {
            default: false,
            type: Boolean,
        },
        model: {
            required: true,
            type: Object,
        },
        name: {
            required: true,
            type: String,
        },
        occupationIsLoading: {
            default: false,
            type: Boolean,
        },
        occupations: {
            default: () => [],
            type: Array,
        },
        occupationsToExclude: {
            default: () => [],
            type: Array,
        },
        outlined: {
            default: false,
            type: Boolean,
        },
        rules: {
            default: '',
            type: [Object, String],
        },
        singleLine: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            searchValue: '',
            noDataTextOccupation: '',
            finalOccupations: [],
        };
    },
    computed: {
        noDataText() {
            return this.$t('noOccupationData', { newValue: this.noDataTextOccupation });
        },
    },
    watch: {
        occupationIsLoading(newVal, oldVal) {
            if (newVal !== oldVal && !newVal) {
                this.finalOccupations = this.setOccupations(this.occupations, this.occupationsToExclude);
                this.emitModelWithoutDescription(this.finalOccupations, this.model, this.name);
            }
        },
    },
    methods: {
        handleInputChanged(val) {
            this.noDataTextOccupation = val;
        },
        emitModelWithoutDescription(occupations, model, name) {
            if (model.occupationId && model.description === '') {
                const occupation = occupations.find((item) => item.occupationId === this.model.occupationId);
                this.$emit('update:model', occupation, name);
            }
        },
        setOccupations(occupations, occupationsToExclude) {
            if (occupationsToExclude.length === 0) {
                return occupations;
            }
            return occupations.filter((occupation) => occupationsToExclude.indexOf(occupation.occupationId) === -1);
        },
        getOccupationInOccupationList(value) {
            let data = value;
            if (typeof value === 'string') {
                const occupation = find(this.finalOccupations, (item) => item.description[this.$i18n.locale].toLowerCase() === value.toLowerCase());
                if (occupation) {
                    data = occupation;
                }
                else {
                    data = {
                        occupationId: null,
                        description: getI18nWithSameValue(value),
                        notListedValue: value,
                    };
                }
            }
            return data;
        },
        update(value, name) {
            const data = this.getOccupationInOccupationList(value);
            this.$emit('update:model', data, name);
        },
    },
    i18n: {
        messages: {
            fr: {
                occupationHint: 'Veuillez saisir l\'occupation si elle n\'existe pas dans la liste',
                noOccupationData: 'Aucune donnée n\'a été trouvée pour <strong>{newValue}</strong>.<br/>Appuyez sur <kbd>Entrer</kbd> pour ajouter une nouvelle occupation à la liste.',
            },
            en: {
                occupationHint: 'Enter the occupation if it doesn\'t exist in the list',
                noOccupationData: 'No results matching <strong>{newValue}</strong>. Press <kbd>Enter</kbd> to create a new occupation.',
            },
        },
    },
};

import { checkAccess } from '@/mixins/security';
import { EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES } from '@/mixins/security/codes/employer-portal';
import { getEmptyComponents } from '@/utils/page-layouts';
import SECURITY_PERMISSIONS from 'segic_shared_front_end_utils/src/enums-bitwise/security-permissions';
import FollowUp from './follow-up/router';
import Invite from './invite/router';
import ONBOARDING_ROUTE_NAME from './route-name';
const routes = [];
let children = [];
children = children.concat(Invite);
children = children.concat(FollowUp);
const components = getEmptyComponents();
components.default = () => import(/* webpackChunkName: "participants" */ './index.vue');
routes.push({
    name: ONBOARDING_ROUTE_NAME.BASE,
    path: 'onboarding',
    redirect: {
        name: ONBOARDING_ROUTE_NAME.INVITE,
    },
    components,
    children,
    meta: {
        resourcePermission: SECURITY_PERMISSIONS.VIEW,
        resource: EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.ONBOARDING_RESOURCE,
        menuTitle: 'onboarding',
        icon: 's-icon-self-enrollment',
        conditionalRedirect(route, securityPermissionSets) {
            if (checkAccess(SECURITY_PERMISSIONS.VIEW_EDIT, EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.ONBOARDING_RESOURCE, securityPermissionSets)) {
                route.redirect.name = ONBOARDING_ROUTE_NAME.INVITE;
            }
            else if (checkAccess(SECURITY_PERMISSIONS.VIEW, EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.ONBOARDING_RESOURCE, securityPermissionSets)) {
                route.redirect.name = ONBOARDING_ROUTE_NAME.FOLLOWUP;
            }
        },
    },
});
export default routes;

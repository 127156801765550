import HTTP_CODES from 'segic_shared_front_end_utils/src/constants/http-codes';
const FR = {
    get: {
        [HTTP_CODES.OK]: '',
    },
    create: {
        [HTTP_CODES.OK]: 'Votre demande de changement a été envoyée avec succès.',
        '0018': 'Le Numéro d\'Assurance Sociale (NAS) que vous avez entré est invalide. Veuillez saisir un NAS valide de 9 chiffres.',
    },
    delete: {
        [HTTP_CODES.OK]: 'La demande a été supprimée.',
    },
    update: {
        [HTTP_CODES.OK]: 'La demande a été modifiée.',
        '0018': 'Le Numéro d\'Assurance Sociale (NAS) que vous avez entré est invalide. Veuillez saisir un NAS valide de 9 chiffres.',
    },
};
const EN = {
    get: {
        [HTTP_CODES.OK]: '',
    },
    create: {
        [HTTP_CODES.OK]: 'Your change request has been sent successfully.',
        '0018': 'The Social Insurance Number (SIN) you have entered is invalid. Please input a valid 9-digit SIN.',
    },
    delete: {
        [HTTP_CODES.OK]: 'The request has been deleted.',
    },
    update: {
        [HTTP_CODES.OK]: 'The request has been updated.',
        '0018': 'The Social Insurance Number (SIN) you have entered is invalid. Please input a valid 9-digit SIN.',
    },
};
export default {
    fr: FR,
    en: EN,
};

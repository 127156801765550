import SComponentDialog from '@/components/dialogs/dialog.vue';
import ComponentMixins from '@/mixins/segic-component';
// CONFIRM CANCEL
import DialogConfirmCancelMixin from '@/page-layouts/administrator/dialogs/confirm-cancel';
import SPageLayoutsParticipantAddParticipantForm from './form.vue';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantDialog',
    components: {
        SComponentDialog,
        SPageLayoutsParticipantAddParticipantForm,
    },
    mixins: [ComponentMixins, DialogConfirmCancelMixin],
    props: {
        showDialogAddParticipantForm: {
            required: true,
            type: Boolean,
        },
    },
};

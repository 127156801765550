import { getEnumValue, getEnumValues, toValuesEnum } from './_utils';
export var ONBOARDING_PLAN_MEMBER_FILE_STATUS;
(function (ONBOARDING_PLAN_MEMBER_FILE_STATUS) {
    ONBOARDING_PLAN_MEMBER_FILE_STATUS[ONBOARDING_PLAN_MEMBER_FILE_STATUS["VALID"] = 1] = "VALID";
    ONBOARDING_PLAN_MEMBER_FILE_STATUS[ONBOARDING_PLAN_MEMBER_FILE_STATUS["ON_ERROR"] = 2] = "ON_ERROR";
    ONBOARDING_PLAN_MEMBER_FILE_STATUS[ONBOARDING_PLAN_MEMBER_FILE_STATUS["CORRUPTED"] = 3] = "CORRUPTED";
    ONBOARDING_PLAN_MEMBER_FILE_STATUS[ONBOARDING_PLAN_MEMBER_FILE_STATUS["EMPTY"] = 4] = "EMPTY";
})(ONBOARDING_PLAN_MEMBER_FILE_STATUS || (ONBOARDING_PLAN_MEMBER_FILE_STATUS = {}));
const values = toValuesEnum(ONBOARDING_PLAN_MEMBER_FILE_STATUS);
const FR = {
    [ONBOARDING_PLAN_MEMBER_FILE_STATUS.VALID]: 'Fichier valide',
    [ONBOARDING_PLAN_MEMBER_FILE_STATUS.ON_ERROR]: 'Fichier en erreur',
    [ONBOARDING_PLAN_MEMBER_FILE_STATUS.CORRUPTED]: 'Fichier corrompu',
    [ONBOARDING_PLAN_MEMBER_FILE_STATUS.EMPTY]: 'Fichier vide',
};
const EN = {
    [ONBOARDING_PLAN_MEMBER_FILE_STATUS.VALID]: 'Valid file',
    [ONBOARDING_PLAN_MEMBER_FILE_STATUS.ON_ERROR]: 'Error file',
    [ONBOARDING_PLAN_MEMBER_FILE_STATUS.CORRUPTED]: 'Corrupted file',
    [ONBOARDING_PLAN_MEMBER_FILE_STATUS.EMPTY]: 'Empty file',
};
const Dictionaries = {
    fr: FR,
    en: EN,
};
export function getOnboardingPlanMemberFileStatus(code, lang) {
    return getEnumValue(values, Dictionaries, lang, code);
}
export function getOnboardingPlanMemberFileStatusesByLang(lang) {
    return getEnumValues(values, Dictionaries, lang);
}
export function isOnboardingPlanMemberFileHasError(code) {
    return code === ONBOARDING_PLAN_MEMBER_FILE_STATUS.ON_ERROR;
}
export function isOnboardingPlanMemberFileValid(code) {
    return code === ONBOARDING_PLAN_MEMBER_FILE_STATUS.VALID || isOnboardingPlanMemberFileHasError(code);
}

import SPageTitle from '@/components/ui/page-title.vue';
import storeMixin from '@/mixins/page-layout/store';
import { EventBus } from '@/utils/event-bus';
import EVENTS from 'segic_shared_front_end_utils/src/constants/events';
import SegicLayoutComponent from '@/mixins/segic-layout-component';
import AddParticipant from '@/page-layouts/administrator/forms/add-participant/index.vue';
import ParticipantsDependentsReport from '@/page-layouts/administrator/participants/list/participant-dependent-report-R038/index.vue';
export default {
    name: 'SearchParticipant',
    components: {
        SPageTitle,
        's-participants-dependents-report-r-038': ParticipantsDependentsReport,
        's-add-participant': AddParticipant,
    },
    mixins: [
        SegicLayoutComponent,
        storeMixin,
    ],
    data() {
        return {
            appliedFiltersQuantity: null,
            filterOn: false,
            hasChanged: false,
        };
    },
    mounted() {
        EventBus.$on(EVENTS.TOGGLE.PARTICIPANT_LIST_FILTERS, this.handleParticipantList);
    },
    beforeDestroy() {
        EventBus.$off(EVENTS.TOGGLE.PARTICIPANT_LIST_FILTERS);
    },
    methods: {
        handleParticipantList(data) {
            this.hasChanged = data.hasChanged;
            this.filterOn = data.filterOn;
            this.appliedFiltersQuantity = data.appliedFiltersQuantity;
        },
        sendEvent() {
            this.toggleFilter();
            EventBus.$emit(EVENTS.TOGGLE.PARTICIPANT_HEADER_FILTERS, this.filterOn);
        },
        toggleFilter() {
            this.filterOn = !this.filterOn;
        },
    },
};

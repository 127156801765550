import { isNullOrUndefined } from '@/utils/helpers';
import { getStatusCssClassColor } from 'segic_shared_front_end_utils/src/enums/status';
/**
   * @displayName Status with text
   * */
export default {
    name: 'SDisplayStatus',
    props: {
        appendColor: {
            type: String,
            default: 'warning',
        },
        appendIcon: {
            type: String,
            default: 'warning',
        },
        appendTooltip: {
            type: Boolean,
            default: false,
        },
        appendTooltipText: {
            type: String,
            default: '',
        },
        bold: {
            default: false,
            type: Boolean,
        },
        disabledAppendToolTip: {
            type: Boolean,
            default: false,
        },
        icon: {
            default: 'circle',
        },
        name: {
            type: String,
        },
        /**
           * Status base on the enum.
           */
        status: {
            type: Number,
            required: true,
        },
        /**
           * Function for the right color status.
           */
        statusColor: {
            type: Function,
        },
        /**
           * Text.
           */
        text: {
            type: String,
        },
        /**
           * Tooltip for the status.
           */
        tooltip: {
            type: String,
        },
        /**
           * Make the component samaller.
           */
        xSmall: {
            type: Boolean,
        },
    },
    computed: {
        /**
           * Return color dot
           * */
        color() {
            if (this.statusColor) {
                return this.statusColor(this.status);
            }
            return getStatusCssClassColor(this.status);
        },
        cssClassText() {
            let cssClass = this.bold ? 'font-weight-medium' : '';
            if (this.xSmall) {
                cssClass += ' s-font-12';
            }
            else {
                cssClass += ' s-font-14 font-weight-medium';
            }
            return cssClass;
        },
        /**
           * If no tooltips disabled tooltip
           * */
        disabledToolTip() {
            return isNullOrUndefined(this.tooltip);
        },
    },
};

export default {
    currency: '{currency} $',
    days: '{count} jour | {count} jour | {count} jours',
    download: 'Télécharger',
    effectiveDateFuture: 'Date d\'entrée en vigueur',
    error: 'Une erreur est survenue.',
    insuredCapitalLabels: {
        fixedAmount: 'Montant forfaitaire à {amount}',
        unitValue: 'Unités de {units}',
        salaryMultipleWeekly: '{times} fois le salaire hebdomadaire',
        salaryMultipleMonthly: '{times} fois le salaire mensuel',
        salaryMultipleYearly: '{times} fois le salaire annuel',
        firstPercentage: 'du premier',
        remaining: 'du reste',
        nextPercentage: 'du suivant',
        salaryPercentageWeekly: '{percentage}% du salaire hebdomadaire',
        salaryPercentageMonthly: '{percentage}% du salaire mensuel',
        salaryPercentageYearly: '{percentage}% du salaire annuel',
    },
    label: 'Étiquette',
    months: '{count} mois | {count} mois',
    next: 'Suivant',
    previous: 'Retour',
    protection: 'Protection',
    reinitialize: 'Réinitialiser',
    removeWaitingPeriod: 'Supprimer la période d\'admissibilité',
    residentialProvince: 'Province de résidence',
    recipient: 'Déductions de la paie',
    requestedChange: 'Changement demandé',
    requestsChange: 'Demande de changement',
    requestDescription: 'Description des changements',
    referenceNumber: 'Numéro de référence',
    requestNumber: 'No demande',
    reset: 'Réinitialiser',
    save: 'Sauvegarder',
    schoolAttendanceConfirmation: 'Attestation scolaire',
    search: 'Rechercher',
    send: 'Envoyer',
    sendRequest: 'Envoyer la demande',
    sendFiles: 'Envoyer des documents',
    sendNewSchoolAttendanceConfirmation: 'Envoyer une confirmation de fréquentation scolaire',
    simulationDate: 'Date de la simulation',
    since: 'Depuis le',
    smokerStatus: 'Statut fumeur',
    socialInsuranceNumber: 'N.A.S.',
    spouse: 'Conjoint',
    spouseChildAmount: 'Conjoint: {spouseAmount} <br/> Enfant: {childAmount}',
    spouseMan: 'Conjoint',
    spouseWoman: 'Conjointe',
    start: 'Début',
    statusRequest: 'Statut de la demande',
    stateWithDate: '{state} depuis le {date}',
    stateFutureWithDate: '{state} le {date}',
    type: 'Type',
    willBeActive: '<span class="caption--text">Sera actif le</span> <span class="title_color--text font-weight-bold">{effectiveDate}</span>',
    work: 'Emploi',
    workProvince: 'Province d\'emploi',
    years: '{count} an | {count} ans',
    yes: 'Oui',
};

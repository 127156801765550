// Faire a attention a l'ordre des imports, s'il bouge ca brise le css.
import modifyLocale from '@/utils/localeHelper';
import Vue from 'vue';
import router from '@/startup/router';
import VueRouter from 'vue-router';
import i18n from '@/plugins/vue-i18n';
import vuetify from '@/startup/vuetify-theme';
import store from '@/store';
import browserDetect from 'vue-browser-detect-plugin';
import VueTheMask from 'vue-the-mask';
import AuthService from '@/plugins/msal';
import device from '@/startup/device';
import '@/plugins/idle-vue';
import '@/plugins/service-worker';
import '@/plugins/vee-validate';
import VueI18n from 'vue-i18n';
import App from '@/app.vue';
import SegicSharedComponents from 'segic_shared_front_end_components';
Vue.use(VueRouter);
Vue.prototype.$locale = {
    change(lang) {
        modifyLocale(lang);
    },
};
Vue.use(browserDetect);
Vue.use(VueTheMask);
Vue.use(SegicSharedComponents, { VueI18n });
Vue.prototype.$authService = AuthService;
Vue.prototype.$device = device;
Vue.config.devtools = process.env.NODE_ENV === 'development';
Vue.config.productionTip = process.env.NODE_ENV === 'development';
const VueApp = new Vue({
    i18n,
    router,
    vuetify,
    store,
    render: (h) => h(App),
}).$mount('#app');
export default VueApp;

import { camelCase, forEach, mapKeys } from 'lodash';
import SETTINGS from 'segic_shared_front_end_utils/src/constants/settings';
const entities = {
    decode(str) {
        const element = document.createElement('div');
        // eslint-disable-next-line no-shadow
        function decodeHTMLEntities(str) {
            let result;
            if (str && typeof str === 'string') {
                // strip script/html tags
                result = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
                result = result.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
                element.innerHTML = result;
                result = element.textContent;
                element.textContent = '';
            }
            return result || str;
        }
        return decodeHTMLEntities(str);
    },
};
export function i18nEmpty() {
    const i18n = {};
    forEach(SETTINGS.LANGUAGES, (value) => {
        i18n[value] = '';
    });
    return i18n;
}
export function cultureName(items) {
    if (!Array.isArray(items)) {
        return items;
    }
    if (!items) {
        return i18nEmpty();
    }
    const i18n = {};
    forEach(items, (item) => {
        const value = (mapKeys(item, (v, k) => camelCase(k)));
        const i18nValue = value.text;
        const lang = value.cultureName.replace(/-../g, '');
        i18n[lang] = entities.decode(i18nValue);
    });
    return i18n;
}

import { forEach } from 'lodash';
import i18n from '@/plugins/vue-i18n';
import moment from 'moment';
import { NULL_DATE_TIME } from 'segic_shared_front_end_utils/src/constants/null';
/* Possible type: unix, moment */
// eslint-disable-next-line no-shadow
export function date(dateTime, type) {
    if (!dateTime) {
        return undefined;
    }
    // eslint-disable-next-line no-shadow
    function getFormattedDate(dateTime, type) {
        let returnData = moment(dateTime);
        if (!type) {
            returnData = returnData.format(i18n.t('format.date.view'));
        }
        else if (type === 'unix') {
            returnData = returnData.valueOf();
        }
        return (dateTime !== NULL_DATE_TIME) ? returnData : null;
    }
    let returnData;
    if (typeof dateTime === 'object' && dateTime.length) {
        returnData = [];
        forEach(dateTime, (item) => {
            returnData.push(getFormattedDate(item, type));
        });
    }
    else {
        returnData = getFormattedDate(dateTime, type);
    }
    return returnData;
}
/* Possible type: unix, moment */
// eslint-disable-next-line no-shadow
export function time(dateTime, type) {
    if (!dateTime) {
        return undefined;
    }
    // eslint-disable-next-line no-shadow
    function getFormattedTime(dateTime, type) {
        let returnData = moment(dateTime);
        if (!type) {
            returnData = returnData.format(i18n.t('format.time.view'));
        }
        else if (type === 'unix') {
            returnData = returnData.valueOf();
        }
        return (dateTime !== NULL_DATE_TIME) ? returnData : null;
    }
    let returnData;
    if (typeof dateTime === 'object' && dateTime.length) {
        returnData = [];
        forEach(dateTime, (item) => {
            returnData.push(getFormattedTime(item, type));
        });
    }
    else {
        returnData = getFormattedTime(dateTime, type);
    }
    return returnData;
}
/* Possible type: unix, moment */
// eslint-disable-next-line no-shadow
export function dateTime(dateTime, type) {
    if (!dateTime) {
        return undefined;
    }
    // eslint-disable-next-line no-shadow
    function getFormattedDateTime(dateTime, type) {
        let returnData;
        if (typeof dateTime === 'string') {
            if (dateTime.indexOf('T') !== -1 && dateTime.indexOf('Z') !== -1) {
                returnData = moment(`${dateTime}`).utc().local();
            }
            else if (dateTime.indexOf('T') !== -1) {
                returnData = moment(`${dateTime}Z`).utc().local();
            }
        }
        else {
            returnData = moment(dateTime);
        }
        if (!type) {
            returnData = returnData.format(i18n.t('format.dateTime.view'));
        }
        else if (type === 'unix') {
            returnData = returnData.valueOf();
        }
        return (dateTime !== `${NULL_DATE_TIME}Z`) ? returnData : null;
    }
    let returnData;
    if (typeof dateTime === 'object' && dateTime.length) {
        returnData = [];
        forEach(dateTime, (item) => {
            returnData.push(getFormattedDateTime(item, type));
        });
    }
    else {
        returnData = getFormattedDateTime(dateTime, type);
    }
    return returnData;
}
// eslint-disable-next-line no-shadow
export function age(dateTime) {
    const birthday = date(dateTime, 'moment');
    const now = moment();
    const nbYears = now.diff(birthday, 'years');
    const nbMonths = Math.round(now.diff(birthday, 'months', true));
    const nbDays = Math.round(now.diff(birthday, 'days', true));
    let result = i18n.tc('common.labels.days', nbDays, { count: nbDays });
    if (nbYears) {
        result = i18n.tc('common.labels.years', nbYears, { count: nbYears });
    }
    else if (nbMonths) {
        result = i18n.tc('common.labels.months', nbMonths, { count: nbMonths });
    }
    return {
        value: result,
        date: date(dateTime),
    };
}
/* Possible type: unix, moment */
// eslint-disable-next-line no-shadow
export function getDate(dateTime, type) {
    if (!dateTime) {
        return undefined;
    }
    // eslint-disable-next-line no-shadow
    function getFormattedDate(dateTime, type) {
        let returnData = moment(dateTime);
        if (!type && i18n) {
            returnData = returnData.format(i18n.t('format.date.view'));
        }
        else if (type === 'unix') {
            returnData = returnData.valueOf();
        }
        return (dateTime !== NULL_DATE_TIME) ? returnData : null;
    }
    let returnData;
    if (typeof dateTime === 'object' && dateTime.length) {
        returnData = [];
        forEach(dateTime, (item) => {
            returnData.push(getFormattedDate(item, type));
        });
    }
    else {
        returnData = getFormattedDate(dateTime, type);
    }
    return returnData;
}
export const dateFilter = (value) => moment(value).format('YYYY-MM-DD');
export function displayDate(value) {
    const lang = moment.locale();
    const format = i18n.t('format.displayDate').toString();
    moment.locale(i18n.locale);
    const result = moment(value).format(format);
    moment.locale(lang);
    return result;
}
export function dateMonth(value) {
    return moment(value).format(i18n.t('format.monthDate.view').toString());
}

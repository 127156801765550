import LayoutComponentMixins from '@/mixins/segic-layout-component';
import SPageTitle from '@/components/ui/page-title.vue';
import ONBOARDING_ROUTE_NAME from '@/page-layouts/administrator/onboarding/route-name';
import SECURITY_PERMISSIONS from 'segic_shared_front_end_utils/src/enums-bitwise/security-permissions';
import { EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES } from '@/mixins/security/codes/employer-portal';
export default {
    components: { SPageTitle },
    mixins: [LayoutComponentMixins],
    data() {
        return {
            tab: null,
        };
    },
    computed: {
        tabs() {
            let tabs = [];
            if (this.checkAccess(SECURITY_PERMISSIONS.VIEW_EDIT, EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.ONBOARDING_RESOURCE)) {
                tabs = [{
                        name: ONBOARDING_ROUTE_NAME.INVITE,
                        label: 'inviteNewPlanMembers',
                    }, {
                        name: ONBOARDING_ROUTE_NAME.FOLLOWUP,
                        label: 'followUp',
                    }];
            }
            return tabs;
        },
    },
};

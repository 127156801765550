import I18n from '@/plugins/vue-i18n';
import { i18n } from '@/utils/data-formatting/display/i18n';
import StatusData from '@/utils/status';
import { date } from '@/utils/data-formatting/service-input/dates';
import Status from '@/components/ui/status.vue';
import Moment from 'moment';
export default {
    input(data) {
        const EffectiveDate = date(data.EffectiveDate);
        return {
            ...data,
            EffectiveDate,
            isFutureParticipant: Moment(EffectiveDate).isAfter(),
            Status: {
                Description: i18n(StatusData[data.SystemParticipantStatusId].Description),
                Code: StatusData[data.SystemParticipantStatusId].Code,
            },
        };
    },
    display(data) {
        data.ComponentStatus = {
            component: Status,
            props: {
                status: data.Status.Code,
                text: data.Status.Description[I18n.locale],
            },
        };
        if (data.isFutureParticipant) {
            const tooltipData = {
                state: data.Status.Description[I18n.locale],
                date: data.EffectiveDate,
            };
            data.ComponentStatus.props.icon = 'trip_origin';
            data.ComponentStatus.props.text = I18n.t('common.stateFutureWithDate', tooltipData);
        }
        return data;
    },
};

import shared from 'segic_shared_front_end_utils/src/locales/en';
import api from './en/api';
import common from './en/common';
import format from './en/format';
import global from './en/global';
import title from './en/title';
import validation from './en/validation';
/* COMMONS */
const en = {
    api,
    common,
    format,
    title,
    validation,
    ...global,
    ...shared,
    activePlanMembersOnly: 'Active plan members only',
    actions: 'Actions',
};
export default en;

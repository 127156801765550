import EXEMPTED_VALUE from '@/page-layouts/administrator/forms/protections-change-request/protection-exempted-value';
import { currencyWithoutSeparator, isNotNullOrUndefined, maskedPhoneNumber, unMaskedPhone, unMaskNas, } from '@/utils/helpers';
import { forEach, isEqual } from 'lodash';
import CIVIL_STATUS, { isCommonLawSpouse } from 'segic_shared_front_end_utils/src/enums/civil-status';
import MESSAGE_CONTENT_TYPE, { isGeneralChangeRequest } from 'segic_shared_front_end_utils/src/enums/message-content-type';
import { isNullOrUndefinedOrEmptyString } from 'segic_shared_front_end_utils/src/utils/types';
function formatProtectionBenefits(protectionBenefits) {
    return protectionBenefits.filter((protectionBenefit) => !protectionBenefit.benefitsGroupingId);
}
function formatProtectionBenefitsGroupings(protectionBenefits) {
    return protectionBenefits.filter((protectionBenefit) => protectionBenefit.benefitsGroupingId);
}
export function removeEmptyPhones(phones, mask = true) {
    const newPhones = [];
    phones.forEach((phone) => {
        if (isNotNullOrUndefined(phone.phoneType) && phone.number !== '') {
            newPhones.push({
                phoneType: phone.phoneType,
                extension: phone.extension,
                number: mask ? maskedPhoneNumber(phone.number) : unMaskedPhone(phone.number),
            });
        }
    });
    return newPhones.length !== 0 ? newPhones : null;
}
export function formatOccupation(occupation) {
    if (isNullOrUndefinedOrEmptyString(occupation.occupationId) && isNullOrUndefinedOrEmptyString(occupation.notListedValue)) {
        return null;
    }
    return {
        occupationId: occupation.occupationId,
        notListedValue: occupation.notListedValue,
    };
}
export default {
    outputUpdate(data) {
        const result = this.outputCreate(data);
        delete result.displayCreated;
        delete result.dateCreated;
        delete result.displayParticipantName;
        if (!data.participantId) {
            delete result.participantId;
        }
        return result;
    },
    outputCreate(data) {
        data.hasAttachments = data.attachments.length !== 0;
        delete data.displayAttachments;
        delete data.deleteAttachmentIds;
        delete data.participant;
        delete data.attachments;
        if (isGeneralChangeRequest(data.contentType)) {
            data.content.description = data.content.note;
            delete data.content.note;
        }
        else if (data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.PHONE_CHANGE_REQUEST) {
            const changesPhones = [];
            forEach(data.content.changePhones, (value) => {
                if (value.actualValue) {
                    delete value.actualValue.display;
                    delete value.actualValue.displayType;
                    delete value.actualValue.isDeleted;
                    value.actualValue.number = unMaskedPhone(value.actualValue.number);
                }
                if (value.newValue?.isDeleted) {
                    value.newValue = null;
                }
                else if (value.newValue) {
                    delete value.newValue.display;
                    delete value.newValue.displayType;
                    delete value.newValue.isDeleted;
                    value.newValue.number = unMaskedPhone(value.newValue.number);
                }
                if (!isEqual(value.newValue, value.actualValue)) {
                    changesPhones.push(value);
                }
            });
            data.content.changePhones = changesPhones;
        }
        else if (data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.CIVIL_STATUS_CHANGE_REQUEST) {
            if (data.content.newValue.civilStatusType !== CIVIL_STATUS.CONSTANT.COMMON_LAW_SPOUSE) {
                delete data.content.newValue.cohabitationDate;
            }
        }
        else if (data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.EMAIL_CHANGE_REQUEST) {
            if (data.content.newValue === '') {
                data.content.newValue = null;
            }
        }
        else if (data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.SMOKER_STATE_CHANGE_REQUEST
            || data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.PARTICIPANT_STATUS_CHANGE_REQUEST
            || data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.SPOUSE_SMOKER_STATE_CHANGE_REQUEST
            || data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.WORK_PROVINCE_CHANGE_REQUEST) {
            data.content.actualValue = data.content.actualValue.value;
            data.content.newValue = data.content.newValue.value;
        }
        else if (data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.SPOUSE_STATUS_CHANGE_REQUEST
            || data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.CHILD_STATUS_CHANGE_REQUEST) {
            delete data.content.actualValue.display;
            delete data.content.newValue.display;
        }
        else if (data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.SALARY_CHANGE_REQUEST) {
            data.content.actualValue = currencyWithoutSeparator(data.content.actualValue, true);
            data.content.newValue = currencyWithoutSeparator(data.content.newValue, true);
        }
        else if (data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.DIVISION_CHANGE_REQUEST) {
            forEach(data.content.futurChanges, (item) => {
                delete item.descriptionRecipient;
            });
            data.content.actualValue = data.content.actualValue.value;
            data.content.newValue = data.content.newValue.value;
        }
        else if (data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.RECIPIENT_CHANGE_REQUEST
            || data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.CATEGORY_CHANGE_REQUEST) {
            data.content.actualValue = data.content.actualValue.value;
            data.content.newValue = data.content.newValue.value;
        }
        else if (data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.SPOUSE_DESIGNATION_REQUEST) {
            data.content.status.effectiveDate = data.content.effectiveDate;
            delete data.content.smokerState.display;
            delete data.content.status.display;
            delete data.content.spouse.display;
        }
        else if (data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.CHILD_CREATION_REQUEST) {
            data.content.status.effectiveDate = data.content.effectiveDate;
            delete data.content.status.display;
            delete data.content.child.display;
        }
        else if (data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.SPOUSE_DEPENDENT_CHANGE_REQUEST
            || data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.CHILD_DEPENDENT_CHANGE_REQUEST) {
            delete data.content.actualValue.displayGender;
            delete data.content.newValue.displayGender;
        }
        else if (data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.PROTECTION_CHANGE_REQUEST) {
            const protectionBenefits = [];
            const protectionBenefitsGroupings = [];
            data.content.planMemberBenefits.forEach((item, index) => {
                const initialPlanMemberBenefit = data.content.initialPlanMemberBenefits[index];
                const protectionId = item.coverage.protection.id === EXEMPTED_VALUE ? initialPlanMemberBenefit.coverage.protection.id : item.coverage.protection.id;
                if (protectionId !== initialPlanMemberBenefit.coverage.protection.id
                    || item.status.benefitStatusType !== initialPlanMemberBenefit.status.benefitStatusType) {
                    const protectionBenefit = {
                        actualProtectionId: initialPlanMemberBenefit.coverage.protection.id,
                        actualStatus: initialPlanMemberBenefit.status.benefitStatusType,
                        newProtectionId: item.coverage.protection.id !== EXEMPTED_VALUE ? item.coverage.protection.id : initialPlanMemberBenefit.coverage.protection.id,
                        newStatus: item.status.benefitStatusType,
                        optionId: item.option.id,
                        participantBenefitId: item.participantBenefitId,
                    };
                    if (item.isGrouping) {
                        protectionBenefit.benefitsGroupingId = item.id;
                        protectionBenefitsGroupings.push(protectionBenefit);
                    }
                    else {
                        protectionBenefit.groupBenefitId = item.id;
                        protectionBenefits.push(protectionBenefit);
                    }
                }
            });
            data.content = {
                effectiveDate: data.content.effectiveDate,
            };
            if (protectionBenefits.length) {
                data.content.protectionBenefits = protectionBenefits;
            }
            if (protectionBenefitsGroupings.length) {
                data.content.protectionBenefitsGroupings = protectionBenefitsGroupings;
            }
        }
        else if (data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.ADDRESS_CHANGE_REQUEST) {
            const actualValue = data.content.actualValue.value;
            if (actualValue.city === '' || actualValue.postalCode === '' || actualValue.civicAddress === '') {
                data.content.actualValue = null;
            }
            else {
                data.content.actualValue = data.content.actualValue.value;
            }
            data.content.newValue = data.content.newValue.value;
            forEach(data.content.futurChanges, (item) => {
                const value = {
                    civicAddress: item.value.civicAddress,
                    city: item.value.city,
                    postalCode: item.value.postalCode,
                    provinceId: item.value.provinceId,
                    country: item.value.country,
                };
                item.value = value;
            });
        }
        else if (data.contentType === MESSAGE_CONTENT_TYPE.CONSTANT.PARTICIPANT_CREATION_REQUEST) {
            if (data.content?.participantRegistration) {
                data.content.participantRegistration.socialInsuranceNumber = unMaskNas(data.content.participantRegistration.socialInsuranceNumber);
                data.content.participantRegistration.phoneNumbers = removeEmptyPhones(data.content.participantRegistration.phoneNumbers, false);
                data.content.participantRegistration.salary = data.content.participantRegistration.salary !== '' ? currencyWithoutSeparator(data.content.participantRegistration.salary, true) : 0;
                data.content.participantRegistration.email = data.content.participantRegistration.email === '' ? null : data.content.participantRegistration.email;
                data.content.participantRegistration.socialInsuranceNumber = data.content.participantRegistration.socialInsuranceNumber === '' ? null : data.content.participantRegistration.socialInsuranceNumber;
                data.content.participantRegistration.occupation = formatOccupation(data.content.participantRegistration.occupation);
                if (!isCommonLawSpouse(data.content.participantRegistration.civilStatus.civilStatusType)) {
                    data.content.participantRegistration.civilStatus.cohabitationDate = null;
                }
            }
        }
        if (data.content.futurChanges?.length !== 0) {
            forEach(data.content.futurChanges, (item) => {
                delete item.display;
            });
        }
        else {
            delete data.content.futurChanges;
            delete data.content.keepFuturChanges;
        }
        delete data.attachments;
        if (data.content.note === '') {
            delete data.content.note;
        }
        return data;
    },
};

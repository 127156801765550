import CURRENCY from '../constants/currencies';
import SETTINGS from '../constants/settings';
import { getI18nDictionnary } from './i18n';
import { isNaNGlobal, isNullOrUndefined } from './types';
const currencyLocales = {
    fr: {
        currency: '{currency}\u00A0$',
    },
    en: {
        currency: '${currency}',
    },
};
export function defaultCurrencyFormat(value) {
    return formatCurrency(value, true, 2);
}
export function formatCurrencyWithDollarSignAndZeroDigit(value, emptyValue = '') {
    if (value === undefined) {
        return emptyValue;
    }
    return formatCurrency(value, true, 0);
}
export function formatCurrency(num, withDollarSign, digitLength) {
    if ((typeof num === 'undefined' || num === null) && isNaNGlobal(num)) {
        return num;
    }
    digitLength = digitLength !== undefined ? digitLength : 2;
    const divider = 10 ** digitLength;
    const numWithoutSeparator = currencyWithoutSeparator(num, false, digitLength);
    let value = (Math.round(numWithoutSeparator * divider) / divider).toFixed(digitLength);
    if (withDollarSign) {
        const dictionnary = getI18nDictionnary(currencyLocales, SETTINGS.LANGUAGE);
        const formatCurrency = dictionnary.t('currency');
        value = formatCurrency.replace('{currency}', value);
    }
    const currencySeparator = CURRENCY.SEPARATOR[SETTINGS.LANGUAGE.toUpperCase()];
    const parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, currencySeparator);
    return parts.join('.');
}
export function currencyWithoutSeparator(currency, returnNumber, digitLength) {
    if (!currency) {
        return currency;
    }
    let currencyString = currency.toString();
    const replace = `[${CURRENCY.SEPARATOR.EN}${CURRENCY.SEPARATOR.FR}$]`;
    const regex = new RegExp(replace, 'g');
    const hasDigit = digitLength !== undefined && parseInt(digitLength, 10) !== 0;
    if (hasDigit && !currencyString.match(/\./g)) {
        currencyString = currencyString.replace(',', '.');
    }
    let currencyFormated = currencyString.replace(regex, '');
    if (returnNumber) {
        currencyFormated = Number(currencyFormated);
    }
    return currencyFormated;
}
export function IntlNumberFormat(number, language, minimumFractionDigits = 2) {
    if (isNullOrUndefined(number)) {
        return '';
    }
    return new Intl.NumberFormat(`${language}-ca`, { style: 'currency', currency: 'CAD', minimumFractionDigits }).format(number);
}
export function IntlNumberFormatI18n(number, minimumFractionDigits = 2) {
    const i18n = {};
    SETTINGS.LANGUAGES.forEach((language) => {
        i18n[language] = IntlNumberFormat(number, language, minimumFractionDigits);
    });
    return i18n;
}

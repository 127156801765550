import Masker from 'vue-the-mask/src/masker';
import Mask from './vue-the-mask-extend';
export { Mask, Masker };
export const TOKENS = {
    '*': {
        pattern: /.*/,
    },
    n: {
        pattern: /[1-9a-zA-Z]/,
    },
    s: {
        pattern: /[0-9a-zA-Z-_]/,
    },
    '#': {
        pattern: /\d/,
    },
    1: {
        pattern: /[1]/,
    },
    t: {
        pattern: /[2-9]/,
    },
    X: {
        pattern: /[0-9a-zA-Z]/,
    },
    S: {
        pattern: /[a-zA-Z]/,
    },
    A: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
    },
    a: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
    },
    '!': {
        escape: true,
    },
    // Postal Code
    C: {
        pattern: /[ABCEGHJ-NPRSTVXYabceghj-nprstvxy]/,
        transform: (v) => v.toLocaleUpperCase(),
    },
    D: {
        pattern: /[ABCEGHJ-NPRSTV-Zabceghj-nprstv-z]/,
        transform: (v) => v.toLocaleUpperCase(),
    },
};
export const MASKS = {
    PHONE: '(t##) ###-####',
    POSTAL_CODE: 'C#D #D#',
    DATE: '####-##-##',
    NAS: '### ### ###',
};

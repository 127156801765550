import ComponentDialog from '@/components/dialogs/component.vue';
import FormDialog from '@/components/dialogs/display.vue';
import SActionIcon from '@/components/ui/action-icon.vue';
import ComponentMixins from '@/mixins/segic-component';
import DialogConfirmCancelMixin from '@/page-layouts/administrator/dialogs/confirm-cancel';
// CONFIRM CANCEL
import DialogConfirmCancel from '@/page-layouts/administrator/dialogs/confirm-cancel.vue';
import { isObject } from 'segic_shared_front_end_utils/src/utils/types';
import { filter } from 'lodash';
import moment from 'moment';
import SECURITY_PERMISSIONS from 'segic_shared_front_end_utils/src/enums-bitwise/security-permissions';
import { EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES } from '@/mixins/security/codes/employer-portal';
export default {
    name: 'SInfo',
    components: {
        's-form-dialog': FormDialog,
        's-component-dialog': ComponentDialog,
        's-dialog-confirm-cancel': DialogConfirmCancel,
        SActionIcon,
    },
    mixins: [
        ComponentMixins,
        DialogConfirmCancelMixin,
    ],
    props: {
        content: {
            type: [Object, String],
        },
        contentXl: {
            default: false,
        },
        contentMd: {
            default: false,
        },
        contentSmall: {
            default: false,
        },
        edit: {
            type: Object,
        },
        isContentLoading: {
            default: false,
        },
        isLight: {
            default: false,
        },
        isLoading: {
            default: false,
        },
        marginRight: {
            default: 'mr-10',
        },
        name: {
            type: String,
        },
        title: {
            required: true,
        },
        subHeader: {},
        history: {
            type: Object,
        },
        emptyContent: {
            default: '-',
        },
        widthDialogEdit: {
            default: 500,
        },
        showIconEdit: {
            default: true,
        },
        infoData: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            showDialogHistory: false,
            showDialogEdit: false,
        };
    },
    computed: {
        hasHistory() {
            return isObject(this.history);
        },
        showIconEditComputed() {
            return this.showIconEdit && this.hasEdit;
        },
        hasEdit() {
            return this.checkAccess(SECURITY_PERMISSIONS.VIEW_EDIT, EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.EMPLOYER_CHANGE_REQUEST_RESOURCE) && isObject(this.edit);
        },
        contentFormatted() {
            if (this.isLoading) {
                return '';
            }
            return typeof this.content !== 'undefined' && this.content !== '' ? this.content : this.emptyContent;
        },
        dialogWidth() {
            return this.history.dialogWidth ? this.history.dialogWidth : '400px';
        },
        classContentComputed() {
            // { 's-info_contentXl': contentXl, 's-font-32': contentXl, 's-font-17': contentMd, 's-font-16': !contentXl && !contentSmall, 's-font-12': contentSmall }
            let cssClass = '';
            if (this.contentXl) {
                cssClass = 's-contentXl';
            }
            else if (this.contentMd) {
                cssClass = 's-contentMd';
            }
            else if (this.contentSmall) {
                cssClass = 'contentSmall';
            }
            else {
                cssClass = 's-font-16';
            }
            return cssClass;
        },
        hasFutureChangesDates() {
            return filter(this.history?.component?.props?.items, (item) => {
                const effectiveDate = item.EffectiveDate || item.effectiveDate;
                return moment(effectiveDate).isAfter(moment());
            }).length !== 0;
        },
        tooltipHistory() {
            return this.hasFutureChangesDates ? this.$t('tooltips.futureHistory') : this.$t('history');
        },
        iconClass() {
            let iconClass = 's-icon-light-link';
            iconClass += this.hasFutureChangesDates ? ' off' : '';
            return iconClass;
        },
    },
    methods: {
        isComponent() {
            return isObject(this.content);
        },
        toggleDialogHistory(display) {
            this.showDialogHistory = display;
        },
        toggleDialogEdit(display) {
            this.showDialogEdit = display;
        },
    },
    i18n: {
        messages: {
            fr: {
                tooltips: {
                    futureHistory: 'Des changements futurs à la date du jour existent déjà.',
                },
            },
            en: {
                tooltips: {
                    futureHistory: 'Future changes to the current date already exist.',
                },
            },
        },
    },
};

import ComponentMixins from '@/mixins/segic-component';
export default {
    name: 'SLabelForm',
    mixins: [ComponentMixins],
    props: {
        appendLabel: {
            default: null,
            type: String,
        },
        label: {
            required: true,
            type: String,
        },
        tooltipText: {
            default: null,
            type: String,
        },
    },
};

// eslint-disable-next-line no-shadow
var ITEM_CHANGED;
(function (ITEM_CHANGED) {
    ITEM_CHANGED[ITEM_CHANGED["noItems"] = 0] = "noItems";
    ITEM_CHANGED[ITEM_CHANGED["modelFound"] = 1] = "modelFound";
    ITEM_CHANGED[ITEM_CHANGED["notFound"] = 2] = "notFound";
    ITEM_CHANGED[ITEM_CHANGED["autofill"] = 3] = "autofill";
    ITEM_CHANGED[ITEM_CHANGED["equal"] = 4] = "equal";
    ITEM_CHANGED[ITEM_CHANGED["multiplePrefill"] = 5] = "multiplePrefill";
    ITEM_CHANGED[ITEM_CHANGED["emptyValue"] = 6] = "emptyValue";
    ITEM_CHANGED[ITEM_CHANGED["singlePrefill"] = 7] = "singlePrefill";
})(ITEM_CHANGED || (ITEM_CHANGED = {}));
export default ITEM_CHANGED;

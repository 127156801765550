import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
import SPayrollFilesConversionsFeatureGuide from '@/page-layouts/administrator/payroll-files/list/payroll-files-conversion-feature-guide.vue';
export default {
    components: {
        SPayrollFilesConversionsFeatureGuide,
    },
    props: {
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showMenu: false,
        };
    },
    mounted() {
        setTimeout(() => {
            this.showMenu = !localStorage.getItem('sPayrollFilesConversionFeatureGuideDisplayed');
        }, 1500);
    },
    methods: {
        closeMenu() {
            this.showMenu = false;
            localStorage.setItem('sPayrollFilesConversionFeatureGuideDisplayed', 'true');
        },
        handleOpenDialog() {
            this.$emit('openDialog');
            this.closeMenu();
        },
    },
    i18n,
};

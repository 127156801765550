import Logo from '@/components/ui/logo.vue';
import { getEmptyComponents } from '@/utils/page-layouts';
const routes = [];
const children = [];
const components = getEmptyComponents();
components.default = () => import(/* webpackChunkName: "dashboard" */ './index.vue');
routes.push({
    name: 'groups',
    path: '/groups',
    components,
    children,
    meta: {
        hasDrawser: false,
        headerComponents: {
            left: {
                component: Logo,
            },
        },
        title: 'groups',
    },
});
export default routes;

import { find } from 'lodash';
import { getEnumValue, getEnumValues, toValues } from './_utils';
const CONSTANT = {
    PENDING: 0,
    ACKNOWLEDGED: 1,
    REFUSED: 2,
    COMPLETED: 3,
    ERROR: 4,
};
const values = toValues(CONSTANT);
const FR = {
    [CONSTANT.PENDING]: 'En attente de traitement',
    [CONSTANT.ACKNOWLEDGED]: 'Reçu',
    [CONSTANT.REFUSED]: 'Refusée',
    [CONSTANT.COMPLETED]: 'Traitée',
    [CONSTANT.ERROR]: 'En erreur',
};
const EN = {
    [CONSTANT.PENDING]: 'Awaiting processing',
    [CONSTANT.ACKNOWLEDGED]: 'Acknowledged',
    [CONSTANT.REFUSED]: 'Declined',
    [CONSTANT.COMPLETED]: 'Processed',
    [CONSTANT.ERROR]: 'In error',
};
const Dictionaries = {
    fr: FR,
    en: EN,
};
function getValues(lang) {
    return getEnumValues(values, Dictionaries, lang);
}
function getValuePerCode(code, lang) {
    return getEnumValue(values, Dictionaries, lang, code);
}
function cssColorMessageStatus(code) {
    const messageStatus = find(values, ['Code', code]);
    return messageStatus ? messageStatus.Key.toLowerCase() : '';
}
function iconMessageStatus(code) {
    switch (code) {
        case CONSTANT.REFUSED:
            return 'clear';
        case CONSTANT.COMPLETED:
            return 'done';
        case CONSTANT.ERROR:
            return 'warning';
        default:
            return 'hourglass_empty';
    }
}
const MESSAGE_STATUS = {
    CONSTANT,
    getValues,
    getValuePerCode,
    cssColorMessageStatus,
    iconMessageStatus,
};
export default MESSAGE_STATUS;

import SComponentDatatableBase from '@/components/datatables/base.vue';
import SCellError from '@/page-layouts/administrator/onboarding/invite/dialog/component/send-invitation-cells/error.vue';
import { DATA_TABLE_KEYS_TO_SEARCH } from 'segic_shared_front_end_utils/src/utils/datatable';
import SCellNotes from './notes.vue';
import SHeaderFileValidation from './header-file-validation.vue';
import SCellText from './text.vue';
import SPageLayoutOnBoardingCellParticipantName from './name.vue';
import { hasCertificateNumberError, hasMappingCodeError, } from './certificate-numbers-bulk-mapping-error-codes';
import i18n from '../utils/locales';
export default {
    name: 'SInvitationsFileValidation',
    components: {
        SHeaderFileValidation,
        SCellNotes,
        SCellText,
        SPageLayoutOnBoardingCellParticipantName,
        SComponentDatatableBase,
        SCellError,
    },
    props: {
        errorCount: {
            required: true,
            type: Number,
        },
        validationResults: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {};
    },
    computed: {
        headers() {
            const headers = [{
                    text: this.$t('line'),
                    value: 'line',
                },
                {
                    text: this.$t('certificate'),
                    value: 'certificateNumber',
                },
                {
                    text: `${this.$t('lastName')}, ${this.$t('firstName')}`,
                    value: 'participantDetails',
                },
                {
                    text: this.$t('division'),
                    value: 'division',
                },
                {
                    text: this.$t('employeeNumber'),
                    value: 'mappingCode',
                }];
            if (this.errorCount !== 0) {
                headers.push({
                    text: this.$t('notes'),
                    value: 'notes',
                    class: 's-text-center s-w-250',
                });
            }
            return headers;
        },
        items() {
            const items = [];
            this.validationResults.forEach((validation) => {
                const participant = validation?.participant;
                let allErrorCodes = [];
                if (!validation?.validationResult?.isValid && validation.validationResult.errors.length) {
                    allErrorCodes = validation.validationResult.errors.map((item) => item.errorCode);
                }
                items.push({
                    line: validation.line,
                    certificateNumber: this.setCertificateNumber(participant.certificateNumber, allErrorCodes),
                    participantDetails: this.setParticipantDetails(participant.firstName, participant.lastName),
                    division: this.setDivisionDescription(participant?.division),
                    mappingCode: this.setMappingCodeDescription(participant?.mappingCode, hasMappingCodeError(allErrorCodes)),
                    errors: validation.validationResult.errors ? validation.validationResult.errors : [],
                });
            });
            return items;
        },
    },
    methods: {
        createErrorValueItem(text, hasError) {
            return {
                text,
                hasError,
            };
        },
        setCertificateNumber(certificateNumber, errorCodes) {
            const text = certificateNumber || '';
            return { [DATA_TABLE_KEYS_TO_SEARCH]: ['certificateNumber.text'], ...this.createErrorValueItem(text, hasCertificateNumberError(errorCodes)) };
        },
        setDivisionDescription(division) {
            return {
                [DATA_TABLE_KEYS_TO_SEARCH]: ['division'],
                division,
            };
        },
        setMappingCodeDescription(mappingCode, hasError) {
            return this.createErrorValueItem(mappingCode, hasError);
        },
        setParticipantDetails(firstName, lastName) {
            return {
                [DATA_TABLE_KEYS_TO_SEARCH]: ['lastName', 'firstName'],
                firstName,
                lastName,
            };
        },
    },
    i18n,
};

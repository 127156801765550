import StoreExtender from '@/store/extender';
import STORE_CONSTANT_NAMES from '@/store/names';
import GlobalLoaderStore from '@/store/global/loader';
export default {
    created() {
        this.loaderStore = new StoreExtender(this, STORE_CONSTANT_NAMES.GLOBALS.LOADER, GlobalLoaderStore);
    },
    data() {
        return {
            loaderStore: null,
        };
    },
    computed: {
        isGlobalLoading() {
            return (this.loaderStore) ? this.loaderStore.getState('isLoading') : false;
        },
    },
};

import { camelCase, forEach, mapKeys } from 'lodash';
import SETTINGS from '../../../constants/settings';
import { isJson } from '../../types';
const entities = {
    decode(str) {
        if (!str) {
            return str;
        }
        return str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
    },
};
export function decode(str) {
    return entities.decode(str);
}
export function i18n(value) {
    if (!value || typeof value !== 'string') {
        return value;
    }
    let json = value;
    if (value.indexOf('[{"') === -1) {
        json = value.replace(/'([^}:]+)'/g, '"$1"').replace(/',[ ]?'/g, '","');
    }
    if (!isJson(json)) {
        if (json.indexOf('[{') !== -1 && window.location.hostname === 'localhost') {
            throw `Invalide json: ${json}`;
        }
        return value;
    }
    const languages = SETTINGS.LANGUAGES;
    const i18n = {};
    const fieldString = JSON.parse(json);
    forEach(fieldString, (value) => {
        const i18nValue = value.Value;
        i18n[languages[value.Code - 1]] = entities.decode(i18nValue);
    });
    return i18n;
}
export function i18nEmpty(defaultValue = '') {
    const i18n = {};
    forEach(SETTINGS.LANGUAGES, (value) => {
        i18n[value] = defaultValue;
    });
    return i18n;
}
export function cultureName(items, emptyValue = '') {
    if (!Array.isArray(items)) {
        return items;
    }
    if (!items) {
        return i18nEmpty();
    }
    const i18n = {};
    forEach(items, (item) => {
        const value = (mapKeys(item, (v, k) => camelCase(k)));
        const i18nValue = value.text;
        const lang = value.cultureName.replace(/-../g, '');
        i18n[lang] = entities.decode(i18nValue);
        if ((i18n[lang] === '' || i18n[lang] === null)) {
            i18n[lang] = emptyValue;
        }
    });
    return i18n;
}

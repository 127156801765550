import ComponentMixins from '@/mixins/segic-component';
import CIVIL_STATUS, { isCommonLawSpouse } from 'segic_shared_front_end_utils/src/enums/civil-status';
import GENDER from 'segic_shared_front_end_utils/src/enums/gender';
import LANGUAGE_CODES_CLIENT_PORTAL from 'segic_shared_front_end_utils/src/enums/language-codes-client-portal';
import PARTICIPANT_STATUS from 'segic_shared_front_end_utils/src/data-dump/participant-status';
import PHONE_LOCALIZATION_TYPES from 'segic_shared_front_end_utils/src/enums/phone-localization-types';
import SMOKER_STATE from 'segic_shared_front_end_utils/src/enums/smoker-state';
import SYSTEM_PROTECTIONS from 'segic_shared_front_end_utils/src/enums-bitwise/system-protections';
import { currencyWithoutSeparator, IntlNumberFormat } from 'segic_shared_front_end_utils/src/utils/currencies';
import { displayYesNoByBoolean } from 'segic_shared_front_end_utils/src/enums/yes-no';
import { getMessageContentTypeValuePerCode, MESSAGE_CONTENT_TYPE_CONSTANT } from 'segic_shared_front_end_utils/src/enums/message-content-type';
import { getProvinceDescription } from 'segic_shared_front_end_utils/src/data-dump/provinces';
import { isNotNullOrUndefined, isNullOrUndefinedOrEmptyString } from 'segic_shared_front_end_utils/src/utils/types';
import SComponentPhones from '@/page-layouts/administrator/participants/single/components/phones.vue';
import StoreExtender from '@/store/extender';
import STORE_CONSTANT_NAMES from '@/store/names';
import GroupsCategoriesStore from '@/store/groups/categories';
import { removeEmptyPhones } from '@/store/change-requests/single/entities';
import GroupsDivisionsStore from '@/store/groups/divisions';
import GroupsOccupationsStore from '@/store/groups/occupations';
import { formatFileName } from 'segic_shared_front_end_utils/src/utils/file';
import TabLocale from '../tabs-local';
import SPageLayoutsParticipantsAddParticipantFormSectionsConfirmCard from './components/confirm-card.vue';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantFormSectionsConfirm',
    components: { SPageLayoutsParticipantsAddParticipantFormSectionsConfirmCard },
    mixins: [ComponentMixins],
    props: {
        formData: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            categoryDisplay: '',
            divisionDisplay: '',
            recipientDisplay: '',
            occupationDisplay: '',
            recipients: [],
        };
    },
    computed: {
        participantRegistration() {
            return this.formData.content?.participantRegistration;
        },
        allChanges() {
            const confirmCards = [
                this.personalInformationItems,
                this.employmentInformationItems,
                this.contactDetailsItems,
            ];
            if (this.filesMessageItems.length !== 0) {
                confirmCards.push(this.filesMessageItems);
            }
            return confirmCards;
        },
        personalInformationItems() {
            const items = [];
            if (isNotNullOrUndefined(this.participantRegistration.certificateNumber)) {
                items.push(this.setData(this.$t('certificate'), this.participantRegistration.certificateNumber));
            }
            items.push(this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.LAST_NAME_CHANGE_REQUEST, this.$i18n.locale).Value, this.formData.content.lastName), this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.FIRST_NAME_CHANGE_REQUEST, this.$i18n.locale).Value, this.formData.content.firstName), this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.BIRTHDATE_CHANGE_REQUEST, this.$i18n.locale).Value, this.participantRegistration.birthDate));
            if (isNotNullOrUndefined(this.participantRegistration.gender)) {
                items.push(this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.GENDER_CHANGE_REQUEST, this.$i18n.locale).Value, GENDER.getValuePerCode(this.participantRegistration.gender, this.$i18n.locale)?.Value));
            }
            if (isNotNullOrUndefined(this.participantRegistration.smokerStatus)) {
                items.push(this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.SMOKER_STATE_CHANGE_REQUEST, this.$i18n.locale).Value, SMOKER_STATE.getValuePerCode(this.participantRegistration.smokerStatus, this.$i18n.locale)?.Value));
            }
            if (isNotNullOrUndefined(this.participantRegistration.language)) {
                items.push(this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.LANGUAGE_CHANGE_REQUEST, this.$i18n.locale).Value, LANGUAGE_CODES_CLIENT_PORTAL.getValuePerCode(this.participantRegistration.language, this.$i18n.locale)?.Value));
            }
            if (isNotNullOrUndefined(this.participantRegistration.civilStatus.civilStatusType)) {
                items.push(this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.CIVIL_STATUS_CHANGE_REQUEST, this.$i18n.locale).Value, CIVIL_STATUS.getValuePerCode(this.participantRegistration.civilStatus.civilStatusType, this.$i18n.locale)?.Value));
            }
            if (isCommonLawSpouse(this.participantRegistration.civilStatus.civilStatusType)) {
                items.push(this.setData(this.$t('startDateOfCohabitation'), this.formData.content.participantRegistration.civilStatus.cohabitationDate));
            }
            items.push(this.setData(this.$t('common.socialInsuranceNumber'), this.participantRegistration.socialInsuranceNumber), this.setData(this.$t('firstNationsStatus'), displayYesNoByBoolean(this.participantRegistration.hasIndianStatus, this.$i18n.locale)));
            return items;
        },
        employmentInformationItems() {
            return [
                this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.PARTICIPANT_STATUS_CHANGE_REQUEST, this.$i18n.locale).Value, PARTICIPANT_STATUS.getParticipantStatusById(this.participantRegistration.statusId, this.$i18n.locale)?.description),
                this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.HIRE_DATE_CHANGE_REQUEST, this.$i18n.locale).Value, this.participantRegistration.hireDate),
                this.setData(this.$t('common.removeWaitingPeriod'), displayYesNoByBoolean(this.participantRegistration.benefitsAttribution.ignoreEligibilityPeriod, this.$i18n.locale)),
                this.setData(this.$t('coverageEffectiveDate'), this.participantRegistration.benefitsAttribution.insuranceDate),
                this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.WORK_PROVINCE_CHANGE_REQUEST, this.$i18n.locale).Value, getProvinceDescription(this.participantRegistration.workProvinceId)),
                this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.OCCUPATION_CHANGE_REQUEST, this.$i18n.locale).Value, this.occupationDisplay),
                this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.SALARY_CHANGE_REQUEST, this.$i18n.locale).Value, this.setSalary(this.participantRegistration.salary)),
                this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.DIVISION_CHANGE_REQUEST, this.$i18n.locale).Value, this.divisionDisplay),
                this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.CATEGORY_CHANGE_REQUEST, this.$i18n.locale).Value, this.categoryDisplay),
                this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.RECIPIENT_CHANGE_REQUEST, this.$i18n.locale).Value, this.recipientDisplay),
                this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.PROTECTION_CHANGE_REQUEST, this.$i18n.locale).Value, SYSTEM_PROTECTIONS.getValueById(this.participantRegistration.benefitsAttribution.protectionId, this.$i18n.locale)?.Value),
                this.setData(this.$t('exemption'), this.setExemption(this.participantRegistration.benefitsAttribution.healthExemption, this.participantRegistration.benefitsAttribution.dentalExemption)),
            ];
        },
        contactDetailsItems() {
            return [
                this.setData(this.$t('address'), this.setAddress(this.participantRegistration.address)),
                this.setPhonesData(this.participantRegistration.phoneNumbers),
                this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.EMAIL_CHANGE_REQUEST, this.$i18n.locale).Value, this.participantRegistration.email),
            ];
        },
        filesMessageItems() {
            const items = [];
            if (this.formData.attachments.length !== 0 || this.formData.displayAttachments.length !== 0) {
                items.push(this.setAttachments(this.formData.attachments, this.formData.displayAttachments));
            }
            if (!isNullOrUndefinedOrEmptyString(this.formData.content.note)) {
                items.push(this.setData(this.$t('note'), this.formData.content.note));
            }
            return items;
        },
    },
    watch: {
        'participantRegistration.categoryId': {
            async handler(newVal, oldVal) {
                if (isNotNullOrUndefined(newVal) && newVal !== oldVal) {
                    const store = new StoreExtender(this, STORE_CONSTANT_NAMES.GROUPS.CATEGORIES, GroupsCategoriesStore);
                    await store.sendActions('get');
                    const category = store.getGetter('getById', this.participantRegistration.categoryId);
                    this.categoryDisplay = category.DisplayCodeName[this.$i18n.locale];
                }
            },
            immediate: true,
        },
        'participantRegistration.divisionId': {
            async handler(newVal, oldVal) {
                if (isNotNullOrUndefined(newVal) && newVal !== oldVal) {
                    const store = new StoreExtender(this, STORE_CONSTANT_NAMES.GROUPS.DIVISIONS, GroupsDivisionsStore);
                    await store.sendActions('get');
                    const division = store.getGetter('getById', this.participantRegistration.divisionId);
                    this.recipients = division.Recipients;
                    this.recipientDisplay = this.getRecipientDescription(this.recipients, this.participantRegistration.recipientId);
                    this.divisionDisplay = division.CodeDescription;
                }
            },
            immediate: true,
        },
        'participantRegistration.recipientId': {
            async handler(newVal, oldVal) {
                if (isNotNullOrUndefined(newVal) && newVal !== oldVal) {
                    this.recipientDisplay = this.getRecipientDescription(this.recipients, this.participantRegistration.recipientId);
                }
            },
            immediate: true,
        },
        'participantRegistration.occupation': {
            async handler(newVal, oldVal) {
                if (isNotNullOrUndefined(newVal) && newVal !== oldVal) {
                    if (!isNullOrUndefinedOrEmptyString(newVal.notListedValue)) {
                        this.occupationDisplay = newVal.notListedValue;
                    }
                    else if (newVal.occupationId) {
                        const store = new StoreExtender(this, STORE_CONSTANT_NAMES.GROUPS.OCCUPATIONS, GroupsOccupationsStore);
                        await store.sendActions('get');
                        this.occupationDisplay = store.getGetter('getById', newVal.occupationId).description;
                    }
                }
            },
            immediate: true,
        },
    },
    methods: {
        getRecipientDescription(recipients, recipientId) {
            const recipient = recipients.find((item) => item.Recipient.Id === recipientId);
            return recipient?.Recipient?.Description;
        },
        setData(text, value) {
            const newValue = isNullOrUndefinedOrEmptyString(value) ? '-' : value;
            return {
                text,
                value: newValue,
            };
        },
        setSalary(salary) {
            const newSalary = currencyWithoutSeparator(salary, true);
            return IntlNumberFormat(newSalary, this.$i18n.locale, 2);
        },
        setExemption(healthExemption, dentalExemption) {
            let exemption = '';
            exemption = healthExemption ? this.$t('health') : '';
            exemption += healthExemption && dentalExemption ? ', ' : '';
            exemption += dentalExemption ? this.$t('dental') : '';
            return exemption.length !== 0 ? exemption : '-';
        },
        setAddress(addressData) {
            return `${addressData.civicAddress}, ${addressData.city}<br/>
              ${getProvinceDescription(addressData.provinceId)}, ${addressData.country}, ${addressData.postalCode}`;
        },
        setPhonesData(phones) {
            const phonesFormatted = [];
            const newPhones = removeEmptyPhones(phones);
            const data = this.setData(getMessageContentTypeValuePerCode(MESSAGE_CONTENT_TYPE_CONSTANT.PHONE_CHANGE_REQUEST, this.$i18n.locale).Value, null);
            if (!newPhones) {
                return data;
            }
            newPhones.forEach((phone) => {
                phonesFormatted.push({
                    displayType: PHONE_LOCALIZATION_TYPES.getValuePerCode(phone.phoneType, this.$i18n.locale).Value,
                    displayPhone: phone.extension !== '' ? `${phone.number} ext. ${phone.extension}` : phone.number,
                });
            });
            data.component = {
                component: SComponentPhones,
                props: {
                    phones: phonesFormatted,
                    phoneTypeCss: 'font-weight-regular s-font-14',
                    phoneDisplayCss: 'font-weight-bold s-font-16',
                },
            };
            return data;
        },
        setAttachments(newAttachments, displayAttachments) {
            let files = '';
            newAttachments.forEach((attachment) => {
                files += (`<div>${formatFileName(attachment.name)}</div>`);
            });
            displayAttachments.forEach((attachment) => {
                files += (`<div>${formatFileName(attachment.fileName)}</div>`);
            });
            return this.setData(this.$t('files'), files);
        },
    },
    i18n: {
        messages: TabLocale,
    },
};

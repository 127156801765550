import { getEnumValue, getEnumValues, isEnumEq, toValuesEnum, } from './_utils';
export var MESSAGE_CONTENT_TYPE_CONSTANT;
(function (MESSAGE_CONTENT_TYPE_CONSTANT) {
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["MESSAGE"] = 0] = "MESSAGE";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["CHANGE_REQUEST"] = 1] = "CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["NOTIFICATION"] = 2] = "NOTIFICATION";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["EVENT"] = 3] = "EVENT";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["ADDRESS_CHANGE_REQUEST"] = 4] = "ADDRESS_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["BIRTHDATE_CHANGE_REQUEST"] = 5] = "BIRTHDATE_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["CIVIL_STATUS_CHANGE_REQUEST"] = 6] = "CIVIL_STATUS_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["EMAIL_CHANGE_REQUEST"] = 7] = "EMAIL_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["FIRST_NAME_CHANGE_REQUEST"] = 8] = "FIRST_NAME_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["GENDER_CHANGE_REQUEST"] = 9] = "GENDER_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["LANGUAGE_CHANGE_REQUEST"] = 10] = "LANGUAGE_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["LAST_NAME_CHANGE_REQUEST"] = 11] = "LAST_NAME_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["PHONE_CHANGE_REQUEST"] = 12] = "PHONE_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["GENERAL_CHANGE_REQUEST"] = 13] = "GENERAL_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["SALARY_CHANGE_REQUEST"] = 14] = "SALARY_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["SMOKER_STATE_CHANGE_REQUEST"] = 15] = "SMOKER_STATE_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["PARTICIPANT_CREATION_REQUEST"] = 16] = "PARTICIPANT_CREATION_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["PARTICIPANT_STATUS_CHANGE_REQUEST"] = 17] = "PARTICIPANT_STATUS_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["HIRE_DATE_CHANGE_REQUEST"] = 18] = "HIRE_DATE_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["WORK_PROVINCE_CHANGE_REQUEST"] = 19] = "WORK_PROVINCE_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["DIVISION_CHANGE_REQUEST"] = 20] = "DIVISION_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["RECIPIENT_CHANGE_REQUEST"] = 21] = "RECIPIENT_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["CATEGORY_CHANGE_REQUEST"] = 22] = "CATEGORY_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["SPOUSE_DESIGNATION_REQUEST"] = 23] = "SPOUSE_DESIGNATION_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["CHILD_CREATION_REQUEST"] = 24] = "CHILD_CREATION_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["SPOUSE_DEPENDENT_CHANGE_REQUEST"] = 25] = "SPOUSE_DEPENDENT_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["SPOUSE_STATUS_CHANGE_REQUEST"] = 26] = "SPOUSE_STATUS_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["SPOUSE_SMOKER_STATE_CHANGE_REQUEST"] = 27] = "SPOUSE_SMOKER_STATE_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["CHILD_DEPENDENT_CHANGE_REQUEST"] = 28] = "CHILD_DEPENDENT_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["CHILD_STATUS_CHANGE_REQUEST"] = 29] = "CHILD_STATUS_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["SPOUSE_ATTACHMENT_CHANGE_REQUEST"] = 30] = "SPOUSE_ATTACHMENT_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["CHILD_ATTACHMENT_CHANGE_REQUEST"] = 31] = "CHILD_ATTACHMENT_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["CHILD_SCHOOL_ATTENDENCE_ATTACHMENT_CHANGE_REQUEST"] = 32] = "CHILD_SCHOOL_ATTENDENCE_ATTACHMENT_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["CHILD_SCHOOL_ATTENDENCE_DATE_CHANGE_REQUEST"] = 33] = "CHILD_SCHOOL_ATTENDENCE_DATE_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["OCCUPATION_CHANGE_REQUEST"] = 34] = "OCCUPATION_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["PROTECTION_CHANGE_REQUEST"] = 35] = "PROTECTION_CHANGE_REQUEST";
    MESSAGE_CONTENT_TYPE_CONSTANT[MESSAGE_CONTENT_TYPE_CONSTANT["BULK_SALARY_CHANGE_REQUEST"] = 36] = "BULK_SALARY_CHANGE_REQUEST";
})(MESSAGE_CONTENT_TYPE_CONSTANT || (MESSAGE_CONTENT_TYPE_CONSTANT = {}));
;
const values = toValuesEnum(MESSAGE_CONTENT_TYPE_CONSTANT);
const FR = {
    [MESSAGE_CONTENT_TYPE_CONSTANT.MESSAGE]: '',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CHANGE_REQUEST]: '',
    [MESSAGE_CONTENT_TYPE_CONSTANT.NOTIFICATION]: '',
    [MESSAGE_CONTENT_TYPE_CONSTANT.EVENT]: '',
    [MESSAGE_CONTENT_TYPE_CONSTANT.ADDRESS_CHANGE_REQUEST]: 'Adresse',
    [MESSAGE_CONTENT_TYPE_CONSTANT.BIRTHDATE_CHANGE_REQUEST]: 'Date de naissance',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CIVIL_STATUS_CHANGE_REQUEST]: 'État civil',
    [MESSAGE_CONTENT_TYPE_CONSTANT.EMAIL_CHANGE_REQUEST]: 'Courriel de communication',
    [MESSAGE_CONTENT_TYPE_CONSTANT.FIRST_NAME_CHANGE_REQUEST]: 'Prénom',
    [MESSAGE_CONTENT_TYPE_CONSTANT.GENDER_CHANGE_REQUEST]: 'Genre',
    [MESSAGE_CONTENT_TYPE_CONSTANT.LANGUAGE_CHANGE_REQUEST]: 'Langue de communication',
    [MESSAGE_CONTENT_TYPE_CONSTANT.LAST_NAME_CHANGE_REQUEST]: 'Nom',
    [MESSAGE_CONTENT_TYPE_CONSTANT.PHONE_CHANGE_REQUEST]: 'Téléphone',
    [MESSAGE_CONTENT_TYPE_CONSTANT.GENERAL_CHANGE_REQUEST]: '',
    [MESSAGE_CONTENT_TYPE_CONSTANT.SALARY_CHANGE_REQUEST]: 'Salaire annuel',
    [MESSAGE_CONTENT_TYPE_CONSTANT.SMOKER_STATE_CHANGE_REQUEST]: 'Statut fumeur',
    [MESSAGE_CONTENT_TYPE_CONSTANT.PARTICIPANT_CREATION_REQUEST]: 'Ajouter un nouveau participant',
    [MESSAGE_CONTENT_TYPE_CONSTANT.PARTICIPANT_STATUS_CHANGE_REQUEST]: 'Statut du participant',
    [MESSAGE_CONTENT_TYPE_CONSTANT.HIRE_DATE_CHANGE_REQUEST]: 'Date d\'embauche',
    [MESSAGE_CONTENT_TYPE_CONSTANT.WORK_PROVINCE_CHANGE_REQUEST]: 'Province d\'emploi',
    [MESSAGE_CONTENT_TYPE_CONSTANT.DIVISION_CHANGE_REQUEST]: 'Division',
    [MESSAGE_CONTENT_TYPE_CONSTANT.RECIPIENT_CHANGE_REQUEST]: 'Déductions de la paie',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CATEGORY_CHANGE_REQUEST]: 'Catégorie',
    [MESSAGE_CONTENT_TYPE_CONSTANT.SPOUSE_DESIGNATION_REQUEST]: 'Ajouter un nouveau conjoint',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_CREATION_REQUEST]: 'Ajouter un enfant',
    [MESSAGE_CONTENT_TYPE_CONSTANT.SPOUSE_DEPENDENT_CHANGE_REQUEST]: 'Personnel',
    [MESSAGE_CONTENT_TYPE_CONSTANT.SPOUSE_STATUS_CHANGE_REQUEST]: 'Statut du conjoint',
    [MESSAGE_CONTENT_TYPE_CONSTANT.SPOUSE_SMOKER_STATE_CHANGE_REQUEST]: 'Statut fumeur',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_DEPENDENT_CHANGE_REQUEST]: 'Personnel',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_STATUS_CHANGE_REQUEST]: 'Statut',
    [MESSAGE_CONTENT_TYPE_CONSTANT.SPOUSE_ATTACHMENT_CHANGE_REQUEST]: 'Envoyer des documents',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_ATTACHMENT_CHANGE_REQUEST]: 'Envoyer des documents',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_SCHOOL_ATTENDENCE_ATTACHMENT_CHANGE_REQUEST]: 'Envoyer une confirmation de fréquentation scolaire',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_SCHOOL_ATTENDENCE_DATE_CHANGE_REQUEST]: 'Modifier la date de fin de la confirmation de fréquentation scolaire',
    [MESSAGE_CONTENT_TYPE_CONSTANT.OCCUPATION_CHANGE_REQUEST]: 'Occupation',
    [MESSAGE_CONTENT_TYPE_CONSTANT.PROTECTION_CHANGE_REQUEST]: 'Type de protection',
    [MESSAGE_CONTENT_TYPE_CONSTANT.BULK_SALARY_CHANGE_REQUEST]: 'Changement massif des salaires',
};
const EN = {
    [MESSAGE_CONTENT_TYPE_CONSTANT.MESSAGE]: '',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CHANGE_REQUEST]: '',
    [MESSAGE_CONTENT_TYPE_CONSTANT.NOTIFICATION]: '',
    [MESSAGE_CONTENT_TYPE_CONSTANT.EVENT]: '',
    [MESSAGE_CONTENT_TYPE_CONSTANT.ADDRESS_CHANGE_REQUEST]: 'Address',
    [MESSAGE_CONTENT_TYPE_CONSTANT.BIRTHDATE_CHANGE_REQUEST]: 'Birth date',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CIVIL_STATUS_CHANGE_REQUEST]: 'Civil status',
    [MESSAGE_CONTENT_TYPE_CONSTANT.EMAIL_CHANGE_REQUEST]: 'Contact email',
    [MESSAGE_CONTENT_TYPE_CONSTANT.FIRST_NAME_CHANGE_REQUEST]: 'First name',
    [MESSAGE_CONTENT_TYPE_CONSTANT.GENDER_CHANGE_REQUEST]: 'Gender',
    [MESSAGE_CONTENT_TYPE_CONSTANT.LANGUAGE_CHANGE_REQUEST]: 'Communication language',
    [MESSAGE_CONTENT_TYPE_CONSTANT.LAST_NAME_CHANGE_REQUEST]: 'Last name',
    [MESSAGE_CONTENT_TYPE_CONSTANT.PHONE_CHANGE_REQUEST]: 'Phone',
    [MESSAGE_CONTENT_TYPE_CONSTANT.GENERAL_CHANGE_REQUEST]: '',
    [MESSAGE_CONTENT_TYPE_CONSTANT.SALARY_CHANGE_REQUEST]: 'Annual salary',
    [MESSAGE_CONTENT_TYPE_CONSTANT.SMOKER_STATE_CHANGE_REQUEST]: 'Smoker status',
    [MESSAGE_CONTENT_TYPE_CONSTANT.PARTICIPANT_CREATION_REQUEST]: 'Add new plan member',
    [MESSAGE_CONTENT_TYPE_CONSTANT.PARTICIPANT_STATUS_CHANGE_REQUEST]: 'Plan member status',
    [MESSAGE_CONTENT_TYPE_CONSTANT.HIRE_DATE_CHANGE_REQUEST]: 'Hired date',
    [MESSAGE_CONTENT_TYPE_CONSTANT.WORK_PROVINCE_CHANGE_REQUEST]: 'Province of employment',
    [MESSAGE_CONTENT_TYPE_CONSTANT.DIVISION_CHANGE_REQUEST]: 'Division',
    [MESSAGE_CONTENT_TYPE_CONSTANT.RECIPIENT_CHANGE_REQUEST]: 'Payroll deductions file',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CATEGORY_CHANGE_REQUEST]: 'Class',
    [MESSAGE_CONTENT_TYPE_CONSTANT.SPOUSE_DESIGNATION_REQUEST]: 'Add a new spouse',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_CREATION_REQUEST]: 'Add child',
    [MESSAGE_CONTENT_TYPE_CONSTANT.SPOUSE_DEPENDENT_CHANGE_REQUEST]: 'Personal',
    [MESSAGE_CONTENT_TYPE_CONSTANT.SPOUSE_STATUS_CHANGE_REQUEST]: 'Spouse status',
    [MESSAGE_CONTENT_TYPE_CONSTANT.SPOUSE_SMOKER_STATE_CHANGE_REQUEST]: 'Smoker status',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_DEPENDENT_CHANGE_REQUEST]: 'Personal',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_STATUS_CHANGE_REQUEST]: 'Status',
    [MESSAGE_CONTENT_TYPE_CONSTANT.SPOUSE_ATTACHMENT_CHANGE_REQUEST]: 'Send files',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_ATTACHMENT_CHANGE_REQUEST]: 'Send files',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_SCHOOL_ATTENDENCE_ATTACHMENT_CHANGE_REQUEST]: 'Send a school attendance confirmation',
    [MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_SCHOOL_ATTENDENCE_DATE_CHANGE_REQUEST]: 'Change the school attendance confirmation end date',
    [MESSAGE_CONTENT_TYPE_CONSTANT.OCCUPATION_CHANGE_REQUEST]: 'Occupation',
    [MESSAGE_CONTENT_TYPE_CONSTANT.PROTECTION_CHANGE_REQUEST]: 'Coverage type',
    [MESSAGE_CONTENT_TYPE_CONSTANT.BULK_SALARY_CHANGE_REQUEST]: 'Bulk Salary Change Request',
};
const Dictionaries = {
    fr: FR,
    en: EN,
};
function getValues(lang) {
    return getEnumValues(values, Dictionaries, lang);
}
export function getMessageContentTypeValuePerCode(code, lang) {
    return getEnumValue(values, Dictionaries, lang, code);
}
function hasChanges(contentType) {
    return contentType === MESSAGE_CONTENT_TYPE_CONSTANT.SMOKER_STATE_CHANGE_REQUEST
        || contentType === MESSAGE_CONTENT_TYPE_CONSTANT.SALARY_CHANGE_REQUEST
        || contentType === MESSAGE_CONTENT_TYPE_CONSTANT.PARTICIPANT_STATUS_CHANGE_REQUEST
        || contentType === MESSAGE_CONTENT_TYPE_CONSTANT.WORK_PROVINCE_CHANGE_REQUEST
        || contentType === MESSAGE_CONTENT_TYPE_CONSTANT.SPOUSE_SMOKER_STATE_CHANGE_REQUEST;
}
function isChildDependentChangeRequest(contentType) {
    return contentType === MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_DEPENDENT_CHANGE_REQUEST
        || contentType === MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_STATUS_CHANGE_REQUEST
        || contentType === MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_ATTACHMENT_CHANGE_REQUEST
        || contentType === MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_SCHOOL_ATTENDENCE_ATTACHMENT_CHANGE_REQUEST
        || contentType === MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_SCHOOL_ATTENDENCE_DATE_CHANGE_REQUEST;
}
function isSpouseDependentChangeRequest(contentType) {
    return contentType === MESSAGE_CONTENT_TYPE_CONSTANT.SPOUSE_DEPENDENT_CHANGE_REQUEST
        || contentType === MESSAGE_CONTENT_TYPE_CONSTANT.SPOUSE_STATUS_CHANGE_REQUEST
        || contentType === MESSAGE_CONTENT_TYPE_CONSTANT.SPOUSE_ATTACHMENT_CHANGE_REQUEST;
}
function isNewDependentRequest(contentType) {
    return contentType === MESSAGE_CONTENT_TYPE_CONSTANT.SPOUSE_DESIGNATION_REQUEST
        || contentType === MESSAGE_CONTENT_TYPE_CONSTANT.CHILD_CREATION_REQUEST;
}
function isDependentChangeRequest(contentType) {
    return isChildDependentChangeRequest(contentType)
        || isSpouseDependentChangeRequest(contentType);
}
export function isGlobalChange(contentType, participantId) {
    return (isEnumEq(contentType, MESSAGE_CONTENT_TYPE.CONSTANT.GENERAL_CHANGE_REQUEST) || isEnumEq(contentType, MESSAGE_CONTENT_TYPE.CONSTANT.BULK_SALARY_CHANGE_REQUEST)) && !participantId;
}
export function isGeneralChangeRequest(contentType) {
    return isEnumEq(contentType, MESSAGE_CONTENT_TYPE.CONSTANT.GENERAL_CHANGE_REQUEST);
}
export function isBulkSalaryChangeRequest(contentType) {
    return contentType === MESSAGE_CONTENT_TYPE_CONSTANT.BULK_SALARY_CHANGE_REQUEST;
}
const MESSAGE_CONTENT_TYPE = {
    CONSTANT: MESSAGE_CONTENT_TYPE_CONSTANT,
    getValuePerCode: getMessageContentTypeValuePerCode,
    getValues,
    hasChanges,
    isBulkSalaryChangeRequest,
    isChildDependentChangeRequest,
    isDependentChangeRequest,
    isGlobalChange,
    isNewDependentRequest,
    isSpouseDependentChangeRequest,
};
export default MESSAGE_CONTENT_TYPE;

import SActionIcon from '@/components/ui/action-icon.vue';
import SButtonSubmit from '@/components/ui/btn-submit.vue';
import SegicComponent from '@/mixins/segic-component';
import { EventBus } from '@/utils/event-bus';
import MESSAGE_TYPE from 'segic_shared_front_end_utils/src/constants/message-types';
import EVENTS from 'segic_shared_front_end_utils/src/constants/events';
import { merge } from 'lodash';
export default {
    name: 'SComponentDialogComponent',
    components: {
        SActionIcon,
        SButtonSubmit,
    },
    mixins: [SegicComponent],
    props: {
        btnConfirmText: {
            default: null,
            type: String,
        },
        component: {
            required: true,
            type: Object,
        },
        iconHeader: {
            default: null,
            type: String,
        },
        name: {
            default: '',
            type: String,
        },
        showDialog: {
            required: true,
            type: Boolean,
        },
        subHeader: {
            type: String,
            default: null,
        },
        subTitle: {
            default: null,
            type: String,
        },
        title: {
            default: null,
            type: String,
        },
        width: {
            default: '1200px',
            type: [Number, String],
        },
        showAction: {
            default: true,
            type: Boolean,
        },
    },
    data() {
        return {
            EVENTS,
            isLoading: false,
            btnSubmitDisabled: true,
            alert: {
                type: MESSAGE_TYPE.ERROR,
                show: false,
                message: '',
                dismissible: false,
            },
        };
    },
    computed: {
        btnConfirmTextFormatted() {
            return this.btnConfirmText ? this.btnConfirmText : this.$t('confirm');
        },
        isEdit() {
            return Boolean(this.component.props && this.component.props.id);
        },
    },
    methods: {
        cancel() {
            if (typeof this.$refs.component.cancelDialog === 'function') {
                this.$refs.component.cancelDialog();
            }
            else {
                this.closeDialog();
            }
        },
        closeDialog() {
            this.$emit('close:dialog', false);
            // eslint-disable-next-line no-underscore-dangle
            EventBus.$emit(`CLOSE_DIALOG_COMPONENT_${this._uid}`, false);
            this.toggleError(false);
        },
        formChange(isChanged) {
            this.btnSubmitDisabled = !isChanged;
        },
        headerClick() {
            this.$emit('headerClick');
        },
        showError(error) {
            this.alert = merge(this.alert, error);
        },
        submit() {
            if (typeof this.$refs.component.submitDialog === 'function') {
                this.$refs.component.submitDialog();
            }
        },
        toggleError(show) {
            this.alert.show = show;
        },
        toggleLoading(isLoading) {
            this.isLoading = isLoading;
        },
    },
};

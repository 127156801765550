import { findIndex, merge } from 'lodash';
import FormMixin from '@/mixins/form';
import SegicLayoutComponent from '@/mixins/segic-layout-component';
import storeMixin from '@/mixins/page-layout/store';
import FileMixin from '@/mixins/file';
import STORE_CONSTANT_NAMES from '@/store/names';
import ChangeRequestsSingleStore from '@/store/change-requests/single';
import SStepper from '@/components/ui/stepper.vue';
import COMMUNICATION_TYPES from 'segic_shared_front_end_utils/src/constants/communication-types';
import MESSAGE_CONTENT_TYPE from 'segic_shared_front_end_utils/src/enums/message-content-type';
import SYSTEM_TYPES from 'segic_shared_front_end_utils/src/constants/system-types';
import { STEPPER_TYPE, } from '@/page-layouts/administrator/forms/add-participant/byForm/form.types';
import SDialogConfirmCancel from '@/page-layouts/administrator/dialogs/confirm-cancel.vue';
import DialogConfirmCancelMixin from '@/page-layouts/administrator/dialogs/confirm-cancel';
import { getI18nEmpty } from 'segic_shared_front_end_utils/src/utils/i18n';
import SPageLayoutsParticipantsAddParticipantFormSectionsPersonalInformation from './sections/personal-information.vue';
import SPageLayoutsParticipantsAddParticipantFormSectionsEmploymentInformation from './sections/employment-information.vue';
import SPageLayoutsParticipantsAddParticipantFormSectionsContactDetails from './sections/contact-details.vue';
import SPageLayoutsParticipantsAddParticipantFormSectionsFilesMessages from './sections/files-messages.vue';
import SPageLayoutsParticipantsAddParticipantFormSectionsConfirm from './sections/confirm.vue';
import TabLocale from './tabs-local';
import SStepperControls from './stepper-controls.vue';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantForm',
    components: {
        SStepperControls,
        SStepper,
        SDialogConfirmCancel,
    },
    mixins: [
        SegicLayoutComponent,
        FormMixin,
        storeMixin,
        FileMixin,
        DialogConfirmCancelMixin,
    ],
    props: {
        forceHasReach: {
            default: false,
            type: Boolean,
        },
        showDialogAddParticipantForm: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            storeConstant: {
                name: STORE_CONSTANT_NAMES.CHANGE_REQUESTS.SINGLE,
                template: ChangeRequestsSingleStore,
            },
            disabledNextBtn: true,
            stepperErrors: [
                {
                    name: 'content.participantRegistration.socialInsuranceNumber',
                    step: 1,
                    errorCode: '0018',
                    showError: false,
                    message: null,
                },
            ],
        };
    },
    methods: {
        ShowErrorByFormName(name, showError) {
            const index = findIndex(this.stepperErrors, { name, showError: true });
            this.setShowErrorByIndex(index, showError);
        },
        addErrorStepper(errorCode, errorMessage) {
            const indexOfError = findIndex(this.stepperErrors, ['errorCode', errorCode]);
            if (indexOfError !== 1 && this.stepperErrors[indexOfError]?.message !== undefined) {
                this.setShowErrorByIndex(indexOfError, true);
                this.stepperErrors[indexOfError].message = errorMessage;
            }
        },
        callbackFormError(response) {
            this.addErrorStepper(response.internalCode, response.message);
        },
        cancel(showConfirm) {
            this.toggleConfirmCloseSave(showConfirm);
            if (!showConfirm) {
                this.emitClose();
                this.$refs.stepper.returnToFirstStep();
            }
        },
        contactDetailsData() {
            return {
                content: {
                    participantRegistration: {
                        address: {
                            city: '',
                            civicAddress: '',
                            postalCode: '',
                            provinceId: '',
                            country: 'Canada',
                        },
                        phoneNumbers: [
                            {
                                extension: '',
                                number: '',
                                phoneType: null,
                            },
                        ],
                        email: '',
                    },
                },
            };
        },
        employmentInformationData() {
            return {
                content: {
                    participantRegistration: {
                        statusId: null,
                        hireDate: '',
                        workProvinceId: null,
                        occupation: {
                            notListedValue: null,
                            occupationId: null,
                            description: getI18nEmpty(),
                        },
                        salary: '',
                        divisionId: null,
                        categoryId: null,
                        recipientId: null,
                        benefitsAttribution: {
                            dentalExemption: false,
                            healthExemption: false,
                            ignoreEligibilityPeriod: false,
                            insuranceDate: '',
                            protectionId: null,
                        },
                    },
                },
            };
        },
        filesMessagesData() {
            return {
                content: {
                    note: '',
                },
                attachments: [],
                displayAttachments: [],
                deleteAttachmentIds: [],
            };
        },
        getDefaultSectionFormData(index) {
            switch (index) {
                case STEPPER_TYPE.PERSONAL_INFORMATION:
                    return this.personalInformationData();
                case STEPPER_TYPE.EMPLOYMENT_INFORMATION:
                    return this.employmentInformationData();
                case STEPPER_TYPE.CONTACT_DETAILS:
                    return this.contactDetailsData();
                case STEPPER_TYPE.FILES_MESSAGES:
                    return this.filesMessagesData();
                default:
                    return null;
            }
        },
        getStepper(index) {
            switch (index) {
                case STEPPER_TYPE.PERSONAL_INFORMATION:
                    return SPageLayoutsParticipantsAddParticipantFormSectionsPersonalInformation;
                case STEPPER_TYPE.EMPLOYMENT_INFORMATION:
                    return SPageLayoutsParticipantsAddParticipantFormSectionsEmploymentInformation;
                case STEPPER_TYPE.CONTACT_DETAILS:
                    return SPageLayoutsParticipantsAddParticipantFormSectionsContactDetails;
                case STEPPER_TYPE.FILES_MESSAGES:
                    return SPageLayoutsParticipantsAddParticipantFormSectionsFilesMessages;
                case STEPPER_TYPE.CONFIRM:
                    return SPageLayoutsParticipantsAddParticipantFormSectionsConfirm;
                default:
                    return null;
            }
        },
        personalInformationData() {
            return {
                content: {
                    firstName: '',
                    lastName: '',
                    participantRegistration: {
                        birthDate: '',
                        certificateNumber: null,
                        civilStatus: {
                            civilStatusType: null,
                            cohabitationDate: null,
                        },
                        gender: null,
                        hasIndianStatus: false,
                        language: null,
                        smokerStatus: null,
                        socialInsuranceNumber: '',
                    },
                },
            };
        },
        resetFormData() {
            const defaultData = {
                clientId: this.$route.params.clientGuid,
                groupId: this.$route.params.groupGuid,
                subject: this.$t('addNewPlanMember'),
                communicationType: COMMUNICATION_TYPES.CHANGE_REQUEST,
                contentType: MESSAGE_CONTENT_TYPE.CONSTANT.PARTICIPANT_CREATION_REQUEST,
                system: SYSTEM_TYPES.EMPLOYER_PORTAL,
            };
            this.defaultData = merge(defaultData, this.personalInformationData(), this.employmentInformationData(), this.contactDetailsData(), this.filesMessagesData());
        },
        setShowErrorByIndex(index, isValid) {
            if (index === -1) {
                return;
            }
            this.stepperErrors[index].showError = isValid;
        },
        setSubmitParams() {
            const params = {};
            if (this.formData.id) {
                params.requestGuid = this.formData.id;
            }
            return params;
        },
        stepperItems() {
            const items = [];
            for (let i = 0; i < 5; i += 1) {
                const item = {
                    header: {
                        text: this.$t(`step.headers.header${i}`),
                    },
                    stepper: {
                        component: this.getStepper(i),
                        props: {
                            showDialogAddParticipantForm: this.showDialogAddParticipantForm,
                            overwriteData: this.overwriteData,
                            defaultSectionFormData: this.getDefaultSectionFormData(i),
                        },
                    },
                };
                if (i === STEPPER_TYPE.CONFIRM) {
                    item.stepper.props = {
                        formData: this.formData,
                    };
                }
                items.push(item);
            }
            return items;
        },
        updateForm(key, value) {
            this.ShowErrorByFormName(key, false);
            this.updateField(key, value);
        },
    },
    i18n: {
        messages: TabLocale,
    },
};

export const EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES = {
    EMPLOYER_RESTRICTED_PARTICIPANT_AUTHORIZE_RESOURCE: 'EmployerRestrictedParticipantAuthorizeResource',
    EMPLOYER_GROUP_AUTHORIZE_RESOURCE: 'EmployerGroupAuthorizeResource',
    EMPLOYER_PARTICIPANT_AUTHORIZE_RESOURCE: 'EmployerParticipantAutorizeResource',
    EMPLOYER_BILLING_AUTHORIZE_RESOURCE: 'EmployerBillingAutorizeResource',
    EMPLOYER_PARTICIPANT_FINANCIAL_RESOURCE: 'EmployerParticipantFinancialResource',
    EMPLOYER_CHANGE_REQUEST_RESOURCE: 'EmployerChangeRequestResource',
    ONBOARDING_RESOURCE: 'OnboardingResource',
};
export default {
    [EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.EMPLOYER_RESTRICTED_PARTICIPANT_AUTHORIZE_RESOURCE]: 'e691a797-43ad-1b5c-be1b-71f92f746063',
    [EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.EMPLOYER_GROUP_AUTHORIZE_RESOURCE]: '4ca23f9c-cda9-fc1c-18e5-7d16fa5ef100',
    [EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.EMPLOYER_PARTICIPANT_AUTHORIZE_RESOURCE]: 'bf8e2b7f-bafc-65a8-1ba7-bd0d3b54c93b',
    [EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.EMPLOYER_BILLING_AUTHORIZE_RESOURCE]: 'ef2d0723-986c-af25-901b-da0bc5eb0790',
    [EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.EMPLOYER_PARTICIPANT_FINANCIAL_RESOURCE]: 'f8d058ed-4f66-4084-b192-2397c8452b1f',
    [EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.EMPLOYER_CHANGE_REQUEST_RESOURCE]: 'fc8f067d-57d5-94ea-8464-f6cec295050f',
    [EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.ONBOARDING_RESOURCE]: '9931755b-56c1-498a-868d-4ea5fd7bc217',
};

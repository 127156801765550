import { getEnumValue, getEnumValues, toValuesEnum } from 'segic_shared_front_end_utils/src/enums/_utils';
// eslint-disable-next-line no-shadow
export var CERTIFICATE_NUMBERS_BULK_MAPPING;
(function (CERTIFICATE_NUMBERS_BULK_MAPPING) {
    CERTIFICATE_NUMBERS_BULK_MAPPING[CERTIFICATE_NUMBERS_BULK_MAPPING["CERTIFICATE_NUMBER_IS_NULL"] = 792] = "CERTIFICATE_NUMBER_IS_NULL";
    CERTIFICATE_NUMBERS_BULK_MAPPING[CERTIFICATE_NUMBERS_BULK_MAPPING["CERTIFICATE_NUMBER_EXCEED_MAXIMUM_LENGTH"] = 789] = "CERTIFICATE_NUMBER_EXCEED_MAXIMUM_LENGTH";
    CERTIFICATE_NUMBERS_BULK_MAPPING[CERTIFICATE_NUMBERS_BULK_MAPPING["CERTIFICATE_NUMBER_NOT_FOUND"] = 900] = "CERTIFICATE_NUMBER_NOT_FOUND";
    CERTIFICATE_NUMBERS_BULK_MAPPING[CERTIFICATE_NUMBERS_BULK_MAPPING["EMPLOYEE_NUMBER_IS_NULL"] = 901] = "EMPLOYEE_NUMBER_IS_NULL";
    CERTIFICATE_NUMBERS_BULK_MAPPING[CERTIFICATE_NUMBERS_BULK_MAPPING["EMPLOYEE_NUMBER_EXCEED_MAXIMUM_LENGTH"] = 902] = "EMPLOYEE_NUMBER_EXCEED_MAXIMUM_LENGTH";
    CERTIFICATE_NUMBERS_BULK_MAPPING[CERTIFICATE_NUMBERS_BULK_MAPPING["DUPLICATE_CERTIFICATE_NUMBER"] = 904] = "DUPLICATE_CERTIFICATE_NUMBER";
    CERTIFICATE_NUMBERS_BULK_MAPPING[CERTIFICATE_NUMBERS_BULK_MAPPING["EMPLOYEE_NUMBER_NOT_NUMERIC"] = 905] = "EMPLOYEE_NUMBER_NOT_NUMERIC";
    CERTIFICATE_NUMBERS_BULK_MAPPING[CERTIFICATE_NUMBERS_BULK_MAPPING["DUPLICATE_EMPLOYEE_NUMBER"] = 906] = "DUPLICATE_EMPLOYEE_NUMBER";
})(CERTIFICATE_NUMBERS_BULK_MAPPING || (CERTIFICATE_NUMBERS_BULK_MAPPING = {}));
const values = toValuesEnum(CERTIFICATE_NUMBERS_BULK_MAPPING);
const FR = {
    [CERTIFICATE_NUMBERS_BULK_MAPPING.CERTIFICATE_NUMBER_IS_NULL]: 'Le numéro de certificat est requis.',
    [CERTIFICATE_NUMBERS_BULK_MAPPING.CERTIFICATE_NUMBER_EXCEED_MAXIMUM_LENGTH]: 'Le numéro de certificat ne doit pas dépasser 12 caractères.',
    [CERTIFICATE_NUMBERS_BULK_MAPPING.CERTIFICATE_NUMBER_NOT_FOUND]: 'Le numéro de certificat n\'a pas été trouvé.',
    [CERTIFICATE_NUMBERS_BULK_MAPPING.DUPLICATE_CERTIFICATE_NUMBER]: 'Le numéro de certificat est en double.',
    [CERTIFICATE_NUMBERS_BULK_MAPPING.EMPLOYEE_NUMBER_IS_NULL]: 'Le numéro d\'employé est requis.',
    [CERTIFICATE_NUMBERS_BULK_MAPPING.EMPLOYEE_NUMBER_EXCEED_MAXIMUM_LENGTH]: 'Le numéro d\'employé ne doit pas dépasser 9 caractères.',
    [CERTIFICATE_NUMBERS_BULK_MAPPING.EMPLOYEE_NUMBER_NOT_NUMERIC]: 'Le numéro d\'employé doit être numérique.',
    [CERTIFICATE_NUMBERS_BULK_MAPPING.DUPLICATE_EMPLOYEE_NUMBER]: 'Le numéro d\'employé est en double.',
};
const EN = {
    [CERTIFICATE_NUMBERS_BULK_MAPPING.CERTIFICATE_NUMBER_IS_NULL]: 'The certificate number is required.',
    [CERTIFICATE_NUMBERS_BULK_MAPPING.CERTIFICATE_NUMBER_EXCEED_MAXIMUM_LENGTH]: 'The certificate number must not exceed 12 characters.',
    [CERTIFICATE_NUMBERS_BULK_MAPPING.CERTIFICATE_NUMBER_NOT_FOUND]: 'The certificate number was not found.',
    [CERTIFICATE_NUMBERS_BULK_MAPPING.DUPLICATE_CERTIFICATE_NUMBER]: 'The certificate number is duplicated.',
    [CERTIFICATE_NUMBERS_BULK_MAPPING.EMPLOYEE_NUMBER_IS_NULL]: 'The employee number is required.',
    [CERTIFICATE_NUMBERS_BULK_MAPPING.EMPLOYEE_NUMBER_EXCEED_MAXIMUM_LENGTH]: 'The employee number must not exceed 9 characters.',
    [CERTIFICATE_NUMBERS_BULK_MAPPING.EMPLOYEE_NUMBER_NOT_NUMERIC]: 'The employee number must be numeric.',
    [CERTIFICATE_NUMBERS_BULK_MAPPING.DUPLICATE_EMPLOYEE_NUMBER]: 'The employee number is duplicated.',
};
export const Dictionaries = {
    fr: FR,
    en: EN,
};
export function FR_WITH_ZERO_PREFIX() {
    const result = {};
    Object.keys(FR).forEach((key) => {
        result[`0${key}`] = FR[key];
    });
    return result;
}
export function EN_WITH_ZERO_PREFIX() {
    const result = {};
    Object.keys(EN).forEach((key) => {
        result[`0${key}`] = FR[key];
    });
    return result;
}
export function getMappingErrorDictionariesByCode(code) {
    return {
        fr: FR[code],
        en: EN[code],
    };
}
export function getMappingErrorCodes(lang, excluded = []) {
    return getEnumValues(values, Dictionaries, lang, excluded);
}
export function getMappingErrorValuesPerCode(code, lang) {
    return getEnumValue(values, Dictionaries, lang, code);
}
export function hasError(errorsToCheck, errorCodes) {
    return errorsToCheck.some((errorToCheck) => errorCodes.indexOf(errorToCheck) !== -1);
}
export const CERTIFICATE_NUMBER_ERROR = [
    CERTIFICATE_NUMBERS_BULK_MAPPING.CERTIFICATE_NUMBER_IS_NULL,
    CERTIFICATE_NUMBERS_BULK_MAPPING.CERTIFICATE_NUMBER_EXCEED_MAXIMUM_LENGTH,
    CERTIFICATE_NUMBERS_BULK_MAPPING.CERTIFICATE_NUMBER_NOT_FOUND,
    CERTIFICATE_NUMBERS_BULK_MAPPING.DUPLICATE_CERTIFICATE_NUMBER,
];
export function hasCertificateNumberError(errorCodes) {
    return hasError(CERTIFICATE_NUMBER_ERROR, errorCodes);
}
export const EMPLOYEE_NUMBERS = [
    CERTIFICATE_NUMBERS_BULK_MAPPING.EMPLOYEE_NUMBER_IS_NULL,
    CERTIFICATE_NUMBERS_BULK_MAPPING.EMPLOYEE_NUMBER_EXCEED_MAXIMUM_LENGTH,
    CERTIFICATE_NUMBERS_BULK_MAPPING.EMPLOYEE_NUMBER_NOT_NUMERIC,
];
export function hasMappingCodeError(errorCodes) {
    return hasError(EMPLOYEE_NUMBERS, errorCodes);
}

import { forEach, isEqual, merge } from 'lodash';
import LOGGER from './debugging';
export function getCrudServiceUrl() {
    return 'api/me/crudservices/';
}
export function getClientGroupGuid(params, userStore, noEmpty) {
    if (!userStore) {
        LOGGER.error('You need a userStore');
        return;
    }
    if (!params) {
        return;
    }
    if (!params.clientGuid && params.clientCode && params.clientCode !== 'all') {
        if (userStore.getGetter('client', params.clientCode)) {
            params.clientGuid = userStore.getGetter('client', params.clientCode).Id;
        }
    }
    else if (!params.clientGuid && !noEmpty) {
        params.clientGuid = 'all';
    }
    if (!params.groupGuid && params.groupCode && params.groupCode !== 'all' && params.clientCode && params.clientCode !== 'all') {
        const data = {
            clientCode: params.clientCode,
            groupCode: params.groupCode,
        };
        if (userStore.getGetter('group', data)) {
            params.groupGuid = userStore.getGetter('group', data).Id;
        }
    }
    else if (!params.groupGuid && !noEmpty) {
        params.groupGuid = 'all';
    }
    return params;
}
export function replaceStringData(string, data = {}) {
    return string.replace(/\${([^}]*)}/g, (string, matched) => ((typeof data[matched] !== 'undefined') ? data[matched] : matched));
}
export function replaceStringRoutingData(string, data = {}) {
    return string.replace(/:{([^}]*)}/g, (string, matched) => ((typeof data[matched] !== 'undefined') ? data[matched] : ''));
}
export function replaceStringRoutingDataWithAny(string) {
    return string.replace(/:{([^}]*)}/g, () => 'any');
}
export function replaceStringLocaleData(string, i18n) {
    if (!i18n) {
        LOGGER.warn('You need to pass translate function');
    }
    return string.replace(/t{([^}]*)}/g, (string, matched) => i18n.call(this, matched));
}
export function replaceStringBrackets(string) {
    return string.replace(/[{}]*/g, '');
}
export function getQueryString(query) {
    let finalFilters = '';
    for (const key in query) {
        if (query.hasOwnProperty(key)) {
            finalFilters += (finalFilters.length === 0) ? '?' : '&';
            if (typeof query[key] === 'object') {
                finalFilters += `${key}=${JSON.stringify(query[key])}`;
            }
            else {
                finalFilters += `${key}=${query[key]}`;
            }
        }
    }
    return finalFilters;
}
export function getFiltersQueryString(obj) {
    const queryString = Object.entries(obj)
        .map(([key, value]) => {
        // Skip parameters with empty values
        if (value === '' || value === null || value === undefined) {
            return '';
        }
        if (Array.isArray(value)) {
            // For arrays, create multiple query params for each element
            return value.map((val) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');
        }
        // For single values, encode key and value
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
        .filter((param) => param !== '') // Filter out empty parameters
        .join('&');
    return queryString ? `?${queryString}` : '';
}
export function compareRoute(route, cRouteObject, debug) {
    if (debug) {
        LOGGER.log(route, cRouteObject);
    }
    if (route && route.params && route.query) {
        const combineParams = merge({}, cRouteObject.params, route.params);
        const combineQuery = merge({}, cRouteObject.query, route.query);
        return (isEqual(cRouteObject.params, combineParams) && isEqual(cRouteObject.query, combineQuery));
    }
    if (route && route.params) {
        const combineParams = merge({}, cRouteObject.params, route.params);
        return (isEqual(cRouteObject.params, combineParams));
    }
    if (route && route.query) {
        const combineQuery = merge({}, cRouteObject.query, route.query);
        return (isEqual(cRouteObject.query, combineQuery));
    }
    return true;
}
export function hasSameParams(route, windowTabRoute) {
    if (!route.params) {
        return true;
    }
    return (isEqual(route.params, windowTabRoute.params));
}
export function hasSameQueryParams(route, windowTabRoute) {
    if (!route.query) {
        return true;
    }
    return (isEqual(route.query, windowTabRoute.query));
}
export function hasAtLeastRouteParams(route, windowTabRoute) {
    if (!route.params) {
        return true;
    }
    let hasSameParams = true;
    forEach(route.params, (val, key) => {
        if (route.params[key] !== windowTabRoute.params[key]) {
            hasSameParams = false;
            return false;
        }
    });
    return hasSameParams;
}
export function hasAtLeastRouteQueryParams(route, windowTabRoute) {
    if (!route.query) {
        return true;
    }
    let hasSameParams = true;
    forEach(route.query, (val, key) => {
        if (route.query[key] !== windowTabRoute.query[key]) {
            hasSameParams = false;
            return false;
        }
    });
    return hasSameParams;
}
export function getEnv() {
    const envs = {
        local: 'localhost',
        static: 'segicdev.azurewebsites.net',
        prod: 'segic.ca',
    };
    let env = 'local';
    const { host } = window.location;
    forEach(envs, (value, key) => {
        if (host.indexOf(value) !== -1) {
            env = key;
            return false;
        }
    });
    return env;
}

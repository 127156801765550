import SLabelForm from '@/components/ui/label-form.vue';
import SFieldFileDropzone from '@/components/form/fields/file-drop-zone.vue';
import LayoutComponentMixins from '@/mixins/segic-layout-component';
import StoreExtender from '@/store/extender';
import STORE_CONSTANT_NAMES from '@/store/names';
import PayrollFileConversionStore from '@/store/payroll-files-conversion';
import FormSimpleMixin from '@/mixins/form-simple';
import SSendInvitationFileCorrupted from '@/page-layouts/administrator/onboarding/invite/dialog/file-corrupted.vue';
import { EventBus } from '@/utils/event-bus';
import { createAnchor, download } from 'segic_shared_front_end_utils/src/utils/download-file';
import LANGUAGE_CODES from 'segic_shared_front_end_utils/src/enums/language-codes';
import SETTINGS from 'segic_shared_front_end_utils/src/constants/settings';
import { isOnboardingPlanMemberFileHasError, isOnboardingPlanMemberFileValid } from 'segic_shared_front_end_utils/src/enums/onboarding-plan-member-file-status';
import SMappingConfirmation from './mapping-confirmation.vue';
import SMappingFileValidation from './mapping-file-validation.vue';
import i18n from '../utils/locales';
import SEmptyState from './empty-state.vue';
export default {
    name: 'SOnboardingDialog',
    components: {
        SSendInvitationFileCorrupted,
        SMappingConfirmation,
        SMappingFileValidation,
        SFieldFileDropzone,
        SLabelForm,
        SEmptyState,
    },
    mixins: [
        LayoutComponentMixins,
        FormSimpleMixin,
    ],
    props: {
        disableDialogButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showDialog: false,
            templateIsLoading: false,
            validationIsLoading: false,
            isFileValid: false,
            hasErrors: false,
            validationResults: [],
            errorCount: 0,
            hasSendValidationFile: false,
            firstLoad: true,
        };
    },
    computed: {
        hasValidationResults() {
            if (this.validationResults === null) {
                return false;
            }
            return this.validationResults.length !== 0;
        },
    },
    beforeMount() {
        EventBus.$on('confirmBulkMapping', () => this.toggleDialog(false));
    },
    methods: {
        async downloadTemplate() {
            this.templateIsLoading = true;
            const extraParams = {
                clientGuid: this.$route.params.clientGuid,
                groupGuid: this.$route.params.groupGuid,
            };
            const store = new StoreExtender(this, STORE_CONSTANT_NAMES.PAYROLL_SYSTEM_CONVERSION.BULK_CERTIFICATE_MAPPING_TEMPLATE, PayrollFileConversionStore, { extraParams });
            await store.sendActions('getTemplate', { query: { culture: LANGUAGE_CODES.getCodeByLanguage(SETTINGS.LANGUAGE) } });
            const template = store.getGetter('getTemplate');
            download(createAnchor(template.fileName, template.url));
            this.templateIsLoading = false;
        },
        resetFormData() {
            this.isFileValid = false;
            this.hasErrors = false;
            this.validationResults = [];
            this.errorCount = 0;
            this.hasSendValidationFile = false;
            this.firstLoad = true;
            this.defaultData = {
                fileData: {
                    name: null,
                },
            };
        },
        async sendData() {
            this.validationIsLoading = true;
            try {
                const store = new StoreExtender(this, STORE_CONSTANT_NAMES.PAYROLL_SYSTEM_CONVERSION.BULK_CERTIFICATE_MAPPING_VALIDATE, PayrollFileConversionStore, { extraParams: { clientGuid: this.$route.params.clientGuid, groupGuid: this.$route.params.groupGuid } });
                await store.sendActions('validate', this.formData.fileData.files);
                const fileValidate = store.getGetter('validate');
                this.validationResults = fileValidate.excelValidationResults ? fileValidate.excelValidationResults : [];
                this.showCertificateNumber = fileValidate.showCertificateNumber;
                this.isFileValid = isOnboardingPlanMemberFileValid(fileValidate.fileStatus);
                this.hasErrors = isOnboardingPlanMemberFileHasError(fileValidate.fileStatus);
                this.errorCount = fileValidate.errorCount;
                this.validationIsLoading = false;
                this.firstLoad = false;
                this.hasSendValidationFile = true;
            }
            catch (e) {
                this.validationIsLoading = false;
            }
        },
        async sendDataAndResetFile() {
            await this.sendData();
            this.updateField('fileData', this.defaultData.fileData);
        },
        async sendRequestAndResetFile() {
            this.$refs.observer.validate().then((valid) => {
                if (valid) {
                    this.sendDataAndResetFile();
                }
            });
        },
        toggleDialog(show) {
            this.showDialog = show;
        },
    },
    i18n,
};

import shared from 'segic_shared_front_end_utils/src/locales/fr';
import api from './fr/api';
import common from './fr/common';
import format from './fr/format';
import global from './fr/global';
import title from './fr/title';
import validation from './fr/validation';
/* COMMONS */
const fr = {
    api,
    common,
    format,
    title,
    validation,
    ...global,
    ...shared,
    activePlanMembersOnly: 'Participants actifs seulement',
    actions: 'Actions',
};
export default fr;

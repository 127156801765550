import StoreBasics from '@/store/basics';
import storeUtils from '@/store/utils';
import { ageFromYear } from '@/utils/helpers';
import { filter, forEach, orderBy } from 'lodash';
import entities from './entities';
import Service from './service';
const cacheDelay = -1;
const storeData = [
    {
        key: 'data',
        defaultValue: {},
        methods: [
            {
                key: 'get',
                params: ['clientGuid', 'groupGuid', 'participantGuid', 'data'],
                cacheDelay,
                inputFormatting(state, stateKey, data) {
                    const newData = entities.input(data);
                    state[stateKey].value = newData;
                    state.history.value = newData.addresses;
                },
                displayFormatting(data) {
                    return entities.display(data);
                },
            },
        ],
        service: Service,
    },
    {
        key: 'data2',
        defaultValue: null,
        methods: [
            {
                key: 'getProfile',
                params: ['clientGuid', 'groupGuid', 'participantGuid', 'data'],
                cacheDelay,
                inputFormatting(state, stateKey, data, payload) {
                    const emptyObject = {
                        Code: '',
                        Value: '',
                    };
                    data.Spouse.BirthDate = data.Spouse.BirthDate && data.Spouse.Gender && data.Spouse.SmokerStatus ? ageFromYear(data.Spouse.BirthDate, payload.query.requestDate) : null;
                    data.Spouse.Gender = data.Spouse.Gender ? data.Spouse.Gender : emptyObject;
                    data.Spouse.SmokerStatus = data.Spouse.SmokerStatus ? data.Spouse.SmokerStatus : emptyObject;
                    state[stateKey].value = data;
                },
            },
        ],
        service: Service,
    },
    {
        key: 'history',
        defaultValue: [],
        additionalGetters: [
            {
                key: 'history',
            },
        ],
    },
];
const state = storeUtils.mergeStates(StoreBasics.statesFromData(storeData));
const getters = storeUtils.mergeGetters(StoreBasics.gettersFromData(storeData), {
    // eslint-disable-next-line no-shadow
    getHistory(state) {
        let newData = [];
        forEach(state.history.value, (item) => {
            newData.push(item);
        });
        newData = orderBy(newData, ['effectiveDate'], ['desc']);
        return filter(newData, { isRecordCanceled: false });
    },
});
const mutations = storeUtils.mergeMutations(StoreBasics.mutationsFromData(storeData));
const actions = storeUtils.mergeActions(StoreBasics.actionsFromData(storeData));
const ParticipantsSingleStore = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default ParticipantsSingleStore;

/**
     * @displayName Title Header
     * */
export default {
    name: 'SPageTitle',
    props: {
        /**
                 * Text
                 */
        text: {},
        /**
                 * Text color
                 */
        color: {
            default: '$segic-dark-theme-white',
        },
    },
    computed: {
        title() {
            let title = this.$route.name ? `${this.$route.meta.title}` : '';
            if (this.text) {
                title = this.text;
            }
            return this.$t(title);
        },
    },
};

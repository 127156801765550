import SLabelForm from '@/components/ui/label-form.vue';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantFormSectionsConfirmCard',
    components: { SLabelForm },
    mixins: [],
    props: {
        title: {
            required: true,
            type: String,
        },
        items: {
            required: true,
            type: Array,
        },
    },
};

import FieldsMixin from '@/mixins/components/fields';
import ComponentMixins from '@/mixins/segic-component';
export default {
    name: 'SSwitch',
    mixins: [
        FieldsMixin,
        ComponentMixins,
    ],
    props: {
        hideDetails: {
            type: Boolean,
            default: true,
        },
        text: {
            type: String,
            default: '',
        },
    },
    computed: {
        textLabel() {
            return this.model ? this.$t('on') : this.$t('off');
        },
    },
    i18n: {
        messages: {
            fr: {
                on: 'Oui',
                off: 'Non',
            },
            en: {
                on: 'Yes',
                off: 'No',
            },
        },
    },
};

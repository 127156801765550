import SFieldCheckbox from '@/components/form/fields/checkbox.vue';
import storeMixin from '@/mixins/page-layout/store';
import SegicLayoutComponent from '@/mixins/segic-layout-component';
import StoreExtender from '@/store/extender';
import STORE_CONSTANT_NAMES from '@/store/names';
import dependentsReport038 from '@/store/reports/R038-dependents/single/index';
import participantReport038 from '@/store/reports/R038-participants/single/index';
import moment from 'moment';
import SETTINGS from 'segic_shared_front_end_utils/src/constants/settings';
import LANGUAGE_CODES from 'segic_shared_front_end_utils/src/enums/language-codes';
import { createAnchor, download } from 'segic_shared_front_end_utils/src/utils/download-file';
export default {
    name: 'SBtnParticipantDependentReport',
    components: { SFieldCheckbox },
    mixins: [
        storeMixin,
        SegicLayoutComponent,
    ],
    data() {
        return {
            isCreatingReport: false,
            isDownloadReportDisabled: false,
            showDialog: false,
            isParticipant: true,
            isDependents: false,
            activeParticipants: false,
            activeOnly: false,
        };
    },
    computed: {
        validationParticipantDependentCheckBox() {
            return !this.isParticipant && !this.isDependents ? 'isTrue' : '';
        },
    },
    methods: {
        displayDialog() {
            this.showDialog = true;
        },
        handleUpdate(value, key) {
            this[key] = value;
            this.$nextTick(async () => {
                const isValid = await this.$refs.observer.validate();
                this.isDownloadReportDisabled = !isValid;
            });
        },
        async handleDownloadFile(storeName, storeTemplate) {
            const store = new StoreExtender(this, storeName, storeTemplate);
            await store.sendActions('downloadReport', {
                query: {
                    culture: LANGUAGE_CODES.getCodeByLanguage(SETTINGS.LANGUAGE),
                    requestDate: moment().format('YYYY-MM-DD'),
                    activeOnly: this.activeOnly,
                },
            });
            const response = store.getGetter('downloadReport');
            download(createAnchor(response.fileName, response.url));
        },
        async downloadFiles() {
            if (this.isParticipant) {
                await this.handleDownloadFile(STORE_CONSTANT_NAMES.REPORTS.R038_PARTICIPANTS, participantReport038);
            }
            if (this.isDependents) {
                await this.handleDownloadFile(STORE_CONSTANT_NAMES.REPORTS.R038_DEPENDENTS, dependentsReport038);
            }
        },
        async handleClickCreateReport() {
            this.isCreatingReport = true;
            await this.downloadFiles();
            this.showDialog = false;
            this.isCreatingReport = false;
        },
    },
};

import { camelCase, forEach, mapKeys } from 'lodash';
import References from '../constants/references';
import SETTINGS from '../constants/settings';
import LOGGER from './debugging';
import { isJson } from './types';
function decodeString(str) {
    if (!str) {
        return str;
    }
    return str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
}
export function getI18n(value) {
    if (!value || typeof value !== 'string') {
        return value;
    }
    let json = value;
    if (value.indexOf('[{"') === -1) {
        json = value.replace(/'([^}:]+)'/g, '"$1"').replace(/',[ ]?'/g, '","');
    }
    if (!isJson(json)) {
        if (json.indexOf('[{') !== -1 && window.location.hostname === 'localhost') {
            throw `Invalide json: ${json}`;
        }
        return value;
    }
    const languages = SETTINGS.LANGUAGES;
    const i18n = {};
    const fieldString = JSON.parse(json);
    forEach(fieldString, (value) => {
        const i18nValue = value.Value;
        i18n[languages[value.Code - 1]] = decodeString(i18nValue);
    });
    return i18n;
}
export function setI18n(value) {
    if (!value || !isJson(value) || typeof value === 'string') {
        return value;
    }
    const languages = SETTINGS.LANGUAGES;
    let i18n = '[';
    forEach(languages, (code, index) => {
        const i18nValue = value[code].trim();
        i18n += `{"Code":${(index + 1)},"Value":"${i18nValue}"}`;
        if (index !== (languages.length - 1)) {
            i18n += ',';
        }
    });
    i18n += ']';
    return i18n;
}
export function getI18nEmpty() {
    return getI18nWithSameValue('');
}
export function getI18nWithSameValue(value) {
    const i18n = {};
    forEach(SETTINGS.LANGUAGES, (language) => {
        i18n[language] = value;
    });
    return i18n;
}
export function getI18nFromCulture(items, emptyValue = '') {
    if (!Array.isArray(items)) {
        return items;
    }
    if (!items) {
        return getI18nEmpty();
    }
    const i18n = {};
    forEach(items, (item) => {
        const value = (mapKeys(item, (v, k) => camelCase(k)));
        const i18nValue = value.text;
        const lang = value.cultureName.replace(/-../g, '');
        i18n[lang] = decodeString(i18nValue);
        if ((i18n[lang] === '' || i18n[lang] === null)) {
            i18n[lang] = emptyValue;
        }
    });
    return i18n;
}
export function setI18nFromCulture(value) {
    if (!value || !isJson(value) || typeof value === 'string') {
        return value;
    }
    const languages = SETTINGS.LANGUAGES;
    const i18n = [];
    forEach(languages, (code) => {
        const i18nValue = value[code].trim();
        i18n.push({ CultureName: `${code}-CA`, Text: i18nValue });
    });
    return i18n;
}
export function isI18nFromCultureEmpty(value) {
    const languages = SETTINGS.LANGUAGES;
    let isEmpty = true;
    forEach(languages, (code) => {
        if (value[code] !== '') {
            isEmpty = false;
            return false;
        }
    });
    return isEmpty;
}
export function getI18nLocalized(data, lang, placeholder) {
    let string = (placeholder) || null;
    if (data) {
        if (typeof data[lang] !== 'string') {
            LOGGER.warn('language not supported for that string:', lang, data);
        }
        string = data[lang];
    }
    return string;
}
function vueI18nConfig(messages, lang) {
    return {
        locale: lang,
        fallbackLocale: SETTINGS.FALLBACK_LANGUAGE,
        messages,
        silentTranslationWarn: true,
        missing: (opts) => {
            console.error(opts);
        },
    };
}
/*
* NOTE: this is used in apps with vue-i18n v8 and v9
* v8 is used in apps with vue 2 and v9 in vue 3.
* Both vue-i18n versions have different APIs.
 */
export function getI18nDictionnary(messages, lang) {
    if (!References.VueI18n) {
        LOGGER.error('VueI18n reference hasnt been set');
        return null;
    }
    let dictionary;
    if (References?.VueI18n?.version && References?.VueI18n?.version.length && References.VueI18n.version[0] === '8') {
        dictionary = new References.VueI18n(vueI18nConfig(messages, lang));
    }
    else {
        dictionary = References.VueI18n(vueI18nConfig(messages, lang));
    }
    return dictionary.global || dictionary;
}

import { getEnumValue, getEnumValues, toValues } from './_utils';
export var YesNo;
(function (YesNo) {
    YesNo[YesNo["NO"] = 0] = "NO";
    YesNo[YesNo["YES"] = 1] = "YES";
})(YesNo || (YesNo = {}));
const CONSTANT = {
    YES: YesNo.YES,
    NO: YesNo.NO,
};
const values = toValues(CONSTANT);
const FR = {
    [CONSTANT.YES]: 'Oui',
    [CONSTANT.NO]: 'Non',
};
const EN = {
    [CONSTANT.YES]: 'Yes',
    [CONSTANT.NO]: 'No',
};
const Dictionaries = {
    fr: FR,
    en: EN,
};
function getValues(lang) {
    return getEnumValues(values, Dictionaries, lang);
}
function getValuePerCode(code, lang) {
    return getEnumValue(values, Dictionaries, lang, code);
}
export function displayYesNoByBoolean(value, lang) {
    return value ? getValuePerCode(CONSTANT.YES, lang).Value : getValuePerCode(CONSTANT.NO, lang).Value;
}
const YES_NO = {
    CONSTANT,
    getValues,
    getValuePerCode,
};
export default YES_NO;

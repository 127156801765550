import StoreBasics from '@/store/basics';
import storeUtils from '@/store/utils';
import { isDateSameOrAfter } from '@/utils/helpers';
import { cloneDeep, filter, find, forEach, orderBy, } from 'lodash';
import entities from './entities';
import Service from './service';
const cacheDelay = 0;
const storeData = [
    {
        key: 'data',
        defaultValue: [],
        methods: [
            {
                key: 'get',
                params: ['clientGuid', 'groupGuid', 'data'],
                cacheDelay,
                inputFormatting(state, stateKey, data) {
                    let newData = data.map((value) => entities.input(value));
                    newData = orderBy(newData, ['EffectiveDate'], ['desc']);
                    const status = find(newData, (status) => isDateSameOrAfter(status.EffectiveDate));
                    state[stateKey].value = status || newData[0];
                    state.history.value = cloneDeep(newData).map((item) => {
                        delete item.isFutureParticipant;
                        return item;
                    });
                },
                displayFormatting(data) {
                    return entities.display(data);
                },
            },
        ],
        service: Service,
    },
    {
        key: 'history',
        defaultValue: [],
        additionalGetters: [
            {
                key: 'history',
            },
        ],
    },
];
const state = storeUtils.mergeStates(StoreBasics.statesFromData(storeData));
const getters = storeUtils.mergeGetters(StoreBasics.gettersFromData(storeData), {
    getHistory(data) {
        let newData = [];
        forEach(data.history.value, (item) => {
            newData.push(entities.display(item));
        });
        newData = orderBy(newData, ['EffectiveDate'], ['desc']);
        return filter(newData, { IsRecordCanceled: false });
    },
});
const mutations = storeUtils.mergeMutations(StoreBasics.mutationsFromData(storeData));
const actions = storeUtils.mergeActions(StoreBasics.actionsFromData(storeData));
const ParticipantsStatusStore = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default ParticipantsStatusStore;

export default {
    active: 'Actif',
    add: 'Ajouter',
    addANewSpouse: 'Ajouter un nouveau conjoint',
    addChild: 'Ajouter un enfant',
    addNewPlanMember: 'Ajouter un nouveau participant',
    address: 'Adresse',
    age: 'Âge',
    allDivisions: 'Toutes les divisions',
    annualSalary: 'Salaire annuel',
    at: 'at',
    bankAccount: 'Compte de banque',
    benefit: 'Garantie',
    benefitOrService: 'Garantie ou service',
    benefits: 'Garanties',
    benefitsAndServices: 'Garanties & services',
    birthDate: 'Date de naissance',
    bornOnMan: 'Né le',
    bornOnWoman: 'Née le',
    bulkSalaryUpdate: 'Changement massif des salaires',
    cancel: 'Annuler',
    carrier: 'Assureur',
    cellPhone: 'Cellulaire',
    certificate: 'Certificat',
    changeRequests: 'Suivi des demandes de changement',
    changeTheSchoolAttendanceConfirmationEndDate: 'Modifier la date de fin de la confirmation de fréquentation scolaire',
    child: 'Enfant',
    children: 'Enfants',
    civilStatus: 'État civil',
    class: 'Catégorie',
    clientPortal: 'Espace client',
    close: 'Fermer',
    communicationLanguage: 'Langue de communication',
    confirm: 'Confirmer',
    contactEmail: 'Courriel de communication',
    coverageEffectiveDate: 'Date effective d\'assurance',
    covered: 'Couvert',
    coveredPeople: 'Personnes couvertes',
    createReport: 'Créer le rapport',
    current: 'Actuel',
    date: 'Date',
    dateFrom: 'Date du',
    dateOnWhichTheChangeIsToTakeEffect: 'Date à laquelle le changement doit prendre effet',
    dateTo: 'Date au',
    delete: 'Supprimer',
    deleted: 'Supprimé',
    dental: 'Dentaire',
    dependents: 'Personnes à charge',
    description: 'Description',
    discardChanges: 'Abandonner les changements',
    division: 'Division',
    downloadInvoice: 'Télécharger la facture',
    downloadPayrollDeductionsFile: 'Télécharger le fichier de déductions',
    downloadTemplate: 'Télécharger le gabarit',
    effective: 'Effectif',
    effectiveDate: 'Date effective',
    effectiveOn: 'Effectif le',
    end: 'Fin',
    exemption: 'Exemption',
    existing: 'Existant',
    extension: 'Extension',
    fictive: 'Fictif',
    files: 'Fichiers',
    filter: 'Filtre',
    filterBy: 'Filtrer',
    filters: 'Filtres',
    firstName: 'Prénom',
    firstNationsStatus: 'Statut des Premières Nations',
    gainNumber: 'Code de gain',
    gender: 'Genre',
    globalChange: 'Changement global',
    goBack: 'Retour',
    group: 'Régime',
    groups: 'Régimes',
    health: 'Santé',
    hiredDate: 'Date d\'embauche',
    history: 'Historique',
    ifRequired: '(si requis)',
    import: 'Importer',
    informationAbout: 'Information sur',
    insuredAmount: 'Capital assuré',
    invoiceDate: 'Date de la facture',
    invoiceNo: 'No facture',
    invoicePeriod: 'Période facturée',
    invoices: 'Factures',
    lastName: 'Nom',
    logout: 'Se déconnecter',
    makeAChangeRequest: 'Faire une demande de changement',
    memberPortal: 'Espace membre',
    messageToThePlanAdministrator: 'Message à l\'intention de l\'administrateur du régime',
    name: 'Nom',
    new: 'Nouveau',
    no: 'No',
    noData: 'Aucune donnée disponible',
    noMatchingRecordFound: 'Aucune donnée trouvée',
    notCovered: 'Non couvert',
    note: 'Note',
    number: 'Numéro',
    occupation: 'Occupation',
    option: 'Option',
    optional: '(facultatif)',
    planMemberStatus: 'Statut du participant',
    planMembersAndDependentsReport: 'Rapport des participants et des personnes à charge',
    payrollFiles: 'Fichiers de déductions',
    payrollFrequency: 'Fréquence de la paie',
    personal: 'Personnel',
    phone: 'Téléphone',
    phones: 'Téléphones',
    planMember: 'Participant',
    planMembers: 'Participants',
    policy: 'Police',
    premiumsSimulation: 'Simulation des primes',
    profile: 'Profil',
    selectAPlanMember: 'Sélectionnez un participant',
    startDateOfCohabitation: 'Date de début de la cohabitation',
};

import { isNullOrUndefined } from './types';
export function formatFileName(fileName, maxLength = 26) {
    if (isNullOrUndefined(fileName)) {
        return null;
    }
    if (fileName.length >= maxLength) {
        return `${fileName.substring(0, maxLength / 2)}...${fileName.substring(fileName.length, (fileName.length - (maxLength / 2)))}`;
    }
    return fileName;
}
;

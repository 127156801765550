const EVENTS = {
    CANCEL: 'CANCEL',
    CLOSE: 'CLOSE',
    DELETE: 'DELETE',
    EDIT: 'EDIT',
    CLAIM_CODES: 'CLAIM_CODES',
    VIEW: 'VIEW',
    REFRESH: 'REFRESH',
    SNACKBAR: 'SNACKBAR',
    DOWNLOAD: 'DOWNLOAD',
    HISTORY: 'HISTORY',
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    NEXT: 'NEXT',
    PREVIOUS: 'PREVIOUS',
    SUCCESS: 'SUCCESS',
    SUBMIT: 'SUBMIT',
    SEARCH: {
        PARTICIPANTS: 'SEARCH_PARTICIPANTS',
        REQUEST: 'SEARCH_REQUEST',
        REFRESH: 'REFRESH',
        HEALTH_SERVICE: 'HEALTH_SERVICE',
    },
    TOGGLE: {
        PARTICIPANT_HEADER_FILTERS: 'PARTICIPANT_HEADER_FILTERS',
        PARTICIPANT_LIST_FILTERS: 'PARTICIPANT_LIST_FILTERS',
        PARTICIPANT_LIST_FILTER_BTN: 'PARTICIPANT_LIST_FILTER_BTN',
        CARES_LIST: 'CARES_LIST',
    },
};
export default EVENTS;

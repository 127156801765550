import { getEmptyComponents } from '@/utils/page-layouts';
import Logo from '@/components/ui/logo.vue';
import UserAccountRoutes from '@/page-layouts/manage-account/route-name';
const routes = [];
const children = [];
const components = getEmptyComponents();
components.default = () => import(/* webpackChunkName: "userAccount" */ './index.vue');
routes.push({
    name: UserAccountRoutes.USER_ACCOUNT,
    path: '/user-account',
    components,
    children,
    meta: {
        headerComponents: {
            left: {
                component: Logo,
            },
        },
        forceRouterFirstRoute: true,
    },
});
export default routes;

// import _ from 'lodash';
import StoreBasics from '@/store/basics';
import storeUtils from '@/store/utils';
import { outputFormData } from 'segic_shared_front_end_utils/src/utils/output-FormData';
import Service from '../service';
const cacheDelay = -1;
const storeData = [
    {
        key: 'data',
        defaultValue: [],
        methods: [
            {
                key: 'create',
                params: ['clientGuid', 'groupGuid', 'requestGuid', 'data'],
                cacheDelay,
                outputFormatting(data) {
                    return outputFormData(data, 'files');
                },
            },
            {
                key: 'delete',
                params: ['clientGuid', 'groupGuid', 'requestGuid', 'data'],
                cacheDelay,
            },
            {
                key: 'download',
                params: ['clientGuid', 'groupGuid', 'requestGuid', 'attachmentGuid'],
                cacheDelay,
            },
        ],
        service: Service,
    },
];
const state = storeUtils.mergeStates(StoreBasics.statesFromData(storeData));
const getters = storeUtils.mergeGetters(StoreBasics.gettersFromData(storeData));
const mutations = storeUtils.mergeMutations(StoreBasics.mutationsFromData(storeData));
const actions = storeUtils.mergeActions(StoreBasics.actionsFromData(storeData));
const ChangeRequestsAttachmentsSingleStore = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default ChangeRequestsAttachmentsSingleStore;

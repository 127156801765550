import Vue from 'vue';
import { currencyWithoutSeparator, formatCurrency } from '@/utils/helpers';
import { Languages } from 'segic_shared_front_end_utils/src/types/language.types';
import SFieldText from './text.vue';
export default Vue.extend({
    name: 'SFieldTextMoney',
    components: {
        SFieldText,
    },
    inheritAttrs: false,
    props: {
        rules: {
            default: null,
            type: String,
        },
        model: {
            required: true,
            type: [String, Number],
        },
        name: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            isValid: null,
        };
    },
    computed: {
        rulesComputed() {
            let rules = 'money';
            if (this.rules) {
                rules += `|${this.rules}`;
            }
            return rules;
        },
    },
    watch: {
        '$i18n.locale': function watchLocale(newVal, oldVal) {
            const data = {
                model: this.model,
                flags: {
                    valid: this.isValid,
                },
                name: this.name,
            };
            this.validationOnUpdate(data, oldVal);
        },
    },
    methods: {
        validationOnUpdate(data, locale = this.$i18n.locale) {
            this.isValid = data.flags.valid;
            if (data.flags.valid) {
                let currWithoutSeparator = data.model;
                if (locale === Languages.FR) {
                    currWithoutSeparator = currWithoutSeparator.toString().replace(',', '.');
                }
                currWithoutSeparator = currencyWithoutSeparator(currWithoutSeparator);
                this.$emit('update:modelRaw', currWithoutSeparator);
                const value = formatCurrency(currWithoutSeparator, false, 2, true);
                this.$emit('update:model', value, data.name);
            }
        },
    },
});

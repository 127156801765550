import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
export default {
    name: 'TabHeader',
    props: {
        hasWarning: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
    },
    i18n,
};

import { find } from 'lodash';
import { getEnumValues, toValues } from '../enums/_utils';
const SECURITY_PERMISSIONS = {
    NONE: 1 << 0,
    VIEW: 1 << 1,
    EDIT: 1 << 2,
    VIEW_EDIT: 6, // todo a retirer
};
const values = toValues(SECURITY_PERMISSIONS);
const FR = {
    [SECURITY_PERMISSIONS.NONE]: 'None',
    [SECURITY_PERMISSIONS.VIEW]: 'View',
    [SECURITY_PERMISSIONS.EDIT]: 'Edit',
    [SECURITY_PERMISSIONS.VIEW_EDIT]: 'View/Edit',
};
const EN = {
    [SECURITY_PERMISSIONS.NONE]: 'None',
    [SECURITY_PERMISSIONS.VIEW]: 'View',
    [SECURITY_PERMISSIONS.EDIT]: 'Edit',
    [SECURITY_PERMISSIONS.VIEW_EDIT]: 'View/Edit',
};
const Dictionaries = {
    fr: FR,
    en: EN,
};
export function getSecurityPermissions(lang, excluded = []) {
    return getEnumValues(values, Dictionaries, lang, excluded);
}
function hasSecurityPermissionsInValue(value, code) {
    return value & code;
}
export function getSecurityPermissionsValuesByBitWise(bitwiseValue, lang) {
    return getSecurityPermissions(lang).filter((value) => hasSecurityPermissionsInValue(value.Code, bitwiseValue));
}
export function getSecurityPermissionsValueByBitWise(bitwiseValue, lang) {
    return find(getSecurityPermissions(lang), (value) => hasSecurityPermissionsInValue(value.Code, bitwiseValue));
}
export function isSecurityPermissionViewEdit(bitwiseValue) {
    return hasSecurityPermissionsInValue(bitwiseValue, SECURITY_PERMISSIONS.VIEW) && hasSecurityPermissionsInValue(bitwiseValue, SECURITY_PERMISSIONS.EDIT);
}
export default SECURITY_PERMISSIONS;

import { getEmptyComponents } from '@/utils/page-layouts';
const routes = [];
const children = [];
const components = getEmptyComponents();
components.default = () => import(/* webpackChunkName: "billing" */ './index.vue');
routes.push({
    name: 'billing',
    path: '/billing',
    components,
    children,
    meta: {},
});
export default routes;

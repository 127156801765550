export default {
    name: 'SButtonSubmit',
    components: {},
    mixins: [],
    props: {
        icon: {
            default: 'send',
            type: String,
        },
        isLoading: {
            default: false,
            type: Boolean,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        btnConfirmText: {
            default: null,
            type: String,
        },
        showIcon: {
            default: true,
            type: Boolean,
        },
    },
    data() {
        return {};
    },
    computed: {
        btnConfirmTextFormatted() {
            return this.btnConfirmText ? this.btnConfirmText : this.$t('confirm');
        },
    },
    methods: {
        submit() {
            this.$emit('click:submit');
        },
    },
    i18n: {
        messages: {
            fr: {},
            en: {},
        },
    },
};

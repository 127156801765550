import { forEach, isEqual } from 'lodash';
import { isArray, isObject } from 'segic_shared_front_end_utils/src/utils/types';
export default {
    props: {
        classes: {
            default() {
                return [];
            },
        },
        name: {
            type: String,
            required: true,
        },
        model: {
            required: true,
        },
        initialModel: {},
        type: {},
        debugName: {
            default: null,
        },
        forceOutlined: {
            default: false,
        },
    },
    data() {
        return {
            inputType: 'text',
            hasChanged: false,
        };
    },
    mounted() {
        if (this.name && this.name !== '') {
            if (this.type) {
                this.inputType = this.type;
            }
            this.watchHandle();
        }
    },
    watch: {
        model: {
            handler() {
                this.watchHandle();
            },
            deep: true,
        },
        initialModel: {
            handler() {
                this.watchHandle();
            },
            deep: true,
        },
    },
    computed: {
        classesOutput() {
            const classesOutput = [];
            if ((this.hasChanged || this.forceOutlined)) {
                classesOutput.push('s-value-changed');
            }
            if (this.name === this.debugName) {
                console.log(`field Debugging: ${this.name}`);
                console.log(`classesOutput: ${classesOutput}`);
            }
            return classesOutput;
        },
    },
    methods: {
        watchHandle() {
            if (this.name === this.debugName) {
                console.log(`field Debugging: ${this.name}`);
                console.log('model:', this.model);
                console.log('initialModel:', this.initialModel);
                if (this.value) {
                    console.log('value:', this.value);
                }
                console.log(`typeof model: ${typeof this.model}`, `typeof initialModel: ${typeof this.initialModel}`);
                console.log('this.model !== this.initialModel', this.model !== this.initialModel);
            }
            let isDifferent = false;
            if (this.model !== undefined && this.initialModel !== undefined) {
                const initialModelValue = (typeof this.initialModel !== 'object') ? this.initialModel.toString() : '';
                if (typeof this.model !== 'object' && this.model.toString() !== initialModelValue) {
                    isDifferent = true;
                }
                else if (isArray(this.model)) {
                    const includeInModel = this.model.includes(this.value);
                    const includeInInitialModel = this.initialModel.includes(this.value);
                    const initialModelLength = (isArray(this.initialModel)) ? this.initialModel.length : 0;
                    if (includeInInitialModel !== includeInModel) {
                        isDifferent = true;
                    }
                    if (this.model.length !== initialModelLength) {
                        isDifferent = true;
                    }
                    else {
                        let valueFindInInitial = true;
                        this.$lodash.each(this.model, (item) => {
                            const initialItem = this.$lodash.find(this.initialModel, [this.valueKey, item[this.valueKey]]);
                            if (!initialItem) {
                                valueFindInInitial = false;
                            }
                        });
                        if (!valueFindInInitial) {
                            isDifferent = true;
                        }
                    }
                }
                else if (isObject(this.model)) {
                    if (this.name === this.debugName) {
                        console.log('isObject', this.valueKey);
                    }
                    // eslint-disable-next-line consistent-return
                    forEach((this.model), (item, key) => {
                        if (this.name === this.debugName && this.initialModel) {
                            console.log(this.initialModel[key]);
                        }
                        if (this.initialModel && typeof this.initialModel[key] !== 'undefined') {
                            isDifferent = !isEqual(this.model[key], this.initialModel[key]);
                        }
                        if (isDifferent) {
                            return false;
                        }
                    });
                }
                else if (this.model === null && this.model !== this.initialModel) {
                    isDifferent = true;
                }
                this.hasChanged = isDifferent;
                if (this.name === this.debugName) {
                    console.log(`this.hasChanged ${this.hasChanged}`);
                }
            }
        },
    },
};

import StoreExtender from '@/store/extender';
import STORE_CONSTANT_NAMES from '@/store/names';
import GroupsCategoriesStore from '@/store/groups/categories';
import ParticipantsCategoriesStore from '@/store/participants/categories';
export default {
    data() {
        return {
            groupCategoriesStore: null,
            participantCategoriesStore: null,
        };
    },
    methods: {
        initializeParticipantCategoryStore() {
            this.groupCategoriesStore = new StoreExtender(this, STORE_CONSTANT_NAMES.GROUPS.CATEGORIES, GroupsCategoriesStore);
            this.participantCategoriesStore = new StoreExtender(this, STORE_CONSTANT_NAMES.PARTICIPANTS.CATEGORIES, ParticipantsCategoriesStore);
        },
        startParticipantCategoryLoadingData() {
            this.groupCategoriesStore.sendActions('get');
            this.participantCategoriesStore.sendActions('get');
        },
    },
    computed: {
        isCategoriesLoading() {
            const participantCategoriesLoading = this.participantCategoriesStore ? this.participantCategoriesStore.getGetter('getIsLoading') : true;
            const groupCategoriesLoading = this.groupCategoriesStore ? this.groupCategoriesStore.getGetter('getIsLoading') : true;
            return participantCategoriesLoading || groupCategoriesLoading;
        },
        groupCategories() {
            return !this.isCategoriesLoading ? this.groupCategoriesStore.getGetter('get') : [];
        },
        participantCategorie() {
            return !this.isCategoriesLoading ? this.participantCategoriesStore.getGetter('get') : [];
        },
    },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",attrs:{"autocomplete":"off","tag":"form"}},[_c('VContainer',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('VDivider'),_c('div',{staticClass:"mt-2 mb-6 full-width d-flex justify-end align-center"},[_c('div',{staticClass:"s-font-16 label_color--text"},[_c('SSwitch',{attrs:{"model":_vm.useSegicCertificateNumbers,"text":_vm.$t('useSegicCertificateNumbers'),"name":"useSegicCertificateNumbers","s-e2e":"useSegicCertificateNumbers"},on:{"update:model":_vm.handleToggleUseSegicCertificateNumbers},scopedSlots:_vm._u([{key:"tooltipActivator",fn:function(){return [_c('VTooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('VIcon',_vm._g({staticClass:"mr-3 mt-1 ml-n1",attrs:{"color":"icon_lightcolor","size":"20"}},on),[_vm._v(" help ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('useSegicCertificateNumbersTooltip')))])])]},proxy:true}])})],1),_c('VDivider',{staticClass:"mx-6",attrs:{"vertical":""}}),_c('SCertificateNumbersMappingBulkDialog',{attrs:{"disableDialogButton":_vm.useSegicCertificateNumbers}})],1),_c('SDatatableBase',{attrs:{"headers":_vm.headers,"hide-default-footer":_vm.items.length <= 100,"items":_vm.items,"items-per-page":100,"items-per-page-options":[100, 200, 300, -1],"classes":"s-dense-inputs-table","min-width":"725px","name-e2-e-suffix":"participants","outlined":""},on:{"event":_vm.dispatchEvent},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('SCheckbox',{staticClass:"s-font-14",attrs:{"label":_vm.$t('displayUnmappedParticipants'),"model":_vm.showOnlyUnmappedParticipants,"name":"showOnlyUnmappedParticipants","s-e2e":"showOnlyUnmappedParticipants"},on:{"update:model":_vm.filterItems}})]},proxy:true},{key:"certificate",fn:function(ref){
var ref_item = ref.item;
var certificate = ref_item.certificate;
var id = ref_item.id;
return [_c('span',{attrs:{"s-e2e":("certificate_" + id)}},[_vm._v(_vm._s(certificate))])]}},{key:"fullName",fn:function(ref){
var ref_item = ref.item;
var fullName = ref_item.fullName;
var id = ref_item.id;
return [_c('span',{attrs:{"s-e2e":("fullName_" + id)}},[_vm._v(" "+_vm._s(fullName))])]}},{key:"division",fn:function(ref){
var ref_item = ref.item;
var division = ref_item.division;
var id = ref_item.id;
return [_c('span',{attrs:{"s-e2e":("division_" + id)}},[_vm._v(_vm._s(division))])]}},{key:"mappingCode",fn:function(ref){
var index = ref.index;
var ref_item_mappingCode = ref.item.mappingCode;
var mappingCode = ref_item_mappingCode.mappingCode;
var id = ref_item_mappingCode.id;
return [_c('SMappingCode',{attrs:{"id":id,"has-warning":(_vm.formData.isFormSaved || _vm.canShowMappingCodeWarning) && !mappingCode,"initial-model":_vm.initialFormData.content.participants ? _vm.initialFormData.content.participants[index].mappingCode : null,"model":mappingCode,"readonly":_vm.useSegicCertificateNumbers,"rules":{
              anyFieldInteger: true,
              anyFieldMax: 9,
              unique: {
                otherValues: _vm.getAllMappingCodesExceptId(id),
              }
            },"s-e2e":("mappingCode_" + id),"name":"mappingCode"},on:{"update:model":_vm.dispatchEvent}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
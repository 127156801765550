import MicroServices from '@/configs/MicroServices.types';
import proxy from '@/store/proxy';
import { getQueryString, replaceStringRoutingData } from 'segic_shared_front_end_utils/src/utils/routing';
import messages from './locales';
export default {
    namespace: 'global.user',
    uri: 'usercontext/',
    get(query) {
        const methodName = 'get';
        const service = MicroServices.SECURITY_MS;
        const params = {};
        const url = this.uri;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
        };
        return proxy.get(replacedUrl, requestData);
    },
    updateLanguage(data, query) {
        const methodName = 'updateLanguage';
        const service = MicroServices.SECURITY_MS;
        const params = {
            securityPrincipalsGuid: data.securityPrincipalsGuid,
        };
        const url = '/securityprincipals/:{securityPrincipalsGuid}/language';
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
            data,
        };
        return proxy.put(replacedUrl, data.languageCode, requestData);
    },
};

import FieldsMixin from '@/mixins/components/fields';
import SegicCommons from '@/mixins/segic-commons';
import { setQueryParamsObject } from '@/utils/helpers';
import ChangeHighLight from '@/mixins/components/highlight';
import SLabelForm from '@/components/ui/label-form.vue';
import { get } from 'lodash';
export default {
    name: 'SFieldCombobox',
    components: { SLabelForm },
    mixins: [
        FieldsMixin,
        SegicCommons,
        ChangeHighLight,
    ],
    props: {
        // Add color to input in grey background
        backgroundColor: {
            type: String,
            default: 'transparent',
        },
        /**
         * Hint shown under combobox.
         */
        hint: {
            default: null,
            type: String,
        },
        /**
         * Set property of items's text value.
         */
        itemText: {
            default: 'text',
            type: String,
        },
        /**
         * Set property of items's value - must be primitive.
         */
        itemValue: {
            default: 'value',
            type: String,
        },
        /**
         * Items shown in combobox.
         */
        items: {
            required: true,
            type: Array,
        },
        maxLength: {
            default: null,
            type: Number,
        },
        /**
         * Multiple Selection
         */
        multiple: {
            default: false,
            type: Boolean,
        },
        noDataText: {
            default: '',
            type: String,
        },
        /**
         * Adds border to give it vuetify outlined style
         */
        outlined: {
            default: false,
            type: Boolean,
        },
        /**
         * Hint is always visible.
         */
        persistentHint: {
            default: false,
            type: Boolean,
        },
        /**
         * Placeholder shown in textfield.
         */
        placeholder: {
            default: '',
            type: String,
        },
        /**
         * Icon displayed in front of text
         */
        prependIcon: {
            default: null,
            type: String,
        },
        // must be included in props
        value: {
            default: null,
            type: [String, Object],
        },
    },
    data() {
        return {
            search: null,
        };
    },
    computed: {
        classComputer() {
            let classes = this.classField;
            if (this.backgroundColor) {
                classes += 'inputPadding';
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.classesOutput.push(classes);
            return this.classesOutput;
        },
    },
    watch: {
        search(val) {
            this.$emit('searchInputChanged', val);
        },
    },
    methods: {
        change(value) {
            if (this.addQuery) {
                setQueryParamsObject(this.name, value);
            }
            this.$refs.combobox.isMenuActive = false;
        },
        getData(data, key) {
            return get(data, key);
        },
    },
};

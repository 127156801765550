import { getEmptyComponents } from '@/utils/page-layouts';
import administratorRoutes from '@/page-layouts/administrator/route-names';
import Participant from './participants/router';
import Requests from './change-requests/router';
import Invoices from './invoices/router';
import PayrollFiles from './payroll-files/router';
import Simulation from './premiums-simulation/router';
import Onboarding from './onboarding/router';
const routes = [];
let children = [];
children = children.concat(Participant);
children = children.concat(Requests);
children = children.concat(Invoices);
children = children.concat(PayrollFiles);
/*
children = children.concat(Documents);
*/
children = children.concat(Simulation);
children = children.concat(Onboarding);
const components = getEmptyComponents();
components.default = () => import(/* webpackChunkName: "administrator" */ './index.vue');
routes.push({
    name: administratorRoutes.INDEX,
    path: '/clients/:clientCode/groups/:groupCode/',
    redirect: {
        name: 'participants.list',
    },
    components,
    children,
});
export default routes;

import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
import employerDFR from '@/assets/images/employeurd-logo-fr.png';
import employerDEN from '@/assets/images/employeurd-logo-en.png';
export default {
    name: 'SPayrollFilesConversionFeatureGuide',
    computed: {
        logoPath() {
            const logos = {
                fr: employerDFR,
                en: employerDEN,
            };
            return logos[this.$i18n.locale];
        },
    },
    methods: {
        handleCloseMenu() {
            this.$emit('closeMenu');
        },
        handleOpenDialog() {
            this.$emit('openDialog');
        },
    },
    i18n,
};

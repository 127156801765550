import SFieldSelect from '@/components/form/fields/select.vue';
import StoreExtender from '@/store/extender';
import STORE_CONSTANT_NAMES from '@/store/names';
import GroupsDivisionsStore from '@/store/groups/divisions';
import ComponentMixins from '@/mixins/segic-component';
import { find } from 'lodash';
import { isNotNullOrUndefined } from '@/utils/helpers';
import SBtnHistoryDialog from '@/components/display/btn-history-dialog.vue';
import { getMessageContentTypeValuePerCode, MESSAGE_CONTENT_TYPE_CONSTANT } from 'segic_shared_front_end_utils/src/enums/message-content-type';
export default {
    name: 'SDivisionsField',
    components: {
        SFieldSelect,
        SBtnHistoryDialog,
    },
    mixins: [ComponentMixins],
    props: {
        attach: {
            default: false,
            type: Boolean,
        },
        divisionsToExclude: {
            default: () => [],
            type: Array,
        },
        itemText: {
            default: 'StatusText',
            type: String,
        },
        label: {
            default: null,
            type: String,
        },
        model: {
            default: null,
            type: String,
        },
        name: {
            default: null,
            type: String,
        },
        rules: {
            default: '',
            type: [Object, String],
        },
    },
    data() {
        return {
            MESSAGE_CONTENT_TYPE_CONSTANT,
            divisions: [],
            divisionsIsLoading: false,
            store: null,
            divisionHistoryItems: [],
            divisionHistoryIsLoading: false,
        };
    },
    mounted() {
        this.getDivisions();
    },
    methods: {
        emitUpdateRecipients(value) {
            this.$emit('update:recipients', this.getRecipients(value).Recipients);
        },
        async getDivisionHistory(isShowed) {
            if (isShowed) {
                this.divisionHistoryIsLoading = true;
                const extraParams = {
                    divisionGuid: this.model,
                };
                const store = new StoreExtender(this, STORE_CONSTANT_NAMES.GROUPS.DIVISIONS_HISTORY, GroupsDivisionsStore, { extraParams });
                const payload = {
                    params: {
                        divisionGuid: this.model,
                    },
                };
                await store.sendActions('getHistory', payload);
                this.divisionHistoryItems = store.getGetter('getHistory', this.$i18n.locale);
                this.divisionHistoryIsLoading = false;
            }
        },
        async getDivisions() {
            this.divisions = [];
            this.divisionsIsLoading = true;
            this.store = new StoreExtender(this, STORE_CONSTANT_NAMES.GROUPS.DIVISIONS, GroupsDivisionsStore);
            await this.store.sendActions('get');
            this.divisions = this.setDivisions(this.store.getGetter('get'), this.divisionsToExclude);
            this.divisionsIsLoading = false;
            if (isNotNullOrUndefined(this.model)) {
                this.emitUpdateRecipients(this.model);
            }
        },
        getMessageContentTypeValuePerCode,
        getRecipients(DivisionId) {
            return find(this.divisions, { DivisionId });
        },
        historyHeaders() {
            return [
                {
                    text: this.$t('status'),
                    value: 'Status',
                },
                {
                    text: this.$t('effectiveDate'),
                    value: 'EffectiveDate',
                },
            ];
        },
        setDivisions(divisions, divisionsToExclude) {
            if (divisionsToExclude.length === 0) {
                return divisions;
            }
            return divisions.filter((division) => divisionsToExclude.indexOf(division.DivisionId) === -1);
        },
        update(value, name) {
            this.$emit('update:model', value, name);
            this.emitUpdateRecipients(value);
        },
    },
};

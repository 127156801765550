import { cloneDeep, find, findIndex, first, last, map, orderBy, property, reverse, } from 'lodash';
import moment from 'moment';
import { isNullOrUndefined } from './types';
export function getSortedItems(items, key, direction = 'desc') {
    return orderBy(cloneDeep(items), [key], [direction]);
}
export function getSortedItemsWithoutKey(items, direction = 'desc') {
    if (direction === 'desc') {
        return items.sort().reverse();
    }
    return items.sort();
}
export function getEvents(items, key) {
    return reverse(map(getSortedItems(items, key), property(key)));
}
export function getSortedEventEffectiveDate(items) {
    return items.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
}
export function getActiveItem(items, key, requestDate, exact, fallbackToNearestFuture) {
    if (isNullOrUndefined(items) || items.length === 0) {
        console.warn('are your sure you pass the good items');
        return null;
    }
    const sortedItems = (key) ? getSortedItems(items, key) : getSortedItemsWithoutKey(items);
    if (!requestDate) {
        return first(sortedItems);
    }
    const item = find(sortedItems, (item) => {
        if (key) {
            if (exact) {
                return item[key] === requestDate;
            }
            return item[key] <= requestDate;
        }
        if (exact) {
            return item === requestDate;
        }
        return item <= requestDate;
    });
    if (fallbackToNearestFuture && !item) {
        const inverseItems = sortedItems.reverse();
        const fallbackItem = find(inverseItems, (item) => moment(requestDate).isSameOrBefore(item[key]));
        return fallbackItem;
    }
    return item;
}
export function getFutureItem(items, key, requestDate) {
    if (isNullOrUndefined(items) || items.length === 0) {
        return false;
    }
    const sortedItems = (key) ? getSortedItems(items, key, 'asc') : getSortedItemsWithoutKey(items, 'asc');
    if (!requestDate) {
        return last(sortedItems);
    }
    return find(sortedItems, (item) => {
        if (key) {
            return item[key] >= requestDate;
        }
        return item >= requestDate;
    });
}
export function getActiveItemIndex(items, key, requestDate, exact) {
    if (!requestDate) {
        return -1;
    }
    const sortedItems = getSortedItems(items, key);
    return findIndex(sortedItems, (item) => {
        if (exact) {
            return item[key] === requestDate;
        }
        return item[key] <= requestDate;
    });
}

// eslint-disable-next-line no-shadow
export var PAYROLL_FILE_CONVERSION_STEPPER_TYPE;
(function (PAYROLL_FILE_CONVERSION_STEPPER_TYPE) {
    PAYROLL_FILE_CONVERSION_STEPPER_TYPE[PAYROLL_FILE_CONVERSION_STEPPER_TYPE["RECIPIENTS_MAPPING"] = 0] = "RECIPIENTS_MAPPING";
    PAYROLL_FILE_CONVERSION_STEPPER_TYPE[PAYROLL_FILE_CONVERSION_STEPPER_TYPE["GROUP_BENEFITS_MAPPING"] = 1] = "GROUP_BENEFITS_MAPPING";
    PAYROLL_FILE_CONVERSION_STEPPER_TYPE[PAYROLL_FILE_CONVERSION_STEPPER_TYPE["TAXABLE_BENEFITS_MAPPING"] = 2] = "TAXABLE_BENEFITS_MAPPING";
    PAYROLL_FILE_CONVERSION_STEPPER_TYPE[PAYROLL_FILE_CONVERSION_STEPPER_TYPE["CERTIFICATES_MAPPING"] = 3] = "CERTIFICATES_MAPPING";
})(PAYROLL_FILE_CONVERSION_STEPPER_TYPE || (PAYROLL_FILE_CONVERSION_STEPPER_TYPE = {}));
// eslint-disable-next-line no-shadow
export var PAYROLL_INTEGRATION_TYPES;
(function (PAYROLL_INTEGRATION_TYPES) {
    PAYROLL_INTEGRATION_TYPES[PAYROLL_INTEGRATION_TYPES["NONE"] = 1] = "NONE";
    PAYROLL_INTEGRATION_TYPES[PAYROLL_INTEGRATION_TYPES["NETHRIS"] = 2] = "NETHRIS";
    PAYROLL_INTEGRATION_TYPES[PAYROLL_INTEGRATION_TYPES["EMPLOYER_D"] = 3] = "EMPLOYER_D";
})(PAYROLL_INTEGRATION_TYPES || (PAYROLL_INTEGRATION_TYPES = {}));

import MicroServices from '@/configs/MicroServices.types';
import proxy from '@/store/proxy';
import { getQueryString, replaceStringRoutingData, } from 'segic_shared_front_end_utils/src/utils/routing';
import messages from './locales';
export default {
    namespace: 'participants.single',
    uri: '/client/:{clientGuid}/group/:{groupGuid}/participants/:{participantGuid}/',
    get(clientGuid, groupGuid, participantGuid, data, query) {
        const methodName = 'get';
        const service = MicroServices.PARTICIPANT;
        const params = {
            clientGuid,
            groupGuid,
            participantGuid,
        };
        const url = `${this.uri}?fields=GroupId,CertificateNumber,Language,HireDate,Person(FirstName,LastName,BirthDate,Gender,SmokerStatus,HasIndianStatus),Addresses,CivilStatus,CohabitationDate,CivilStatusId, Spouse`;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
        };
        return proxy.get(replacedUrl, requestData);
    },
    getProfile(clientGuid, groupGuid, participantGuid, data, query) {
        const methodName = 'getProfile';
        const service = MicroServices.PARTICIPANT;
        const params = {
            clientGuid,
            groupGuid,
            participantGuid,
        };
        const url = `${this.uri}profile/`;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
        };
        return proxy.get(replacedUrl, requestData);
    },
};

import { find } from 'lodash';
import SETTINGS from '../constants/settings';
import { getEnumValues, toValues } from '../enums/_utils';
import INSURANCE_STATUS, { INSURANCE_STATUS_CONSTANT } from '../enums/insurance-status';
export var SystemProtections;
(function (SystemProtections) {
    SystemProtections[SystemProtections["SINGLE"] = 1] = "SINGLE";
    SystemProtections[SystemProtections["COUPLE"] = 2] = "COUPLE";
    SystemProtections[SystemProtections["SINGLE_PARENT"] = 4] = "SINGLE_PARENT";
    SystemProtections[SystemProtections["FAMILY"] = 8] = "FAMILY";
})(SystemProtections || (SystemProtections = {}));
;
const CONSTANT = {
    SINGLE: 1 << 0,
    COUPLE: 1 << 1,
    SINGLE_PARENT: 1 << 2,
    FAMILY: 1 << 3,
};
const values = toValues(CONSTANT);
const FR = {
    [CONSTANT.SINGLE]: 'Individuelle',
    [CONSTANT.COUPLE]: 'Couple',
    [CONSTANT.SINGLE_PARENT]: 'Monoparentale',
    [CONSTANT.FAMILY]: 'Familiale',
};
const EN = {
    [CONSTANT.SINGLE]: 'Single',
    [CONSTANT.COUPLE]: 'Couple',
    [CONSTANT.SINGLE_PARENT]: 'Single-Parent',
    [CONSTANT.FAMILY]: 'Family',
};
export const SYSTEM_PROTECTIONS_IDS = {
    [CONSTANT.SINGLE]: 'f88171fb-027a-4a75-b38c-e7ab8ee553dc',
    [CONSTANT.COUPLE]: '795b664e-d3c9-435d-8035-314d364487ed',
    [CONSTANT.SINGLE_PARENT]: '088f9efb-dbf6-4f6e-8ba2-884119c91028',
    [CONSTANT.FAMILY]: '2fe0a9cb-11cd-436e-887f-2a15826fc70b',
};
const Dictionaries = {
    fr: FR,
    en: EN,
};
function EXEMPTED(lang = SETTINGS.LANGUAGE) {
    return {
        Value: INSURANCE_STATUS.getValuePerCode(INSURANCE_STATUS_CONSTANT.EXEMPTED, lang).Value,
        Id: INSURANCE_STATUS_CONSTANT.EXEMPTED,
    };
}
;
function addProtectionId(protection) {
    protection.Id = SYSTEM_PROTECTIONS_IDS[protection.Code];
    return protection;
}
export function systemProtectionsGetValues(lang) {
    const systemProtectionBitWise = getEnumValues(values, Dictionaries, lang);
    const items = [];
    systemProtectionBitWise.forEach((item) => {
        items.push(addProtectionId(item));
    });
    return items;
}
function isInValue(value, code) {
    return value & code;
}
function getValuesByBitWise(bitwiseValue, lang, addExepted = false) {
    const protections = systemProtectionsGetValues(lang).filter((value) => isInValue(value.Code, bitwiseValue));
    return addExepted
        ? [...protections, SYSTEM_PROTECTIONS.EXEMPTED(lang)]
        : protections;
}
function getValueByBitWise(bitwiseValue, lang) {
    return find(systemProtectionsGetValues(lang), (value) => isInValue(value.Code, bitwiseValue));
}
function getValueById(id, lang) {
    return find(systemProtectionsGetValues(lang), ['Id', id]);
}
export function hasSystemProtectionsCouple(protections) {
    return protections.map((item) => item.Id).find((protectionId) => protectionId === SYSTEM_PROTECTIONS_IDS[CONSTANT.COUPLE]);
}
export function hasSystemProtectionsSingleParent(protections) {
    return protections.map((item) => item.Id).find((protectionId) => protectionId === SYSTEM_PROTECTIONS_IDS[CONSTANT.SINGLE_PARENT]);
}
const SYSTEM_PROTECTIONS = {
    CONSTANT,
    getValues: systemProtectionsGetValues,
    getValueById,
    isInValue,
    getValuesByBitWise,
    getValueByBitWise,
    EXEMPTED,
};
export default SYSTEM_PROTECTIONS;

// CONFIRM CANCEL
import SDialogConfirmCancel from '@/page-layouts/administrator/dialogs/confirm-cancel.vue';
import DialogConfirmCancelMixin from '@/page-layouts/administrator/dialogs/confirm-cancel';
import AddParticipantForm from '@/page-layouts/administrator/forms/add-participant/byFile/add-participant-form.vue';
import SegicLayoutComponent from '@/mixins/segic-layout-component';
import SComponentDialogComponent from '@/components/dialogs/component.vue';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantByFile',
    components: {
        SDialogConfirmCancel,
        SComponentDialogComponent,
    },
    mixins: [
        DialogConfirmCancelMixin,
        SegicLayoutComponent,
    ],
    props: {
        showDialog: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {};
    },
    computed: {
        addParticipantComponent() {
            return {
                component: AddParticipantForm,
            };
        },
    },
};

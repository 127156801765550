import { getI18nWithSameValue } from 'segic_shared_front_end_utils/src/utils/i18n';
export default {
    input(data) {
        const newData = {
            occupationId: data.Id,
            description: getI18nWithSameValue(data.Code),
            notListedValue: null,
        };
        return newData;
    },
    display(data) {
        return data;
    },
};

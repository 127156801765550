import SInfo from '@/components/ui/info.vue';
import storeMixin from '@/mixins/page-layout/store';
import SecurityCodes from '@/mixins/security/codes/employer-portal';
import LayoutComponentMixins from '@/mixins/segic-layout-component';
import MESSAGE_TYPE from 'segic_shared_front_end_utils/src/constants/message-types';
import EVENTS from 'segic_shared_front_end_utils/src/constants/events';
import { EventBus } from '@/utils/event-bus';
import StoreExtender from '@/store/extender';
import STORE_CONSTANT_NAMES from '@/store/names';
import moment from 'moment';
import { downloadFile } from 'segic_shared_front_end_utils/src/utils/download-file';
import ParticipantsStatusStore from '@/store/participants/status';
import ReportR001Store from '@/store/reports/R001';
import ParticipantsSingleStore from '@/store/participants/single';
import ParticipantDivisionMixin from './mixins/division';
import ParticipantCategoryMixin from './mixins/category';
export default {
    name: 'SAdministratorParticipantsHeader',
    components: {
        SInfo,
    },
    mixins: [
        storeMixin,
        LayoutComponentMixins,
        ParticipantDivisionMixin,
        ParticipantCategoryMixin,
    ],
    data() {
        return {
            isReportLoading: false,
            participantStore: null,
            participantStatusStore: null,
            participantReportsStore: null,
            tab: null,
        };
    },
    computed: {
        canViewSimulationButton() {
            return this.userPermissions.filter((el) => el.groupId === this.$route.params.groupGuid).some((el) => el.resourceId === SecurityCodes.EmployerRestrictedParticipantAuthorizeResource);
        },
        isParticipantLoading() {
            return this.participantStore ? this.participantStore.getGetter('getIsLoading') : true;
        },
        participant() {
            return !this.isParticipantLoading ? this.participantStore.getGetter('get') : {};
        },
        person() {
            return !this.isParticipantLoading ? this.participant.Person : {};
        },
        currentGroup() {
            return this.userStore.getGetter('group', this.$route.params);
        },
        isStatusLoading() {
            return this.participantStatusStore ? this.participantStatusStore.getGetter('getIsLoading') : true;
        },
        participantStatus() {
            let status = '';
            if (!this.isStatusLoading) {
                status = this.participantStatusStore.getGetter('get').ComponentStatus;
                status.props.xSmall = true;
            }
            return status;
        },
        divisionDescription() {
            let description = '';
            if (this.groupDivision) {
                description = this.description(this.groupDivision.Code, this.groupDivision.Text[this.$i18n.locale]);
            }
            return description;
        },
        categoryDescription() {
            let description = '';
            if (!this.isCategoriesLoading) {
                const groupCategory = this.groupCategoriesStore.getGetter('getById', this.participantCategorie.CategoryId);
                description = this.description(groupCategory.Code, groupCategory.Name[this.$i18n.locale]);
            }
            return description;
        },
    },
    methods: {
        initializeStore() {
            this.initializeParticipantDivisionStore();
            this.initializeParticipantCategoryStore();
            this.participantStore = new StoreExtender(this, STORE_CONSTANT_NAMES.PARTICIPANTS.SINGLE, ParticipantsSingleStore);
            this.participantStatusStore = new StoreExtender(this, STORE_CONSTANT_NAMES.PARTICIPANTS.STATUS, ParticipantsStatusStore);
            this.participantReportsStore = new StoreExtender(this, STORE_CONSTANT_NAMES.REPORTS.R001, ReportR001Store, { isHandlingErrors: true });
        },
        async startLoadingData() {
            this.startParticipantDivisionLoadingData();
            this.startParticipantCategoryLoadingData();
            this.participantStore.sendActions('get');
            this.participantStatusStore.sendActions('get', {
                query: { participantId: this.$route.params.participantGuid },
            });
        },
        back() {
            this.$router.push({ name: 'participants.list' });
        },
        changeTab(tab) {
            EventBus.$emit('participantTab', tab);
        },
        description(text1, text2) {
            return `<div class="s-font-12 font-weight-regular">${text1}</div><div class="s-font-15 font-weight-regular text-truncate">${text2}</div>`;
        },
        downloadParticipantSummary() {
            this.isReportLoading = true;
            const payload = {
                ClientId: this.$route.params.clientGuid,
                GroupId: this.participant.GroupId,
                ParticipantId: this.$route.params.participantGuid,
                CultureName: this.participant.Language,
                simulationDate: moment().add(6, 'M').format('YYYY-MM-DD'),
            };
            this.participantReportsStore.sendActions('get', payload).then((response) => {
                const fileName = this.$t('fileName', { certificateNumber: this.participant.CertificateNumber });
                downloadFile(fileName, 'pdf', response.data);
                this.isReportLoading = false;
            }).catch(() => {
                const error = {
                    message: this.$t('api.generic.error'),
                    type: MESSAGE_TYPE.ERROR,
                };
                EventBus.$emit(EVENTS.SNACKBAR, error);
                this.isReportLoading = false;
            });
        },
        goToSimulation() {
            this.$router.push({
                name: 'premiums-simulation',
                query: {
                    participantGuid: this.$route.params.participantGuid,
                    certificateNumber: this.participant.CertificateNumber,
                },
            });
        },
    },
    i18n: {
        messages: {
            fr: {
                tooltipBack: 'Retour à la liste des participants',
                goToSimulation: 'Simulation des primes',
                downloadParticipantSummary: 'Sommaire du participant',
                downloadParticipantSummaryTooltip: 'Télécharger un sommaire du profil, des garanties et des primes du participant',
                goToSimulationTooltip: 'Voir et simuler les primes du participant',
                downloadParticipantSummaryTooltipFootnote: 'Note: Ne contient pas les données des demandes de changement en attente de traitement.',
                fileName: 'Sommaire Participant - {certificateNumber}',
            },
            en: {
                tooltipBack: 'Back to plan members list',
                goToSimulation: 'Premium simulation',
                downloadParticipantSummary: 'Plan member\'s summary',
                goToSimulationTooltip: 'View and simulate this plan member\'s premiums',
                downloadParticipantSummaryTooltip: 'Download a summary of the plan member\'s profile, benefits and premiums',
                downloadParticipantSummaryTooltipFootnote: 'Note: Does not contain data from change requests awaiting processing.',
                fileName: 'Plan Member Summary - {certificateNumber}',
            },
        },
    },
};

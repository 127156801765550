import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import en from 'vuetify/src/locale/en';
import fr from 'vuetify/src/locale/fr';
Vue.use(Vuetify);
export default new Vuetify({
    lang: {
        locales: { en, fr },
        current: 'fr',
    },
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        themes: {
            light: {
                background: '#f7fafb',
                primary: '#4165F6',
                primary_light: '#66B6F2',
                primary_lighter: '#BADEF9',
                primary_lightest: '#E2F2FC',
                primary_dark: '#288AE0',
                primary_darker: '#2378CD',
                primary_darkest: '#174B9C',
                secondary: '#637D8A',
                secondary_light: '#92A4AE',
                secondary_lighter: '#B2BEC5',
                secondary_lightest: '#ECEFF1',
                secondary_dark: '#576D79',
                secondary_darker: '#485A63',
                secondary_darkest: '#283238',
                secondary_accent: '#00897B',
                secondary_note: '#92A4AE',
                title_color: '#212121',
                subtitle_color: '#637D8A',
                label_color: '#666666',
                caption_color: '#757575',
                text_color: '#212121',
                segic_orange: '#eeaa22',
                segic_darkorange: '#d49519',
                purple_accent: '#6200ea',
                green_accent: '#00c853',
                body_color: '#EDEDEE',
                nav_color: '#283238',
                navprod_color: '#8a2e2e',
                background_color: '#e0e0e0',
                background_lightcolor: '#f2f2f2',
                background_lighesttcolor: '#f7f7f7',
                background_darkcolor: '#c2c2c2',
                border_color: '#e0e0e0',
                border_lightcolor: '#f0f0f0',
                border_darkcolor: '#9e9e9e',
                icon_color: '#757575',
                icon_lightcolor: '#adadad',
                icon_darkcolor: '#191919',
                error: '#FF5252',
                error_border: '#ffa1a1',
                error_background: '#ffebeb',
                success: '#4CAF50',
                success_background: '#e9f5ea',
                warning: '#FB8C00',
                info: '#47A6F1',
                disabled: '#9e9e9e',
                red: '#DC5854',
                red_light: '#E57373',
                red_lighter: '#FFCDD2',
                red_lightest: '#FFEBEE',
                red_dark: '#E53935',
                red_darker: '#D32F2F',
                red_darkest: '#B71C1C',
                green: '#77AC56',
                green_light: '#63c564',
                green_lighter: '#A5D6A7',
                green_lightest: '#E8F5E9',
                green_dark: '#388E3C',
                green_darker: '#2E7D32',
                green_darkest: '#1B5E20',
                highlight_border: '#9ed4a0',
                hover: '#c9c9c9',
                viebase: '#44B7AE',
                viefac: '#FE646D',
                sante: '#63C1DC',
                dentaire: '#F7C63E',
                malred: '#D54852',
                grey: '#9E9E9E',
                grey_light: '#BDBDBD',
                grey_lighter: '#E0E0E0',
                grey_lightest: '#F5F5F5',
                grey_dark: '#757575',
                grey_darker: '#616161',
                grey_darkest: '#424242',
                accent: '#eeaa22',
                accent_light: '#F9C51C',
                accent_lighter: '#FAD846',
                accent_lightest: '#FCE581',
                accent_dark: '#F7AC1C',
                accent_darker: '#F6901C',
                accent_darkest: '#F5701C',
                blue: '#4165F6',
                blue_darkest: '#2A2E34',
            },
        },
    },
});

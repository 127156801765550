var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"autocomplete":"off","tag":"form"}},[_c('VContainer',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('SDatatableBase',{attrs:{"disable-sort":true,"headers":_vm.headers,"hide-default-footer":_vm.items.length <= 100,"hide-top":true,"items":_vm.items,"items-per-page":100,"items-per-page-options":[100, 200, 300, -1],"classes":"s-dense-inputs-table","min-width":"725px","name-e2-e-suffix":"taxableBenefit","outlined":""},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var ref_item = ref.item;
var id = ref_item.id;
var description = ref_item.name.description;
return [_c('span',{attrs:{"s-e2e":("benefitStatusName_" + id)}},[_vm._v(" "+_vm._s(description))])]}},_vm._l((_vm.mappingCodeHeaders),function(header){return {key:header.value,fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('SMappingCode',{key:("mappingCode_" + (header.value)),attrs:{"id":item.id,"model":_vm.getMappingValueByCode(item.mappings, header.integrationType, header.recipientMappingCode),"initial-model":_vm.initialFormData.content.taxableBenefits ? _vm.getMappingValueByCode(_vm.initialFormData.content.taxableBenefits[index].mappings, header.integrationType, header.recipientMappingCode) : null,"s-e2e":("mappingCode_" + (header.name.replace(/\s/g,'')) + "_" + (item.id)),"has-warning":_vm.canShowMappingCodeWarning && !_vm.getMappingValueByCode(item.mappings, header.integrationType, header.recipientMappingCode),"name":"mappingCode","rules":"required|anyFieldInteger|anyFieldMax:3|anyFieldMin:3"},on:{"update:model":function($event){return _vm.handleUpdateMappings($event, item.id, header.integrationType, header.recipientMappingCode)}}})]}}})],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
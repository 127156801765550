import { date } from '@/utils/data-formatting/service-input/dates';
export default {
    input(data) {
        return {
            ...data,
            EffectiveDate: date(data.EffectiveDate),
        };
    },
    display(data) {
        return data;
    },
};

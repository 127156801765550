import SNoteFiles from '@/components/form/note-files.vue';
import SFieldText from '@/components/form/fields/text.vue';
import MESSAGE_CONTENT_TYPE from 'segic_shared_front_end_utils/src/enums/message-content-type';
import FileMixin from '@/mixins/file';
import FormMixin from '@/mixins/form';
import storeMixin from '@/mixins/page-layout/store';
import SegicLayoutComponent from '@/mixins/segic-layout-component';
import COMMUNICATION_TYPES from 'segic_shared_front_end_utils/src/constants/communication-types';
import SYSTEM_TYPES from 'segic_shared_front_end_utils/src/constants/system-types';
import STORE_CONSTANT_NAMES from '@/store/names';
import ChangeRequestsSingleStore from '@/store/change-requests/single';
export default {
    components: {
        SFieldText,
        SNoteFiles,
    },
    mixins: [
        SegicLayoutComponent,
        FormMixin,
        storeMixin,
        FileMixin,
    ],
    data() {
        return {
            storeConstant: {
                name: STORE_CONSTANT_NAMES.CHANGE_REQUESTS.SINGLE,
                template: ChangeRequestsSingleStore,
            },
        };
    },
    computed: {
        rulesFiles() {
            let rule = '';
            if (!this.overwriteData || (this.formData.displayAttachments.length === 0 && this.formData.attachments.length === 0)) {
                rule = 'required';
            }
            return rule;
        },
    },
    methods: {
        resetFormData() {
            const defaultData = {
                clientId: this.$route.params.clientGuid,
                groupId: this.$route.params.groupGuid,
                subject: this.$t('addNewPlanMember'),
                communicationType: COMMUNICATION_TYPES.CHANGE_REQUEST,
                content: {
                    note: '',
                    firstName: '',
                    lastName: '',
                },
                contentType: MESSAGE_CONTENT_TYPE.CONSTANT.PARTICIPANT_CREATION_REQUEST,
                attachments: [],
                displayAttachments: [],
                deleteAttachmentIds: [],
                system: SYSTEM_TYPES.EMPLOYER_PORTAL,
            };
            this.defaultData = defaultData;
        },
        setSubmitParams() {
            const param = {};
            if (this.overwriteData) {
                param.requestGuid = this.formData.id;
            }
            return param;
        },
        formChanged() {
            if (this.id) {
                this.formSendDataHasChanged = this.formHasChangedValues.content.hasChanged;
            }
            return this.formHasChangedValues.hasChanged;
        },
    },
    i18n: {
        messages: {
            fr: {
                labels: {
                    file: 'Attacher la fiche d\'adhésion du participant',
                    document: 'Attacher le formulaire d\'adhésion',
                },
            },
            en: {
                labels: {
                    file: 'Attach participant\'s enrollment form',
                    document: 'Attach enrollment form',
                },
            },
        },
    },
};

import ChangeRequestsAttachmentsSingleStore from '@/store/change-requests-attachments/single';
import StoreExtender from '@/store/extender';
import STORE_CONSTANT_NAMES from '@/store/names';
import { downloadFile } from 'segic_shared_front_end_utils/src/utils/download-file';
export default {
    data() {
        return {
            downloadStore: null,
            filesDownloading: [],
        };
    },
    methods: {
        downloadFile(attachment) {
            this.filesDownloading.push(attachment.id);
            this.downloadStore = new StoreExtender(this, STORE_CONSTANT_NAMES.ATTACHMENTS.SINGLE, ChangeRequestsAttachmentsSingleStore);
            this.downloadStore.sendActions('download', {
                requestGuid: attachment.messageId,
                attachmentGuid: attachment.id,
            }).then((response) => {
                const extension = attachment.fileName.split('.').pop().toLowerCase();
                const fileNameWithoutExt = attachment.fileName.split('.').slice(0, -1).join('.');
                downloadFile(fileNameWithoutExt, extension, response.data);
                const index = this.filesDownloading.indexOf(attachment.id);
                if (index > -1) {
                    this.filesDownloading.splice(index, 1);
                }
            });
        },
    },
};

import ComponentMixins from '@/mixins/segic-component';
export default {
    name: 'SComponentDialogConfirm',
    mixins: [ComponentMixins],
    props: {
        title: {},
        btnColor: {
            default: 'primary',
        },
        cancelText: {},
        confirmText: {},
        showDialog: {
            required: true,
            default: false,
        },
        width: {
            default: '475px',
        },
        confirmBtnIsloading: {
            default: false,
            type: Boolean,
        },
    },
    computed: {
        confirmTextFormatted() {
            return this.confirmText ? this.confirmText : this.$t('confirm');
        },
        cancelTextFormatted() {
            return this.cancelText ? this.cancelText : this.$t('cancel');
        },
    },
    methods: {
        confirm(value) {
            if (value) {
                this.$emit('handle:confirm');
            }
            else {
                this.$emit('handle:cancel');
            }
        },
    },
};

import ITEM_CHANGED from '@/components/form/fields/select.codes';
import SLabelForm from '@/components/ui/label-form.vue';
import FieldsMixin from '@/mixins/components/fields';
import ChangeHighLight from '@/mixins/components/highlight';
import SegicCommons from '@/mixins/segic-commons';
import { setQueryParamsObject } from '@/utils/helpers';
import { cloneDeep, find, findIndex, get, isEqual, } from 'lodash';
import { isNotNullOrUndefined, isUndefined } from 'segic_shared_front_end_utils/src/utils/types';
import slotItemDefault from './select-item-default.vue';
import slotSelectionDefault from './select-selection-default.vue';
/**
   * @displayName Select
   * */
export default {
    name: 'SFieldSelect',
    components: { SLabelForm },
    mixins: [
        ChangeHighLight,
        FieldsMixin,
        SegicCommons,
    ],
    props: {
        appendOuterIconData: {
            default: null,
            type: Object,
        },
        attach: {
            default: false,
            type: Boolean,
        },
        autofillSingleValues: {
            default: false,
            type: Boolean,
        },
        backgroundColor: {
            type: String,
            default: 'transparent',
        },
        cacheItems: {
            default: false,
            type: Boolean,
        },
        clearable: {
            default: false,
            type: Boolean,
        },
        closeOnClickMenuProps: {
            default: false,
            type: Boolean,
        },
        closeOnContentClickMenuProps: {
            default: false,
            type: Boolean,
        },
        contentClass: {
            type: String,
            default: '',
        },
        dense: {
            default: false,
            type: Boolean,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        e2e: {
            default: null,
            type: String,
        },
        emptyValue: {
            default: null,
            type: [String, Object, Number, Boolean, Array],
        },
        filter: {
            default: (item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1,
            require: false,
            type: Function,
        },
        focusNextInput: {
            default: true,
            type: Boolean,
        },
        hasIcon: {
            default: true,
            type: Boolean,
        },
        hideDetails: {
            default: false,
            type: [
                Boolean,
                String,
            ],
        },
        hint: {
            default: undefined,
            required: false,
            type: String,
        },
        itemText: {
            default: 'text',
            type: String,
        },
        itemValue: {
            default: 'value',
            type: String,
        },
        items: {
            required: true,
            type: Array,
            default: () => [],
        },
        keyToUpdate: {},
        label: {
            type: String,
            default: '',
        },
        maxHeight: {
            default: 300,
            type: Number,
        },
        multiple: {
            default: false,
            type: Boolean,
        },
        object: {
            default: false,
            type: Boolean,
        },
        openOnClickMenuProps: {
            default: false,
            type: Boolean,
        },
        outlined: {
            default: false,
            type: Boolean,
        },
        persistentHint: {
            default: false,
            type: Boolean,
        },
        placeholder: {
            default: undefined,
            required: false,
            type: String,
        },
        prefillModel: {
            default: null,
            type: [String, Object, Array],
        },
        prependIcon: {
            default: undefined,
            type: String,
        },
        readOnly: {
            default: false,
            type: Boolean,
        },
        searchInput: {
            default: '',
            type: String,
        },
        singleLine: {
            default: false,
            type: Boolean,
        },
        slotItemTemplate: {
            default: null,
            type: Object,
        },
        slotSelectionTemplate: {
            default: null,
            type: Object,
        },
        statusIconKey: {
            default: 'StatusIcon',
            type: String,
        },
        statusIconColorKey: {
            default: 'StatusIconColor',
            type: String,
        },
        statusKey: {
            default: 'Status',
            type: String,
        },
        subtitleKey: {
            default: 'subtitle',
            type: String,
        },
        value: {
            type: null,
        },
    },
    data() {
        return {
            slotItemDefault,
            slotSelectionDefault,
        };
    },
    computed: {
        classComputer() {
            let classes = this.classField;
            if (this.backgroundColor) {
                classes += 'inputPadding';
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.classesOutput.push(classes);
            return this.classesOutput;
        },
        hasAppendItemSlot() {
            return !!this.$slots['append-item'];
        },
        selectedItem() {
            const model = this.model ? this.model : {};
            return find(this.items, { Code: get(model, this.itemValue) });
        },
        showPrependIcon() {
            return typeof this.selectedItem !== 'undefined' && this.selectedItem.icon;
        },
    },
    watch: {
        items: {
            handler: function itemsChanged() {
                this.$nextTick(() => {
                    this.itemsChanged(this.model, this.items, this.prefillModel, this.object, this.multiple, this.itemValue, this.autofillSingleValues, this.name, this.emptyValue);
                });
            },
            immediate: true,
        },
        prefillModel: {
            handler: function prefillModelChanged(newVal, oldVal) {
                if (newVal && newVal !== oldVal) {
                    const prefillModel = isUndefined(newVal) ? null : newVal;
                    this.handlePrefillModel(prefillModel, this.model, this.items, this.object, this.itemValue, this.multiple);
                }
            },
            immediate: true,
        },
    },
    methods: {
        change(value) {
            if (this.addQuery) {
                const newValue = this.object ? get(value, this.itemValue) : value;
                setQueryParamsObject(this.name, newValue);
            }
            this.validationOnUpdate();
        },
        /*    change() {
          this.$emit('change');
        }, */
        clickAppendOuter() {
            this.$emit('click:append-outer');
        },
        dataBaseOnObjectType(data) {
            return this.object ? data : get(data, this.itemValue);
        },
        findInModel(data) {
            if (this.object) {
                return findIndex(this.model, [
                    this.itemValue,
                    get(data, this.itemValue),
                ]);
            }
            return this.model.indexOf(get(data, this.itemValue));
        },
        findIndexItem(items, newPrefillModel, isAnObject, itemValue) {
            if (!newPrefillModel) {
                return -1;
            }
            return findIndex(items, (o) => {
                const prefillModel = isAnObject ? get(newPrefillModel, itemValue) : newPrefillModel;
                return get(o, itemValue) === prefillModel;
            });
        },
        findItemInList(items, newPrefillModel, isAnObject, itemValue) {
            if (!newPrefillModel) {
                return undefined;
            }
            let itemFound = find(items, (o) => {
                const prefillModel = isAnObject ? get(newPrefillModel, itemValue) : newPrefillModel;
                return get(o, itemValue) === prefillModel;
            });
            if (itemFound) {
                itemFound = isAnObject ? itemFound : get(itemFound, itemValue);
            }
            return itemFound;
        },
        findItemsInList(items, itemsToFound, isAnObject, itemValue) {
            const itemsFound = [];
            itemsToFound.forEach((itemToFound) => {
                const itemFound = this.findItemInList(items, itemToFound, isAnObject, itemValue);
                if (itemFound) {
                    itemsFound.push(itemFound);
                }
            });
            return itemsFound.length !== 0 ? itemsFound : null;
        },
        formatKeyToUpdate() {
            return this.keyToUpdate ? this.keyToUpdate : this.name;
        },
        getData(data, key) {
            return get(data, key);
        },
        getSingleItem(items, isAnObject, isMultiple, itemValue) {
            if (isMultiple && isAnObject) {
                return items;
            }
            if (isMultiple) {
                return [get(items[0], itemValue)];
            }
            if (isAnObject) {
                return items[0];
            }
            return get(items[0], itemValue);
        },
        getStatus(item) {
            return get(item, this.statusKey);
        },
        getStatusColor(item) {
            return get(item, this.statusIconColorKey);
        },
        getStatusIcon(item) {
            const icon = get(item, this.statusIconKey);
            return icon || 'circle';
        },
        handlePrefillModel(prefillModel, model, items, object, itemValue, isMultiple) {
            if (items.length === 0) {
                return ITEM_CHANGED.noItems;
            }
            if (!this.prefillModel) {
                return false;
            }
            if (isEqual(prefillModel, model)) {
                return false;
            }
            let itemFound = null;
            if (isMultiple) {
                itemFound = this.findItemsInList(items, prefillModel, object, itemValue);
            }
            else {
                itemFound = this.findItemInList(items, prefillModel, object, itemValue);
            }
            if (itemFound) {
                this.update(itemFound);
                this.$emit('update:prefill', this.name);
            }
            return true;
        },
        hasStatus(item) {
            return isNotNullOrUndefined(this.getStatus(item)) && isNotNullOrUndefined(this.getStatusColor(item));
        },
        itemsChanged(model, items, prefillModel, isAnObject, isMultiple, itemValue, autofillSingleValues, name, emptyValue) {
            if (items.length === 0) {
                return ITEM_CHANGED.noItems;
            }
            const newModel = isUndefined(model) ? null : model;
            const newPrefillModel = isUndefined(prefillModel) ? null : prefillModel;
            let itemFound = this.findItemInList(items, newModel, isAnObject, itemValue);
            if (!newPrefillModel && itemFound && !isEqual(newModel, itemFound)) {
                this.update(itemFound);
                return ITEM_CHANGED.modelFound;
            }
            if (autofillSingleValues && items.length === 1) {
                this.update(this.getSingleItem(items, isAnObject, isMultiple, itemValue));
                this.$emit('update:prefill', name);
                return ITEM_CHANGED.autofill;
            }
            if (!newPrefillModel || isEqual(newModel, emptyValue)) {
                return ITEM_CHANGED.equal;
            }
            if (!isMultiple) {
                if (newPrefillModel) {
                    // cherche le prefillModel et update la value
                    itemFound = this.findItemInList(items, newPrefillModel, isAnObject, itemValue);
                    if (itemFound) {
                        this.update(itemFound);
                        this.$emit('update:prefill', name);
                        return ITEM_CHANGED.singlePrefill;
                    }
                }
                const findIndexItem = this.findIndexItem(items, newModel, isAnObject, itemValue);
                if (findIndexItem === -1) {
                    this.update(emptyValue);
                    return ITEM_CHANGED.emptyValue;
                }
            }
            else if (newPrefillModel) {
                itemFound = this.findItemsInList(items, newPrefillModel, isAnObject, itemValue);
                if (itemFound) {
                    this.update(itemFound);
                    this.$emit('update:prefill', name);
                    return ITEM_CHANGED.multiplePrefill;
                }
            }
            return ITEM_CHANGED.notFound;
        },
        menuProps() {
            return {
                closeOnClick: this.closeOnClickMenuProps,
                closeOnContentClick: this.closeOnContentClickMenuProps,
                openOnClick: this.openOnClickMenuProps,
                maxHeight: this.maxHeight,
                contentClass: this.contentClass,
            };
        },
        multipleAddValue(data) {
            const values = cloneDeep(this.model);
            const index = this.findInModel(data);
            if (index === -1) {
                values.push(this.dataBaseOnObjectType(data));
            }
            else {
                values.splice(index, 1);
            }
            this.update(values);
        },
        multipleIsActiveValue(data) {
            return this.multiple ? this.findInModel(data) !== -1 : false;
        },
        update(value) {
            let newValue = value;
            if (typeof newValue === 'undefined') {
                newValue = this.emptyValue;
            }
            this.$emit('update:model', newValue, this.formatKeyToUpdate());
        },
        updateSearch(value) {
            this.$emit('update:search-input', value);
        },
    },
    i18n: {
        messages: {
            fr: {
                noData: 'Aucune donnée trouvée',
            },
            en: {
                noData: 'No matching records found',
            },
        },
    },
};

import MicroServices from '@/configs/MicroServices.types';
import proxy from '@/store/proxy';
import { getQueryString, replaceStringRoutingData, } from 'segic_shared_front_end_utils/src/utils/routing';
import messages from './locales';
export default {
    namespace: 'changeRequests.attachments',
    uri: '/clients/:{clientGuid}/groups/:{groupGuid}/changeRequests/:{requestGuid}/attachments/',
    create(clientGuid, groupGuid, requestGuid, data, query) {
        const methodName = 'create';
        const service = MicroServices.MESSAGING;
        const params = {
            clientGuid,
            groupGuid,
            requestGuid,
        };
        const url = this.uri;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
            data,
        };
        return proxy.post(replacedUrl, data, requestData);
    },
    delete(clientGuid, groupGuid, requestGuid, data, query) {
        const methodName = 'delete';
        const service = MicroServices.MESSAGING;
        const params = {
            clientGuid,
            groupGuid,
            requestGuid,
        };
        const url = this.uri;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
            data,
        };
        return proxy.delete(replacedUrl, data, requestData);
    },
    download(clientGuid, groupGuid, requestGuid, attachmentGuid, query) {
        const methodName = 'download';
        const service = MicroServices.MESSAGING;
        const params = {
            clientGuid,
            groupGuid,
            requestGuid,
            attachmentGuid,
        };
        const url = `${this.uri}:{attachmentGuid}/`;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
        };
        return proxy.get(replacedUrl, requestData);
    },
};

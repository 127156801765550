import { date } from '@/utils/data-formatting/service-input/dates';
import { cloneDeep } from 'lodash';
import { getI18nFromCulture } from 'segic_shared_front_end_utils/src/utils/i18n';
export default {
    output(data) {
        const newData = cloneDeep(data);
        if (newData.participantCertificate && newData.participantCertificate !== '') {
            newData.participantCertificate = `*${newData.participantCertificate}*`;
        }
        if (newData.insuredLastName && newData.insuredLastName !== '') {
            newData.insuredLastName = `*${newData.insuredLastName}*`;
        }
        if (newData.insuredFirstname && newData.insuredFirstname !== '') {
            newData.insuredFirstname = `*${newData.insuredFirstname}*`;
        }
        if (newData.participantStatuses) {
            const participantStatuses = [];
            const ParticipantStatusIds = [];
            newData.participantStatuses.forEach((item) => {
                participantStatuses.push(item.status);
                ParticipantStatusIds.push(item.id);
            });
            newData.participantStatuses = participantStatuses;
            newData.ParticipantStatusIds = ParticipantStatusIds;
        }
        return newData;
    },
    input(insuredPlanMember) {
        return {
            birthDate: date(insuredPlanMember.birthDate),
            client: insuredPlanMember.client,
            displayNameSelect: `${insuredPlanMember.participant.certificateNumber}, ${insuredPlanMember.firstName} ${insuredPlanMember.lastName}`,
            firstName: insuredPlanMember.firstName,
            group: insuredPlanMember.group,
            id: insuredPlanMember.id,
            isSecured: insuredPlanMember.isSecured,
            lastName: insuredPlanMember.lastName,
            needSchoolAttestation: insuredPlanMember.needSchoolAttestation,
            participant: {
                category: {
                    description: getI18nFromCulture(insuredPlanMember.participant.category.description),
                    id: insuredPlanMember.participant.category.id,
                },
                certificateNumber: insuredPlanMember.participant.certificateNumber,
                division: {
                    description: getI18nFromCulture(insuredPlanMember.participant.division.description),
                    id: insuredPlanMember.participant.division.id,
                    recipients: insuredPlanMember.participant.division.recipients,
                },
                id: insuredPlanMember.participant.id,
                participantStatus: {
                    description: getI18nFromCulture(insuredPlanMember.participant.participantStatus.description),
                    effectiveDate: date(insuredPlanMember.participant.participantStatus.effectiveDate),
                    id: insuredPlanMember.participant.participantStatus.id,
                    isFutureStatus: insuredPlanMember.participant.participantStatus.isFutureStatus,
                    status: insuredPlanMember.participant.participantStatus.status,
                },
            },
        };
    },
};

import Snackbar from '@/components/snackbar.vue';
import GlobalLoader from '@/components/ui/global-loader.vue';
import storeMixin from '@/mixins/page-layout/store';
import Header from '@/page-layouts/header.vue';
import StoreExtender from '@/store/extender';
import GlobalUserStore from '@/store/global/user';
import STORE_CONSTANT_NAMES from '@/store/names';
import { find } from 'lodash';
export default {
    components: {
        's-header': Header,
        's-snackbar': Snackbar,
        's-global-loader': GlobalLoader,
    },
    mixins: [storeMixin],
    props: {
        source: String,
    },
    data: () => ({
        authServiceLogIn: false,
        userStore: null,
        showDrawer: true,
    }),
    computed: {
        drawer() {
            return this.$route.meta && typeof this.$route.meta.hasDrawser !== 'undefined' ? this.$route.meta.hasDrawser : true;
        },
        hasUser() {
            return (this.authServiceLogIn && !this.isUserLoading && !this.isParticipantPortalOnly);
        },
        user() {
            return (this.userStore && !this.userStore.getState('isLoading')) ? this.userStore.getGetter('get') : null;
        },
        isParticipantPortalOnly() {
            let memberPortalOnly = true;
            if (this.user) {
                const securityPrincipals = this.user.securityGroups;
                const isEmployerPortal = find(securityPrincipals.securityGroups, 'isEmployerPortalGroup');
                const isParticipantPortal = find(securityPrincipals.securityGroups, 'isPortalUsersGroup');
                memberPortalOnly = isParticipantPortal && !isEmployerPortal;
            }
            return memberPortalOnly;
        },
        isUserLoading() {
            let isloading = true;
            if (this.userStore) {
                isloading = this.userStore.getGetter('getIsLoading');
            }
            return isloading;
        },
        pageCss() {
            let css = this.$device.isMobile() ? 'isMobile' : 'isDesktop';
            css += this.$route.meta && this.$route.meta.pageCss ? ` ${this.$route.meta.pageCss}` : '';
            return css;
        },
        leftComponent() {
            const hasLeftComponent = this.$route.meta && this.$route.meta.headerComponents && this.$route.meta.headerComponents.left;
            return hasLeftComponent ? this.$route.meta.headerComponents.left.component : null;
        },
        extensionComponent() {
            const hasExtensionComponent = this.$route.meta && this.$route.meta.headerComponents && this.$route.meta.headerComponents.extension;
            return hasExtensionComponent ? this.$route.meta.headerComponents.extension.component : null;
        },
        extensionHeight() {
            const hasHeight = this.$route.meta && this.$route.meta.headerComponents && this.$route.meta.headerComponents.extension && this.$route.meta.headerComponents.extension.height;
            return hasHeight ? this.$route.meta.headerComponents.extension.height : 48;
        },
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        },
    },
    mounted() {
        this.getAuthServiceState()
            .then(() => {
            this.authServiceLogIn = true;
        });
    },
    onIdle() {
        if (this.$authService.isLogIn) {
            this.$authService.logout();
        }
    },
    methods: {
        async getAuthServiceState() {
            await this.$authService.getLoginState();
            return this.$authService.auth;
        },
        initializeStore() {
            this.userStore = new StoreExtender(this, STORE_CONSTANT_NAMES.GLOBALS.USER, GlobalUserStore);
        },
    },
};

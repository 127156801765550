import SLabelForm from '@/components/ui/label-form.vue';
import FieldsMixin from '@/mixins/components/fields';
import ComponentMixins from '@/mixins/segic-component';
import { forEach } from 'lodash';
export default {
    name: 'SFieldFileInput',
    components: {
        SLabelForm,
    },
    mixins: [
        FieldsMixin,
        ComponentMixins,
    ],
    props: {
        /**
         * Accept type file.
         */
        accept: {
            default: '.xlsx,.xls,.jpeg,.jpg,.doc,.docx,.pdf,.png',
        },
        /**
         * Hint shown under textfield.
         */
        hint: {
            type: String,
        },
        /**
         * Multiple files select.
         */
        multiple: {
            default: true,
        },
        /**
         * Placeholder shown in textfield.
         */
        placeholder: {
            type: String,
            default: '',
        },
        /**
         * Outlined.
         */
        outlined: {
            type: Boolean,
        },
        maxFileSize: {
            type: Number,
            default: 100,
        },
    },
    computed: {
        rulesFormatted() {
            let separator = '';
            if (this.rules !== '') {
                separator = '|';
            }
            return `${this.rules}${separator}fileMaxSize:${this.maxFileSize}`;
        },
        persistentHint() {
            return this.formattedHint !== '';
        },
        formattedHint() {
            let hint = `${this.$t('theTotalSizeOfAttachmentsMustNotExceed', { maxFileSize: this.maxFileSize })}\n`;
            if (this.hint) {
                hint += this.hint;
            }
            else {
                const acceptedFiles = this.filesNameAccepted;
                hint += acceptedFiles.length !== 0 ? this.$t('acceptedFiles', { acceptedFiles }) : '';
            }
            return hint;
        },
        filesNameAccepted() {
            let files = '';
            const accept = this.accept.toLowerCase();
            if (accept.indexOf('jpg') !== -1 || accept.indexOf('jpeg') !== -1) {
                files += 'JPG, ';
            }
            if (accept.indexOf('png') !== -1) {
                files += 'PNG, ';
            }
            if (accept.indexOf('pdf') !== -1) {
                files += 'PDF, ';
            }
            if (accept.indexOf('docx') !== -1 || accept.indexOf('doc') !== -1) {
                files += 'Word, ';
            }
            if (accept.indexOf('xls') !== -1 || accept.indexOf('xlsx') !== -1) {
                files += 'Excel, ';
            }
            return files.length > 2 ? files.substring(0, files.length - 2) : files;
        },
        formattedPlaceholder() {
            let placeholder = this.multiple ? this.$t('placeholderMultiple') : this.$t('placeholderSingle');
            if (this.placeholder) {
                placeholder = this.placeholder;
            }
            return placeholder;
        },
    },
    methods: {
        change(files) {
            const filesFiltered = [];
            forEach((files), (file) => {
                const extension = file.name.split('.').pop().toLowerCase();
                if (this.accept.indexOf(extension) !== -1) {
                    filesFiltered.push(file);
                }
            });
            this.$emit('update:model', filesFiltered);
        },
    },
    i18n: {
        messages: {
            fr: {
                acceptedFiles: 'Formats acceptés: {acceptedFiles}',
                placeholderMultiple: 'Cliquer pour sélectionner un ou plusieurs fichiers',
                placeholderSingle: 'Cliquer pour sélectionner un fichier',
                multipleHint: 'Tenir la touche CTRL pour sélectionner plusieurs fichiers',
                theTotalSizeOfAttachmentsMustNotExceed: 'La taille totale des pièces jointes ne doit pas excéder {maxFileSize} Mo.',
            },
            en: {
                acceptedFiles: 'Accepted formats: {acceptedFiles}',
                placeholderMultiple: 'Click to select one or more files',
                placeholderSingle: 'Click to select a file',
                multipleHint: 'Hold CTRL key to select multiple files',
                theTotalSizeOfAttachmentsMustNotExceed: 'The total size of attachments must not exceed {maxFileSize}MB.',
            },
        },
    },
};

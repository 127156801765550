import { find, forEach } from 'lodash';
import SETTINGS from '../constants/settings';
import LOGGER from '../utils/debugging';
import { i18nEmpty } from '../utils/default-data';
import { getI18nDictionnary } from '../utils/i18n';
import { isNullOrUndefined } from '../utils/types';
export function toValues(constants, value = 'Code') {
    const values = [];
    Object.keys(constants)
        .forEach((itemKey) => {
        const itemKeyCapitalized = itemKey.toLowerCase().replace(/_/g, ' ').replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()).split(' ')
            .join('');
        values.push({ Key: itemKeyCapitalized, [value]: constants[itemKey] });
    });
    return values;
}
export function toValuesEnum(constants, value = 'Code', isNumberEnum = true) {
    let enumKeys = Object.keys(constants);
    if (isNumberEnum) {
        enumKeys = enumKeys.filter((v) => Number.isNaN(Number(v)));
    }
    const values = [];
    enumKeys.forEach((itemKey) => {
        const itemValue = constants[itemKey];
        const itemKeyCapitalized = itemKey.toLowerCase().replace(/_/g, ' ').replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()).split(' ')
            .join('');
        values.push({ Key: itemKeyCapitalized, [value]: itemValue });
    });
    return values;
}
export function toValuesEnumLowerCase(constants, value = 'Code', isNumberEnum = true) {
    const values = toValuesEnum(constants, value, isNumberEnum);
    const newValues = [];
    values.forEach((item) => {
        newValues.push({
            ...item,
            Key: item.Key.toLowerCase(),
        });
    });
    return newValues;
}
export function toValuesLowerCase(constants, value = 'Code') {
    const values = [];
    forEach(constants, (itemValue, itemKey) => {
        values.push({ Key: itemKey.toLowerCase(), [value]: itemValue });
    });
    return values;
}
export function getEnumEmpty() {
    return {
        Code: null,
        Key: '',
        Value: '',
    };
}
export function getEnumValue(values, dictionaries, lang, code) {
    const goodValue = getEnumEmpty();
    const i18nInstance = getI18nDictionnary(dictionaries, lang);
    const valueFound = find(values, { Code: code });
    if (!valueFound) {
        LOGGER.error('Code wasnt found :', values, code);
        return goodValue;
    }
    return {
        Code: valueFound.Code,
        Key: valueFound.Key,
        Value: i18nInstance.t(`${valueFound.Code}`),
    };
}
export function getEnumValueWithCount(values, dictionaries, lang, code, count) {
    const goodValue = getEnumEmpty();
    const i18nInstance = getI18nDictionnary(dictionaries, lang);
    const valueFound = find(values, { Code: code });
    if (!valueFound) {
        LOGGER.error('Code wasnt found :', values, code);
        return goodValue;
    }
    return {
        Code: valueFound.Code,
        Key: valueFound.Key,
        Value: i18nInstance.tc(`${valueFound.Code}`, count),
    };
}
export function getEnumValues(values, dictionaries, lang, excluded = []) {
    const items = [];
    const i18nInstance = getI18nDictionnary(dictionaries, lang);
    forEach(values, (item) => {
        if (excluded.indexOf(item.Code) !== -1) {
            return;
        }
        items.push({
            Code: item.Code,
            Key: item.Key,
            Value: i18nInstance.t(`${item.Code}`),
        });
    });
    return items;
}
export function getEnumValuesForSelect(values, dictionaries, lang, excluded = []) {
    const items = [];
    const i18nInstance = getI18nDictionnary(dictionaries, lang);
    forEach(values, (item) => {
        if (excluded.indexOf(item.Code) !== -1) {
            return;
        }
        items.push({
            value: item.Code,
            text: i18nInstance.t(`${item.Code}`),
        });
    });
    return items;
}
export function isEnumEq(value, enumConstant) {
    return value === enumConstant;
}
export function getI18nValueByCode(code, dictionaries, defaultEmptyValue = '') {
    if (isNullOrUndefined(code)) {
        return i18nEmpty(defaultEmptyValue);
    }
    const i18n = {};
    SETTINGS.LANGUAGES.forEach((language) => {
        i18n[language] = dictionaries[language][code];
    });
    return i18n;
}

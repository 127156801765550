import { getEnumValue, getEnumValues, toValuesEnum } from './_utils';
export var PHONE_LOCALIZATION_TYPES_CONSTANT;
(function (PHONE_LOCALIZATION_TYPES_CONSTANT) {
    PHONE_LOCALIZATION_TYPES_CONSTANT[PHONE_LOCALIZATION_TYPES_CONSTANT["HOME"] = 1] = "HOME";
    PHONE_LOCALIZATION_TYPES_CONSTANT[PHONE_LOCALIZATION_TYPES_CONSTANT["OFFICE"] = 3] = "OFFICE";
    PHONE_LOCALIZATION_TYPES_CONSTANT[PHONE_LOCALIZATION_TYPES_CONSTANT["CELLPHONE"] = 4] = "CELLPHONE";
})(PHONE_LOCALIZATION_TYPES_CONSTANT || (PHONE_LOCALIZATION_TYPES_CONSTANT = {}));
;
const values = toValuesEnum(PHONE_LOCALIZATION_TYPES_CONSTANT);
const FR = {
    [PHONE_LOCALIZATION_TYPES_CONSTANT.HOME]: 'Maison',
    [PHONE_LOCALIZATION_TYPES_CONSTANT.OFFICE]: 'Bureau',
    [PHONE_LOCALIZATION_TYPES_CONSTANT.CELLPHONE]: 'Cellulaire',
};
const EN = {
    [PHONE_LOCALIZATION_TYPES_CONSTANT.HOME]: 'Home',
    [PHONE_LOCALIZATION_TYPES_CONSTANT.OFFICE]: 'Office',
    [PHONE_LOCALIZATION_TYPES_CONSTANT.CELLPHONE]: 'Cell',
};
const Dictionaries = {
    fr: FR,
    en: EN,
};
function getValues(lang, excluded = []) {
    return getEnumValues(values, Dictionaries, lang, excluded);
}
function getValuePerCode(code, lang) {
    return getEnumValue(values, Dictionaries, lang, code);
}
const PHONE_LOCALIZATION_TYPES = {
    CONSTANT: PHONE_LOCALIZATION_TYPES_CONSTANT,
    getValues,
    getValuePerCode,
};
export default PHONE_LOCALIZATION_TYPES;

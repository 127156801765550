import SCertificateNumberMapping from '@/page-layouts/administrator/forms/payroll-files-conversions/certificate-numbers-mapping/index.vue';
import SGroupBenefitsMapping from '@/page-layouts/administrator/forms/payroll-files-conversions/group-benefits-mapping/index.vue';
import SRecipientsMapping from '@/page-layouts/administrator/forms/payroll-files-conversions/recipients-mapping/index.vue';
import STaxableBenefitsMapping from '@/page-layouts/administrator/forms/payroll-files-conversions/taxable-benefits-mapping/index.vue';
import { PAYROLL_FILE_CONVERSION_STEPPER_TYPE } from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/form.types';
export default function getMappingStepsComponents(index) {
    switch (index) {
        case PAYROLL_FILE_CONVERSION_STEPPER_TYPE.RECIPIENTS_MAPPING:
            return SRecipientsMapping;
        case PAYROLL_FILE_CONVERSION_STEPPER_TYPE.GROUP_BENEFITS_MAPPING:
            return SGroupBenefitsMapping;
        case PAYROLL_FILE_CONVERSION_STEPPER_TYPE.TAXABLE_BENEFITS_MAPPING:
            return STaxableBenefitsMapping;
        case PAYROLL_FILE_CONVERSION_STEPPER_TYPE.CERTIFICATES_MAPPING:
            return SCertificateNumberMapping;
        default:
            return null;
    }
}

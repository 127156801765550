export default {
    date: {
        view: 'YYYY-MM-DD',
        validation: 'yyyy-mm-dd',
    },
    dateTime: {
        view: 'YYYY-MM-DD HH:mm:ss',
    },
    monthDate: {
        view: 'YYYY-MM',
        validation: 'yyyy-mm',
    },
    time: {
        view: 'HH:mm:ss',
    },
    displayDate: 'DD MMM [\']YY',
};

import SActionIcon from '@/components/ui/action-icon.vue';
import SComponentDialogDisplay from '@/components/dialogs/display.vue';
import History from '@/page-layouts/administrator/participants/single/components/history.vue';
export default {
    name: 'SBtnHistoryDialog',
    components: { SActionIcon, SComponentDialogDisplay },
    mixins: [],
    props: {
        isLoading: {
            default: false,
            type: Boolean,
        },
        historyItems: {
            required: true,
            type: Array,
        },
        historyHeaders: {
            required: true,
            type: Array,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        titleHistoryDialog: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            showDialogHistory: false,
        };
    },
    computed: {
        history() {
            return {
                component: History,
                props: {
                    headers: this.historyHeaders,
                    items: this.historyItems,
                },
            };
        },
    },
    methods: {
        toggleDialogHistory(show) {
            this.showDialogHistory = show;
            this.$emit('click:history', this.showDialogHistory);
        },
    },
};

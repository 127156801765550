import SLabelForm from '@/components/ui/label-form.vue';
import FieldsMixin from '@/mixins/components/fields';
import ComponentMixins from '@/mixins/segic-component';
export default {
    name: 'SFieldTextarea',
    components: { SLabelForm },
    mixins: [
        FieldsMixin,
        ComponentMixins,
    ],
    props: {
        /**
                 * Icon displayed in after of text
                 */
        appendIcon: {
            type: String,
        },
        /**
                 * Hint shown under textfield.
                 */
        hint: {
            type: String,
        },
        /**
                 * Value of the input
                 */
        model: {
            required: true,
        },
        /**
                 * No resize
                 */
        noResize: {
            type: Boolean,
            default: true,
        },
        /**
                 * Adds border to give it vuetify outlined style
                 */
        outlined: {
            type: Boolean,
            default: false,
        },
        /**
                 * Adds background color to give filled style
                 */
        filled: {
            type: Boolean,
            default: false,
        },
        /**
                 * Hint is always visible.
                 */
        persistentHint: {
            type: Boolean,
        },
        /**
                 * Placeholder shown in textfield.
                 */
        placeholder: {
            type: String,
            default: '',
        },
        /**
                 * Prefix added before textfield.
                 */
        prefixText: {
            type: String,
        },
        /**
                 * Icon displayed in front of text
                 */
        prependIcon: {
            type: String,
        },
        /**
                 * Suffix added after textfield.
                 */
        suffixText: {
            type: String,
        },
        // must be included in props
        value: {
            default: null,
        },
    },
    data() {
        return {};
    },
    methods: {
        /**
                 * When press enter event is send.
                 * @event keyup
                 * @param {Object} e event object
                 * @public
                 */
        keyupEnter(event) {
            this.$emit('keyupEnter', event);
        },
    },
};

import { isNullOrUndefined } from '@/utils/helpers';
import { updateBenefitsMapping } from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/mapping';
import { cloneDeep } from 'lodash';
export default {
    methods: {
        addItemRecipientMappingCode(items) {
            items.forEach((item) => {
                this.mappingCodeHeaders.forEach((header) => {
                    const itemMapping = item.mappings.find((m) => m.integrationType === header.value);
                    item[header.value] = itemMapping ? itemMapping.mappingCode : '';
                });
            });
        },
        getIntegrationTypeName(integrationType) {
            if (!this.formData?.content?.payrollIntegrations?.length) {
                return '';
            }
            return this.formData.content.payrollIntegrations.find((item) => item.type === integrationType)?.description[this.$i18n.locale] || '';
        },
        getHeaders() {
            if (!this?.formData?.content?.groupBenefits?.length || !this?.formData?.content?.groupBenefits[0]?.mappings?.length) {
                return [];
            }
            this.mappingCodeHeaders = this.formData.content.groupBenefits[0].mappings
                .map((item) => ({
                text: `${this.getIntegrationTypeName(item.integrationType)} - ${item.recipientMappingCode}`,
                name: this.getIntegrationTypeName(item.integrationType),
                subtitle: this.mappingCodeLabel,
                value: `${item.integrationType}_${item.recipientMappingCode}`,
                integrationType: item.integrationType,
                recipientMappingCode: item.recipientMappingCode,
            }));
            return [...this.baseHeaders, ...this.mappingCodeHeaders];
        },
        handleUpdateMappings(value, id, integrationType, recipientMappingCode) {
            if (!isNullOrUndefined(value)) {
                const formDataContent = updateBenefitsMapping(value, this.mappingType, id, cloneDeep(this.formData.content), integrationType, recipientMappingCode);
                this.$emit('update:model', this.mappingType, formDataContent[this.mappingType]);
                this.$nextTick(async () => {
                    const isFormValid = await this.validateForm(true);
                    this.emitEnabledNextBtn(this.stepIndex, isFormValid);
                });
            }
        },
    },
};

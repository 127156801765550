export default {
    props: {
        stepValue: {
            required: true,
            type: Number,
        },
        stepIndex: {
            required: true,
            type: Number,
        },
        showDialogAddParticipantForm: {
            default: false,
            type: Boolean,
        },
        defaultSectionFormData: {
            default: null,
            type: Object,
        },
    },
    data() {
        return {};
    },
    watch: {
        showDialogAddParticipantForm(newVal) {
            if (newVal) {
                this.resetForm();
            }
        },
    },
    methods: {
        emitEnabledNextBtn(stepIndex, isEnabled) {
            this.$emit('enabled:nextBtn', stepIndex, isEnabled);
        },
        updateModel(value, key) {
            this.updateField(key, value);
            this.$emit('update:model', key, value);
            this.$nextTick(async () => {
                const isFormValid = await this.validateForm(true);
                this.emitEnabledNextBtn(this.stepIndex, isFormValid);
            });
        },
        async nextAction() {
            return this.validateForm();
        },
        async validateForm(silent = false) {
            return this.$refs.observer.validate({ silent });
        },
        resetFormData() {
            if (!this.defaultSectionFormData) {
                return;
            }
            this.defaultData = this.defaultSectionFormData;
        },
    },
};

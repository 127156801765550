import HTTP_CODES from 'segic_shared_front_end_utils/src/constants/http-codes';
const FR = {
    get: {
        [HTTP_CODES.OK]: '',
    },
};
const EN = {
    get: {
        [HTTP_CODES.OK]: '',
    },
};
export default {
    fr: FR,
    en: EN,
};

// import _ from 'lodash';
import StoreBasics from '@/store/basics';
import storeUtils from '@/store/utils';
import Service from '../service';
import entities from './entities';
const cacheDelay = -1;
const storeData = [
    {
        key: 'data',
        defaultValue: [],
        methods: [
            {
                key: 'create',
                params: ['clientGuid', 'groupGuid', 'data'],
                cacheDelay,
                outputFormatting(data) {
                    return entities.outputCreate(data);
                },
            },
            {
                key: 'delete',
                params: ['clientGuid', 'groupGuid', 'requestGuid'],
                cacheDelay,
            },
            {
                key: 'update',
                params: ['clientGuid', 'groupGuid', 'requestGuid', 'data'],
                cacheDelay,
                outputFormatting(data) {
                    return entities.outputUpdate(data);
                },
            },
        ],
        service: Service,
    },
];
const state = storeUtils.mergeStates(StoreBasics.statesFromData(storeData));
const getters = storeUtils.mergeGetters(StoreBasics.gettersFromData(storeData));
const mutations = storeUtils.mergeMutations(StoreBasics.mutationsFromData(storeData));
const actions = storeUtils.mergeActions(StoreBasics.actionsFromData(storeData));
const ChangeRequestsSingleStore = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default ChangeRequestsSingleStore;

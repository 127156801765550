/**
   * @displayName Action icon
   * */
export default {
    name: 'SActionIcon',
    props: {
        /**
           * Icon color
           */
        color: {
            default: '',
        },
        /**
           * Icon
           */
        icon: {
            type: String,
        },
        /*
          * Tooltip Text
          */
        preSetIcon: {
            type: String,
        },
        tooltip: {
            type: String,
        },
        iconClass: {
            type: String,
            default: 's-icon-link',
        },
        showBadge: {
            default: false,
            type: Boolean,
        },
        /*
           * Small icon
           */
        small: {
            default: false,
        },
        'x-small': {
            default: false,
        },
        nameE2E: {},
        disabled: {
            default: false,
        },
        isLoading: {
            default: false,
        },
        maxWidth: {
            default: 'auto',
            type: [String, Number],
        },
    },
    computed: {
        /**
           * If no tooltips disabled tooltip
           * */
        disabledToolTip() {
            return typeof this.tooltip === 'undefined' || this.disabled;
        },
        setIcon() {
            switch (this.preSetIcon) {
                case 'edit':
                    return 'edit';
                case 'view':
                    return 'remove_red_eye';
                case 'delete':
                    return 'delete';
                default:
                    return this.icon;
            }
        },
    },
    methods: {
        /**
           * Send event click
           * */
        click() {
            this.$emit('click');
        },
        setsize(size) {
            if (size === 'small' && !this.small && !this.xSmall) {
                return true;
            }
            return this[size];
        },
    },
};

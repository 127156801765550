import { getEnumValue, getEnumValues, toValuesEnum } from './_utils';
export var SMOKER_STATE_TYPES;
(function (SMOKER_STATE_TYPES) {
    SMOKER_STATE_TYPES[SMOKER_STATE_TYPES["UNKNOWN"] = 0] = "UNKNOWN";
    SMOKER_STATE_TYPES[SMOKER_STATE_TYPES["NON_SMOKER"] = 1] = "NON_SMOKER";
    SMOKER_STATE_TYPES[SMOKER_STATE_TYPES["SMOKER"] = 2] = "SMOKER";
})(SMOKER_STATE_TYPES || (SMOKER_STATE_TYPES = {}));
;
const values = toValuesEnum(SMOKER_STATE_TYPES);
const FR = {
    [SMOKER_STATE_TYPES.UNKNOWN]: 'Inconnu',
    [SMOKER_STATE_TYPES.NON_SMOKER]: 'Non-fumeur',
    [SMOKER_STATE_TYPES.SMOKER]: 'Fumeur',
};
const EN = {
    [SMOKER_STATE_TYPES.UNKNOWN]: 'Unknown',
    [SMOKER_STATE_TYPES.NON_SMOKER]: 'Non-smoker',
    [SMOKER_STATE_TYPES.SMOKER]: 'Smoker',
};
const Dictionaries = {
    fr: FR,
    en: EN,
};
export function smokerStateGetValues(lang, excluded = []) {
    return getEnumValues(values, Dictionaries, lang, excluded);
}
function getValuePerCode(code, lang) {
    return getEnumValue(values, Dictionaries, lang, code);
}
const SMOKER_STATE = {
    CONSTANT: SMOKER_STATE_TYPES,
    getValues: smokerStateGetValues,
    getValuePerCode,
};
export default SMOKER_STATE;

import { PAYROLL_INTEGRATION_TYPES, PAYROLL_FILE_CONVERSION_STEPPER_TYPE, } from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/form.types';
import { getI18nFromCulture } from 'segic_shared_front_end_utils/src/utils/i18n';
import SETTINGS from 'segic_shared_front_end_utils/src/constants/settings';
import LANGUAGE_CODES from 'segic_shared_front_end_utils/src/enums/language-codes';
export function formatGroup({ name, mappingCode }) {
    return {
        name: getI18nFromCulture(name),
        mappingCode,
    };
}
export function formatGroupBenefits(rawGroupBenefits) {
    return rawGroupBenefits.map(({ description, id, isDisabled, mappings, status, }) => ({
        description: getI18nFromCulture(description),
        id,
        isDisabled: !!isDisabled,
        mappings,
        status,
    }));
}
export function formatParticipants(rawParticipants) {
    return rawParticipants.map(({ certificateNumber, division, firstName, lastName, mappingCode, }) => ({
        id: certificateNumber,
        certificateNumber,
        division,
        fullName: `${lastName}, ${firstName}`,
        firstName,
        lastName,
        mappingCode: mappingCode || '',
    }));
}
export function formatTaxableBenefits(rawTaxableBenefits) {
    return rawTaxableBenefits.map(({ description, type, mappings }) => ({
        description: getI18nFromCulture(description),
        id: type,
        mappings,
    }));
}
export function formatRecipients(rawRecipients) {
    return rawRecipients.map(({ divisions, id, integrationType, mappingCode, name, payrollIntegrationId, }) => ({
        divisions: divisions.map((division) => getI18nFromCulture(division.name)),
        id,
        integrationType,
        mappingCode,
        name: getI18nFromCulture(name),
        payrollIntegrationId,
    }));
}
function formatPayrollIntegrations(rawPayrollIntegrations) {
    return rawPayrollIntegrations.map(({ description, type }) => ({
        description: getI18nFromCulture(description),
        type,
    }));
}
export function formatBenefitsOutputMapping(array) {
    return array.map(({ id, isDisabled, mappings }) => ({
        id,
        isDisabled,
        mappings: mappings.map(({ mappingCode, integrationType, recipientMappingCode, payrollIntegrationId, }) => ({
            mappingCode: isDisabled ? null : mappingCode,
            integrationType,
            recipientMappingCode,
            payrollIntegrationId,
        })),
    }));
}
export function formatTaxableBenefitOutputMapping(array) {
    return array.map(({ id: type, mappings }) => ({
        type,
        mappings: mappings.map((mapping) => ({
            type,
            ...mapping,
        })),
    }));
}
export function isMissingMapping(data) {
    return data ? data.some((item) => item.mappings.some((mapping) => !mapping.mappingCode && !item?.isDisabled)) : true;
}
export function isMissingParticipantMapping(data) {
    return data ? data.some((item) => !item.mappingCode) : true;
}
export default {
    input({ group, groupBenefits, participants, payrollIntegrations, recipients, taxableBenefits, }) {
        return {
            group: formatGroup(group),
            groupBenefits: groupBenefits ? formatGroupBenefits(groupBenefits) : null,
            participants: participants ? formatParticipants(participants) : null,
            payrollIntegrations: formatPayrollIntegrations(payrollIntegrations),
            recipients: formatRecipients(recipients),
            taxableBenefits: taxableBenefits ? formatTaxableBenefits(taxableBenefits) : null,
            isMissingMapping: {
                [PAYROLL_FILE_CONVERSION_STEPPER_TYPE.GROUP_BENEFITS_MAPPING]: isMissingMapping(groupBenefits),
                [PAYROLL_FILE_CONVERSION_STEPPER_TYPE.TAXABLE_BENEFITS_MAPPING]: isMissingMapping(taxableBenefits),
                [PAYROLL_FILE_CONVERSION_STEPPER_TYPE.CERTIFICATES_MAPPING]: isMissingParticipantMapping(participants),
            },
        };
    },
    output({ content: { recipients, groupBenefits, participants, taxableBenefits, }, }) {
        return {
            groupBenefits: formatBenefitsOutputMapping(groupBenefits),
            recipients,
            participants: participants.filter(({ mappingCode }) => mappingCode),
            taxableBenefits: formatTaxableBenefitOutputMapping(taxableBenefits),
        };
    },
    getIntegrationDataStructure(recipients) {
        return {
            culture: LANGUAGE_CODES.getCodeByLanguage(SETTINGS.LANGUAGE),
            recipients: recipients.map(({ id, integrationType, mappingCode, payrollIntegrationId, }) => ({
                id, integrationType, mappingCode: integrationType !== PAYROLL_INTEGRATION_TYPES.NONE ? mappingCode : null, payrollIntegrationId,
            })),
        };
    },
};

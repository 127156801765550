import SDatatableBase from '@/components/datatables/base.vue';
import SFieldText from '@/components/form/fields/text.vue';
import SStatus from '@/components/ui/status.vue';
import ComponentMixins from '@/mixins/segic-component';
import StepperFormMixin from '@/page-layouts/administrator/forms/add-participant/byForm/sections/mixins';
import SMappingCode from '@/page-layouts/administrator/forms/payroll-files-conversions/components/mapping-code.vue';
import SDialogLoader from '@/page-layouts/administrator/forms/payroll-files-conversions/dialog-loader.vue';
import BenefitsMappingMixin from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/benefits-mapping-mixin';
import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
import { getMappingValueByCode, updateMapping } from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/mapping';
import { isNullOrUndefined } from '@/utils/helpers';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantFormSectionsContactDetails',
    components: {
        SStatus,
        SMappingCode,
        SDialogLoader,
        SDatatableBase,
        SFieldText,
    },
    mixins: [
        BenefitsMappingMixin,
        ComponentMixins,
        StepperFormMixin,
    ],
    props: {
        formData: {
            required: true,
            type: Object,
        },
        initialFormData: {
            type: Object,
            required: true,
        },
        canShowMappingCodeWarning: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            items: [],
            baseHeaders: [
                {
                    text: this.$t('taxableBenefit'),
                    value: 'name',
                },
            ],
            headers: [],
            mappingCodeHeaders: [],
            mappingType: 'taxableBenefits',
        };
    },
    computed: {
        mappingCodeLabel() {
            return this.$t('gainNumber');
        },
    },
    watch: {
        'formData.content.taxableBenefits': {
            handler() {
                this.headers = this.getHeaders();
                this.items = this.getItems();
            },
            deep: true,
        },
    },
    beforeMount() {
        this.headers = this.getHeaders();
        this.items = this.getItems();
    },
    methods: {
        getMappingValueByCode,
        async dispatchEvent({ value, id }) {
            if (!isNullOrUndefined(value)) {
                updateMapping(value, this.mappingType, id, this.formData.content);
                this.updateModel(this.formData.content[this.mappingType], `content.${this.mappingType}`);
            }
        },
        getItems() {
            if (!this.formData.content.taxableBenefits) {
                return [];
            }
            const items = this.formData.content.taxableBenefits.map(({ description, id, mappings }) => ({
                id,
                mappings,
                name: { description: description[this.$i18n.locale] },
            }));
            this.addItemRecipientMappingCode(items);
            return items;
        },
    },
    i18n,
};

import SButtonSubmit from '@/components/ui/btn-submit.vue';
import SegicComponent from '@/mixins/segic-component';
import EVENTS from 'segic_shared_front_end_utils/src/constants/events';
import { forEach, get } from 'lodash';
import { EventBus } from '@/utils/event-bus';
export default {
    name: 'SStepper',
    components: {
        SButtonSubmit,
    },
    mixins: [SegicComponent],
    props: {
        forceHasReach: {
            default: false,
            type: Boolean,
        },
        isLoading: {
            default: false,
            type: Boolean,
        },
        isStepLoading: {
            default: false,
            type: Boolean,
        },
        items: {
            required: true,
            type: Array,
        },
        removeBoxShadowHeader: {
            default: true,
            type: Boolean,
        },
        removeBoxShadowStepper: {
            default: true,
            type: Boolean,
        },
        stepperErrors: {
            default: () => [],
            type: Array,
        },
    },
    data() {
        return {
            model: 1,
            NEXT_ACTION_FUNCTION_NAME: 'nextAction',
            PREV_ACTION_FUNCTION_NAME: 'prevAction',
            stepsValidation: {},
            hasReachLastStep: false,
            isValidName: 'isValid',
            stepHasReachName: 'stepHasReach',
        };
    },
    computed: {
        allStepsValid() {
            return this.checkAllStepsByKey(this.isValidName);
        },
        isNonLinear() {
            return this.allStepsValid && this.hasReachLastStep;
        },
    },
    watch: {
        stepperErrors: {
            handler(val) {
                const firstError = val.find((item) => item.showError);
                if (firstError) {
                    this.model = firstError.step;
                }
            },
            deep: true,
        },
    },
    beforeMount() {
        EventBus.$on('setHasReachLastStep', (value) => { this.hasReachLastStep = value; });
        this.resetStepsValidation();
    },
    mounted() {
        this.setHasReachLastStep();
    },
    methods: {
        async action(step, actionFunctionName) {
            let isValid = true;
            const action = get(this.$refs, `stepper_${step}.0.${actionFunctionName}`);
            if (typeof action === 'function') {
                isValid = await action();
                if (!isValid) {
                    this.scrollToFirstError();
                }
            }
            return isValid;
        },
        cancel() {
            this.$emit(EVENTS.CANCEL);
        },
        checkAllStepsByKey(key) {
            let isAllKeysValid = false;
            forEach(this.stepsValidation, (item) => {
                isAllKeysValid = item[key];
                if (!isAllKeysValid) {
                    return false;
                }
                return true;
            });
            return isAllKeysValid;
        },
        hasErrors(step) {
            return this.stepErrors(step).length !== 0;
        },
        isInViewport(element) {
            const rect = element.getBoundingClientRect();
            return (rect.top >= 0
                && rect.left >= 0
                && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
                && rect.right <= (window.innerWidth || document.documentElement.clientWidth));
        },
        isValidByStep(step) {
            const key = this.stepValidationKeyName(step);
            return this.stepsValidation[key]?.[this.isValidName] && this.stepsValidation[key]?.[this.stepHasReachName];
        },
        resetStepsValidation() {
            this.hasReachLastStep = false;
            this.stepsValidation = {};
            this.items.forEach((item, index) => {
                const keyRef = `stepper_${index + 1}`;
                if (typeof this.$refs[keyRef]?.[0]?.emitCurrentEnabledNextBtn === 'function') {
                    this.$refs[keyRef][0].emitCurrentEnabledNextBtn();
                }
                else {
                    this.setStepValidation(index + 1, this.isValidName, true);
                }
            });
        },
        returnToFirstStep() {
            this.model = 1;
            this.resetStepsValidation();
        },
        rules(step) {
            return [() => !this.hasErrors(step)];
        },
        scrollToFirstError() {
            this.$nextTick(() => {
                const errorElement = this.$el.querySelector('.v-input--has-state');
                if (errorElement && !this.isInViewport(errorElement)) {
                    errorElement.scrollIntoView({ behavior: 'smooth' });
                }
            });
        },
        setColor(step) {
            if (this.model === step) {
                return 'primary';
            }
            if (this.isValidByStep(step)) {
                return 'success';
            }
            return 'primary';
        },
        setHasReachLastStep() {
            if (!this.hasReachLastStep && (this.forceHasReach || this.model === this.items.length)) {
                this.hasReachLastStep = true;
                this.setStepValidation(this.model, this.stepHasReachName, true);
            }
        },
        async setModel(value) {
            let changeStep = true;
            if (value < 0) {
                changeStep = this.action(this.model, this.PREV_ACTION_FUNCTION_NAME);
            }
            else {
                changeStep = await this.action(this.model, this.NEXT_ACTION_FUNCTION_NAME);
                this.setStepValidation(this.model, this.stepHasReachName, changeStep);
            }
            if (changeStep) {
                this.$emit('handleClickNext', this.model);
                this.model += value;
            }
            this.setHasReachLastStep();
        },
        setStepValidation(step, objectKey, value) {
            const stepValidationKey = this.stepValidationKeyName(step);
            let validation = get(this.stepsValidation, stepValidationKey);
            if (!validation) {
                validation = {
                    [this.stepHasReachName]: this.forceHasReach,
                };
            }
            validation[objectKey] = value;
            this.$set(this.stepsValidation, stepValidationKey, validation);
        },
        stepErrors(step) {
            return this.stepperErrors.filter((error) => error.showError && error.step === step);
        },
        stepValidationKeyName(step) {
            return `step_${step}`;
        },
    },
};

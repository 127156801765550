import bgHeader from '@/assets/images/bgHeader.svg';
import LayoutComponentMixins from '@/mixins/segic-layout-component';
import URL_CONSTANTS from '@/urls.config.json';
import SETTINGS from 'segic_shared_front_end_utils/src/constants/settings';
import { isObject } from 'segic_shared_front_end_utils/src/utils/types';
import { find } from 'lodash';
import UserAccountRoutes from '@/page-layouts/manage-account/route-name';
import LANGUAGE_CODES from 'segic_shared_front_end_utils/src/enums/language-codes';
import SECURITY_PERMISSIONS from 'segic_shared_front_end_utils/src/enums-bitwise/security-permissions';
import { EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES } from '@/mixins/security/codes/employer-portal';
import { en, fr, isMarshMcLennanLink, OPEN_NEW_TAB, } from '@/utils/marshMcLennanLink';
export default {
    mixins: [
        LayoutComponentMixins,
    ],
    props: {
        extensionComponent: {},
        leftComponent: {},
        extensionHeight: {
            default: 48,
        },
    },
    data() {
        return {
            EVENTS: {
                CHANGE_LOCALE: 'CHANGE_LOCALE',
                LOGOUT: 'logout',
                GO_TO: 'GO_TO',
            },
        };
    },
    computed: {
        bgHeader() {
            return this.hasExtension ? bgHeader : '';
        },
        user() {
            return this.userStore ? this.userStore.getGetter('get') : null;
        },
        userFirstName() {
            return this.user ? this.user.securityPrincipal.firstName : '';
        },
        userLastName() {
            return this.user ? this.user.securityPrincipal.lastName : '';
        },
        userName() {
            return this.user ? `${this.userFirstName} ${this.userLastName}` : '';
        },
        userSecurityPrincipal() {
            return this.user ? this.user.securityPrincipal.id : null;
        },
        otherLanguage() {
            return find(SETTINGS.LANGUAGES, (language) => language !== this.$i18n.locale);
        },
        otherLanguageCode() {
            return LANGUAGE_CODES.CONSTANT[this.otherLanguage.toUpperCase()];
        },
        showSwitchPortal() {
            const isEmployerPortal = find(this.user.securityGroups, 'isEmployerPortalGroup');
            const isParticipantPortal = find(this.user.securityGroups, 'isPortalUsersGroup');
            return (isEmployerPortal && isParticipantPortal) && this.$route.name !== 'dashboard';
        },
        hasExtension() {
            return isObject(this.extensionComponent);
        },
        userMenus() {
            const menus = [
                {
                    listItemClass: 'hidden-sm-and-up text-uppercase',
                    clickEvent: this.EVENTS.CHANGE_LOCALE,
                    icon: 'manage_accounts',
                    text: this.otherLanguage,
                },
            ];
            if (this.checkAccess(SECURITY_PERMISSIONS.VIEW, EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.EMPLOYER_BILLING_AUTHORIZE_RESOURCE)) {
                menus.push({
                    clickEvent: this.EVENTS.GO_TO,
                    to: {
                        name: UserAccountRoutes.USER_ACCOUNT,
                    },
                    icon: 'manage_accounts',
                    text: this.$t('manageAccount'),
                });
            }
            if (isMarshMcLennanLink(this.user?.securityPrincipal?.clientId)) {
                menus.push({
                    clickEvent: OPEN_NEW_TAB,
                    icon: 'privacy_tip',
                    text: this.$t('privacyNotice'),
                    link: this.$t('link'),
                });
            }
            menus.push({
                clickEvent: this.EVENTS.LOGOUT,
                icon: 'power_settings_new',
                text: this.$t('logout'),
            });
            return menus;
        },
    },
    methods: {
        logout() {
            this.$authService.logout();
        },
        changeLocale(languageCode) {
            const params = {
                languageCode,
                securityPrincipalsGuid: this.userSecurityPrincipal,
            };
            this.userStore.sendActions('setUserLanguage', params);
        },
        goToMemberPortal() {
            window.location.href = URL_CONSTANTS.portalParticipant;
        },
        dispatchEvents(event, menuItem) {
            if (event === this.EVENTS.CHANGE_LOCALE) {
                this.changeLocale(this.otherLanguageCode);
            }
            else if (event === this.EVENTS.LOGOUT) {
                this.logout();
            }
            else if (event === this.EVENTS.GO_TO) {
                this.$router.push(menuItem.to);
            }
            else if (event === OPEN_NEW_TAB) {
                window.open(menuItem.link, '_blank');
            }
        },
    },
    i18n: {
        messages: {
            fr: {
                manageAccount: 'Gérer votre compte',
                ...fr,
            },
            en: {
                manageAccount: 'Manage your account',
                ...en,
            },
        },
    },
};

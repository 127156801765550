const marshMcLennanClientId = 'de2c6985-2b58-4397-a95a-e58efd8ff46a';
export const fr = {
    privacyNotice: 'Avis de confidentialité',
    link: 'https://www.marshmma.com/ca/fr/privacy-notice.html',
};
export const en = {
    privacyNotice: 'Privacy Notice',
    link: 'https://www.marshmma.com/ca/en/privacy-notice.html',
};
export const OPEN_NEW_TAB = 'OPEN_NEW_TAB';
export function isMarshMcLennanLink(clientId) {
    return clientId === marshMcLennanClientId;
}

import SNoteFiles from '@/components/form/note-files.vue';
import ComponentMixins from '@/mixins/segic-component';
import FormSimpleMixin from '@/mixins/form-simple';
import StepperFormMixin from '@/page-layouts/administrator/forms/add-participant/byForm/sections/mixins';
import { filter } from 'lodash';
import { isHub } from '@/utils/hub';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantFormSectionsFilesMessages',
    components: {
        SNoteFiles,
    },
    mixins: [
        ComponentMixins,
        FormSimpleMixin,
        StepperFormMixin,
    ],
    computed: {
        isHub() {
            return isHub(this.$route.params.clientGuid) && (this.formData.displayAttachments.length === 0 && this.formData.attachments.length === 0);
        },
        rulesFiles() {
            return this.isHub ? 'required' : '';
        },
        labelFiles() {
            return this.isHub ? this.$t('labelFiles') : null;
        },
    },
    methods: {
        fileToDelete(id) {
            this.formData.displayAttachments = filter(this.formData.displayAttachments, (item) => item.id !== id);
            this.formData.deleteAttachmentIds.push(id);
            this.updateModel(this.formData.displayAttachments, 'displayAttachments');
            this.updateModel(this.formData.deleteAttachmentIds, 'deleteAttachmentIds');
        },
    },
    i18n: {
        messages: {
            fr: {
                labelFiles: 'Attacher des fichiers à la demande : (Le formulaire d\'adhésion est obligatoire).',
                title: 'Téléchargez les documents justificatifs',
                example: '(ex. : attestation scolaire, statut d\'invalidité de l\'enfant, documentation de coordination des prestations)',
            },
            en: {
                labelFiles: 'Attach files to the request: (The group benefits enrollment form is mandatory).',
                title: 'Upload Supporting Documents',
                example: '(e.g., School Attendance Certificate, Child Disability Status, Coordination of Benefits Documentation)',
            },
        },
    },
};

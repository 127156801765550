import { getEnumValue, getEnumValues, toValuesEnum } from './_utils';
export var GENDER_TYPES;
(function (GENDER_TYPES) {
    GENDER_TYPES[GENDER_TYPES["UNKNOWN"] = 0] = "UNKNOWN";
    GENDER_TYPES[GENDER_TYPES["MALE"] = 1] = "MALE";
    GENDER_TYPES[GENDER_TYPES["FEMALE"] = 2] = "FEMALE";
})(GENDER_TYPES || (GENDER_TYPES = {}));
;
const values = toValuesEnum(GENDER_TYPES);
const FR = {
    [GENDER_TYPES.UNKNOWN]: 'Inconnu',
    [GENDER_TYPES.MALE]: 'Homme',
    [GENDER_TYPES.FEMALE]: 'Femme',
};
const EN = {
    [GENDER_TYPES.UNKNOWN]: 'Unknown',
    [GENDER_TYPES.MALE]: 'Male',
    [GENDER_TYPES.FEMALE]: 'Female',
};
const Dictionaries = {
    fr: FR,
    en: EN,
};
export function genderGetValues(lang) {
    return getEnumValues(values, Dictionaries, lang, [GENDER_TYPES.UNKNOWN]);
}
function getValuePerCode(code, lang) {
    return getEnumValue(values, Dictionaries, lang, code);
}
const GENDER = {
    CONSTANT: GENDER_TYPES,
    getValues: genderGetValues,
    getValuePerCode,
};
export default GENDER;

import SFieldText from '@/components/form/fields/text.vue';
import SFieldDatepicker from '@/components/form/fields/datepicker.vue';
import SFieldSelect from '@/components/form/fields/select.vue';
import { genderGetValues } from 'segic_shared_front_end_utils/src/enums/gender';
import { smokerStateGetValues } from 'segic_shared_front_end_utils/src/enums/smoker-state';
import { languageCodeClientGetValues } from 'segic_shared_front_end_utils/src/enums/language-codes-client-portal';
import { civilStatusGetValues, isCommonLawSpouse } from 'segic_shared_front_end_utils/src/enums/civil-status';
import SFieldCheckbox from '@/components/form/fields/checkbox.vue';
import { getMessageContentTypeValuePerCode, MESSAGE_CONTENT_TYPE_CONSTANT } from 'segic_shared_front_end_utils/src/enums/message-content-type';
import FormSimpleMixin from '@/mixins/form-simple';
import { MASKS } from '@/utils/mask';
import StoreExtender from '@/store/extender';
import STORE_CONSTANT_NAMES from '@/store/names';
import CertificateTemplatesStore from '@/store/groups/certificateTemplates';
import ComponentMixins from '@/mixins/segic-component';
import { GENERATION_MODE_CONSTANT } from 'segic_shared_front_end_utils/src/enums/generation-mode';
import HTTP_CODES from 'segic_shared_front_end_utils/src/constants/http-codes';
import { DEFAULT_ADULT_AGE, MAX_AGE, MIN_DATE_OF_BIRTH } from 'segic_shared_front_end_utils/src/constants/age';
import { nowMinusYears, today } from 'segic_shared_front_end_utils/src/utils/moment';
import StepperFormMixin from './mixins';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantFormSectionsPersonalInformation',
    components: {
        SFieldCheckbox,
        SFieldSelect,
        SFieldDatepicker,
        SFieldText,
    },
    mixins: [
        ComponentMixins,
        FormSimpleMixin,
        StepperFormMixin,
    ],
    data() {
        return {
            MESSAGE_CONTENT_TYPE_CONSTANT,
            hasCertificateField: false,
            MASKS,
            DEFAULT_ADULT_AGE,
            MIN_DATE_OF_BIRTH,
            MAX_AGE,
            nowMinusYears,
            today: today('YYYY-MM-DD'),
        };
    },
    mounted() {
        this.getCertificateTemplates();
        this.emitCurrentEnabledNextBtn();
    },
    methods: {
        emitCurrentEnabledNextBtn() {
            this.emitEnabledNextBtn(this.stepIndex, Boolean(this.overwriteData?.id));
        },
        async getCertificateTemplates() {
            const store = new StoreExtender(this, STORE_CONSTANT_NAMES.GROUPS.CERTIFICATE_TEMPLATES, CertificateTemplatesStore);
            const response = await store.sendActions('get');
            this.hasCertificateField = response.code === HTTP_CODES.NO_CONTENT || response.data.generationMode === GENERATION_MODE_CONSTANT.MANUALLY;
        },
        genderGetValues,
        smokerStateGetValues,
        languageCodeClientGetValues,
        civilStatusGetValues,
        getMessageContentTypeValuePerCode,
        isCommonLawSpouse,
    },
};

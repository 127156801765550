import EMPLOYE_PORTAL_CODES from '@/mixins/security/codes/employer-portal';
import StoreBasics from '@/store/basics';
import storeUtils from '@/store/utils';
import { cultureName } from '@/utils/data-formatting/service-input/i18n';
import modifyLocale from '@/utils/localeHelper';
import { filter, find, forEach, map, merge, } from 'lodash';
import SETTINGS from 'segic_shared_front_end_utils/src/constants/settings';
import LANGUAGE_CODES from 'segic_shared_front_end_utils/src/enums/language-codes';
import { formatClient, formatGroup } from './formatting';
import Service from './service';
const cacheDelay = -1;
const storeData = [
    {
        key: 'data',
        defaultValue: null,
        methods: [
            {
                key: 'get',
                cacheDelay,
                inputFormatting(state, stateKey, data) {
                    if (data) {
                        /* set lang */
                        state.lang.value = data.securityPrincipal.language;
                        modifyLocale(find(LANGUAGE_CODES.getValues(SETTINGS.LANGUAGE), { Code: state.lang.value }).Key);
                        // /* set security */
                        const securityPermissionSets = [];
                        forEach(data.securityPermissionSets, (securityPermission) => {
                            // eslint-disable-next-line consistent-return
                            forEach(EMPLOYE_PORTAL_CODES, (id) => {
                                if (securityPermission.resourceId === id) {
                                    securityPermissionSets.push(securityPermission);
                                    return false;
                                }
                            });
                        });
                        state.security.value = securityPermissionSets;
                        /* Groups */
                        const groups = [];
                        forEach(securityPermissionSets, (permissionSet) => {
                            const group = find(data.accessibleGroups, (item) => item.id === permissionSet.groupId && item.clientId === permissionSet.clientId);
                            let isInGroup = null;
                            if (group) {
                                isInGroup = find(groups, {
                                    Id: group.id,
                                });
                            }
                            if (group && !isInGroup) {
                                const client = find(data.accessibleClients, { id: group.clientId });
                                groups.push({
                                    Id: group.id,
                                    Name: cultureName(group.name),
                                    Code: group.code,
                                    ClientId: group.clientId,
                                    ClientName: client.name ? cultureName(client.name) : '',
                                    ClientCode: client.code,
                                });
                            }
                        });
                        data.accessibleGroups = groups;
                        state.groups.value = groups;
                    }
                    state[stateKey].value = data;
                },
            },
        ],
        service: Service,
    },
    {
        key: 'lang',
        defaultValue: LANGUAGE_CODES.CONSTANT.FR,
        methods: [
            {
                key: 'getLanguage',
                cacheDelay,
                displayFormatting(data) {
                    return find(LANGUAGE_CODES.getValues(SETTINGS.LANGUAGE), { Code: data });
                },
                inputFormatting(state, stateKey, data) {
                    state[stateKey].value = data.languageCode;
                    modifyLocale(find(LANGUAGE_CODES.getValues(SETTINGS.LANGUAGE), { Code: state[stateKey].value }).Key);
                },
            },
        ],
        service: Service,
    },
    {
        key: 'clients',
        defaultValue: [],
        additionalGetters: [
            {
                key: 'clients',
                displayFormatting(data) {
                    return map(data, (client) => formatClient(client));
                },
            },
        ],
    },
    {
        key: 'groups',
        defaultValue: [],
        additionalGetters: [
            {
                key: 'groups',
                displayFormatting(data) {
                    return map(data, (group) => formatGroup(group));
                },
            },
        ],
    },
    {
        key: 'security',
        defaultValue: [],
        additionalGetters: [
            {
                key: 'security',
            },
        ],
    },
];
const state = storeUtils.mergeStates(StoreBasics.statesFromData(storeData));
const getters = storeUtils.mergeGetters(StoreBasics.gettersFromData(storeData), {
    // eslint-disable-next-line no-shadow
    client(state) {
        return (code) => {
            const client = find(state.clients.value, ['Code', code]);
            return formatClient(client);
        };
    },
    // eslint-disable-next-line no-shadow
    group(state) {
        return (data) => {
            const group = find(state.groups.value, {
                ClientCode: data.clientCode,
                Code: data.groupCode,
            });
            return formatGroup(group);
        };
    },
    // eslint-disable-next-line no-shadow
    groupsByClientCode(state) {
        return (code) => {
            const groups = filter(state.groups.value, ['ClientCode', code]);
            return map(groups, (group) => formatGroup(group));
        };
    },
});
const mutations = storeUtils.mergeMutations(StoreBasics.mutationsFromData(storeData));
const actions = storeUtils.mergeActions(StoreBasics.actionsFromData(storeData), {
    setUserLanguage(context, payload) {
        const deferred = storeUtils.createDefer();
        const mutationPayload = merge({}, payload);
        mutationPayload.outputData = merge({}, mutationPayload.data);
        context.commit('setLanguage', mutationPayload);
        const serviceCall = Service.updateLanguage(mutationPayload.data, {});
        serviceCall
            .then(() => {
            deferred.resolve(context.getters.get(payload));
        })
            .catch((response) => {
            console.error(response);
            deferred.reject(context.getters.get(payload));
        });
        return storeUtils.returnDeferred(deferred, serviceCall, context, payload);
    },
    setPageLanguage(context, payload) {
        const mutationPayload = merge({}, payload);
        mutationPayload.outputData = merge({}, mutationPayload.data);
        context.commit('setLanguage', mutationPayload);
    },
});
const GlobalUserStore = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default GlobalUserStore;

import { getEnumValue, getEnumValues, toValuesEnum } from './_utils';
export var CIVIL_STATUS_CONSTANT;
(function (CIVIL_STATUS_CONSTANT) {
    CIVIL_STATUS_CONSTANT[CIVIL_STATUS_CONSTANT["SINGLE"] = 1] = "SINGLE";
    CIVIL_STATUS_CONSTANT[CIVIL_STATUS_CONSTANT["COMMON_LAW_SPOUSE"] = 2] = "COMMON_LAW_SPOUSE";
    CIVIL_STATUS_CONSTANT[CIVIL_STATUS_CONSTANT["DIVORCE"] = 3] = "DIVORCE";
    CIVIL_STATUS_CONSTANT[CIVIL_STATUS_CONSTANT["MARRIED"] = 4] = "MARRIED";
    CIVIL_STATUS_CONSTANT[CIVIL_STATUS_CONSTANT["LEGALLY_SEPERATED"] = 5] = "LEGALLY_SEPERATED";
    CIVIL_STATUS_CONSTANT[CIVIL_STATUS_CONSTANT["JUDICIAL_SEPERATION"] = 6] = "JUDICIAL_SEPERATION";
    CIVIL_STATUS_CONSTANT[CIVIL_STATUS_CONSTANT["WIDOWED"] = 7] = "WIDOWED";
})(CIVIL_STATUS_CONSTANT || (CIVIL_STATUS_CONSTANT = {}));
;
const values = toValuesEnum(CIVIL_STATUS_CONSTANT);
const FR = {
    [CIVIL_STATUS_CONSTANT.SINGLE]: 'Célibataire',
    [CIVIL_STATUS_CONSTANT.COMMON_LAW_SPOUSE]: 'Conjoint(e) de fait',
    [CIVIL_STATUS_CONSTANT.DIVORCE]: 'Divorcé(e)',
    [CIVIL_STATUS_CONSTANT.MARRIED]: 'Marié(e)',
    [CIVIL_STATUS_CONSTANT.LEGALLY_SEPERATED]: 'Séparation légale',
    [CIVIL_STATUS_CONSTANT.JUDICIAL_SEPERATION]: 'Séparation de corps',
    [CIVIL_STATUS_CONSTANT.WIDOWED]: 'Veuf/Veuve',
};
const EN = {
    [CIVIL_STATUS_CONSTANT.SINGLE]: 'Single',
    [CIVIL_STATUS_CONSTANT.COMMON_LAW_SPOUSE]: 'Common-law partner',
    [CIVIL_STATUS_CONSTANT.DIVORCE]: 'Divorced',
    [CIVIL_STATUS_CONSTANT.MARRIED]: 'Married',
    [CIVIL_STATUS_CONSTANT.LEGALLY_SEPERATED]: 'Legally separated',
    [CIVIL_STATUS_CONSTANT.JUDICIAL_SEPERATION]: 'Judicially separated',
    [CIVIL_STATUS_CONSTANT.WIDOWED]: 'Widower',
};
const Dictionaries = {
    fr: FR,
    en: EN,
};
export function isCommonLawSpouse(civilStatus) {
    return civilStatus === CIVIL_STATUS_CONSTANT.COMMON_LAW_SPOUSE;
}
export function civilStatusGetValues(lang) {
    return getEnumValues(values, Dictionaries, lang);
}
function getValuePerCode(code, lang) {
    return getEnumValue(values, Dictionaries, lang, code);
}
const CIVIL_STATUS = {
    CONSTANT: CIVIL_STATUS_CONSTANT,
    getValues: civilStatusGetValues,
    getValuePerCode,
};
export default CIVIL_STATUS;

export default {
    name: 'SHeaderFileValidation',
    props: {
        errorCount: {
            required: true,
            type: Number,
        },
        validationResultsLength: {
            required: true,
            type: Number,
        },
    },
};

import Store from '@/store/index';
// import STORE_CONSTANT_NAMES from "@/store-constants/names";
import Utils from '@/store/utils';
import { forEach, isEqual, merge } from 'lodash';
import moment from 'moment';
import HTTP_CODES from 'segic_shared_front_end_utils/src/constants/http-codes';
import MESSAGE_TYPE from 'segic_shared_front_end_utils/src/constants/message-types';
// eslint-disable-next-line import/prefer-default-export
export function actionTemplate(context, payload, service, method, stateKey, defaultValue, params, formatting, cacheDelay, cacheBust) {
    const mutationKey = method.replace('get', 'set');
    const finalDefaultValue = (typeof defaultValue !== 'undefined') ? defaultValue : [];
    const finalParams = (params) || null;
    const namespace = payload.actionNamespace;
    const cacheName = `${namespace}|${stateKey}`;
    const cacheReference = `${namespace}|${method}`;
    const deferred = Utils.createDefer();
    let serviceCall = null;
    const promiseResponse = {
        code: 200,
    };
    payload.getterParams = {};
    payload.getterParams.ignoreDisplay = true;
    // const promiseGet = context.getters.getPromise(method);
    const storeCache = Store.state.storesCaching[cacheName];
    if (storeCache) {
        if (!isEqual(storeCache.query, payload.query) || !isEqual(storeCache.data, payload.data)) {
            if (payload.debug) {
                console.log(cacheName, storeCache.query, payload.query);
                console.log('remove my cache cause of query', cacheName);
            }
            delete Store.state.storesCaching[cacheName];
            if (context.getters[`${method}IsLoading`]) {
                context.commit(`${mutationKey}IsLoading`, false);
                // promiseGet.reject();
            }
        }
    }
    if (context.getters[`${method}IsLoading`]) {
        const isLoadingInterval = setInterval(() => {
            if (!context.getters[`${method}IsLoading`]) {
                clearInterval(isLoadingInterval);
                promiseResponse.code = HTTP_CODES.NOT_MODIFIED;
                promiseResponse.data = context.getters[method](payload);
                deferred.resolve(promiseResponse);
            }
        }, 200);
    }
    else {
        let giveOldData = false;
        if (Store.state.storesCaching[cacheName]) {
            if (Store.state.storesCaching[cacheName].expiring === -1) {
                giveOldData = true;
            }
            else if (moment().diff(Store.state.storesCaching[cacheName].expiring) < 0) {
                giveOldData = true;
            }
        }
        if (cacheDelay && giveOldData) {
            promiseResponse.code = HTTP_CODES.NOT_MODIFIED;
            promiseResponse.data = context.getters[method](payload);
            deferred.resolve(promiseResponse);
        }
        else {
            if (formatting && typeof formatting === 'function' && payload.data) {
                payload.data = formatting.call(payload.scope, payload.data, payload);
            }
            const serviceParams = [];
            forEach(finalParams, (param) => {
                if (param === 'data') {
                    serviceParams.push(payload.data);
                }
                else if (typeof payload.params?.[param] !== 'undefined') {
                    serviceParams.push(payload.params[param]);
                }
                else if (typeof payload.data?.[param] !== 'undefined') {
                    serviceParams.push(payload.data[param]);
                }
                else {
                    console.warn('Params don\'t exist :: ', param);
                }
            });
            serviceParams.push(payload.query);
            serviceParams.push(method);
            if (payload.isResettingData) {
                context.commit(mutationKey, finalDefaultValue);
            }
            context.commit(`${mutationKey}IsLoading`, true);
            Utils.createCache(cacheDelay, cacheName, cacheReference, payload);
            // eslint-disable-next-line prefer-spread
            serviceCall = service[method].apply(service, serviceParams);
            serviceCall
                .then((response) => {
                const resultData = Utils.getAllThenResponse(response, finalDefaultValue);
                if (response.code !== (HTTP_CODES.CREATED || HTTP_CODES.MODIFIED)) {
                    const mutationPayload = merge({}, payload);
                    mutationPayload.outputData = merge({}, mutationPayload.data);
                    mutationPayload.data = resultData;
                    if (response.headers) {
                        mutationPayload.headers = response.headers;
                    }
                    context.commit(mutationKey, mutationPayload);
                }
                promiseResponse.code = response.code;
                promiseResponse.data = context.getters[method](payload);
                promiseResponse.message = response.message;
                promiseResponse.messageType = response.messageType;
                if (response.code === HTTP_CODES.CREATED) {
                    let createdID = (typeof resultData === 'string') ? resultData : null;
                    createdID = (!createdID && resultData) ? resultData.Id : null;
                    promiseResponse.createdID = createdID;
                }
                if (typeof payload.options.cacheBust !== 'undefined') {
                    // eslint-disable-next-line no-param-reassign
                    cacheBust = payload.options.cacheBust;
                }
                Utils.bustCache(cacheBust, namespace, payload);
                Utils.createCache(cacheDelay, cacheName, cacheReference, payload);
                // if (payload.scope && typeof cacheBust === 'object' && cacheBust.others && cacheBust.others.indexOf(STORE_CONSTANT_NAMES.GLOBALS.USER) !== -1) {
                //     payload.scope.userStore.sendActions('get').then(() => {
                //         context.commit(`${mutationKey}IsLoading`, false);
                //         deferred.resolve(promiseResponse);
                //     });
                //     return;
                // }
                context.commit(`${mutationKey}IsLoading`, false);
                deferred.resolve(promiseResponse);
            })
                .catch((response) => {
                Utils.getAllCatchResponse(response, payload);
                promiseResponse.messageType = MESSAGE_TYPE.ERROR;
                promiseResponse.code = HTTP_CODES.INTERNAL_SERVER_ERROR;
                if (response) {
                    console.error(response);
                    promiseResponse.code = response.code;
                    promiseResponse.data = context.getters[method](payload);
                    promiseResponse.message = response.message;
                    promiseResponse.internalCode = response.internalCode;
                }
                context.commit(`${mutationKey}IsLoading`, false);
                delete Store.state.storesCaching[cacheName];
                deferred.reject(promiseResponse);
            });
        }
    }
    return Utils.returnDeferred(deferred, serviceCall, context, payload);
}

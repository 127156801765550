var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"s-import-validation"},[_c('VContainer',{attrs:{"fluid":""}},[_c('VRow',[_c('VCol',[_c('SComponentDatatableBase',{attrs:{"fluid":true,"headers":_vm.headers,"hide-default-footer":_vm.items.length <= 100,"items":_vm.items,"items-per-page":100,"items-per-page-options":[100, 200, 300, -1],"classes":"s-tighter-table-cells","minWidth":"1000","name-e2-e-suffix":"invitations"},scopedSlots:_vm._u([{key:"topHeader",fn:function(){return [_c('SHeaderFileValidation',{attrs:{"errorCount":_vm.errorCount,"validationResultsLength":_vm.validationResults.length}})]},proxy:true},{key:"certificateNumber",fn:function(ref){
var certificateNumber = ref.item.certificateNumber;
return [(certificateNumber)?_c('SCellError',{attrs:{"has-error":certificateNumber.hasError,"text":certificateNumber.text}}):_vm._e()]}},{key:"participantDetails",fn:function(ref){
var ref_item_participantDetails = ref.item.participantDetails;
var lastName = ref_item_participantDetails.lastName;
var firstName = ref_item_participantDetails.firstName;
return [_c('SPageLayoutOnBoardingCellParticipantName',{attrs:{"first-name":firstName,"last-name":lastName}})]}},{key:"division",fn:function(ref){
var division = ref.item.division.division;
return [_c('div',{staticClass:"s-font-11"},[_c('SCellText',{attrs:{"text":division}})],1)]}},{key:"mappingCode",fn:function(ref){
var mappingCode = ref.item.mappingCode;
return [_c('div',{staticClass:"s-font-11"},[_c('SCellError',{attrs:{"hasError":mappingCode.hasError,"text":mappingCode.text}})],1)]}},{key:"notes",fn:function(ref){
var errors = ref.item.errors;
return [_c('SCellNotes',{attrs:{"errors":errors}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
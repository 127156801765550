import { isHub } from '@/utils/hub';
import MESSAGE_CONTENT_TYPE from 'segic_shared_front_end_utils/src/enums/message-content-type';
export function isFileRequired(formData) {
    const contentType = formData?.contentType;
    const participantRegistration = formData?.content?.participantRegistration;
    const clientId = formData?.clientId;
    return (contentType === MESSAGE_CONTENT_TYPE.CONSTANT.PARTICIPANT_CREATION_REQUEST && !participantRegistration)
        || contentType === MESSAGE_CONTENT_TYPE.CONSTANT.SPOUSE_ATTACHMENT_CHANGE_REQUEST
        || contentType === MESSAGE_CONTENT_TYPE.CONSTANT.CHILD_ATTACHMENT_CHANGE_REQUEST
        || contentType === MESSAGE_CONTENT_TYPE.CONSTANT.CHILD_SCHOOL_ATTENDENCE_ATTACHMENT_CHANGE_REQUEST
        || isHub(clientId);
}

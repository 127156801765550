import MicroServices from '@/configs/MicroServices.types';
import proxy from '@/store/proxy';
import { getQueryString, replaceStringRoutingData, } from 'segic_shared_front_end_utils/src/utils/routing';
import messages from './locales';
export default {
    namespace: 'reports.R001',
    uri: '/clients/:{clientGuid}/groups/:{groupGuid}/participants/:{participantGuid}/R001/',
    get(clientGuid, groupGuid, participantGuid, data, query) {
        const methodName = 'get';
        const service = MicroServices.REPORTING_MS;
        const params = {
            clientGuid,
            groupGuid,
            participantGuid,
        };
        const url = this.uri;
        const replacedUrl = replaceStringRoutingData(`${url}${getQueryString(query)}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
            data,
        };
        return proxy.post(replacedUrl, data, requestData);
    },
};

import ComponentMixins from '@/mixins/segic-component';
export default {
    name: 'SComponentGlobalLoader',
    mixins: [ComponentMixins],
    i18n: {
        messages: {
            fr: {
                altText: 'Icon de chargement en forme de coeur',
            },
            en: {
                altText: 'Heart shaped loader icon',
            },
        },
    },
};

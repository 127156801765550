import BCP_LANGUAGE_CODES from './bcp-language-codes';
const SETTINGS = {
    // @ts-ignore
    VERSION: window.version,
    C_URL: `${window.location.origin}/`,
    LANGUAGE: process.env.VUE_APP_I18N_LOCALE || 'fr',
    FALLBACK_LANGUAGE: process.env.VUE_APP_I18N_LOCALE || 'fr',
    LANGUAGES: ['fr', 'en'],
    CULTURE_NAME: [BCP_LANGUAGE_CODES.fr, BCP_LANGUAGE_CODES.en],
};
export default SETTINGS;

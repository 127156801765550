// eslint-disable-next-line no-shadow
var PAYROLL_FILES_CONVERSION_CONFIGURATION_STATUS;
(function (PAYROLL_FILES_CONVERSION_CONFIGURATION_STATUS) {
    PAYROLL_FILES_CONVERSION_CONFIGURATION_STATUS[PAYROLL_FILES_CONVERSION_CONFIGURATION_STATUS["UNINITIALIZED"] = 0] = "UNINITIALIZED";
    PAYROLL_FILES_CONVERSION_CONFIGURATION_STATUS[PAYROLL_FILES_CONVERSION_CONFIGURATION_STATUS["IN_PROGRESS"] = 1] = "IN_PROGRESS";
    PAYROLL_FILES_CONVERSION_CONFIGURATION_STATUS[PAYROLL_FILES_CONVERSION_CONFIGURATION_STATUS["COMPLETED"] = 2] = "COMPLETED";
})(PAYROLL_FILES_CONVERSION_CONFIGURATION_STATUS || (PAYROLL_FILES_CONVERSION_CONFIGURATION_STATUS = {}));
export default PAYROLL_FILES_CONVERSION_CONFIGURATION_STATUS;
export function isConfigurationStatusCompleted(status) {
    return status === PAYROLL_FILES_CONVERSION_CONFIGURATION_STATUS.COMPLETED;
}
export function isConfigurationStatusInProgress(status) {
    return status === PAYROLL_FILES_CONVERSION_CONFIGURATION_STATUS.IN_PROGRESS;
}
export function isConfigurationStatusUninitialized(status) {
    return status === PAYROLL_FILES_CONVERSION_CONFIGURATION_STATUS.UNINITIALIZED;
}

import SComponentDatatableSearchFilter from '@/components/datatables/search-filter.vue';
import SegicComponent from '@/mixins/segic-component';
import { getNestedObject } from '@/utils/helpers';
import { searchWithQueryKey, sortItems, updateSearch } from 'segic_shared_front_end_utils/src/utils/datatable';
import { isArray, isObject } from 'segic_shared_front_end_utils/src/utils/types';
export default {
    name: 'SComponentDatatableBase',
    components: {
        SComponentDatatableSearchFilter,
    },
    mixins: [SegicComponent],
    props: {
        disableSort: {
            default: false,
            type: Boolean,
        },
        e2eKey: {
            default: 'certificateNumber',
        },
        headers: {
            required: true,
        },
        hideDefaultFooter: {
            default: false,
            type: Boolean,
        },
        hideTop: {
            default: false,
        },
        outlined: {
            default: false,
            type: Boolean,
        },
        flat: {
            default: false,
            type: Boolean,
        },
        dense: {
            default: false,
            type: Boolean,
        },
        classes: {
            default: '',
            type: String,
        },
        minWidth: {
            default: '',
            type: String,
        },
        items: {
            required: true,
        },
        itemsPerPage: {
            default: 10,
        },
        itemsPerPageOptions: {
            type: Array,
            default: () => {
                // eslint-disable-next-line no-unused-expressions
                [5, 10, 15, -1];
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
        actionLoadingItems: {
            default: null,
        },
        fluid: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            search: '',
            getNestedObject,
        };
    },
    computed: {
        getNoDataText() {
            return this.$t('noData');
        },
        getNoResultsText() {
            return this.$t('noResult');
        },
        getLoadingText() {
            return this.$t('loadingText');
        },
        footerProps() {
            return {
                'items-per-page-text': this.$t('rowPerPageLabel'),
                'page-text': this.$t('paging'),
                'items-per-page-options': this.itemsPerPageOptions,
            };
        },
    },
    methods: {
        sortItems,
        updateSearch,
        isSlot(slotName) {
            return !!this.$slots[slotName] || !!this.$scopedSlots[slotName];
        },
        isStatus(item) {
            return isObject(item) && typeof item.status === 'number' && item.text;
        },
        isComponent(item) {
            return isObject(item) && item.component;
        },
        isAction(item, headerValue) {
            return isArray(item) && headerValue === 'actions';
        },
        event(event, data, index, headerValue, eventValue) {
            this.$emit('event', {
                event,
                data,
                index,
                headerValue,
                eventValue,
            });
        },
        customFilter(value, search, item) {
            return searchWithQueryKey(value, search, item, this.$i18n.locale);
        },
        isActionLoading(item, header) {
            const idActionLoadingKey = header?.props?.idLoadingKey ? header?.props?.idLoadingKey : 'IdActionLoading';
            return this.actionLoadingItems && item[idActionLoadingKey] && this.actionLoadingItems.indexOf(item[idActionLoadingKey]) !== -1;
        },
    },
    i18n: {
        messages: {
            fr: {
                noData: 'Aucune donnée disponible',
                noResult: 'Aucune donnée trouvée',
                rowPerPageItemAll: 'Tout',
                rowPerPageLabel: 'Entrée par page :',
                paging: '{0}-{1} sur {2}',
                loadingText: 'Chargement',
                searchLabel: 'Filtrer les résultats',
            },
            en: {
                noData: 'No data available',
                noResult: 'No matching records found',
                rowPerPageItemAll: 'All',
                rowPerPageLabel: 'Rows per page:',
                paging: '{0}-{1} of {2}',
                loadingText: 'Loading items',
                searchLabel: 'Filter results',
            },
        },
    },
};

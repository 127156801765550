import { PAYROLL_INTEGRATION_TYPES } from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/form.types';
import { isEqual, uniqWith } from 'lodash';
export default function hasNewIntegration(initialRecipients, currentRecipients) {
    // We only want to compare 2 arrays of recipients by 2 properties: integrationType and mappingCode
    // We consider 2 recipients equal if they have the same integrationType and mappingCode
    const normalizeArray = function (arr) {
        return uniqWith(arr.filter((integrations) => integrations.integrationType !== PAYROLL_INTEGRATION_TYPES.NONE).map((obj) => JSON.stringify({
            integrationType: obj.integrationType,
            mappingCode: obj.mappingCode,
        })), isEqual);
    };
    return normalizeArray(currentRecipients).length > normalizeArray(initialRecipients).length;
}

import SegicClientLogo from '@/assets/images/segicClientLogo.png';
import storeMixin from '@/mixins/page-layout/store';
import SegicComponent from '@/mixins/segic-component';
import SETTINGS from 'segic_shared_front_end_utils/src/constants/settings';
import StoreExtender from '@/store/extender';
import { find, isEmpty, uniqBy } from 'lodash';
import STORE_CONSTANT_NAMES from '@/store/names';
import CLientLogoStore from '@/store/clients/logo';
/**
   * @displayName Logo Client
   * */
export default {
    name: 'SComponentLogo',
    mixins: [
        SegicComponent,
        storeMixin,
    ],
    data() {
        return {
            logoStore: null,
        };
    },
    computed: {
        isLogoLoading() {
            return this.logoStore ? this.logoStore.getGetter('getIsLoading') : false;
        },
        logo() {
            let logo = null;
            if (this.logoStore && !this.isLogoLoading) {
                const logos = this.logoStore.getGetter('get');
                if (!isEmpty(logos)) {
                    if (logos[this.$i18n.locale]) {
                        logo = logos[this.$i18n.locale];
                    }
                    else {
                        const otherLanguage = find(SETTINGS.LANGUAGES, (language) => language !== this.$i18n.locale);
                        if (logos[otherLanguage]) {
                            logo = logos[otherLanguage];
                        }
                    }
                }
                else {
                    logo = SegicClientLogo;
                }
            }
            return logo;
        },
        groups() {
            return this.userStore ? this.userStore.getGetter('groups') : [];
        },
        setAltLogoText() {
            if (this.logo === SegicClientLogo) {
                return this.$t('logoAltText');
            }
            const group = find(this.groups, ['ClientId', this.getClientGuid()]);
            return group ? group.ClientName : '';
        },
    },
    methods: {
        clientsUniq() {
            return uniqBy(this.groups, 'ClientId');
        },
        nbClients() {
            return this.clientsUniq().length;
        },
        getClientGuid() {
            let clientGuid = null;
            if (this.$route?.params?.clientGuid) {
                clientGuid = this.$route.params.clientGuid;
            }
            else if (this.nbClients() === 1) {
                clientGuid = this.clientsUniq()[0].ClientId;
            }
            return clientGuid;
        },
        startLoadingData() {
            const clientGuid = this.getClientGuid();
            if (clientGuid) {
                const extraParams = {
                    clientGuid,
                };
                this.logoStore = new StoreExtender(this, STORE_CONSTANT_NAMES.CLIENTS.LOGO, CLientLogoStore, { extraParams });
                const payload = {
                    params: {
                        clientGuid,
                    },
                };
                this.logoStore.sendActions('get', payload);
            }
        },
    },
    i18n: {
        messages: {
            fr: {
                logoAltText: 'Segic, innovation en assurance et santé collectives',
            },
            en: {
                logoAltText: 'Segic, innovation in global insurance and healthcare.',
            },
        },
    },
};

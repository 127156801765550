import SFieldText from '@/components/form/fields/text.vue';
import FieldsMixin from '@/mixins/components/fields';
import ComponentMixins from '@/mixins/segic-component';
import { MASKS } from '@/utils/mask/index';
import moment from 'moment';
import SLabelForm from '@/components/ui/label-form.vue';
/**
     * @displayName DatePicker
     * */
export default {
    name: 'SFieldDatepicker',
    components: {
        SFieldText,
        SLabelForm,
    },
    mixins: [
        ComponentMixins,
        FieldsMixin,
    ],
    props: {
        /**
         * Restricts which dates can be selected.
         */
        allowedDates: {
            default: null,
            type: Function,
        },
        /**
         * Array of dates or object defining events or colors or function returning boolean/color/array of colors.
         */
        events: {
            default: null,
            type: [Array, Function, Object],
        },
        /**
         * Event color.
         */
        eventColor: {
            default: 'warning',
            type: String,
        },
        initialModel: {},
        /**
         * Minimum allowed date/month (ISO 8601 format).
         */
        min: {},
        /**
         * Maximum allowed date/month (ISO 8601 format).
         */
        max: {},
        /**
         * Determines the type of the picker.
         * @values date, month
         */
        type: {
            default: 'date',
            type: String,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        // Add color to input
        backgroundColor: {
            default: '',
            type: String,
        },
        width: {
            default: null,
            type: [String, Number],
        },
        isSelectByYear: {
            default: false,
            type: Boolean,
        },
        tooltipText: {
            default: null,
            type: String,
        },
    },
    data() {
        return {
            menu: false,
            lang: this.$i18n.locale,
            x: 0,
            y: 0,
            MASKS,
        };
    },
    computed: {
        modelMoment() {
            const today = moment();
            let inputDate = moment(this.model, this.$t('format.date.view'));
            if (!inputDate.isValid()) {
                inputDate = today;
            }
            return inputDate.format(this.$t('format.date.view'));
        },
        formattedRules() {
            let rules = 'date';
            if (this.rules) {
                rules += `|${this.rules}`;
            }
            return rules;
        },
    },
    watch: {
        menu(val) {
            if (this.isSelectByYear) {
                // eslint-disable-next-line no-unused-expressions,no-return-assign
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
            }
        },
    },
    methods: {
        /**
         * Show picker.
         * @public
         */
        togglePicker(event) {
            event.preventDefault();
            event.stopPropagation();
            this.x = event.clientX - 10;
            this.y = event.clientY - 10;
            this.menu = !this.menu;
        },
        /**
         * Update value and close the date picker.
         * @param {String} value Text updated
         * @public
         */
        updateAndClose(value) {
            this.update(value);
            this.menu = false;
            this.emitValidationOnUpdateNewFlags(value);
        },
        emitValidationOnUpdateNewFlags(value) {
            const validationFlags = this.setValidationFlags(value);
            this.emitValidationOnUpdate(validationFlags);
        },
        setValidationFlags(value) {
            const format = value?.indexOf('-') !== -1 ? this.$t('format.date.view') : 'YYYYMMDD';
            const inputDate = moment(value, format, true);
            let valid = inputDate.isValid();
            if (this.formattedRules.indexOf('required') === -1 && value === '') {
                valid = true;
            }
            const data = {
                name: this.name,
                flags: {
                    changed: false,
                    dirty: false,
                    failed: false,
                    invalid: false,
                    passed: true,
                    pending: false,
                    pristine: true,
                    required: true,
                    touched: true,
                    untouched: false,
                    valid,
                    validated: true,
                },
                validationProviderName: this.validationProviderName,
            };
            return data;
        },
        updateCustom(value) {
            this.update(value);
            this.emitValidationOnUpdateNewFlags(value);
        },
    },
};

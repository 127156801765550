import SFieldText from '@/components/form/fields/text.vue';
import SFieldSelect from '@/components/form/fields/select.vue';
import SPhones from '@/components/form/fields/phones.vue';
import { provincesSelectData } from 'segic_shared_front_end_utils/src/data-dump/provinces';
import SLabelForm from '@/components/ui/label-form.vue';
import { MASKS } from '@/utils/mask';
import { getMessageContentTypeValuePerCode, MESSAGE_CONTENT_TYPE_CONSTANT } from 'segic_shared_front_end_utils/src/enums/message-content-type';
import ComponentMixins from '@/mixins/segic-component';
import FormSimpleMixin from '@/mixins/form-simple';
import StepperFormMixin from '@/page-layouts/administrator/forms/add-participant/byForm/sections/mixins';
import { cloneDeep } from 'lodash';
import SCategoriesField from '@/components/form/fields/categories-select.vue';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantFormSectionsContactDetails',
    components: {
        SCategoriesField,
        SFieldText,
        SFieldSelect,
        SLabelForm,
        SPhones,
    },
    mixins: [
        ComponentMixins,
        FormSimpleMixin,
        StepperFormMixin,
    ],
    data() {
        return {
            MESSAGE_CONTENT_TYPE_CONSTANT,
            MASKS,
        };
    },
    computed: {
        phones() {
            return cloneDeep(this.formData.content.participantRegistration.phoneNumbers);
        },
    },
    mounted() {
        this.emitCurrentEnabledNextBtn();
    },
    methods: {
        emitCurrentEnabledNextBtn() {
            this.emitEnabledNextBtn(this.stepIndex, Boolean(this.overwriteData?.id));
        },
        getMessageContentTypeValuePerCode,
        provincesSelectData,
    },
    i18n: {
        messages: {
            fr: {
                labels: {
                    city: 'Ville',
                    civicAddress: 'Numéro civique et rue',
                    province: 'Province',
                    country: 'Pays',
                    postalCode: 'Code postal',
                },
                disclaimers: {
                    country: 'Note: Seules les adresses canadiennes sont acceptées.',
                },
                hints: {
                    email: 'Requis pour l\'accès au Portail Participant',
                },
            },
            en: {
                labels: {
                    city: 'City',
                    country: 'Country',
                    civicAddress: 'Civic number and street',
                    province: 'Province',
                    postalCode: 'Postal code',
                },
                disclaimers: {
                    country: 'Only Canadian addresses are accepted.',
                },
                hints: {
                    email: 'Required for access to the Member Portal',
                },
            },
        },
    },
};

export default {
    name: 'SComponentDialog',
    props: {
        contentClass: {
            type: String,
            default: '',
        },
        eager: {
            default: false,
            type: Boolean,
        },
        isShowed: {
            required: true,
            type: Boolean,
        },
        overflow: {
            type: Boolean,
            default: false,
        },
        scrollable: {
            default: false,
            type: Boolean,
        },
        subtitle: {
            default: null,
            type: String,
        },
        title: {
            default: '',
            type: String,
        },
        width: {
            type: String,
            default: '850px',
        },
        cardTextCss: {
            default: '',
            type: String,
        },
    },
    methods: {
        hasFooterSlot() {
            return !!this.$slots.footer;
        },
        isShowedUpdate(value) {
            this.$emit('update:isShowed', value);
        },
    },
};

export default {
    data() {
        return {
            nameE2E: '',
        };
    },
    props: {
        nameE2ESuffix: {},
    },
    mounted() {
        this.setName(this.$route);
        this.setE2EName();
    },
    computed: {
        cViewLastLayout() {
            return this.getLastSegicLayout(this.$parent);
        },
        cViewLastForm() {
            return this.getLastSegicForm(this.$parent);
        },
    },
    methods: {
        getLastSegicLayout(scope) {
            if (!scope) {
                return null;
            }
            if (scope.isSegicPageLayout) {
                return scope;
            }
            return this.getLastSegicLayout(scope.$parent);
        },
        getLastSegicForm(scope) {
            if (!scope) {
                return null;
            }
            if (scope.isSegicForm) {
                return scope;
            }
            return this.getLastSegicForm(scope.$parent);
        },
        /* eslint-disable no-underscore-dangle */
        setName(view) {
            let name = this.$options.name || '';
            this.$options._name = name;
            if (this.isSegicPageLayout) {
                name = 's-layout';
                if (view) {
                    name += `-${view.name.replace(/\./g, '_')}`;
                }
            }
            else if (this.isSegicLayoutComponent) {
                if (this.$options._componentTag) {
                    name = `${this.$options._componentTag.replace('s-', '')}`;
                }
                else if (this.$options._name) {
                    name = `${this.$options._name.replace('s-', '')}`;
                }
                if (this.cViewLastLayout) {
                    name = `${this.cViewLastLayout.$options.name}-${name}`;
                }
                else {
                    name = `s-app-${name}`;
                }
            }
            else if (this.isSegicComponent) {
                if (this.isSegicForm || this.isSegicDatatable) {
                    name = name.replace('s-component-', '');
                    if (this.cViewLastLayout) {
                        name = `${this.cViewLastLayout.$options.name}-${name}`;
                    }
                    else {
                        name = `s-app-${name}`;
                    }
                }
            }
            this.$options.name = name;
        },
        /* eslint-enable no-underscore-dangle */
        setE2EName() {
            let viewName = this.$options.name || '';
            const dataName = this.name;
            if (this.isSegicComponent) {
                if (this.cViewLastForm) {
                    viewName = `${this.cViewLastForm.$options.name}_${viewName.replace('s-component-', '')}`;
                }
            }
            if (dataName && !this.isSegicForm && !this.isSegicDatatable) {
                viewName += `_${dataName}`;
            }
            if (this.nameE2ESuffix) {
                viewName += `-${this.nameE2ESuffix}`;
            }
            this.nameE2E = viewName;
        },
    },
};

import SButtonSubmit from '@/components/ui/btn-submit.vue';
export default {
    components: {
        SButtonSubmit,
    },
    props: {
        allStepsValid: {
            type: Boolean,
            default: false,
        },
        formHasChangedValues: {
            type: Object,
            required: true,
        },
        handleSubmit: {
            type: Object,
            required: true,
        },
        hasReachLastStep: {
            type: Boolean,
            default: false,
        },
        isFormSaved: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isStepLoading: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            required: true,
        },
        model: {
            type: Number,
            default: 1,
        },
        nameE2E: {
            type: String,
            default: 's-stepper',
        },
        showNextButton: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        cancelText() {
            return this.isFormSaved ? this.$t('close') : this.$t('cancel');
        },
        disabledSubmitBtn() {
            return !this.allStepsValid || (this.isFormSaved && !this.hasReachLastStep) || (this.isFormSaved && !this.formHasChangedValues.hasChanged);
        },
        showSubmitButton() {
            return (this.model === this.items.length) || this.isFormSaved;
        },
    },
    methods: {
        cancel() {
            this.$parent.cancel();
        },
        setModel(value) {
            this.$parent.setModel(value);
        },
    },
};

import StoreBasics from '@/store/basics';
import storeUtils from '@/store/utils';
import { find, forEach } from 'lodash';
import STATUS from 'segic_shared_front_end_utils/src/enums/status';
import { isNullOrUndefined } from 'segic_shared_front_end_utils/src/utils/types';
import entities from './entities';
import Service from './service';
const cacheDelay = 0;
const storeData = [
    {
        key: 'data',
        defaultValue: [],
        methods: [
            {
                key: 'get',
                params: ['clientGuid', 'groupGuid'],
                cacheDelay,
                inputFormatting(state, stateKey, data) {
                    const newData = data.map((value) => entities.input(value));
                    state[stateKey].value = newData;
                },
            },
        ],
        service: Service,
    },
    {
        key: 'data2',
        defaultValue: [],
        methods: [
            {
                key: 'getComplete',
                params: ['clientGuid', 'groupGuid', 'data'],
                cacheDelay,
                inputFormatting(state, stateKey, data) {
                    const newData = [];
                    forEach(data, (item) => {
                        newData.push(entities.input(item));
                    });
                    state[stateKey].value = newData;
                },
            },
        ],
        service: Service,
    },
];
const state = storeUtils.mergeStates(StoreBasics.statesFromData(storeData));
const getters = storeUtils.mergeGetters(StoreBasics.gettersFromData(storeData), {
    // eslint-disable-next-line no-shadow
    getById(state) {
        return (CategoryId) => find(state.data.value, {
            CategoryId,
        });
    },
}, {
    // eslint-disable-next-line no-shadow
    getHistory(state) {
        return (data) => {
            if (isNullOrUndefined(data.CategoryId)) {
                return [];
            }
            const category = find(state.data.value, {
                CategoryId: data.CategoryId,
            });
            const history = [];
            if (category) {
                category.CategoryDetails.forEach((item) => {
                    history.push({
                        EffectiveDate: item.EffectiveDate,
                        Status: STATUS.getValuePerCode(item.Status, data.locale).Value,
                    });
                });
            }
            return history;
        };
    },
});
const mutations = storeUtils.mergeMutations(StoreBasics.mutationsFromData(storeData));
const actions = storeUtils.mergeActions(StoreBasics.actionsFromData(storeData));
const GroupsCategoriesStore = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default GroupsCategoriesStore;

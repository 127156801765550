import Logo from '@/components/ui/logo.vue';
import { EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES } from '@/mixins/security/codes/employer-portal';
import { getEmptyComponents } from '@/utils/page-layouts';
import SECURITY_PERMISSIONS from 'segic_shared_front_end_utils/src/enums-bitwise/security-permissions';
const routes = [];
const children = [];
const components = getEmptyComponents();
components.default = () => import(/* webpackChunkName: "dashboard" */ './index.vue');
routes.push({
    name: 'dashboard',
    path: '/',
    components,
    children,
    meta: {
        pageCss: 's-dashboard-page',
        resourcePermission: SECURITY_PERMISSIONS.VIEW,
        resource: EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.EMPLOYER_PARTICIPANT_AUTHORIZE_RESOURCE,
        hasDrawser: false,
        headerComponents: {
            left: {
                component: Logo,
            },
        },
        title: 'title.dashboard',
    },
});
export default routes;

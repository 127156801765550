import Vue from 'vue';
import VueI18n from 'vue-i18n';
import i18nFR from '@/locales/fr';
import i18nEN from '@/locales/en';
import REFERENCES from 'segic_shared_front_end_utils/src/constants/references';
import SETTINGS from 'segic_shared_front_end_utils/src/constants/settings';
const Dictionnaries = {
    fr: i18nFR,
    en: i18nEN,
};
Vue.use(VueI18n);
REFERENCES.VueI18n = VueI18n;
const I18n = new VueI18n({
    locale: SETTINGS.LANGUAGE,
    fallbackLocale: SETTINGS.LANGUAGE,
    messages: Dictionnaries,
    silentTranslationWarn: true,
});
export default I18n;

import ComponentMixins from '@/mixins/segic-component';
import StoreExtender from '@/store/extender';
import GroupsOccupationsStore from '@/store/groups/occupations';
import STORE_CONSTANT_NAMES from '@/store/names';
import SOccupationField from '@/components/form/fields/occupation-select-logic.vue';
import { sortWithAccents } from 'segic_shared_front_end_utils/src/utils/string';
export default {
    name: 'SOccupationsSelect',
    components: { SOccupationField },
    mixins: [ComponentMixins],
    props: {
        appendLabel: {
            default: null,
            type: String,
        },
        dense: {
            default: false,
            type: Boolean,
        },
        label: {
            default: null,
            type: String,
        },
        labelOnTop: {
            default: false,
            type: Boolean,
        },
        model: {
            required: true,
            type: Object,
        },
        name: {
            required: true,
            type: String,
        },
        occupationsToExclude: {
            default: () => [],
            type: Array,
        },
        outlined: {
            default: false,
            type: Boolean,
        },
        rules: {
            default: '',
            type: [Object, String],
        },
        singleLine: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            occupations: [],
            occupationIsLoading: false,
            store: null,
        };
    },
    mounted() {
        this.getOccupations();
    },
    methods: {
        async getOccupations() {
            this.occupations = [];
            this.occupationIsLoading = true;
            this.store = new StoreExtender(this, STORE_CONSTANT_NAMES.GROUPS.OCCUPATIONS, GroupsOccupationsStore);
            await this.store.sendActions('get');
            this.occupations = sortWithAccents(this.store.getGetter('get'), `description.${this.$i18n.locale}`);
            this.occupationIsLoading = false;
        },
        update(value, name) {
            this.$emit('update:model', value, name);
        },
    },
    i18n: {
        messages: {
            fr: {
                occupationHint: 'Veuillez saisir l\'occupation si elle n\'existe pas dans la liste',
                noOccupationData: 'Aucune donnée n\'a été trouvée pour <strong>{newValue}</strong>.<br/>Appuyez sur <kbd>Entrer</kbd> pour ajouter une nouvelle occupation à la liste.',
            },
            en: {
                occupationHint: 'Enter the occupation if it doesn\'t exist in the list',
                noOccupationData: 'No results matching <strong>{newValue}</strong>. Press <kbd>Enter</kbd> to create a new occupation.',
            },
        },
    },
};

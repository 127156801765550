export default {
    generic: {
        success: '',
        info: '',
        error: 'Une erreur est survenue.',
    },
    file: {
        virusCreated: `<div class="font-weight-bold s-font-16">Votre demande de changement n'a pas été envoyée.</div>
                <div class="mt-2">Ces fichiers attachés ont été identifiés comme malveillants par notre système :</div>
                <ul>
                {files}
                </ul>`,
        virusModified: `<div class="font-weight-bold s-font-16">Votre demande a été modifiée, mais certains fichiers n'ont pas pu être attachés.</div>
                <div class="mt-2">Ces fichiers attachés ont été identifiés comme suspects ou malveillants par notre système :</div>
                <ul>
                {files}
                </ul>`,
    },
};

import { getNestedObject } from '@/utils/helpers';
import ComponentMixins from '@/mixins/segic-component';
export default {
    name: 'SSlotTemplateSelectDefaultSelection',
    mixins: [ComponentMixins],
    props: {
        attrs: {},
        disabled: {},
        item: {
            required: true,
        },
        multiple: {
            type: Boolean,
        },
        readOnly: {},
        select: {},
        selected: {},
        itemText: {
            type: String,
        },
        itemValue: {
            type: String,
        },
    },
    methods: {
        getNestedObject,
        clickClose(item) {
            this.$emit('click:close', item);
        },
    },
};

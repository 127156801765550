import SButtonSubmit from '@/components/ui/btn-submit.vue';
import SStepper from '@/components/ui/stepper.vue';
import FormMixin from '@/mixins/form';
import storeMixin from '@/mixins/page-layout/store';
import SegicLayoutComponent from '@/mixins/segic-layout-component';
import DialogConfirmCancelMixin from '@/page-layouts/administrator/dialogs/confirm-cancel';
import SDialogConfirmCancel from '@/page-layouts/administrator/dialogs/confirm-cancel.vue';
import SDialogLoader from '@/page-layouts/administrator/forms/payroll-files-conversions/dialog-loader.vue';
import getMappingStepsComponents from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/getMappingStepsComponents';
import i18n from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/locales';
import { getExistingBenefitMapping } from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/mapping';
import StoreExtender from '@/store/extender';
import STORE_CONSTANT_NAMES from '@/store/names';
import PayrollFilesConversion from '@/store/payroll-files-conversion';
import { EventBus } from '@/utils/event-bus';
import { cloneDeep, get, isEmpty, isEqual, } from 'lodash';
import EVENTS from 'segic_shared_front_end_utils/src/constants/events';
import LANGUAGE_CODES from 'segic_shared_front_end_utils/src/enums/language-codes';
import { isNotNullOrUndefined } from 'segic_shared_front_end_utils/src/utils/types';
import hasNewIntegration from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/warnings/hasNewIntegration';
import STabHeader from './components/tab-header.vue';
import { PAYROLL_FILE_CONVERSION_STEPPER_TYPE } from './utils/form.types';
import handleRecipientsWarnings from './utils/warnings/handleRecipientsWarnings';
import handleWarnings from './utils/warnings/handleWarnings';
export default {
    name: 'SPageLayoutsParticipantsAddParticipantForm',
    components: {
        SButtonSubmit,
        SDialogLoader,
        SStepper,
        SDialogConfirmCancel,
    },
    mixins: [
        SegicLayoutComponent,
        FormMixin,
        storeMixin,
        DialogConfirmCancelMixin,
    ],
    props: {
        showPayrollFilesConversionsDialog: {
            required: true,
            type: Boolean,
        },
    },
    data() {
        return {
            formLoading: false,
            isAllFormStepsValid: false,
            isDataLoading: false,
            isFormSaved: false,
            isMappingTabsEnabled: true,
            isValidName: 'isValid',
            PAYROLL_FILE_CONVERSION_STEPPER_TYPE,
            payrollFilesConversionStore: null,
            storeConstant: {
                name: STORE_CONSTANT_NAMES.PAYROLL_SYSTEM_CONVERSION.SINGLE,
                template: PayrollFilesConversion,
            },
            tab: 0,
            tabsTitles: [],
        };
    },
    computed: {
        disabledSubmitBtn() {
            return this.isDataLoading || !this.formHasChangedValues.hasChanged || !this.isAllFormStepsValid;
        },
    },
    async beforeMount() {
        this.payrollFilesConversionStore = new StoreExtender(this, this.storeConstant.name, this.storeConstant.template, { extraParams: { clientGuid: this.$route.params.clientGuid, groupGuid: this.$route.params.groupGuid } });
        this.isDataLoading = true;
        await this.getMappingData();
        this.tabsTitles = this.getTabsTitles();
        this.setTab();
        this.isDataLoading = false;
    },
    methods: {
        async callbackForm(response) {
            this.formLoading = false;
            this.formData.isFormSaved = true;
            await this.getMappingData();
            EventBus.$emit(EVENTS.REFRESH);
        },
        cancel(showConfirm) {
            this.toggleConfirmCloseSave(showConfirm);
            if (!showConfirm) {
                this.emitClose();
            }
        },
        getExistingBenefitMapping,
        async getIntegrationDataStructure() {
            this.formData.previousFormDataContent = cloneDeep(this.formData.content);
            await this.payrollFilesConversionStore.sendActions('getIntegrationDataStructure', { data: this.formData.content.recipients });
            this.setFormData(this.payrollFilesConversionStore.getGetter('getIntegrationDataStructure'));
            this.tabsTitles = this.getTabsTitles();
        },
        async getMappingData() {
            await this.payrollFilesConversionStore.sendActions('get', { query: { culture: LANGUAGE_CODES.getCodeByLanguage(this.$i18n.locale) } });
            this.$set(this.formData, 'content', this.payrollFilesConversionStore.getGetter('get'));
            this.formData.previousFormDataContent = cloneDeep(this.formData.content);
            this.$set(this.initialFormData, 'content', this.payrollFilesConversionStore.getGetter('get'));
        },
        getTabsTitles() {
            const result = [];
            for (let i = 0; i < 4; i += 1) {
                const item = {
                    component: STabHeader,
                    props: {
                        hasWarning: !isEmpty(this.formData.content.isMissingMapping) ? this.formData.content.isMissingMapping[i] : false,
                        title: this.$t(`dialog.header${i}`),
                    },
                };
                result.push(item);
            }
            return result;
        },
        async handleRecipientsUpdate(data) {
            this.isMappingTabsEnabled = await this.validateStep(this.tab);
            if (data.value) {
                await handleRecipientsWarnings.call(this);
            }
        },
        async handleSendData() {
            let hasWarnings = false;
            if (this.tab === PAYROLL_FILE_CONVERSION_STEPPER_TYPE.RECIPIENTS_MAPPING) {
                this.formLoading = true;
                await this.getIntegrationDataStructure();
                hasWarnings = hasNewIntegration(this.initialFormData.content.recipients, this.formData.content.recipients);
                this.formLoading = false;
            }
            if (!hasWarnings) {
                this.sendData();
            }
        },
        handleWarnings,
        resetFormData() {
            const defaultData = {
                clientId: this.$route.params.clientGuid,
                groupId: this.$route.params.groupGuid,
                previousFormDataContent: {},
                content: {
                    groupBenefits: [{
                            isDisabled: false, mappings: [{ mappingCode: null }],
                        }],
                    participants: [{ mappingCode: null }],
                    recipients: [{ integrationType: 0, mappingCode: null }],
                    taxableBenefits: [{ mappings: [{ mappingCode: null }] }],
                },
            };
            this.defaultData = { ...defaultData };
        },
        setFormData(data) {
            if (!this?.formData?.previousFormDataContent?.groupBenefits?.length) {
                this.$set(this.formData, 'content', data);
                return;
            }
            const newData = cloneDeep(data);
            newData.participants = cloneDeep(this.formData.previousFormDataContent.participants);
            newData.groupBenefits = this.getExistingBenefitMapping(newData, 'groupBenefits', this.formData.previousFormDataContent);
            newData.taxableBenefits = this.getExistingBenefitMapping(newData, 'taxableBenefits', this.formData.previousFormDataContent);
            this.$set(this.formData, 'content', newData);
        },
        setTab() {
            const isInitialConfiguration = this.payrollFilesConversionStore.getGetter('isInitialConfiguration');
            this.tab = isInitialConfiguration ? PAYROLL_FILE_CONVERSION_STEPPER_TYPE.CERTIFICATES_MAPPING : PAYROLL_FILE_CONVERSION_STEPPER_TYPE.RECIPIENTS_MAPPING;
            this.payrollFilesConversionStore.sendActions('updateIsInitialConfiguration', { data: { value: false } });
        },
        async tabChangeHandler() {
            if (this.tab === PAYROLL_FILE_CONVERSION_STEPPER_TYPE.RECIPIENTS_MAPPING && !isEqual(this.formData.content.recipients, this.formData?.previousFormDataContent?.recipients)) {
                this.isDataLoading = true;
                await this.getIntegrationDataStructure();
                this.isDataLoading = false;
            }
        },
        tabsItems() {
            const items = [];
            for (let i = 0; i < 4; i += 1) {
                const item = {
                    component: getMappingStepsComponents(i),
                    props: {
                        showDialogAddParticipantForm: this.showDialogAddParticipantForm,
                        overwriteData: this.overwriteData,
                        defaultSectionFormData: { ...this.defaultData },
                        formData: this.formData,
                        initialFormData: this.initialFormData,
                        canShowMappingCodeWarning: true,
                    },
                };
                items.push(item);
            }
            return items;
        },
        async updateForm(key, value) {
            this.$set(this.formData.content, key, isNotNullOrUndefined(value) ? value : null);
            await this.$nextTick();
            await this.handleWarnings();
        },
        async validateAllSteps() {
            const validation = [];
            for (let i = 0; i < this.tabsItems().length; i += 1) {
                // eslint-disable-next-line no-await-in-loop
                validation[i] = await this.validateStep(i);
            }
            return validation.every((isValid) => isValid);
        },
        async validateStep(step) {
            const action = get(this.$refs, `tab_${step}.0.validateForm`) || get(this.$refs, `tab_${step}.1.validateForm`);
            if (typeof action === 'function') {
                return action(true);
            }
            return true;
        },
    },
    i18n,
};

import english from 'vee-validate/dist/locale/en.json';
english.messages.required = 'Required';
english.messages.email = 'Invalid format';
const validation = english.messages;
// custom Validations
validation.date = 'The date format must be YYYY-MM-DD.';
validation.postalCode = 'Format must be A1B 2C3.';
validation.phone = 'Invalid format.';
validation.money = 'Invalid format.';
validation.isSame = 'Must be different from {otherValue}.';
validation.isDateBeforeEffectiveDate = 'The date entered is before the plan member\'s effective date.';
validation.isDateBeforeMax = 'The date must be before {date}.';
validation.isDateAfter = 'The date must be after {date}.';
validation.isDateNotInFuture = 'The date cannot be in the future.';
validation.spouseBirthDateByAge = 'The spouse must be at least {age} years old.';
validation.maxAge = 'The date of birth cannot indicate an age of more than {age} years.';
validation.participantBirthDateByAge = 'The plan member must be at least {age} years old.';
validation.Sin = 'Enter a valid social insurance number.';
validation.fileMaxSize = 'The attached file exceeds the maximum size limit allowed. Select a file that is smaller than {maxFileSize}MB.';
validation.filesMaxSize = 'The attached files exceed the maximum size limit allowed. Select files with a total size smaller than {maxFileSize}MB.';
validation.maxMonthsInFuture = 'You can\'t go more than {months} months in the future.';
validation.anyFieldInteger = 'The field must be an integer.';
validation.anyFieldMax = 'The field cannot contain more than {length} characters.';
validation.anyFieldMin = 'The field must contain at least {length} characters.';
validation.unique = 'This field value must be unique.';
export default validation;

import StoreBasics from '@/store/basics';
import storeUtils from '@/store/utils';
import { cloneDeep, find, orderBy } from 'lodash';
import STATUS from 'segic_shared_front_end_utils/src/enums/status';
import entities from './entities';
import Service from './service';
const cacheDelay = 0;
const storeData = [
    {
        key: 'data',
        defaultValue: [],
        methods: [
            {
                key: 'get',
                params: ['clientGuid', 'groupGuid', 'data'],
                cacheDelay,
                inputFormatting(state, stateKey, data) {
                    state[stateKey].value = data.map((value) => entities.input(value));
                },
                displayFormatting(data) {
                    const newData = data.map((item) => entities.display(item));
                    return orderBy(newData, ['CodeDescription']);
                },
            },
        ],
        service: Service,
    },
    {
        key: 'dataHistory',
        defaultValue: [],
        methods: [
            {
                key: 'getHistory',
                params: ['clientGuid', 'groupGuid', 'divisionGuid'],
                cacheDelay,
                inputFormatting(state, stateKey, data) {
                    const newData = [];
                    data.Statuses.forEach((status) => {
                        if (!status.IsRecordCanceled) {
                            newData.push(entities.inputHistory(status));
                        }
                    });
                    state[stateKey].value = newData;
                },
            },
        ],
        service: Service,
    },
];
const state = storeUtils.mergeStates(StoreBasics.statesFromData(storeData));
const getters = storeUtils.mergeGetters(StoreBasics.gettersFromData(storeData), {
    // eslint-disable-next-line no-shadow
    getById(state) {
        return (DivisionId) => {
            const division = find(state.data.value, {
                DivisionId,
            });
            return entities.display(cloneDeep(division));
        };
    },
    // eslint-disable-next-line no-shadow
    getHistory(state) {
        return (locale) => {
            const history = [];
            state.dataHistory.value.forEach((item) => {
                history.push({
                    EffectiveDate: item.EffectiveDate,
                    Status: STATUS.getValuePerCode(item.Status, locale).Value,
                });
            });
            return history;
        };
    },
});
const mutations = storeUtils.mergeMutations(StoreBasics.mutationsFromData(storeData));
const actions = storeUtils.mergeActions(StoreBasics.actionsFromData(storeData));
const GroupsDivisionsStore = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default GroupsDivisionsStore;

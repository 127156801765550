import SCellError from '@/page-layouts/administrator/onboarding/invite/dialog/component/send-invitation-cells/error.vue';
import { getMappingErrorValuesPerCode } from './certificate-numbers-bulk-mapping-error-codes';
export default {
    name: 'SCellNotes',
    components: { SCellError },
    props: {
        errors: {
            required: true,
            type: Array,
        },
    },
    methods: {
        getMappingErrorValuesPerCode,
    },
};

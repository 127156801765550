const BCP_LANGUAGE_CODES = {
    ar: 'ar-SA',
    bn: 'bn-BD',
    // bn: 'bn-IN',
    cs: 'cs-CZ',
    da: 'da-DK',
    // de: 'de-AT',
    // de: 'de-CH',
    de: 'de-DE',
    el: 'el-GR',
    // en: 'en-AU',
    en: 'en-CA',
    // en: 'en-GB',
    // en: 'en-IE',
    // en: 'en-IN',
    // en: 'en-NZ',
    // en: 'en-US',
    // en: 'en-ZA',
    // es: 'es-AR',
    // es: 'es-CL',
    // es: 'es-CO',
    es: 'es-ES',
    // es: 'es-MX',
    // es: 'es-US',
    fi: 'fi-FI',
    // fr: 'fr-BE',
    fr: 'fr-CA',
    // fr: 'fr-CH',
    // fr: 'fr-FR',
    he: 'he-IL',
    hi: 'hi-IN',
    hu: 'hu-HU',
    id: 'id-ID',
    // it: 'it-CH',
    it: 'it-IT',
    ja: 'ja-JP',
    ko: 'ko-KR',
    nl: 'nl-BE',
    // nl: 'nl-NL',
    no: 'no-NO',
    pl: 'pl-PL',
    pt: 'pt-BR',
    // pt: 'pt-PT',
    ro: 'ro-RO',
    ru: 'ru-RU',
    sk: 'sk-SK',
    sv: 'sv-SE',
    ta: 'ta-IN',
    // ta: 'ta-LK',
    th: 'th-TH',
    tr: 'tr-TR',
    zh: 'zh-CN',
    // zh: 'zh-HK',
    // zh: 'zh-TW',
};
export default BCP_LANGUAGE_CODES;

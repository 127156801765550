import storeMixin from '@/mixins/page-layout/store';
import SPageTitle from '@/components/ui/page-title.vue';
import SegicLayoutComponent from '@/mixins/segic-layout-component';
export default {
    name: 'SearchInvoices',
    components: {
        SPageTitle,
    },
    mixins: [
        SegicLayoutComponent,
        storeMixin,
    ],
    data() {
        return {
            participantsStore: null,
            outlined: true,
            clearable: true,
            formData: {
                InvoicePeriod: '',
                DivisionId: '',
                groupIds: [],
            },
            isSegicForm: true,
            widthDate: '175px',
        };
    },
};

import SButtonSubmit from '@/components/ui/btn-submit.vue';
export default {
    components: {
        SButtonSubmit,
    },
    props: {
        allStepsValid: {
            type: Boolean,
            default: false,
        },
        cancel: {
            type: Function,
            required: true,
        },
        forceDisabledSubmit: {
            type: Boolean,
            default: false,
        },
        handleSubmit: {
            type: Object,
            required: true,
        },
        hasReachLastStep: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            required: true,
        },
        model: {
            type: Number,
            default: 1,
        },
        nameE2E: {
            type: String,
            default: 's-stepper',
        },
        setModel: {
            type: Function,
            required: true,
        },
    },
    computed: {
        disabledSubmitBtn() {
            return !this.allStepsValid || this.forceDisabledSubmit;
        },
    },
};
